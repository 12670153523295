import { FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import ControlledInput from '@quanterix-ui/core/ControlledInput';

import { useStyles } from './styles';
import {
  FIELD_NAME,
  TITLE_MAX_LENGTH,
  TITLE_PATTERN,
  TITLE_RESTRICTED_SYMBOLS,
} from './constants';

interface Props {
  isEditable?: boolean;
}

const TitleTextField: FC<Props> = ({ isEditable = true }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[FIELD_NAME];

  return (
    <MuiBox>
      <ControlledInput
        name={FIELD_NAME}
        className={classes.inputRoot}
        disabled={!isEditable}
        placeholder={formatMessage({
          id: 'page.content_management.input.placeholder.title',
        })}
        rules={{
          required: 'page.content_management.input.validation.title.required',
          maxLength: {
            value: TITLE_MAX_LENGTH,
            message:
              'page.content_management.input.validation.title.max_length',
          },
          pattern: {
            value: TITLE_PATTERN,
            message: 'page.content_management.input.validation.title.not_valid',
          },
        }}
      />
      <MuiBox className={classes.formErrorsContainer}>
        {error && (
          <MuiFormHelperText error>
            <FormattedMessage
              id={error.message}
              values={{
                symbols: TITLE_RESTRICTED_SYMBOLS.join(' '),
                maxLength: TITLE_MAX_LENGTH,
              }}
            />
          </MuiFormHelperText>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default TitleTextField;

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  tabsSection: {
    paddingTop: 36,
    paddingBottom: 59,
    color: theme.palette.secondary.main,

    '& a': {
      padding: '0 5px',
      marginLeft: 30,
      textDecoration: 'none',
      fontFamily: theme.fonts.fontFamily.bold,
      fontSize: 16,
    },
  },

  title: {
    fontFamily: theme.fonts.fontFamily.bold,
    fontSize: 28,
    marginTop: 3,
    lineHeight: '52px',
  },

  linkActive: {
    borderBottom: `solid 2px ${theme.palette.primary.main}`,
  },

  status: {
    fontSize: 21,
    fontFamily: theme.fonts.fontFamily.bold,

    '&.not-confirmed': {
      color: theme.palette.warning.main,
    },

    '&.confirmed': {
      color: theme.palette.primary.main,
    },
  },

  actionButton: {
    width: 52,
    height: 52,
  },
}));

import { privateGateway } from '../../../config';
import {
  ContentHubObjectSchema,
  EditableContentHubPageVariables,
} from '../typings';

export const createContentHubRootPage = async (
  data: EditableContentHubPageVariables
): Promise<ContentHubObjectSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post('/pages', data);

  return response.data;
};

import { FC, MouseEvent, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiPaper from '@material-ui/core/Paper';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTypography from '@material-ui/core/Typography';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiIconDelete from '@material-ui/icons/Close';
import MuiIconExpandMore from '@material-ui/icons/ChevronRight';

import {
  FAQSectionItemSchema,
  useDeleteFAQSectionItem,
} from 'src/api/endpoints/faq';
import IconButton from 'src/components/elements/IconButton';
import { Markup } from 'src/components/RichTextEditor';

import { useDialogContext } from '../../context/Dialog';
import SectionItemEditButton from '../SectionItemEditButton';

import { useStyles } from './styles';

interface Props {
  item: FAQSectionItemSchema;
}

const SectionItem: FC<Props> = ({ item }) => {
  const classes = useStyles();

  const { id, question, answer, section_id } = item;

  const location = useLocation();

  const { formatMessage } = useIntl();

  const { isOpen } = useDialogContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { mutate: deleteFAQSectionItem, isLoading: isDeleting } =
    useDeleteFAQSectionItem();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleExpand = () => {
    if (isOpen) return;

    setIsExpanded(!isExpanded);
  };

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    deleteFAQSectionItem({ id, section_id });
  };

  useEffect(() => {
    if (!isOpen) {
      setIsHovered(false);
    }
  }, [isOpen]);

  const isFAQManagementPage = location.pathname.startsWith('/admin/');

  const isActionsLoading = isDeleting;
  const isActionsVisible =
    isFAQManagementPage && (isHovered || isActionsLoading || isOpen);

  return (
    <MuiPaper
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MuiAccordion
        classes={{
          expanded: classes.accordionExpanded,
        }}
        expanded={isExpanded}
        onChange={handleExpand}
      >
        <MuiAccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
          }}
        >
          <MuiGrid container>
            <MuiGrid item xs={12}>
              <MuiGrid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <MuiBox className={cx(classes.expandIcon, 'expandIcon')}>
                  <MuiIconExpandMore color="secondary" />
                </MuiBox>
                <MuiTypography
                  className={classes.title}
                  variant="h6"
                  color="secondary"
                >
                  {question}
                </MuiTypography>
                {isActionsVisible && (
                  <MuiGrid item>
                    <MuiGrid container wrap="nowrap">
                      <MuiBox ml={1}>
                        <MuiTooltip
                          title={formatMessage({ id: 'app.button.edit' })}
                          disableHoverListener={isOpen}
                        >
                          <div>
                            <SectionItemEditButton item={item} />
                          </div>
                        </MuiTooltip>
                      </MuiBox>
                      <MuiBox ml={1}>
                        <MuiTooltip
                          title={formatMessage({ id: 'app.button.delete' })}
                          disableHoverListener={isOpen}
                        >
                          <div>
                            <IconButton
                              size="small"
                              loading={isDeleting}
                              disabled={isDeleting}
                              onClick={handleDelete}
                            >
                              <MuiIconDelete color="secondary" />
                            </IconButton>
                          </div>
                        </MuiTooltip>
                      </MuiBox>
                    </MuiGrid>
                  </MuiGrid>
                )}
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiAccordionSummary>
        <MuiAccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <MuiTypography component="div">
            <Markup value={JSON.parse(answer)} />
          </MuiTypography>
        </MuiAccordionDetails>
      </MuiAccordion>
    </MuiPaper>
  );
};

export default SectionItem;

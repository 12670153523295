import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiShareIcon from '@material-ui/icons/Share';

import Button from '@quanterix-ui/core/Button';

import { ContentHubObjectSchema } from 'src/api/endpoints/contentHub';
import QRCodeGenerator from 'src/components/QRCodeGenerator';
import { PUBLIC_ROUTES_MAP } from 'src/router';

import { useStyles } from './styles';
import { HOST, TEST_ID } from './constants';

interface Props {
  page?: ContentHubObjectSchema;
}

const ShareButton: FC<Props> = ({ page }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!page || !HOST || HOST.length === 0) {
    return null;
  }

  const publicUrl = `${HOST}${PUBLIC_ROUTES_MAP.contentHubViewerPublic.replace(
    ':pageSlug',
    String(page.slug)
  )}`;

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        startIcon={<MuiShareIcon fontSize="small" />}
        className={classes.button}
        data-testid={TEST_ID.shareButton}
        onClick={handleOpen}
      >
        <MuiBox mt={0.5}>
          <FormattedMessage id="app.button.share" />
        </MuiBox>
      </Button>
      <QRCodeGenerator
        isOpen={isOpen}
        value={publicUrl}
        onClose={handleClose}
      />
    </>
  );
};

export default ShareButton;

import { useCallback, useEffect, useState } from 'react';

import { useCognito } from 'src/aws/Cognito';
import { useNotFoundContext } from 'src/context/NotFoundContext';
import { useSnackbar } from 'src/utils/hooks';

import { USER_POOL_ID } from '../constants';
import { UserSchema } from '../typings';

import { useIdentityService } from './useIdentityService';

export interface GetUserOptions {
  username: string;
}

interface UseGetUserResult {
  isLoading: boolean;
  user: UserSchema;
  refetchUser: () => void;
}

export const useGetUser = ({ username }: GetUserOptions): UseGetUserResult => {
  const { identityService } = useIdentityService();
  const { isAdmin } = useCognito();
  const { enqueueErrorSnackbar } = useSnackbar();
  const { setPageExists } = useNotFoundContext();

  const [user, setUser] = useState<UserSchema>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = useCallback(() => {
    if (identityService && isAdmin) {
      setIsLoading(true);

      identityService.adminGetUser(
        {
          UserPoolId: USER_POOL_ID as string,
          Username: username,
        },
        (error, data) => {
          if (error) {
            if (error.name === 'UserNotFoundException') {
              setPageExists(false);
            } else {
              enqueueErrorSnackbar({ message: error.message });
              throw new Error(error.message);
            }
          }

          setUser(data);
          setIsLoading(false);
        }
      );
    } else {
      setUser(null);
    }
  }, [username, identityService, isAdmin, enqueueErrorSnackbar, setPageExists]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return { isLoading, user, refetchUser: fetchUser };
};

import { FC, MouseEvent } from 'react';

import { createPortal } from 'react-dom';

import MuiBox from '@material-ui/core/Box';
import MuiIconEdit from '@material-ui/icons/EditOutlined';

import IconButton from 'src/components/elements/IconButton';
import { FAQSectionSchema, useUpdateFAQSection } from 'src/api/endpoints/faq';

import { useDialogContext } from '../../context/Dialog';
import SectionDialog, { FormValuesSchema } from '../SectionDialog';

interface Props {
  section: FAQSectionSchema;
}

const SectionEditButton: FC<Props> = ({ section }) => {
  const { isOpen, setIsOpen } = useDialogContext();

  const { mutateAsync: updateFAQSection, isLoading } = useUpdateFAQSection();

  const handleDialogOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSectionUpdate = async ({ title }: FormValuesSchema) => {
    await updateFAQSection({ id: section.id, name: title });
  };

  return (
    <MuiBox>
      <IconButton color="secondary" size="small" onClick={handleDialogOpen}>
        <MuiIconEdit />
      </IconButton>
      <div>
        {createPortal(
          <SectionDialog
            open={isOpen}
            loading={isLoading}
            section={section}
            titleTranslationId="page.faq_management.dialog.title.section.update"
            submitButtonTranslationId="app.button.update"
            onClose={handleDialogClose}
            onSubmit={handleSectionUpdate}
          />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </MuiBox>
  );
};

export default SectionEditButton;

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import AWS from 'aws-sdk';

import 'overlayscrollbars/css/OverlayScrollbars.css';

import 'src/aws/AwsAmplifyConfig';

import App from './App';
import './index.css';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

AWS.config.correctClockSkew = true;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    initialScope: {
      tags: { component: 'customer-platform' },
    },
    tracesSampleRate: 0.2,
    environment: process.env.REACT_APP_STAGE_NAME,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

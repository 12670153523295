import { privateGateway } from '../../../config';
import { UserRequestSchema } from '../typings';

export const fetchUserRequest = async (
  requestId: string
): Promise<UserRequestSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get(`/user_requests/${requestId}`);

  return response.data;
};

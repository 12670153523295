import { FC, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormLabel from '@material-ui/core/FormLabel';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step5: FC<AcceleratorFormStepComponentProps> = ({
  inProgress,
  formData,
  isMalwareScanInProgress,
  hasFilesWithMalware,
  onNext,
  onPrevious,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const { handleSubmit, watch, getValues, reset } = useForm({
    defaultValues: { ...formData },
  });

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const isSubmitDisabled = useMemo(() => {
    return (
      formData.sampleReagentsManifests.length === 0 ||
      isMalwareScanInProgress ||
      hasFilesWithMalware
    );
  }, [
    formData.sampleReagentsManifests,
    isMalwareScanInProgress,
    hasFilesWithMalware,
  ]);

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <FormLabel component="legend">
          <FormattedMessage id="page.accelerator_form.step5.address.note" />
        </FormLabel>
        <br />
        <h2>
          QUANTERIX <br />
          CRO &amp; LABORATORY SERVICES <br />
          900 MIDDLESEX TPKE, BUILDING 1 <br />
          BILLERICA, MA 01821
        </h2>
      </div>
      <StepperNavigation
        onFormCancelOpen={onFormCancelOpen}
        onFormCancel={onFormCancel}
      >
        <StepperNavButton direction="previous" onClick={handleOnPrevious} />
        <StepperNavButton
          direction="submit"
          loading={inProgress}
          disabled={isSubmitDisabled}
        />
      </StepperNavigation>
    </form>
  );
};

export default Step5;

import { privateGateway } from '../../../config';
import { ToggleBookmarkVariables } from '../typings';

export const addToBookmarks = async ({
  id,
}: ToggleBookmarkVariables): Promise<void> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post('/bookmarks', { file_id: id });

  return response.data;
};

import { FC, useEffect } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledInput from '@quanterix-ui/core/ControlledInput';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

import {
  validateIsNumber,
  validateMaxLength,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import {
  AcceleratorFormStepComponentProps,
  SampleTypesFull,
} from '../../typings';
import { useStyles } from '../../styles';
import CheckboxList from '../CheckboxList';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step4: FC<AcceleratorFormStepComponentProps> = ({
  onNext,
  onPrevious,
  formData,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: { ...formData },
  });

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = formMethods;

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  const handleChangeSampleTypes = (value: SampleTypesFull) => {
    const array = value.items.map((i) => (i !== 'Other' ? i : value.other));

    setValue('sampleTypes', array.join(', '));
    trigger('sampleTypes');
    setValue('sampleTypesFull', value);
    trigger('sampleTypesFull');
  };

  const validateSampleTypes = (value: SampleTypesFull) => {
    if (!value || !value.items || value.items.length === 0) {
      return false;
    }

    const hasOther = value.items.includes('Other');

    if (hasOther) {
      const { pattern } = validateSpecialCharacters2;
      const { maxLength } = validateMaxLength(40);

      if ((value.other || '').length > 40) {
        return maxLength.message;
      }

      if (
        value.other &&
        value.other.length > 0 &&
        !pattern.value.test(value.other || '')
      ) {
        return pattern.message;
      }

      if (!value.other) {
        return false;
      }
    }

    return true;
  };

  const sampleTypesFull = watch('sampleTypesFull');
  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  return (
    <FormProvider {...formMethods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.formControl}>
          <FormLabel
            required
            component="legend"
            error={!!errors.sampleTypesFull}
          >
            <FormattedMessage id="page.accelerator_form.step4.field.sample_types.label" />
          </FormLabel>
          <Controller
            name="sampleTypesFull"
            control={control}
            rules={{
              required: true,
              ...validateSpecialCharacters2,
              validate: {
                validateSampleTypes,
              },
            }}
            render={() => (
              <CheckboxList
                value={sampleTypesFull}
                items={[
                  { name: 'Plasma', value: 'Plasma' },
                  { name: 'Serum', value: 'Serum' },
                  { name: 'CSF', value: 'CSF' },
                  { name: 'Other', value: 'Other' },
                ]}
                onChangeValue={handleChangeSampleTypes}
              />
            )}
          />
          <ControlledInput
            name="sampleTypes"
            type="hidden"
            control={control}
            style={{ display: 'none' }}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step4.field.species_origin.label" />
          </FormLabel>
          <FormHelperText>
            <FormattedMessage id="page.accelerator_form.step4.field.species_origin.help_text" />
          </FormHelperText>
          <ControlledTextField
            fullWidth
            name="speciesOrigin"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.speciesOrigin?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step4.field.total_sample_number.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="totalSampleNumber"
            control={control}
            rules={{
              required: true,
              validate: {
                validateWhitespaces,
                validateIsNumber,
              },
            }}
            helperText={errors.totalSampleNumber?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step4.field.sample_type_volumes.label" />
          </FormLabel>
          <FormHelperText>
            <FormattedMessage id="page.accelerator_form.step4.field.sample_type_volumes.help_text" />
          </FormHelperText>
          <ControlledTextField
            fullWidth
            multiline
            name="sampleTypeVolumes"
            minRows={5}
            maxRows={5}
            control={control}
            rules={{
              required: true,
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.sampleTypeVolumes?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step4.field.assay_details.label" />
          </FormLabel>
          <FormHelperText>
            <FormattedMessage id="page.accelerator_form.step4.field.assay_details.help_text" />
          </FormHelperText>
          <ControlledTextField
            fullWidth
            name="sampleTestingInformation"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(2000),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.sampleTestingInformation?.message}
          />
        </div>
        <StepperNavigation
          onFormCancelOpen={onFormCancelOpen}
          onFormCancel={onFormCancel}
        >
          <StepperNavButton direction="previous" onClick={handleOnPrevious} />
          <StepperNavButton direction="next" />
        </StepperNavigation>
      </form>
    </FormProvider>
  );
};

export default Step4;

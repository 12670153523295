/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';

import 'systemjs/dist/system.min';
import 'systemjs/dist/extras/amd.min';

(System as any).shouldFetch = () => true;

const fetchHook = System.constructor.prototype.fetch;
System.constructor.prototype.fetch = async function (args: any) {
  const currentSession = await Auth.currentSession();

  const idToken = currentSession.getIdToken().getJwtToken();
  const accessToken = currentSession.getAccessToken().getJwtToken();

  return Promise.resolve(
    fetchHook.call(this, args, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'ID-Token': `Bearer ${idToken}`,
      },
    })
  );
};

export async function importRemoteScript<T extends System.Module>(url: string) {
  // eslint-disable-next-line no-restricted-properties
  return System.import<T>(url);
}

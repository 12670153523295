import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 16,
    fontFamily: theme.fonts.fontFamily.medium,
    color: theme.palette.text.primary,
  },
}));

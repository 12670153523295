export const blockquoteStyle = {
  margin: 0,
  marginTop: 5,
  paddingLeft: 20,
  paddingTop: 5,
  paddingBottom: 5,
  borderLeft: `3px solid #eeeeee`,
};

export const listStyle = {
  marginTop: 10,
  marginBottom: 5,
  paddingLeft: 35,
};

export const paragraphStyle = {
  marginTop: 10,
  marginBottom: 5,
};

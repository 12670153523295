import { FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import ControlledInput from '@quanterix-ui/core/ControlledInput';

import { useStyles } from './styles';
import {
  FIELD_NAME,
  MAX_LENGTH,
  PATTERN,
  RESTRICTED_SYMBOLS,
} from './constants';

interface Props {
  disabled: boolean;
}

const QuestionTextField: FC<Props> = ({ disabled }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[FIELD_NAME];

  return (
    <MuiBox>
      <ControlledInput
        name={FIELD_NAME}
        className={classes.inputRoot}
        disabled={disabled}
        placeholder={formatMessage({
          id: 'page.faq_management.input.question.placeholder',
        })}
        rules={{
          required: 'page.faq_management.input.question.validation.required',
          maxLength: {
            value: MAX_LENGTH,
            message: 'page.faq_management.input.question.validation.max_length',
          },
          pattern: {
            value: PATTERN,
            message: 'page.faq_management.input.question.validation.invalid',
          },
        }}
      />
      <MuiBox className={classes.formErrorsContainer}>
        {error && (
          <MuiFormHelperText error>
            <FormattedMessage
              id={error.message}
              values={{
                symbols: RESTRICTED_SYMBOLS.join(' '),
                maxLength: MAX_LENGTH,
              }}
            />
          </MuiFormHelperText>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default QuestionTextField;

import { OptionsObject } from 'notistack';

export enum APP_PORTALS {
  root = 'root-portal',
  modal = 'modal-portal',
}

const CONTENT_LG_HORIZONTAL_SPACING = 80;
const CONTENT_SM_HORIZONTAL_SPACING = 30;

export const LAYOUT_DIMENSIONS = {
  header: {
    height: {
      desktop: 75,
      mobile: 75,
    },

    spacing: {
      horizontal: {
        desktop: CONTENT_LG_HORIZONTAL_SPACING,
        mobile: CONTENT_SM_HORIZONTAL_SPACING,
      },
    },
  },

  content: {
    spacing: {
      horizontal: {
        desktop: CONTENT_LG_HORIZONTAL_SPACING,
        mobile: CONTENT_SM_HORIZONTAL_SPACING,
      },

      vertical: {
        desktop: 90,
        mobile: 40,
      },
    },
  },

  footer: {
    height: {
      desktop: 166,
      mobile: 166,
    },

    spacing: {
      horizontal: {
        desktop: CONTENT_LG_HORIZONTAL_SPACING,
        mobile: CONTENT_SM_HORIZONTAL_SPACING,
      },
    },
  },
};

export const SNACKBAR = {
  defaultOptions: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  } as Partial<OptionsObject>,
  resumeHideDuration: {
    success: 800,
    error: 2400,
  },
};

export const COMMON_ERRORS = {
  invalidSignature:
    'For security reasons, your portal accessibility is limited due to a discrepancy between the time on your computer and the local time zone. Please set your computer to automatically adjust to the local time zone and refresh the page.',
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1300,
  },

  noDataPlaceholderCell: {
    paddingTop: 30,
    paddingBottom: 30,
    borderColor: 'transparent',
  },

  tableLoadingIndicator: {
    position: 'absolute',
    width: '100%',
    height: 4,
  },

  tableLoadingIndicatorCell: {
    padding: 0,
    border: 'none',
  },

  isTableLoading: {
    pointerEvents: 'none',
    opacity: 0.2,
  },

  scrollbars: {
    width: '100%',
    height: '100%',
  },
}));

import { FC, MouseEvent, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';
import ReactGA from 'react-ga';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import { useTheme } from '@material-ui/core';
import MuiPaper from '@material-ui/core/Paper';
import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiBookmarkIcon from '@material-ui/icons/Bookmark';
import MuiBookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import MuiModal from '@material-ui/core/Modal';
import MuiBackdrop from '@material-ui/core/Backdrop';
import MuiFade from '@material-ui/core/Fade';

import {
  FileSearchItemSchema,
  RequestSearchContentHubFilesVariables,
} from 'src/api/endpoints/contentHub';
import {
  BookmarksFilters,
  useToggleBookmark,
} from 'src/api/endpoints/bookmarks';
import { getFileTypeIcon, isVideo } from 'src/utils/helpers/file';
import IconButton from 'src/components/elements/IconButton';
import VideoPlayer from 'src/components/VideoPlayer';

import VideoPreview from './components/VideoPreview';
import { useStyles } from './styles';
import { TEST_ID } from './constants';

interface Props {
  file: FileSearchItemSchema;
  simpleView?: boolean;
  searchParams?: RequestSearchContentHubFilesVariables;
  bookmarksFilter?: BookmarksFilters;
}

const FileCard: FC<Props> = ({
  file,
  simpleView = false,
  searchParams = {} as RequestSearchContentHubFilesVariables,
  bookmarksFilter = {} as BookmarksFilters,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const linkRef = useRef<HTMLAnchorElement>(null);

  const isCurrentFileVideo = isVideo(file.extension);

  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);

  const { mutate: toggleBookmark, isLoading: isToggleBookmarkInProgress } =
    useToggleBookmark({ searchParams, bookmarksFilter });

  const uploadDate = useMemo(
    () => format(new Date(file.created_at), 'dd MMM yyyy, HH:mm'),
    [file.created_at]
  );

  const handleBookmarkClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    toggleBookmark(file);
  };

  const handleVideoPlayerOpen = () => {
    setIsVideoPlayerOpen(true);
  };

  const handleVideoPlayerClose = () => {
    setIsVideoPlayerOpen(false);
  };

  const handleLinkClick = () => {
    if (isCurrentFileVideo) {
      handleVideoPlayerOpen();
    } else {
      linkRef.current?.click();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).ga) {
      ReactGA.event({
        category: 'Content Hub: Category',
        action: file.name,
        label: 'Clicked',
      });
    }
  };

  return (
    <>
      <MuiPaper className={classes.fileCard} data-testid={TEST_ID.fileCard}>
        <MuiButtonBase
          component="div"
          className={cx(classes.fileButton, { isVideo: isCurrentFileVideo })}
          data-testid={TEST_ID.fileCardButton}
          onClick={handleLinkClick}
        >
          {isCurrentFileVideo && (
            <MuiBox className={classes.videoPreview}>
              <VideoPreview
                name={file.name}
                thumbnail={file.metadata_.thumbnail}
                duration={file.metadata_.duration}
              />
            </MuiBox>
          )}
          <MuiGrid container direction="column" alignItems="flex-start">
            <MuiGrid item className={classes.gridRowTop}>
              <MuiGrid container alignItems="center">
                {!isCurrentFileVideo && (
                  <MuiBox display="flex" mr={1} mt={-0.5}>
                    {getFileTypeIcon(file.extension, {
                      color: theme.palette.secondary.main,
                    })}
                  </MuiBox>
                )}
                <MuiLink
                  ref={linkRef}
                  variant="button"
                  color="secondary"
                  href={!isCurrentFileVideo ? file.url : undefined}
                  className={classes.fileName}
                  data-testid={TEST_ID.fileCardLink}
                >
                  {file.name}.{file.extension}
                </MuiLink>
                {!simpleView && (
                  <MuiBox className={classes.fileBookmarkWrapper}>
                    <IconButton
                      className={classes.fileBookmarkButton}
                      loading={isToggleBookmarkInProgress}
                      data-testid={TEST_ID.bookmarkButton}
                      onClick={handleBookmarkClick}
                    >
                      {file.bookmark && (
                        <MuiBookmarkIcon
                          color="primary"
                          data-testid={TEST_ID.bookmarkedTrueIcon}
                        />
                      )}
                      {!file.bookmark && (
                        <MuiBookmarkBorderIcon
                          color="secondary"
                          data-testid={TEST_ID.bookmarkedFalseIcon}
                        />
                      )}
                    </IconButton>
                  </MuiBox>
                )}
              </MuiGrid>
            </MuiGrid>
            <MuiBox display="flex" alignItems="center" pl={0.4}>
              <MuiTypography>{uploadDate}</MuiTypography>
            </MuiBox>
          </MuiGrid>
        </MuiButtonBase>
      </MuiPaper>
      <div>
        {createPortal(
          <MuiModal
            closeAfterTransition
            className={classes.videoPlayerModal}
            open={isVideoPlayerOpen}
            BackdropComponent={MuiBackdrop}
            BackdropProps={{
              timeout: 500,
            }}
            onClose={handleVideoPlayerClose}
          >
            <div>
              {/* MuiFade does not work properly without div (console error) */}
              <MuiFade in={isVideoPlayerOpen}>
                <VideoPlayer name={file.name} url={file.url} />
              </MuiFade>
            </div>
          </MuiModal>,

          /* document.createElement('div') - is for testing purpose */
          document.getElementById('modal-root') || document.createElement('div')
        )}
      </div>
    </>
  );
};

export default FileCard;

import { Editor } from 'slate';

import { FormattedText } from 'src/typings/slate';

export const isMarkActive = (editor: Editor, format: keyof FormattedText) => {
  const marks = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: keyof FormattedText) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

import { FC, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiTypography from '@material-ui/core/Typography';
import MuiButtonBase from '@material-ui/core/ButtonBase';

import Button from '@quanterix-ui/core/Button';

import { useSnackbar } from 'src/utils/hooks';
import UserViewerProfileNavigation from 'src/components/UserViewerProfileNavigation';
import ControlledInstrumentSelect, {
  InstrumentField as FormInstrumentField,
  Instrument,
} from 'src/components/form/ControlledInstrumentSelect';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import InstrumentCard from './components/InstrumentCard';
import { useUserViewerProfileInstrumentsContext } from './context';
import { useStyles } from './styles';
import { InstrumentField } from './typings';

interface Props {
  isLoading: boolean;
  isFetched: boolean;
  onUpdateUserInstruments: (
    instruments: InstrumentField,
    onSuccessCallback: () => void
  ) => Promise<void>;
}

const UserViewerProfileInstruments: FC<Props> = ({
  isLoading,
  isFetched,
  onUpdateUserInstruments,
}) => {
  const classes = useStyles();

  const { enqueueSuccessSnackbar } = useSnackbar();

  const { instruments } = useUserViewerProfileInstrumentsContext();

  const [isInstrumentEditMode, setIsInstrumentEditMode] = useState(false);

  const formMethods = useForm();

  const { reset, handleSubmit } = formMethods;

  const handleActivateInstrumentEditMode = () => {
    setIsInstrumentEditMode(true);
  };

  const handleCloseInstrumentEditMode = () => {
    reset();
    setIsInstrumentEditMode(false);
  };

  const handleEditInstrumentsSubmit: SubmitHandler<
    FieldValues & { instruments?: FormInstrumentField }
  > = async (data) => {
    const handleSuccessCallback = () => {
      setIsInstrumentEditMode(false);

      enqueueSuccessSnackbar({ message: 'page.profile.instruments.snackbar' });
    };

    onUpdateUserInstruments(
      data.instruments || instruments,
      handleSuccessCallback
    );
  };

  useEffect(() => {
    if (instruments) {
      reset({ instruments });
    }
  }, [instruments, reset]);

  return (
    <ProgressOverlay withLoadingDisk loading={!isFetched} opacity={1} size={40}>
      <UserViewerProfileNavigation />
      <MuiGrid container>
        <MuiGrid container item xs={12} lg={9} spacing={5}>
          {Object.entries(instruments)
            .filter(([_, instrument]) => instrument.selected)
            .map(([name, instrument]) => (
              <MuiGrid key={name} item xs={6} md={4}>
                <InstrumentCard
                  name={name as Instrument}
                  instrument={instrument}
                />
              </MuiGrid>
            ))}
          {!isInstrumentEditMode && (
            <MuiGrid item xs={6} md={4}>
              <MuiButtonBase
                className={classes.addInstruments}
                onClick={handleActivateInstrumentEditMode}
              >
                <MuiCard variant="outlined" className={classes.instrumentCard}>
                  <MuiCardContent className={classes.cardContent}>
                    <MuiTypography gutterBottom className={classes.title}>
                      <FormattedMessage id="page.profile.instruments.add_instrument" />
                    </MuiTypography>
                  </MuiCardContent>
                </MuiCard>
              </MuiButtonBase>
            </MuiGrid>
          )}
        </MuiGrid>
      </MuiGrid>
      {isInstrumentEditMode && (
        <MuiBox py={5} width={{ md: '40%' }}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleEditInstrumentsSubmit)}>
              <MuiBox mb={3}>
                <ControlledInstrumentSelect name="instruments" />
              </MuiBox>
              <MuiBox display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  onClick={handleCloseInstrumentEditMode}
                >
                  <FormattedMessage id="app.button.cancel" />
                </Button>
                <Button type="submit" loading={isLoading} disabled={isLoading}>
                  <FormattedMessage id="app.button.submit" />
                </Button>
              </MuiBox>
            </form>
          </FormProvider>
        </MuiBox>
      )}
    </ProgressOverlay>
  );
};

export default UserViewerProfileInstruments;

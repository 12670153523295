import {
  CSSProperties,
  forwardRef,
  MutableRefObject,
  ReactNode,
  useCallback,
} from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface Props {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const VirtuosoCustomScrollbar = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const handleRef = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (scrollbarsRef: any) => {
        if (scrollbarsRef) {
          const element = scrollbarsRef.osInstance().getElements().viewport;
          (ref as MutableRefObject<HTMLDivElement>).current = element;
        }
      },
      [ref]
    );

    return (
      <OverlayScrollbarsComponent
        ref={handleRef}
        options={{
          scrollbars: { autoHide: 'move' },
          sizeAutoCapable: false,
        }}
        {...props}
      />
    );
  }
);

export default VirtuosoCustomScrollbar;

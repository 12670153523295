import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';

export const includeAccessToken = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();
  const idToken = session.getIdToken().getJwtToken();

  config.headers!['Authorization'] = `Bearer ${accessToken}`;
  config.headers!['ID-Token'] = `Bearer ${idToken}`;

  return config;
};

export const transformErrorResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: AxiosError<any, any>
): Promise<AxiosError> => {
  switch (error?.response?.status) {
    case 400: {
      // eslint-disable-next-line no-throw-literal
      throw new AxiosError({
        ...error.response.data,
        message: error.response.data.detail,
      });
    }
    default: {
      if (axios.isCancel(error)) {
        throw error;
      }

      throw new Error('api.response.unknown_error');
    }
  }
};

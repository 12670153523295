import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface ContextResult {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DialogContext = createContext<ContextResult>({
  isOpen: false,
  setIsOpen: () => {
    // void
  },
});

const DialogContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DialogContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = (): ContextResult => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider'
    );
  }

  return context;
};

export default DialogContextProvider;

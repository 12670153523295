import { privateGateway } from '../../../config';
import { FetchFilesCountResult } from '../typings';

export const fetchFilesCount = async (): Promise<FetchFilesCountResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/files_count`);

  return response.data;
};

import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import MuiFormLabel from '@material-ui/core/FormLabel';

import ControlledSelect, {
  ControlledSelectProps,
} from '@quanterix-ui/core/ControlledSelect';
import SelectOption from '@quanterix-ui/core/SelectOption';

import countries from 'src/utils/countries';

import { useStyles as useCommonClasses } from '../styles';

import { useStyles } from './styles';

interface Props extends ControlledSelectProps {
  label?: string;
}

const ControlledCountrySelect: FC<Props> = ({
  id,
  name,
  label,
  required = true,
  ...restProps
}) => {
  const classes = useStyles();
  const commonClasses = useCommonClasses();

  const { formatMessage } = useIntl();

  return (
    <>
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.country" />}
      </MuiFormLabel>
      <ControlledSelect
        fullWidth
        id={id ?? name}
        name={name}
        color="secondary"
        className={classes.select}
        rules={{ required }}
        placeholder={formatMessage({ id: 'app.form.placeholder.country' })}
        {...restProps}
      >
        <SelectOption value="">
          <FormattedMessage id="app.form.placeholder.country" />
        </SelectOption>
        {countries.map((country) => (
          <SelectOption key={country.name} value={country.name}>
            {country.name}
          </SelectOption>
        ))}
      </ControlledSelect>
    </>
  );
};

export default ControlledCountrySelect;

import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import MuiFormLabel from '@material-ui/core/FormLabel';

import ControlledTextField, {
  ControlledTextFieldProps,
} from '@quanterix-ui/core/ControlledTextField';

import { useStyles as useCommonStyles } from '../styles';

import { useValidateZipCode } from './hooks/useValidateZipCode';

interface Props extends ControlledTextFieldProps {
  label?: string;
}

const ControlledZipCodeTextField: FC<Props> = ({
  id,
  name,
  label,
  required = true,
  ...restProps
}) => {
  const commonClasses = useCommonStyles();

  const { formatMessage } = useIntl();

  const validateZipCode = useValidateZipCode();

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.zip_code" />}
      </MuiFormLabel>
      <ControlledTextField
        fullWidth
        id={id ?? name}
        name={name}
        rules={{ required, validate: validateZipCode }}
        placeholder={formatMessage({ id: 'app.form.placeholder.zip_code' })}
        helperText={errors[name]?.message}
        {...restProps}
      />
    </>
  );
};

export default ControlledZipCodeTextField;

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import OverlayScrollbars from 'overlayscrollbars';

export interface ContextResult {
  osInstance: OverlayScrollbars | null;
  setOsInstance: Dispatch<SetStateAction<OverlayScrollbars | null>>;
}

export const OverlayScrollbarsContext = createContext<ContextResult>({
  osInstance: null,
  setOsInstance: () => {
    // void
  },
});

const OverlayScrollbarsContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [osInstance, setOsInstance] = useState<OverlayScrollbars | null>(null);

  return (
    <OverlayScrollbarsContext.Provider value={{ osInstance, setOsInstance }}>
      {children}
    </OverlayScrollbarsContext.Provider>
  );
};

export const useOverlayScrollbarsContext = (): ContextResult => {
  const context = useContext(OverlayScrollbarsContext);

  if (!context) {
    throw new Error(
      'useOverlayScrollbarsContext must be used within a OverlayScrollbarsProvider'
    );
  }

  return context;
};

export default OverlayScrollbarsContextProvider;

import { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import {
  PAGE_SLUG,
  useFetchContentHubPageBySlug,
} from 'src/api/endpoints/contentHub';
import { PRIVATE_ROUTES_MAP } from 'src/router';

import { TEST_ID } from '../../constants';

import { useStyles } from './styles';

const UserGuideLinks: FC = () => {
  const classes = useStyles();

  const { data: customerSupportToolData } = useFetchContentHubPageBySlug(
    PAGE_SLUG.customerSupportTool
  );

  const { data: userGuidesData } = useFetchContentHubPageBySlug(
    PAGE_SLUG.userGuides
  );

  const customerSupportToolLink = useMemo(() => {
    return PRIVATE_ROUTES_MAP.contentHubViewer
      .replace(':pageId', customerSupportToolData?.id.toString() || '')
      .replace(':pageName', customerSupportToolData?.name || '');
  }, [customerSupportToolData]);

  const userGuidesLink = useMemo(() => {
    return PRIVATE_ROUTES_MAP.contentHubViewer
      .replace(':pageId', userGuidesData?.id.toString() || '')
      .replace(':pageName', userGuidesData?.name || '');
  }, [userGuidesData]);

  return (
    <MuiGrid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.userGuideLinks}
      data-testid={TEST_ID.userGuideLinks}
    >
      <MuiBox className={classes.textSection}>
        <MuiBox pb={2}>
          <MuiTypography variant="h2" component="span">
            <FormattedMessage id="page.support_file_upload.user_guide_links.title" />
          </MuiTypography>
          <MuiTypography variant="subtitle2">
            <FormattedMessage id="page.support_file_upload.user_guide_links.sub_header" />
          </MuiTypography>
        </MuiBox>
        <MuiTypography variant="subtitle2">
          <Link className={classes.links} to={customerSupportToolLink}>
            HD-X
          </Link>
        </MuiTypography>
        <MuiTypography variant="subtitle2">
          <Link className={classes.links} to={userGuidesLink}>
            SR-X
          </Link>
        </MuiTypography>
      </MuiBox>
    </MuiGrid>
  );
};

export default UserGuideLinks;

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface SearchFilterState {
  pagesIds: number[];
  searchByTitle: boolean;
  searchByBody: boolean;
}

export type SearchFilterContextResult = {
  searchFilter: SearchFilterState;
  setSearchFilter: Dispatch<SetStateAction<SearchFilterState>>;
};

export interface SearchFilterProviderProps {
  defaultSearchFilter?: SearchFilterState;
}

export const DEFAULT_STATE: SearchFilterState = {
  pagesIds: [],
  searchByTitle: true,
  searchByBody: true,
};

export const SearchFilterContext = createContext<SearchFilterContextResult>({
  searchFilter: DEFAULT_STATE,
  setSearchFilter: () => {
    // void
  },
});

export const useSearchFilterContext = (): SearchFilterContextResult => {
  const context = useContext(SearchFilterContext);

  if (!context) {
    throw new Error(
      'useSearchFilterContext must be used within a SearchFilterProvider'
    );
  }

  return context;
};

const SearchFilterProvider: FC<
  PropsWithChildren<SearchFilterProviderProps>
> = ({ children, defaultSearchFilter = DEFAULT_STATE }) => {
  const [searchFilter, setSearchFilter] = useState(defaultSearchFilter);

  return (
    <SearchFilterContext.Provider value={{ searchFilter, setSearchFilter }}>
      {children}
    </SearchFilterContext.Provider>
  );
};

export default SearchFilterProvider;

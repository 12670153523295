import { FC } from 'react';

import { useStyles } from './styles';

interface QHubLogoProps {
  color?: 'primary' | 'secondary';
  className?: string;
}

const QHubLogo: FC<QHubLogoProps> = ({ color = 'primary', className = '' }) => {
  const classes = useStyles({ color });

  return (
    <svg
      id="QHubLogo"
      data-name="QHubLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 144 48.55"
      className={className}
    >
      <path
        className={classes.qhubPath}
        d="M125.13 22.48c3.44 0 5.91 2.47 5.91 6.57s-2.56 6.66-5.95 6.66c-3.75 0-6.09-3-6.09-6.61s2.34-6.62 6.13-6.62zM51.65 9.69v30.87h6.17V28.39h12.79v12.17h6.18V9.69h-6.18v13H57.82v-13zm62.1-1.41v32.28h4.72l.75-2.91a7.91 7.91 0 0 0 6.93 3.35c6.61 0 10.85-5.64 10.85-11.95s-4.19-11.86-10.54-11.86c-3 0-5.38.83-6.84 2.73V8.28zm-29 9.35v13.76c0 6.13 3 9.61 9 9.61a7.69 7.69 0 0 0 6.53-3l.75 2.6h4.72V17.63h-6.16v13.54c0 2.78-2 4-4.41 4-3 0-4.32-1.41-4.32-4.1V17.63zM27.61 33.22a19 19 0 0 0 1.77-8.33 19.64 19.64 0 0 0-1-6.28l-.14-.39a1.42 1.42 0 0 1-.07-.22 14.79 14.79 0 0 0-3-4.95 13.64 13.64 0 0 0-10.35-4.29h-.26C5.17 8.76 0 16.24 0 24.89 0 34.18 5.47 41 14.51 41h.36a13.9 13.9 0 0 0 9.35-3.29 13.2 13.2 0 0 0 7.14 3.77v-5.87a8.28 8.28 0 0 1-3.75-2.39m-12.75 2.36c-5 0-8.73-4.35-8.73-10.56 0-5.61 3.17-10.78 8.34-10.78 4.22 0 8.69 3.56 8.65 10.65 0 4.64-2.26 10.69-8.26 10.69"
      />
      <path
        className={classes.iconPath}
        d="M23.07 4.67a2.52 2.52 0 1 1 1.2-2 1.89 1.89 0 0 1-.08.53l12.34 9.18a4 4 0 0 1 .75-.44 3.4 3.4 0 0 1 1.23-.29l.75-3.34a2.51 2.51 0 1 1 3.94-2 2.52 2.52 0 0 1-2.1 2.39l-.75 3.3a3.46 3.46 0 0 1 1.55 1.7 3.52 3.52 0 0 1-1.42 4.43l1.89 6.93a4.94 4.94 0 1 1-.75 9.74l-3.36 7.53a3.35 3.35 0 0 1 1 1.3 3.5 3.5 0 1 1-2.7-2.07L39.9 34a5 5 0 0 1-1.69-2 5 5 0 0 1 2.34-6.41l-1.89-6.93a3.5 3.5 0 0 1-3.25-4.77z"
      />
      <path
        className={classes.tmPath}
        d="M139.59 6.27v.66h-1.1v2.9h-.7v-2.9h-1.1v-.66zM141 6.27l1 2.52 1-2.52h1v3.56h-.71V7.36l-.93 2.26h-.63l-.93-2.26v2.47h-.71V6.27z"
      />
    </svg>
  );
};

export default QHubLogo;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

import { getDefaultInstruments } from 'src/utils/helpers/accelerator';
import { jsonParseSafe } from 'src/utils/ParseHelper';
import { MAX_STRING_LENGTH } from 'src/components/UserList';

import { capitalize } from './StringHelper';

export async function prepareCognitoIdentityServiceProvider() {
  const creds = await Auth.currentCredentials();
  const session = await Auth.currentSession();
  const sessionToken = session.getAccessToken();

  return new AWS.CognitoIdentityServiceProvider({
    region: process.env.REACT_APP_IDENTITY_POOL_REGION,
    sessionToken,
    credentials: creds,
  });
}

export function parseUserAttribute(attributes, attribute_name) {
  if (attributes) {
    if (Array.isArray(attributes)) {
      return attributes.find((x) => x.Name === attribute_name)?.Value || '';
    }

    return attributes[attribute_name] || '';
  }

  return '';
}

export const formatFullName = (givenName, familyName) => {
  const fullName = `${givenName} ${familyName}`;

  return fullName.length > MAX_STRING_LENGTH
    ? `${fullName.slice(0, MAX_STRING_LENGTH - 3)}...`
    : fullName;
};

export const formatEmail = (email) => {
  return email.length > MAX_STRING_LENGTH
    ? `${email.slice(0, MAX_STRING_LENGTH - 3)}...`
    : email;
};

export const parseInstruments = (instrumenAttributes, serialAttributes) => {
  const instruments = jsonParseSafe(instrumenAttributes, []);
  const serials = jsonParseSafe(serialAttributes, []);
  const result = getDefaultInstruments();

  instruments.forEach((element, index) => {
    const resultIndex = result.findIndex((x) => x.Instrument === element);

    if (resultIndex >= 0) {
      result[resultIndex].Selected = true;

      if (serials.length > index) {
        result[resultIndex].Serial = serials[index];
      }
    }
  });

  return result;
};

export async function confirmUserByAdmin(username) {
  const cognitoidentityserviceprovider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Username: username /* required */,
    ClientMetadata: {
      sourceEmailAddress: process.env.REACT_APP_SOURCE_EMAIL_ADDRESS,
    },
  };

  const request = cognitoidentityserviceprovider.adminConfirmSignUp(
    params,
    (err, data) => {
      if (err) {
        console.error(err, err.stack);
      }
    }
  );

  updateUserAttributesByAdmin(username, [
    {
      Name: 'email_verified',
      Value: 'true',
    },
  ]);

  return request;
}

export async function updateUserAttributesByAdmin(username, attributes) {
  const cognitoIdentityServiceProvider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Username: username /* required */,
    UserAttributes: attributes.map((x) => {
      return typeof x.Value === 'boolean'
        ? { ...x, Value: x.Value.toString() }
        : x;
    }),
  };

  return cognitoIdentityServiceProvider.adminUpdateUserAttributes(
    params,
    (err, data) => {
      if (err) {
        console.error(err, err.stack);
      }
    }
  );
}

export async function createUserByAdmin(username, attributes) {
  const cognitoIdentityServiceProvider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Username: username /* required */,
    UserAttributes: attributes
      .filter((x) => x.Name !== 'phone_number')
      .map((x) => {
        return typeof x.Value === 'boolean'
          ? { ...x, Value: x.Value.toString() }
          : x;
      }),
  };

  params.UserAttributes.push({
    Name: 'email_verified',
    Value: 'true',
  });

  return cognitoIdentityServiceProvider.adminCreateUser(params, (err, data) => {
    updateUserAttributesByAdmin(
      username,
      attributes.filter((x) => x.Name === 'phone_number')
    );

    if (err) {
      console.error(err, err.stack);
    }
  });
}

export async function enableUserByAdmin(username) {
  const cognitoIdentityServiceProvider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Username: username /* required */,
  };

  return cognitoIdentityServiceProvider.adminEnableUser(params, (err, data) => {
    if (err) {
      console.error(err, err.stack);
    }
  });
}

export async function deleteUserByAdmin(username) {
  const cognitoIdentityServiceProvider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Username: username /* required */,
  };

  return cognitoIdentityServiceProvider.adminDeleteUser(params, (err, data) => {
    if (err) {
      console.error(err, err.stack);
    }
  });
}

export async function disableUserByAdmin(username) {
  const cognitoIdentityServiceProvider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Username: username /* required */,
  };

  return cognitoIdentityServiceProvider.adminDisableUser(
    params,
    (err, data) => {
      if (err) {
        console.error(err, err.stack);
      }
    }
  );
}

export async function getUsersByAdmin(paginationToken, filter) {
  const cognitoIdentityServiceProvider =
    await prepareCognitoIdentityServiceProvider();

  const params = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID /* required */,
    Limit: 0,
  };

  if (paginationToken) {
    params.PaginationToken = paginationToken;
  }

  if (filter && filter.field && filter.value) {
    params.Filter = `${filter.field} ${filter.operator} "${filter.value}"`;
  }

  return cognitoIdentityServiceProvider.listUsers(params).promise();
}

export function parseAddress(addressJson, address_part) {
  try {
    const address = JSON.parse(addressJson);
    return address[address_part] || '';
  } catch {
    return '';
  }
}

export function isUserAdmin(user) {
  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
  return groups && groups.find((x) => x === 'admins') != null;
}

import { FC, memo, MouseEvent } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  FAQSectionSchema,
  useFetchFAQSectionItems,
} from 'src/api/endpoints/faq';
import { useOverlayScrollbarsContext } from 'src/context/OverlayScrollbarsContext';

import { useStyles } from './styles';

interface Props {
  href: string;
  section?: FAQSectionSchema;
  isForAllSections?: boolean;
}

const NavLink: FC<Props> = ({ section, href, isForAllSections = false }) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const { osInstance } = useOverlayScrollbarsContext();

  const { data: faqSectionItems = [] } = useFetchFAQSectionItems(
    section?.id || 0,
    { enabled: !!section?.id }
  );

  const handleClick = (event: MouseEvent) => {
    if (!isForAllSections) return;

    event.preventDefault();
    osInstance?.scroll({ top: 0 });
    history.push(href);
  };

  const isFAQManagementPage = location.pathname.startsWith('/admin/');

  const isActive =
    location.hash === `#faq-section-${section?.id}` ||
    (isForAllSections && !location.hash);

  if (
    !isFAQManagementPage &&
    !isForAllSections &&
    faqSectionItems.length === 0
  ) {
    return null;
  }

  return (
    <ListItem
      button
      className={cx(classes.listItem, {
        isActive,
      })}
      component="a"
      href={href}
      onClick={handleClick}
    >
      <ListItemText className={classes.listItemText}>
        {isForAllSections ? 'All' : section?.name}
      </ListItemText>
    </ListItem>
  );
};

const MemoizedNavLink = memo(NavLink);

export default MemoizedNavLink;

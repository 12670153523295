import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

import { LAYOUT_DIMENSIONS } from 'src/utils/constants/app';

const { footer } = LAYOUT_DIMENSIONS;

export const useStyles = makeStyles((theme: Theme) => ({
  appFooter: {
    position: 'relative',
    width: '100%',
    minHeight: footer.height.desktop,
    paddingTop: 25,
    paddingLeft: footer.spacing.horizontal.desktop,
    paddingRight: footer.spacing.horizontal.desktop,
    boxSizing: 'border-box',
    fontFamily: theme.fonts.fontFamily.regular,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.background.default,
    zIndex: 100,

    '& [class*=MuiGrid-item]:not(:last-child)': {
      marginBottom: 5,
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: footer.spacing.horizontal.mobile,
      paddingRight: footer.spacing.horizontal.mobile,
    },
  },

  footerTop: {
    borderBottom: `0.1px solid ${theme.palette.grey[200]}`,
    paddingBottom: 10,

    [theme.breakpoints.down('md')]: {
      paddingBottom: 30,
    },
  },

  footerTopRightColumn: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
    },
  },

  footerLogo: {
    '& a': {
      display: 'inline-block',
      textDecoration: 'none',
    },

    '& span': {
      display: 'block',
    },

    '& span:nth-child(1)': {
      fontWeight: 'bold',
      fontSize: 29,
    },

    '& span:nth-child(2)': {
      position: 'relative',
      fontSize: 9.25,
      marginTop: -8,
    },
  },

  footerLocation: {
    paddingTop: 2,
    paddingLeft: 60,
    maxWidth: 300,

    '& span, & a, & p': {
      color: theme.palette.secondary.main,
    },

    '& span': {
      fontFamily: theme.fonts.fontFamily.bold,
      fontWeight: theme.typography.fontWeightBold,
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingTop: 30,
    },
  },

  footerContactUs: {
    paddingTop: 2,
    paddingRight: 60,
    maxWidth: 400,

    '& span, & a': {
      display: 'block',
      color: theme.palette.secondary.main,
    },

    '& span': {
      fontFamily: theme.fonts.fontFamily.bold,
      fontWeight: theme.typography.fontWeightBold,
    },

    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      paddingTop: 36,
      margin: 0,
      textAlign: 'right',
    },
  },

  socials: {
    height: 34,

    '& a': {
      display: 'inline-block',
      marginLeft: 20,
    },

    [theme.breakpoints.down('md')]: {
      height: 50,
    },
  },

  footerBottom: {
    paddingTop: 5,
    paddingBottom: 5,
  },

  footerCopyRights: {
    fontSize: 9.25,
  },

  copyrightIcon: {
    fontSize: 9.5,
    verticalAlign: -1.25,
  },

  privacyAndTerms: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 9.5,
    fontFamily: theme.fonts.fontFamily.medium,

    '& a': {
      marginLeft: 40,
      color: theme.palette.grey[500],
    },
  },
}));

import { privateGateway } from '../../../config';
import {
  FAQSectionItemSchema,
  UpdateFAQSectionItemVariables,
} from '../typings';

export const updateFAQSectionItem = async ({
  id,
  section_id,
  question,
  answer,
}: UpdateFAQSectionItemVariables): Promise<FAQSectionItemSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).put(`/faq/sections/${section_id}/items/${id}`, { question, answer });

  return response.data;
};

import { FC } from 'react';

import { IntlProvider } from 'react-intl';

import ThemeProvider from '@quanterix-ui/core/ThemeProvider';

import Router from 'src/router';
import CognitoProvider from 'src/aws/Cognito';
import ReactQueryProvider from 'src/config/ReactQueryProvider';
import SnackbarProvider from 'src/config/SnackbarProvider';
import OverlayScrollbarsContextProvider from 'src/context/OverlayScrollbarsContext';
import NotFoundContextProvider from 'src/context/NotFoundContext';
import UploadProgressContext from 'src/context/UploadProgressContext';
import { defaultLanguage, translations } from 'src/localization';
import GlobalEventHandler from 'src/micro-frontend/components/GlobalEventHandler';
import {
  ServicesStoreInstance,
  ServicesStoreProvider,
} from 'src/micro-frontend/stores/ServicesStore';

const App: FC = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ThemeProvider theme="light">
      <IntlProvider
        locale={defaultLanguage}
        messages={translations[defaultLanguage]}
      >
        <UploadProgressContext>
          <SnackbarProvider>
            <ReactQueryProvider>
              <CognitoProvider>
                <OverlayScrollbarsContextProvider>
                  <NotFoundContextProvider>
                    <ServicesStoreProvider value={ServicesStoreInstance}>
                      <GlobalEventHandler />
                      <Router />
                    </ServicesStoreProvider>
                  </NotFoundContextProvider>
                </OverlayScrollbarsContextProvider>
              </CognitoProvider>
            </ReactQueryProvider>
          </SnackbarProvider>
        </UploadProgressContext>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;

import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@quanterix-ui/core/TextField';

import { addOrRemoveArrayItem } from 'src/utils/StringHelper';

import { SampleTypesFull } from '../../typings';

import { Item } from './typings';

// {
//   items: ['1, 2, 3', 'Other'],
//   other: 'asd'
// }

interface Props {
  value: SampleTypesFull;
  items: Item[];
  size?: 'small' | 'medium';
  onChangeValue: (options: SampleTypesFull) => void;
}

const CheckboxeList: FC<Props> = ({
  items,
  value,
  size = 'medium',
  onChangeValue,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const [isOther, setIsOther] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    if (value) {
      setIsOther(value.items?.includes('Other'));
      setOtherValue(value.other || '');
    }
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: newValue } = event.target;
    const currentItems = value ? value.items : [];
    const newItems = addOrRemoveArrayItem(currentItems, newValue);
    const isOtherNew = newValue === 'Other' ? !isOther : isOther;

    onChangeValue({
      items: newItems,
      other: isOtherNew ? otherValue : undefined,
    });
    setIsOther(isOtherNew);
  };

  const handleOtherChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value: newValue } = event.target;

    let newItems = [...value.items];
    if (newValue.length > 0) {
      if (!value.items.includes('Other')) {
        newItems = [...value.items, 'Other'];
      }
    } else {
      newItems = value.items.filter((val) => val !== 'Other');
    }

    setOtherValue(newValue);
    onChangeValue({
      items: newItems,
      other: newValue,
    });
  };

  const isOtherError =
    !!errors.sampleTypesFull &&
    ((isOther && !errors.sampleTypesFull.ref.value.other) ||
      (isOther && errors.sampleTypesFull.ref.value.other?.length > 0));

  return (
    <FormGroup>
      {items.map((item) => (
        <FormControlLabel
          key={item.value}
          control={
            <Checkbox
              size={size}
              checked={Boolean(value?.items?.indexOf(item.value) > -1)}
              disabled={item.value === 'Other' && otherValue.length > 0}
              name={item.value}
              value={item.value}
              onChange={handleChange}
            />
          }
          label={item.name}
        />
      ))}
      <TextField
        fullWidth
        placeholder="Sample Types (Other)"
        value={otherValue}
        error={isOtherError}
        helperText={errors.sampleTypesFull?.message}
        onChange={handleOtherChange}
      />
    </FormGroup>
  );
};

export default CheckboxeList;

import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

import {
  VIDEO_PLAYER_HEIGHT,
  VIDEO_PLAYER_WIDTH,
  ZINDEX_OVER_FULLSCREEN,
} from './constants';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: VIDEO_PLAYER_WIDTH,
    height: VIDEO_PLAYER_HEIGHT,
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.constants.borderRadius,
    boxShadow: 'none',
    overflow: 'hidden',

    '& video::-webkit-media-controls, & video::-webkit-media-controls-enclosure':
      {
        display: 'none !important',
      },
  },

  videoHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: 100,
    padding: '0 40px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `linear-gradient(${theme.palette.common.black}, transparent)`,
    borderRadius: `${theme.constants.borderRadius}px ${theme.constants.borderRadius}px 0 0`,

    '&.isFullscreen': {
      zIndex: ZINDEX_OVER_FULLSCREEN,
    },
  },

  videoTitle: {
    fontFamily: theme.fonts.fontFamily.bold,
    fontSize: 28,
    color: theme.palette.common.white,
    textShadow: `1px 1px 5px ${alpha(theme.palette.common.black, 0.5)}`,
  },

  readySpinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.common.black, 0.5),
  },

  progressBar: {
    position: 'absolute',
    top: 0,
    left: 15,
    right: 15,
    width: 'calc(100% - 30px)',
    transform: 'translateY(-40%)',
  },

  controls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 70,
    padding: '0 25px',
    backgroundColor: alpha(theme.palette.common.black, 0.85),
    borderRadius: `0 0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px`,
    boxShadow: `0 -15px 25px ${alpha(theme.palette.common.black, 0.5)}`,

    '&.isFullscreen': {
      zIndex: ZINDEX_OVER_FULLSCREEN,
    },
  },

  controlsHighlight: {
    color: theme.palette.common.white,

    '&.isActive': {
      color: theme.palette.primary.main,
    },
  },
}));

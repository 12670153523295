export const getQueryKey = (queryKey: string, val1?: number, val2?: number) => {
  const result: (number | string)[] = [queryKey];

  if (val1) {
    result.push(val1);
  }

  if (val2) {
    result.push(val2);
  }

  return result;
};

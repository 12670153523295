import { CreateUserRequestParams, UserRequestSchema } from '../typings';
import { publicGateway } from '../../../config';

export const createUserRequest = async (
  params: CreateUserRequestParams
): Promise<UserRequestSchema> => {
  const response = await publicGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).post(`/user_requests`, params);

  return response.data;
};

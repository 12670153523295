import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';

import MuiFormLabel from '@material-ui/core/FormLabel';

import TextField, { TextFieldProps } from '@quanterix-ui/core/TextField';

import { handleChangeWithLengthLimit } from 'src/utils/helpers/form';

import { useStyles as useCommonStyles } from '../styles';

import { LAST_NAME_MAX_LENGTH } from './constants';

interface Props extends TextFieldProps {
  name: string;
  label?: string;
}

const ControlledLastNameTextField: FC<Props> = ({
  id,
  name,
  label,
  required = true,
  ...restProps
}) => {
  const commonClasses = useCommonStyles();

  const { formatMessage } = useIntl();

  return (
    <>
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.last_name" />}
      </MuiFormLabel>
      <Controller
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextField
            fullWidth
            id={id ?? name}
            value={value}
            error={!!error}
            placeholder={formatMessage({
              id: 'app.form.placeholder.last_name',
            })}
            onChange={(event) =>
              onChange(
                handleChangeWithLengthLimit(event, value, LAST_NAME_MAX_LENGTH)
              )
            }
            {...restProps}
          />
        )}
      />
    </>
  );
};

export default ControlledLastNameTextField;

import { privateGateway } from '../../../config';
import { RequestFileUrlResponse, RequestFileUrlVariables } from '../typings';

export const requestFileUrl = async ({
  file_name,
}: RequestFileUrlVariables): Promise<RequestFileUrlResponse> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post('/files/download', { file_name });

  return response.data;
};

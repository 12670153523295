import { FC, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import Button from '@quanterix-ui/core/Button';
import ControlledTextField from '@quanterix-ui/core/ControlledTextField';

import Dropzone from 'src/components/elements/Dropzone';
import FileUploadItem from 'src/components/elements/FileUploadItem';
import { useUploadSupportFiles } from 'src/api/endpoints/users';
import { useSessionStorage, useSnackbar } from 'src/utils/hooks';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { useUploadProgressContext } from 'src/context/UploadProgressContext';

import UserGuideLinks from './components/UserGuideLinks';
import { useStyles } from './styles';
import { SupportFileFormSchema } from './typings';
import {
  DEFAULT_SUPPORT_FILE_FORM_VALUES,
  FILES_MAX_SIZE,
  TEST_ID,
} from './constants';

const SupportFileUpload: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { formatMessage } = useIntl();

  const { enqueueErrorSnackbar } = useSnackbar();

  const {
    cancelSource,
    uploadProgress,
    isMalwareScanCompleted,
    filesWithMalware,
    setFiles,
    setCaseObject,
    onCancel,
  } = useUploadProgressContext();

  const { mutate: uploadSupportFiles } = useUploadSupportFiles({
    cancelTokenSource: cancelSource.current!,

    onSuccess: (data) => {
      setFiles(data);
    },

    onCancel: () => {
      onCancel();
    },
  });

  const {
    storedValue: supportFileData,
    setValue: setSupportFileData,
    removeValue: removeSupportFileData,
  } = useSessionStorage<Omit<SupportFileFormSchema, 'files'>>(
    LOCAL_STORAGE_KEY.supportFileFormData,
    DEFAULT_SUPPORT_FILE_FORM_VALUES
  );

  const formMethods = useForm<SupportFileFormSchema>({
    defaultValues: { ...supportFileData, files: [] },
  });

  const {
    watch,
    reset,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  register('files', {
    validate: {
      oneFileRequired: (files: File[]) =>
        files.length >= 1 ||
        formatMessage({
          id: 'page.support_file_upload.form.error.file_required',
        }),
    },
  });

  useEffect(() => {
    const saveFormToLocalStorage = () => {
      const { caseId, caseReference, caseDescription } = getValues();
      setSupportFileData({ caseId, caseReference, caseDescription });
    };

    window.addEventListener('beforeunload', saveFormToLocalStorage);

    history.listen((location) => {
      if (location.pathname !== PRIVATE_ROUTES_MAP.supportFileUpload) {
        removeSupportFileData();
      }
    });

    return () => {
      window.removeEventListener('beforeunload', saveFormToLocalStorage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileList = watch('files');

  useEffect(() => {
    if (isMalwareScanCompleted && uploadProgress === 100) {
      reset({ ...DEFAULT_SUPPORT_FILE_FORM_VALUES, files: [] });
    }
  }, [reset, uploadProgress, isMalwareScanCompleted, filesWithMalware.length]);

  const handleUploadFile = (files: File[]) => {
    const uploadedFilesSize = fileList.reduce(
      (totalSize, file) => totalSize + file.size,
      0
    );

    const totalFilesSize = files.reduce(
      (totalSize, file) => totalSize + file.size,
      uploadedFilesSize
    );

    if (totalFilesSize >= FILES_MAX_SIZE) {
      enqueueErrorSnackbar({
        message:
          'page.support_file_upload.form.snackbar.max_files_size_exceeded',
      });
    } else {
      setValue('files', [...fileList, ...files], { shouldValidate: true });
    }
  };

  const handleRemoveFile = (fileIndex: number) => {
    setValue(
      'files',
      fileList.filter((_, index) => index !== fileIndex),
      { shouldValidate: true }
    );
  };

  const handleFormSubmit = (data: SupportFileFormSchema) => {
    if (uploadProgress === null) {
      const caseDescription = getValues('caseDescription') || '';
      setCaseObject({
        caseId: getValues('caseId'),
        caseReference: getValues('caseReference'),
        caseDescription,
      });
      uploadSupportFiles({
        files: data.files,
      });
    } else {
      enqueueErrorSnackbar({
        message:
          'page.support_file_upload.form.snackbar.previous_upload_in_progress',
      });
    }
  };

  const handleCancelUpload = () => {
    history.push(PRIVATE_ROUTES_MAP.dashboard);
  };

  const { getRootProps, getInputProps, isFocused, isFileDialogActive } =
    useDropzone({
      multiple: true,
      useFsAccessApi: false,
      onDrop: handleUploadFile,
    });

  return (
    <MuiBox data-testid={TEST_ID.supportFileUpload}>
      <MuiBox mb={6}>
        <MuiTypography className={classes.pageTitle} color="secondary">
          <FormattedMessage id="page.support_file_upload.title" />
        </MuiTypography>
      </MuiBox>
      <MuiGrid container spacing={4}>
        <MuiGrid item md={12} lg={7}>
          <MuiBox className={classes.card}>
            <MuiBox mb={3}>
              <MuiTypography className={classes.pageTitle} color="secondary">
                <FormattedMessage id="page.support_file_upload.attach_file" />
              </MuiTypography>
            </MuiBox>
            <MuiBox mb={6}>
              <MuiTypography>
                <FormattedMessage id="page.support_file_upload.attach_file_description" />
              </MuiTypography>
            </MuiBox>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <MuiBox mb={4}>
                  <MuiFormLabel
                    required
                    htmlFor="caseReference"
                    className={classes.formLabel}
                  >
                    <FormattedMessage id="page.support_file_upload.form.label.caseReference" />
                  </MuiFormLabel>
                  <ControlledTextField
                    fullWidth
                    id="caseReference"
                    name="caseReference"
                    rules={{ required: true }}
                    placeholder={formatMessage({
                      id: 'page.support_file_upload.form.placeholder.caseReference',
                    })}
                  />
                </MuiBox>
                <MuiBox mb={4}>
                  <MuiFormLabel
                    required
                    htmlFor="caseId"
                    className={classes.formLabel}
                  >
                    <FormattedMessage id="page.support_file_upload.form.label.caseId" />
                  </MuiFormLabel>
                  <ControlledTextField
                    fullWidth
                    id="caseId"
                    name="caseId"
                    rules={{ required: true }}
                    placeholder={formatMessage({
                      id: 'page.support_file_upload.form.placeholder.caseId',
                    })}
                  />
                </MuiBox>
                <MuiBox mb={3} maxWidth={300}>
                  {fileList.map((file, index) => (
                    <MuiBox key={index} mb={3}>
                      <FileUploadItem onDelete={() => handleRemoveFile(index)}>
                        {file.name}
                      </FileUploadItem>
                    </MuiBox>
                  ))}
                </MuiBox>
                <MuiBox mb={6}>
                  <Dropzone
                    invalid={!!errors.files}
                    rootProps={getRootProps()}
                    inputProps={getInputProps()}
                    isFocused={isFocused}
                    isFileDialogActive={isFileDialogActive}
                  >
                    <FormattedMessage id="page.support_file_upload.form.label.dropzone" />
                  </Dropzone>
                  {errors.files && (
                    <MuiFormHelperText error>
                      {(errors.files as unknown as FieldError).message}
                    </MuiFormHelperText>
                  )}
                </MuiBox>
                <MuiBox mb={4}>
                  <MuiFormLabel
                    htmlFor="caseDescription"
                    className={classes.formLabel}
                  >
                    <FormattedMessage id="page.support_file_upload.form.label.caseDescription" />
                  </MuiFormLabel>
                  <ControlledTextField
                    fullWidth
                    multiline
                    id="caseDescription"
                    name="caseDescription"
                    minRows={10}
                    maxRows={10}
                    inputProps={{ maxLength: 1000 }}
                    rules={{ maxLength: 1000 }}
                    placeholder={formatMessage({
                      id: 'page.support_file_upload.form.placeholder.caseDescription',
                    })}
                  />
                </MuiBox>
                <MuiBox display="flex" justifyContent="space-between">
                  <Button variant="outlined" onClick={handleCancelUpload}>
                    <FormattedMessage id="app.button.cancel" />
                  </Button>
                  <Button type="submit">
                    <FormattedMessage id="app.button.submit" />
                  </Button>
                </MuiBox>
              </form>
            </FormProvider>
          </MuiBox>
        </MuiGrid>
        <MuiGrid item md={12} lg={5}>
          <UserGuideLinks />
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

export default SupportFileUpload;

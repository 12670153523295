import { FC, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiIconClose from '@material-ui/icons/Close';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import {
  FAQSectionSchema,
  useDeleteFAQSection,
  useFetchFAQSectionItems,
} from 'src/api/endpoints/faq';
import IconButton from 'src/components/elements/IconButton';

import DialogContextProvider, { useDialogContext } from '../../context/Dialog';
import SectionEditButton from '../SectionEditButton';
import SectionItem from '../SectionItem';
import SectionItemAddButton from '../SectionItemAddButton';

import { useStyles } from './styles';

interface Props {
  section: FAQSectionSchema;
}

const Section: FC<Props> = ({ section }) => {
  const classes = useStyles();

  const { id, name } = section;

  const location = useLocation();

  const { formatMessage } = useIntl();

  const { isOpen } = useDialogContext();

  const [isHovered, setIsHovered] = useState(false);

  const { data: faqSectionItems = [], isLoading: isSectionItemsLoading } =
    useFetchFAQSectionItems(id);

  const { mutate: deleteFAQSection, isLoading: isSectionDeleting } =
    useDeleteFAQSection();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSectionDelete = () => {
    deleteFAQSection({ id });
  };

  useEffect(() => {
    if (!isOpen) {
      setIsHovered(false);
    }
  }, [isOpen]);

  const isFAQManagementPage = location.pathname.startsWith('/admin/');

  const isActionsLoading = isSectionDeleting;
  const isActionsVisible =
    isFAQManagementPage && (isHovered || isActionsLoading || isOpen);

  if (!isFAQManagementPage && faqSectionItems.length === 0) {
    return null;
  }

  return (
    <MuiBox
      id={`faq-section-${id}`}
      mb={6}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MuiGrid
        container
        className={classes.header}
        justifyContent="space-between"
        alignItems="center"
      >
        <MuiGrid item className={classes.titleContainer}>
          <MuiGrid container alignItems="center">
            <MuiBox className={classes.titleWrapper}>
              <MuiTypography
                className={classes.title}
                variant="h3"
                color={isHovered ? 'primary' : 'secondary'}
              >
                {name}
              </MuiTypography>
            </MuiBox>
            {isActionsVisible && (
              <MuiBox ml={2}>
                <MuiGrid container>
                  <MuiTooltip
                    title={formatMessage({ id: 'app.button.edit' })}
                    disableHoverListener={isOpen}
                  >
                    <div>
                      <SectionEditButton section={section} />
                    </div>
                  </MuiTooltip>
                  <MuiTooltip
                    title={formatMessage({ id: 'app.button.delete' })}
                    disableHoverListener={isOpen}
                  >
                    <div>
                      <IconButton
                        color="secondary"
                        size="small"
                        loading={isSectionDeleting}
                        onClick={handleSectionDelete}
                      >
                        <MuiIconClose />
                      </IconButton>
                    </div>
                  </MuiTooltip>
                </MuiGrid>
              </MuiBox>
            )}
          </MuiGrid>
        </MuiGrid>
        {isFAQManagementPage && (
          <MuiGrid item>
            <SectionItemAddButton sectionId={id} />
          </MuiGrid>
        )}
      </MuiGrid>
      <MuiBox mb={2}>
        <ProgressOverlay
          withLoadingDisk
          loading={isSectionItemsLoading}
          opacity={1}
        >
          {faqSectionItems.map((item) => (
            <div key={item.id}>
              <DialogContextProvider>
                <SectionItem item={item} />
              </DialogContextProvider>
            </div>
          ))}
        </ProgressOverlay>
      </MuiBox>
    </MuiBox>
  );
};

export default Section;

import { QueryBuilder } from 'odata-query-builder';

import { PaginationState } from 'src/context/PaginationContext';
import { SortingState } from 'src/context/SortingContext';
import { ContentFilterState } from 'src/pages/ContentHubViewer/context/ContentFilterContext';

import { privateGateway } from '../../../config';
import {
  RequestSearchContentHubFilesResult,
  RequestSearchContentHubFilesVariables,
} from '../typings';

interface RequestSearchContentHubFilesParams {
  pagination?: PaginationState;
  sorting?: SortingState;
  filters?: ContentFilterState;
}

export const requestSearchContentHubFiles = async (
  data: RequestSearchContentHubFilesVariables,
  { pagination, sorting, filters }: RequestSearchContentHubFilesParams = {}
): Promise<RequestSearchContentHubFilesResult> => {
  const query = new QueryBuilder();

  query.count();

  if (pagination) {
    query.top(pagination.rowsPerPage);
    query.skip(pagination.skip);
  }

  if (sorting && sorting.orderBy && sorting.order) {
    query.orderBy(`${sorting.orderBy} ${sorting.order}`);
  }

  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post(`/files/search${query.toQuery()}`, {
    ...data,
    searchByType: filters?.extensions || [],
  });

  return response.data;
};

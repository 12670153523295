import { privateGateway } from '../../../config';
import { SubmissionSchema } from '../typings';

export const fetchSubmission = async (
  formId: string
): Promise<SubmissionSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).get(`/form/${formId}`);

  return response.data;
};

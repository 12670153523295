import { FC, useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiIconThumbUp from '@material-ui/icons/ThumbUp';
import MuiIconCloudUpload from '@material-ui/icons/CloudUpload';
import MuiIconVisibility from '@material-ui/icons/Visibility';

import Button from '@quanterix-ui/core/Button';

import { getFileTypeIcon } from 'src/utils/helpers/file';
import { getDownloadUrl } from 'src/utils/helpers/blobStorage';
import {
  getFileExtension,
  getFileName,
  getFileStatus,
} from 'src/utils/StringHelper';
import { showFileInBrowser } from 'src/utils/S3Helper';
import { FileStatuses } from 'src/utils/Constants';

import { useStyles } from './styles';
import { FileProps } from './typings';
import { TEST_ID } from './constants';

const File: FC<FileProps> = ({
  isEditMode = false,
  filePath,
  fileData,
  onRequireApproval,
  onFilePublish,
  onFileRemove,
}) => {
  const classes = useStyles();

  const [inProgress, setInProgress] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>();

  const correctFilePath = useMemo(() => {
    return filePath || fileData?.key || '';
  }, [filePath, fileData?.key]);

  const fullFileName = useMemo(() => {
    return getFileName(correctFilePath);
  }, [correctFilePath]);

  const shortFileName = useMemo(() => {
    const fileNameParts = fullFileName.split('.');
    return fileNameParts.slice(0, -1).join('');
  }, [fullFileName]);

  const fileExtension = useMemo(() => {
    return getFileExtension(fullFileName);
  }, [fullFileName]);

  const fileStatus = useMemo(() => {
    return getFileStatus(correctFilePath);
  }, [correctFilePath]);

  const downloadFile = useMemo(() => {
    return !showFileInBrowser(fileExtension);
  }, [fileExtension]);

  const handleRequireApproval = async () => {
    if (onRequireApproval) {
      setInProgress(true);
      try {
        await onRequireApproval(correctFilePath);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInProgress(false);
      }
    }
  };

  const handleFilePublish = async () => {
    if (onFilePublish) {
      setInProgress(true);
      try {
        await onFilePublish(correctFilePath);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInProgress(false);
      }
    }
  };

  const handleFileRemove = async () => {
    if (onFileRemove) {
      setInProgress(true);
      try {
        await onFileRemove(correctFilePath);
      } catch (error) {
        console.error(error.message);
      } finally {
        setInProgress(false);
      }
    }
  };

  useEffect(() => {
    const initDownloadLink = async () => {
      if (filePath) {
        const link = await getDownloadUrl(filePath);
        setDownloadLink(link);
      } else if (fileData?.urlPath) {
        setDownloadLink(fileData.urlPath);
      }
    };

    initDownloadLink();
  }, [filePath, fileData?.urlPath]);

  return (
    <>
      <MuiBox className={classes.root} data-testid={TEST_ID.fileComponent}>
        <MuiGrid container justifyContent="space-between" alignItems="center">
          <MuiBox flex={1}>
            <MuiGrid container alignItems="center" wrap="nowrap">
              <MuiBox className={classes.fileTypeIconContainer} mr={2}>
                {inProgress && (
                  <MuiBox className={classes.fileProgressContainer}>
                    <MuiCircularProgress
                      size={20}
                      thickness={3}
                      data-testid={TEST_ID.inProgressSpinner}
                    />
                  </MuiBox>
                )}
                {!inProgress && (
                  <MuiTooltip title={fileExtension}>
                    <div>
                      {getFileTypeIcon(fileExtension, {
                        className: classes.fileTypeIcon,
                        'data-testid': TEST_ID.fileTypeIcon,
                      })}
                    </div>
                  </MuiTooltip>
                )}
              </MuiBox>
              <MuiTooltip title={fullFileName}>
                <MuiLink
                  href={downloadLink}
                  className={cx(classes.link, {
                    [classes.italicLink]: fileStatus !== FileStatuses.Published,
                  })}
                  target={downloadFile ? '_self' : '_blank'}
                  rel="noopener noreferrer"
                  underline="none"
                >
                  {shortFileName}
                </MuiLink>
              </MuiTooltip>
            </MuiGrid>
          </MuiBox>
          <MuiBox className={classes.gridItemHeightHolder} />
          <MuiBox>
            <MuiGrid container alignItems="center">
              {isEditMode && (
                <>
                  {fileStatus === FileStatuses.New && (
                    <>
                      {onRequireApproval && (
                        <MuiIconButton
                          size="small"
                          data-testid={TEST_ID.fileApprovalButton}
                          onClick={handleRequireApproval}
                        >
                          <MuiIconVisibility fontSize="medium" />
                        </MuiIconButton>
                      )}
                      {onFilePublish && (
                        <MuiIconButton
                          size="small"
                          data-testid={TEST_ID.filePublishButton}
                          onClick={handleFilePublish}
                        >
                          <MuiIconCloudUpload fontSize="medium" />
                        </MuiIconButton>
                      )}
                    </>
                  )}
                  {fileStatus === FileStatuses.Pending && onFilePublish && (
                    <MuiIconButton
                      size="small"
                      data-testid={TEST_ID.filePublishButton}
                      onClick={handleFilePublish}
                    >
                      <MuiIconThumbUp fontSize="medium" />
                    </MuiIconButton>
                  )}
                  <MuiBox ml={2}>
                    {onFileRemove && (
                      <Button
                        className={classes.actionButton}
                        variant="text"
                        color="secondary"
                        data-testid={TEST_ID.fileRemoveButton}
                        onClick={handleFileRemove}
                      >
                        <FormattedMessage id="app.button.remove" />
                      </Button>
                    )}
                  </MuiBox>
                </>
              )}
            </MuiGrid>
          </MuiBox>
        </MuiGrid>
      </MuiBox>
    </>
  );
};

export default File;

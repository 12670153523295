import { useQuery, UseQueryResult } from 'react-query';

import { fetchContentHubChildPages } from '../requests/fetchContentHubChildPages';
import { ContentHubObjectSchema } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseFetchContentHubChildPagesOptions {
  enabled?: boolean;
}

export interface UseFetchContentHubChildPagesResult
  extends Omit<UseQueryResult, 'data'> {
  data: ContentHubObjectSchema[];
}

export const useFetchContentHubChildPages = (
  pageId: number,
  options: UseFetchContentHubChildPagesOptions = defaultOptions
): UseFetchContentHubChildPagesResult => {
  const { data = [], ...restData } = useQuery<ContentHubObjectSchema[]>(
    [QUERY_NAME.fetchContentHubChildPages, pageId],
    () => fetchContentHubChildPages(pageId),
    {
      enabled: options.enabled,
    }
  );

  return {
    data,
    ...restData,
  } as UseFetchContentHubChildPagesResult;
};

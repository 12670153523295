import { FC } from 'react';

import { Router as ReactRouter, Redirect, Switch } from 'react-router-dom';

import SignIn from 'src/pages/SignIn';
import SignUp from 'src/pages/SignUp';
import ForgotPassword from 'src/pages/ForgotPassword';
import ResetPassword from 'src/pages/ResetPassword';
import ChangePassword from 'src/pages/ChangePassword';
import ChangeTemporaryPassword from 'src/pages/ChangeTemporaryPassword';
import ConfirmUser from 'src/pages/ConfirmUser';
import RegisteredUsers from 'src/pages/RegisteredUsers';
import RegisteredUsersAddNew from 'src/pages/RegisteredUsersAddNew';
import RegisteredUserProfile from 'src/pages/RegisteredUserProfile';
import RegisteredUserProfileInstruments from 'src/pages/RegisteredUserProfileInstruments';
import UserRequests from 'src/pages/UserRequests';
import UserRequestProfile from 'src/pages/UserRequestProfile';
import UserRequestProfileInstruments from 'src/pages/UserRequestProfileInstruments';
import ContentHub from 'src/pages/ContentHub';
import ContentHubViewer from 'src/pages/ContentHubViewer';
import ContentManagement from 'src/pages/ContentManagement';
import ContentManagementContextProvider from 'src/pages/ContentManagement/context/ContentManagementContext';
import FAQManagement from 'src/pages/FAQManagement';
import LegalDocument from 'src/pages/LegalDocument';
import Dashboard from 'src/pages/Dashboard';
import DownloadPage from 'src/pages/Download';
import DownloadManifests from 'src/pages/DownloadManifests';
import AcceleratorForm from 'src/pages/AcceleratorForm';
import Profile from 'src/pages/Profile';
import ProfileChangePassword from 'src/pages/ProfileChangePassword';
import ProfileInstruments from 'src/pages/ProfileInstruments';
import ProfileActivity from 'src/pages/ProfileActivity';
import SupportFileUpload from 'src/pages/SupportFileUpload';
import NotFound from 'src/pages/NotFound';
import Logout from 'src/pages/Logout';
import ContentHubViewerPublic from 'src/pages/ContentHubViewerPublic';
import { useGaTracker } from 'src/utils/hooks';
import { IS_PREPROD_ENV, IS_PROD_ENV } from 'src/utils/constants/env';
import Module from 'src/micro-frontend/pages/Module';

import { history } from './config';
import PublicRoute, { PUBLIC_ROUTES_MAP } from './components/PublicRoute';
import OldPublicRoute, {
  OLD_PUBLIC_ROUTES_MAP,
} from './components/OldPublicRoute';
import PrivateRoute, { PRIVATE_ROUTES_MAP } from './components/PrivateRoute';
import ProtectedRoute, {
  PROTECTED_ROUTES_MAP,
} from './components/ProtectedRoute';

export const Routes: FC = () => {
  useGaTracker();

  return (
    <Switch>
      <PublicRoute exact path={PUBLIC_ROUTES_MAP.login}>
        <SignIn />
      </PublicRoute>
      <PublicRoute exact path={PUBLIC_ROUTES_MAP.forgotPassword}>
        <ForgotPassword />
      </PublicRoute>
      <PublicRoute exact path={PUBLIC_ROUTES_MAP.resetPassword}>
        <ResetPassword />
      </PublicRoute>
      <PublicRoute exact path={PUBLIC_ROUTES_MAP.requestAccess}>
        <SignUp />
      </PublicRoute>
      <PublicRoute exact path={PUBLIC_ROUTES_MAP.changeTemporaryPassword}>
        <ChangeTemporaryPassword />
      </PublicRoute>
      <PublicRoute exact path={PUBLIC_ROUTES_MAP.logout}>
        <Logout />
      </PublicRoute>
      <PublicRoute exact strict path={PUBLIC_ROUTES_MAP.contentHubViewerPublic}>
        <ContentHubViewerPublic />
      </PublicRoute>

      <OldPublicRoute exact path={OLD_PUBLIC_ROUTES_MAP.legal}>
        <LegalDocument />
      </OldPublicRoute>

      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.changePassword}>
        <ChangePassword />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.dashboard}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.profile}>
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.profileChangePassword}>
        <ProfileChangePassword />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.profileInstruments}>
        <ProfileInstruments />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.profileActivity}>
        <ProfileActivity />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.contentHub}>
        <ContentHub />
      </PrivateRoute>
      <PrivateRoute
        exact
        strict
        path={[
          PRIVATE_ROUTES_MAP.contentHubViewer,
          PRIVATE_ROUTES_MAP.contentHubViewerAll,
          PRIVATE_ROUTES_MAP.contentHubViewerBookmarks,
        ]}
      >
        <ContentHubViewer />
      </PrivateRoute>
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.downloadFile}>
        <DownloadPage />
      </PrivateRoute>
      {/* TODO: This filter temporary, for release 3.0 */}
      {!(IS_PREPROD_ENV || IS_PROD_ENV) && (
        <PrivateRoute exact path={PRIVATE_ROUTES_MAP.supportFileUpload}>
          <SupportFileUpload />
        </PrivateRoute>
      )}
      <PrivateRoute exact path={PRIVATE_ROUTES_MAP.accelerator}>
        <AcceleratorForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={PRIVATE_ROUTES_MAP.acceleratorDownloadManifests}
      >
        <DownloadManifests />
      </PrivateRoute>
      <PrivateRoute exact strict path={PRIVATE_ROUTES_MAP.faq}>
        <FAQManagement />
      </PrivateRoute>

      {/* NOTE: Micro-Frontend Root App Routes */}
      <PrivateRoute path={PRIVATE_ROUTES_MAP.microFrontendSubmodule}>
        <Module />
      </PrivateRoute>

      <ProtectedRoute
        exact
        strict
        path={[
          PROTECTED_ROUTES_MAP.adminContentManagement,
          PROTECTED_ROUTES_MAP.adminContentManagementPageView,
        ]}
      >
        <ContentManagementContextProvider>
          <ContentManagement />
        </ContentManagementContextProvider>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        strict
        path={PROTECTED_ROUTES_MAP.adminFAQManagement}
      >
        <FAQManagement />
      </ProtectedRoute>
      <ProtectedRoute exact path={PROTECTED_ROUTES_MAP.adminRegisteredUsers}>
        <RegisteredUsers />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={PROTECTED_ROUTES_MAP.adminRegisteredUserProfile}
      >
        <RegisteredUserProfile />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={PROTECTED_ROUTES_MAP.adminRegisteredUserInstruments}
      >
        <RegisteredUserProfileInstruments />
      </ProtectedRoute>
      <ProtectedRoute exact path={PROTECTED_ROUTES_MAP.adminUserRequests}>
        <UserRequests />
      </ProtectedRoute>
      <ProtectedRoute exact path={PROTECTED_ROUTES_MAP.adminUserRequestProfile}>
        <UserRequestProfile />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={PROTECTED_ROUTES_MAP.adminUserRequestInstruments}
      >
        <UserRequestProfileInstruments />
      </ProtectedRoute>
      <ProtectedRoute exact path={PROTECTED_ROUTES_MAP.adminUsersNew}>
        <RegisteredUsersAddNew />
      </ProtectedRoute>
      <ProtectedRoute exact path={PROTECTED_ROUTES_MAP.adminUsersConfirm}>
        <ConfirmUser />
      </ProtectedRoute>
      <PrivateRoute exact path="/">
        <Redirect to={PRIVATE_ROUTES_MAP.dashboard} />
      </PrivateRoute>
      <PrivateRoute path="*">
        <NotFound />
      </PrivateRoute>
    </Switch>
  );
};

const Router: FC = () => (
  <ReactRouter history={history}>
    <Routes />
  </ReactRouter>
);

export default Router;

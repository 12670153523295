import { FC } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { PUBLIC_ROUTES_MAP } from 'src/router';
import ControlledEmailTextField from 'src/components/form/ControlledEmailTextField';

import { HandleCodeSubmitOptions } from './typings';
import { useStyles } from './styles';

const ForgotPassword: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { isLoading, forgotPassword } = useCognito();

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = formMethods;

  const handleCodeSubmit = async ({ email }: HandleCodeSubmitOptions) => {
    try {
      await forgotPassword({ email });
      history.push(PUBLIC_ROUTES_MAP.resetPassword, { email });
    } catch (error) {
      console.error(error.message);

      if (error.message.startsWith('password.error.limit')) {
        history.push(PUBLIC_ROUTES_MAP.resetPassword, {
          email,
          error,
        });
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <MuiBox textAlign="center" mb={3}>
        <Typography variant="subtitle1">
          <FormattedMessage id="page.forgot_password.reset_password_title" />
        </Typography>
      </MuiBox>
      <MuiBox mb={3}>
        <Typography>
          <FormattedMessage id="page.forgot_password.description" />
        </Typography>
      </MuiBox>
      <form onSubmit={handleSubmit(handleCodeSubmit)}>
        <MuiBox mb={4}>
          <ControlledEmailTextField name="email" />
        </MuiBox>
        <MuiBox display="flex" justifyContent="center" mb={2}>
          <Button
            color="secondary"
            className={classes.button}
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage id="page.forgot_password.send_code_button" />
          </Button>
        </MuiBox>
        <MuiBox textAlign="center">
          <Link to={PUBLIC_ROUTES_MAP.login} className={classes.link}>
            <FormattedMessage id="page.forgot_password.link.login" />
          </Link>
        </MuiBox>
      </form>
    </FormProvider>
  );
};

export default ForgotPassword;

import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import Button from '@quanterix-ui/core/Button';
import Dialog from '@quanterix-ui/core/Dialog';
import DialogTitle from '@quanterix-ui/core/DialogTitle';
import DialogActions from '@quanterix-ui/core/DialogActions';

import { useExportUserRequests } from 'src/api/endpoints/userRequests';
import { useCognito } from 'src/aws/Cognito';
import { useSnackbar } from 'src/utils/hooks';
import { requestExportUsersList } from 'src/api/endpoints/admin';

interface Props {
  isRegisteredUsersPage: boolean;
  isUserRequestsPage: boolean;
}

const UsersExport: FC<Props> = ({
  isRegisteredUsersPage,
  isUserRequestsPage,
}) => {
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();

  const { userData } = useCognito();

  const { refetch: exportUserRequests, isFetching } = useExportUserRequests({
    enabled: false,
  });

  const [open, setOpen] = useState(false);
  const [isFetchingRegisteredUsers, setIsFetchingRegisteredUsers] =
    useState(false);

  const handleExportRegisteredUsers = async () => {
    setIsFetchingRegisteredUsers(true);

    try {
      await requestExportUsersList();

      enqueueSuccessSnackbar({
        message: 'page.users.snackbar.export_sent',
        intlValues: { email: userData.email },
      });
    } catch (error) {
      enqueueErrorSnackbar({ message: error.message });
    } finally {
      setIsFetchingRegisteredUsers(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);

    if (isRegisteredUsersPage) {
      handleExportRegisteredUsers();
    }

    if (isUserRequestsPage) {
      exportUserRequests();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        loading={isFetching || isFetchingRegisteredUsers}
        disabled={isFetching || isFetchingRegisteredUsers}
        onClick={handleOpen}
      >
        {isRegisteredUsersPage && (
          <FormattedMessage id="page.users.registered_users.export_button" />
        )}
        {isUserRequestsPage && (
          <FormattedMessage id="page.users.user_requests.export_button" />
        )}
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage id="page.users.export.dialog.description" />
        </DialogTitle>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleConfirm}>
            <FormattedMessage id="app.button.continue" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersExport;

import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';

import ConfirmDialog from 'src/components/ConfirmDialog';

import { AcceleratorFormStepComponentProps } from '../../typings';

interface Props {
  onFormCancel?: AcceleratorFormStepComponentProps['onFormCancel'];
  onFormCancelOpen?: AcceleratorFormStepComponentProps['onFormCancelOpen'];
}

const StepperNavigation: FC<PropsWithChildren<Props>> = ({
  children,
  onFormCancel,
  onFormCancelOpen,
}) => {
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCancelConfirmation = () => {
    if (onFormCancel) {
      onFormCancel();
    }
  };

  useEffect(() => {
    if (onFormCancelOpen) {
      onFormCancelOpen(isOpen);
    }
  }, [isOpen, onFormCancelOpen]);

  return (
    <>
      <MuiBox mt={5}>
        <MuiGrid container justifyContent="space-between">
          <MuiGrid item>
            <MuiGrid container>
              <>{children}</>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item>
            {!!onFormCancel && (
              <Button variant="outlined" onClick={handleOpen}>
                <FormattedMessage id="app.button.cancel" />
              </Button>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
      <div>
        {createPortal(
          <ConfirmDialog
            open={isOpen}
            title={formatMessage({
              id: 'page.accelerator_form.dialog.cancel.confirmation',
            })}
            acceptButtonText={formatMessage({ id: 'app.button.yes' })}
            cancelButtonText={formatMessage({ id: 'app.button.no' })}
            onClose={handleClose}
            onAccept={handleCancelConfirmation}
          />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </>
  );
};

export default StepperNavigation;

import { InstrumentAttributes } from './typings';

export const combineUserInstrumentsFromData = ({
  accelerator_services,
  instruments,
  serialNumbers,
}: InstrumentAttributes) => {
  const userAttributes = [];

  userAttributes.push({
    Name: 'custom:accelerator_services',
    Value: String(accelerator_services),
  });

  userAttributes.push({
    Name: 'custom:instrument',
    Value: JSON.stringify(instruments),
  });

  userAttributes.push({
    Name: 'custom:serial_number',
    Value: JSON.stringify(serialNumbers),
  });

  return userAttributes;
};

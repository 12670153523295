import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

import { fetchContentHubPage } from '../requests/fetchContentHubPage';
import { useOpenTreeViewItems } from '../helpers/useOpenTreeViewItems';
import { ContentHubObjectSchema } from '../typings';
import { QUERY_NAME } from '../constants';

export const defaultOptions = {
  enabled: true,
};

export type UseFetchContentHubPageOptions =
  UseQueryOptions<ContentHubObjectSchema>;

export const useFetchContentHubPage = (
  id: number,
  options: UseFetchContentHubPageOptions = defaultOptions
): UseQueryResult<ContentHubObjectSchema> => {
  const { pageId } = useParams<{ pageId?: string }>();

  const { openTreeViewItem } = useOpenTreeViewItems();

  const result = useQuery<ContentHubObjectSchema>(
    [QUERY_NAME.fetchContentHubPage, id],
    () => fetchContentHubPage({ id }),
    {
      ...options,

      onSuccess: (page) => {
        options.onSuccess?.(page);

        if (pageId && page.id === Number(pageId)) {
          openTreeViewItem(page);
        }
      },
    }
  );

  return result;
};

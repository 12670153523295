import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    fontSize: 28,
    fontFamily: theme.fonts.fontFamily.bold,
  },
}));

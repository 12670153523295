import { FC, ReactNode } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { UserStatuses } from 'src/utils/Constants';

import ConfirmationInfo from '../ConfirmationInfo';
import MailVerifiedInfo from '../MailVerifiedInfo';

interface Props {
  fullName: string;
  mailVerified: boolean;
  controlButtons: ReactNode;
  isAdmin?: boolean;
  isNew?: boolean;
  isEditing?: boolean;
  withDivider?: boolean;
  isConfirmInProgress?: boolean;
  status?: keyof typeof UserStatuses;
  confirmUser?: () => void;
}

const Header: FC<Props> = ({
  isAdmin = false,
  isNew = false,
  isEditing = false,
  withDivider = false,
  isConfirmInProgress = false,
  fullName,
  mailVerified,
  status,
  controlButtons,
  confirmUser,
}) => {
  const handleConfirmUser = () => {
    if (confirmUser) {
      confirmUser();
    }
  };

  const renderAdditionalButtons = () => {
    if (isNew) {
      return null;
    }

    if (isAdmin) {
      return (
        <ConfirmationInfo
          status={status}
          isEditing={isEditing}
          isConfirmInProgress={isConfirmInProgress}
          onConfirm={handleConfirmUser}
        />
      );
    }

    return <MailVerifiedInfo mailVerified={mailVerified} />;
  };

  return (
    <Box width="100%" mt={4} mb={5}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <Box mr={5}>
              <Typography variant="h5">{fullName}</Typography>
            </Box>
            {withDivider && <Divider flexItem orientation="vertical" />}
            <Box ml={5} mb={-0.5}>
              {renderAdditionalButtons()}
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            {controlButtons}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;

export const DEFAULT_USER_FORM_VALUES = {
  email: '',
  family_name: '',
  given_name: '',
  phone_number: '',
  company: '',
  instrums: [],
  accelerator_services: false,
  website: '',
  address_country: '',
  address_postal_code: '',
  address_region: '',
  address_locality: '',
  address_street: '',
  address_street2: '',
};

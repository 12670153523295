import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    justify: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.constants.borderRadius,
    overflow: 'hidden',
  },

  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.common.white,
  },

  details: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `linear-gradient(to top, ${alpha(
      theme.palette.common.black,
      0.6
    )}, transparent)`,

    '& span': {
      position: 'absolute',
      right: 10,
      bottom: 10,
      color: theme.palette.common.white,
      textShadow: `1px 1px 5px ${alpha(theme.palette.common.black, 0.7)}`,
    },
  },
}));

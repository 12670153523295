import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 56,
    borderRadius: theme.constants.borderRadius,
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    marginBottom: 15,
    padding: '0 15px',
    overflow: 'hidden',

    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },

  accordionExpanded: {
    margin: 0,
  },

  accordionSummaryRoot: {
    position: 'relative',
    display: 'block',
    width: '100%',
    minHeight: '56px !important',
    margin: 0,
    border: 'none',
    borderBottom: `1px solid transparent`,
    paddingLeft: 38,

    '&[class*=Mui-expanded]': {
      borderBottom: `1px solid ${theme.palette.divider} !important`,

      '& .expandIcon': {
        rotate: '90deg',
      },
    },
  },

  accordionSummaryContent: {
    display: 'block',
    height: 56,

    '&[class*=Mui-expanded]': {
      margin: 0,
    },

    '& [class*=MuiGrid-container]': {
      height: '100%',
    },
  },

  accordionDetailsRoot: {
    padding: '8px 0 15px',
  },

  title: {
    fontFamily: theme.fonts.fontFamily.regular,
    fontSize: 16,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  expandIcon: {
    position: 'absolute',
    top: '50%',
    left: 0,
    translate: '0 -50%',
    width: 24,
    height: 24,
    transition: 'rotate 50ms linear',
  },
}));

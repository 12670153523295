import { ChangeEvent, FC, MouseEvent } from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTypography from '@material-ui/core/Typography';

import Pagination from '@quanterix-ui/core/Pagination';
import Button from '@quanterix-ui/core/Button';

import { usePaginationContext } from 'src/context/PaginationContext';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { useFilterContext } from 'src/context/FilterContext';
import { PROTECTED_ROUTES_MAP } from 'src/router';

import Filter from './components/Filter';
import UserRow from './components/UserRow';
import UsersExport from './components/UsersExport';
import { UserSchema } from './typings';
import {
  DEFAULT_PAGE,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_COLUMNS_COUNT,
  TEST_ID,
} from './constants';
import { useStyles } from './styles';

interface Props {
  users: UserSchema[];
  usersCount?: number;
  isPreviousData?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  withPagination?: boolean;
  withLoadMore?: boolean;
  paginationToken?: string;
  onLoadMore?: () => void;
  onConfirmUserRequest?: (user: UserSchema) => void;
  onConfirmRegisteredUser?: (user: UserSchema) => void;
  onToggleUserAvailability?: (user: UserSchema) => void;
  onRefetchUsers: () => void;
  onDeleteUser: (user: UserSchema) => void;
  onRedirectToView: (user: UserSchema) => void;
}

const UserList: FC<Props> = ({
  users,
  usersCount = 0,
  isPreviousData = false,
  isFetching = false,
  isLoading = false,
  withPagination = false,
  withLoadMore = false,
  paginationToken,
  onLoadMore,
  onConfirmUserRequest,
  onConfirmRegisteredUser,
  onToggleUserAvailability,
  onRefetchUsers,
  onDeleteUser,
  onRedirectToView,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const registeredUsersPageMatch = useRouteMatch({
    path: PROTECTED_ROUTES_MAP.adminRegisteredUsers,
    exact: true,
    strict: true,
  });
  const isRegisteredUsersPage = Boolean(registeredUsersPageMatch);

  const userRequestsPageMatch = useRouteMatch({
    path: PROTECTED_ROUTES_MAP.adminUserRequests,
    exact: true,
    strict: true,
  });
  const isUserRequestsPage = Boolean(userRequestsPageMatch);

  const { isFilterApplied } = useFilterContext();

  const { pagination, setPagination } = usePaginationContext();

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination({
      ...pagination,
      page: newPage,
      skip: newPage * pagination.rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination({
      page: DEFAULT_PAGE,
      skip: DEFAULT_PAGE * DEFAULT_PAGE,
      rowsPerPage: Number(event.target.value),
    });
  };

  const handleAddRegisteredUser = () => {
    history.push(PROTECTED_ROUTES_MAP.adminUsersNew);
  };

  const isDataEmpty = !usersCount || usersCount === 0;

  return (
    <ProgressOverlay
      withLoadingDisk
      loading={isLoading || isFetching}
      size={40}
    >
      <MuiTableContainer data-testid={TEST_ID.userList}>
        <MuiBox mb={4}>
          <MuiGrid container justifyContent="space-between">
            <MuiGrid item>
              <Filter
                isRegisteredUsersPage={isRegisteredUsersPage}
                isUserRequestsPage={isUserRequestsPage}
                refetch={onRefetchUsers}
              />
            </MuiGrid>
            <MuiGrid item>
              <MuiGrid container>
                <MuiBox mt={4}>
                  <UsersExport
                    isRegisteredUsersPage={isRegisteredUsersPage}
                    isUserRequestsPage={isUserRequestsPage}
                  />
                </MuiBox>
                {isRegisteredUsersPage && (
                  <MuiBox mt={4} ml={2}>
                    <Button onClick={handleAddRegisteredUser}>
                      <FormattedMessage id="page.users.button.add_new" />
                    </Button>
                  </MuiBox>
                )}
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiBox>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'move' },
          }}
          className={classes.scrollbars}
        >
          <MuiTable className={classes.table}>
            <MuiTableHead style={{ position: 'relative' }}>
              <MuiTableRow>
                <MuiTableCell>
                  <FormattedMessage id="page.users.table.column.name" />
                </MuiTableCell>
                <MuiTableCell>
                  <FormattedMessage id="page.users.table.column.email" />
                </MuiTableCell>
                <MuiTableCell>
                  <FormattedMessage id="page.users.table.column.phone" />
                </MuiTableCell>
                <MuiTableCell>
                  <FormattedMessage id="page.users.table.column.company" />
                </MuiTableCell>
                {isRegisteredUsersPage && (
                  <MuiTableCell>
                    <FormattedMessage id="page.users.table.column.status" />
                  </MuiTableCell>
                )}
                {isRegisteredUsersPage && (
                  <MuiTableCell>
                    <FormattedMessage id="page.users.table.column.enabled" />
                  </MuiTableCell>
                )}
                <MuiTableCell align="right" style={{ paddingRight: 32 }}>
                  <FormattedMessage id="page.users.table.column.actions" />
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody
              className={cx({ [classes.isTableLoading]: isPreviousData })}
            >
              {isDataEmpty && (
                <MuiTableRow>
                  <MuiTableCell
                    align="center"
                    colSpan={TABLE_COLUMNS_COUNT}
                    className={classes.noDataPlaceholderCell}
                  >
                    <MuiTypography variant="h3">
                      {isRegisteredUsersPage && (
                        <FormattedMessage id="page.users.registered_users.table.no_data" />
                      )}
                      {isUserRequestsPage && (
                        <FormattedMessage id="page.users.user_requests.table.no_data" />
                      )}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
              {users?.map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  isRegisteredUsersPage={isRegisteredUsersPage}
                  isUserRequestsPage={isUserRequestsPage}
                  onConfirmUserRequest={onConfirmUserRequest}
                  onConfirmRegisteredUser={onConfirmRegisteredUser}
                  onToggleUserAvailability={onToggleUserAvailability}
                  onDeleteUser={onDeleteUser}
                  onRedirectToView={onRedirectToView}
                />
              ))}
            </MuiTableBody>
          </MuiTable>
        </OverlayScrollbarsComponent>
        {withPagination && (
          <Pagination
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            count={usersCount}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {withLoadMore && !isFilterApplied && paginationToken && onLoadMore && (
          <MuiGrid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: 65 }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              variant="outlined"
              onClick={onLoadMore}
            >
              <FormattedMessage id="page.users.button.load_more" />
            </Button>
          </MuiGrid>
        )}
      </MuiTableContainer>
    </ProgressOverlay>
  );
};

export default UserList;

import { useQuery, UseQueryResult } from 'react-query';

import { fetchContentHubPages } from '../requests/fetchContentHubPages';
import { ContentHubObjectSchema, ContentHubPagesFilters } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseFetchContentHubPagesOptions {
  enabled?: boolean;
  onSuccess?: (data: ContentHubObjectSchema[]) => void;
}

export interface UseFetchContentHubPagesResult
  extends Omit<UseQueryResult, 'data'> {
  data: ContentHubObjectSchema[];
}

export const useFetchContentHubPages = (
  filters?: ContentHubPagesFilters,
  options: UseFetchContentHubPagesOptions = defaultOptions
): UseFetchContentHubPagesResult => {
  const { data = [], ...restValues } = useQuery<ContentHubObjectSchema[]>(
    [QUERY_NAME.fetchContentHubPages],
    () => fetchContentHubPages({ filters }),
    options
  );

  return {
    data,
    ...restValues,
  } as UseFetchContentHubPagesResult;
};

import { FC, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import DownloadButton from '@quanterix-ui/core/DownloadButton';

import { useFetchSubmission } from 'src/api/endpoints/submission';
import { getDownloadUrl } from 'src/utils/helpers/blobStorage';
import ProgressIndicator from 'src/components/elements/ProgressIndicator';
import File from 'src/components/File';

import { TEST_ID } from './constants';

const DownloadManifests: FC = () => {
  const { formId } = useParams<{ formId: string }>();

  const [downloadAllLink, setDownloadAllLink] = useState<string>();

  const { data: submission, isFetching } = useFetchSubmission(formId);

  const manifests = useMemo(() => {
    return submission?.sampleReagentsManifests || [];
  }, [submission]);

  useEffect(() => {
    const initDownloadAllLink = async () => {
      const filePath = submission?.manifestsZipFilename;

      if (filePath) {
        const link = await getDownloadUrl(filePath);
        setDownloadAllLink(link);
      }
    };

    if (submission) {
      initDownloadAllLink();
    }
  }, [submission]);

  return (
    <MuiBox width="100%">
      <MuiGrid container direction="column" data-testid={TEST_ID.pageContainer}>
        <MuiGrid item xs={12}>
          <Typography variant="h2">
            <FormattedMessage id="page.download_manifests.title" />
          </Typography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiBox py={3}>
            <MuiGrid container>
              {manifests.map((manifest) => (
                <MuiGrid key={manifest} item xs={12}>
                  <File filePath={manifest} />
                </MuiGrid>
              ))}
            </MuiGrid>
          </MuiBox>
        </MuiGrid>
        {!submission && !isFetching && (
          <MuiGrid item xs={12}>
            <Typography variant="h3" data-testid={TEST_ID.notFoundMessage}>
              <FormattedMessage id="page.download_manifests.not_found" />
            </Typography>
          </MuiGrid>
        )}
        {downloadAllLink && (
          <MuiGrid item xs={12}>
            <DownloadButton
              href={downloadAllLink}
              data-testid={TEST_ID.downloadAllButton}
            >
              <FormattedMessage id="app.button.download_all" />
            </DownloadButton>
          </MuiGrid>
        )}
      </MuiGrid>
      {isFetching && (
        <MuiBox position="absolute" top={0} left={0} width="100%" height="100%">
          <ProgressIndicator isVisible={isFetching} />
        </MuiBox>
      )}
    </MuiBox>
  );
};

export default DownloadManifests;

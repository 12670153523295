import { privateGateway } from '../../../config';
import { PostAdminNotificationParams } from '../typings';

export const postAdminNotification = async (
  params: PostAdminNotificationParams
): Promise<void> => {
  await privateGateway(process.env.REACT_APP_NOTIFICATIONS_API as string).post(
    `/notifications`,
    params
  );
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: 146,
    height: 70,
  },

  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 128,
    height: 70,
  },

  dot: {
    position: 'absolute',
    width: 10,
    height: 10,
    borderRadius: '50px 50px',
    backgroundColor: theme.palette.primary.main,
    animation: '$spin 1.5s linear infinite',
    transformOrigin: 'center center',

    '&.dot-2': {
      animation: '$spin 1.5s -0.75s linear infinite',
    },

    '&.dot-3': {
      left: 30,
      animation: '$spin 1.5s -1.3s linear infinite',
    },

    '&.dot-4': {
      left: 30,
      animation: '$spin 1.5s -0.55s linear infinite',
    },

    '&.dot-5': {
      left: 60,
      animation: '$spin 1.5s -1.1s linear infinite',
    },

    '&.dot-6': {
      left: 60,
      animation: '$spin 1.5s -0.35s linear infinite',
    },

    '&.dot-7': {
      left: 90,
      animation: '$spin 1.5s -0.9s linear infinite',
    },

    '&.dot-8': {
      left: 90,
      animation: '$spin 1.5s -0.15s linear infinite',
    },

    '&.dot-9': {
      left: 120,
      animation: '$spin 1.5s -0.7s linear infinite',
    },

    '&.dot-10': {
      left: 120,
      animation: '$spin 1.5s -0.05s linear infinite',
    },
  },

  base: {
    position: 'absolute',
    top: 30,
    left: 5,
    width: 1,
    height: 10,
    backgroundColor: theme.palette.primary.main,
    animation: '$flex 1.5s linear infinite',
    transformOrigin: 'center center',

    '&.base-2': {
      left: 35,
      animation: '$flex 1.5s -1.3s linear infinite',
    },

    '&.base-3': {
      left: 65,
      animation: '$flex 1.5s -1.1s linear infinite',
    },

    '&.base-4': {
      left: 95,
      animation: '$flex 1.5s -0.9s linear infinite',
    },

    '&.base-5': {
      left: 125,
      animation: '$flex 1.5s -0.75s linear infinite',
    },
  },

  '@keyframes spin': {
    '0%, 100%': {
      transform: 'translateY(0px) scale(1)',
    },
    '25%': {
      transform: 'translateY(30px) scale(2)',
    },
    '55%': {
      transform: 'translateY(60px) scale(1)',
    },
    '75%': {
      transform: 'translateY(30px) scale(0.3)',
    },
  },

  '@keyframes flex': {
    '0%, 100%': {
      transform: 'scaleY(5)',
    },
    '25%': {
      transform: 'scaleY(1)',
    },
    '55%': {
      transform: 'scaleY(5)',
    },
    '75%': {
      transform: 'scaleY(1)',
    },
  },
}));

import { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import {
  PAGE_SLUG,
  PAGE_TYPE,
  useFetchContentHubPage,
} from 'src/api/endpoints/contentHub';
import { PROTECTED_ROUTES_MAP } from 'src/router';
import ContentTreeViewer from 'src/components/ContentTreeViewer';
import { useNotFoundContext } from 'src/context/NotFoundContext';

import EditableContentContextProvider from './context/EditableContent';
import { useContentManagementContext } from './context/ContentManagementContext';
import ContentHeader from './components/ContentHeader';
import ContentBody from './components/ContentBody';
import ContentActionBar from './components/ContentActionBar';
import { DEFAULT_DESCRIPTION_VALUE, FORM_VALUES } from './constants';
import { FormValuesSchema } from './typings';
import { useStyles } from './styles';

const ContentManagement: FC = () => {
  const classes = useStyles();

  const { pageId, pageName } = useParams<{
    pageId: string;
    pageName: string;
  }>();

  const { setPageExists } = useNotFoundContext();
  const {
    isLegalDocumentsPage,
    setIsLegalDocumentsCategory,
    setIsLegalDocumentsPage,
  } = useContentManagementContext();

  const formMethods = useForm<FormValuesSchema>({
    defaultValues: {
      [FORM_VALUES.title]: '',
      [FORM_VALUES.description]: DEFAULT_DESCRIPTION_VALUE,
      [FORM_VALUES.image]: null,
    },
  });
  const { setValue, reset } = formMethods;

  const { data: page } = useFetchContentHubPage(Number(pageId), {
    enabled: Boolean(pageId),
    onError: () => {
      setPageExists(false);
    },
  });

  const { data: category } = useFetchContentHubPage(Number(page?.parent_id), {
    enabled: Boolean(page?.parent_id),
  });

  useEffect(() => {
    if (!page || page.name !== decodeURIComponent(pageName)) {
      setPageExists(false);
    }
  }, [page, pageName, setPageExists]);

  useEffect(() => {
    setIsLegalDocumentsCategory(page?.slug === PAGE_SLUG.legalDocuments);
  }, [page?.slug, setIsLegalDocumentsCategory]);

  useEffect(() => {
    setIsLegalDocumentsPage(category?.slug === PAGE_SLUG.legalDocuments);
  }, [category?.slug, setIsLegalDocumentsPage]);

  useEffect(() => {
    setValue('title', page?.name || '');
  }, [page?.name, setValue]);

  useEffect(() => {
    setValue('description', page?.text || DEFAULT_DESCRIPTION_VALUE);
  }, [page?.text, setValue]);

  const handleEditCancel = () => {
    reset({
      [FORM_VALUES.title]: page?.name || '',
      [FORM_VALUES.description]: page?.text || DEFAULT_DESCRIPTION_VALUE,
      [FORM_VALUES.image]: null,
    });
  };

  const isContentBodyAvailable =
    !isLegalDocumentsPage && page?.type !== PAGE_TYPE.section;

  return (
    <FormProvider {...formMethods}>
      <EditableContentContextProvider>
        <MuiBox mb={4}>
          <MuiGrid container className={classes.mainGridContainer}>
            <MuiGrid item xs={12} md={3}>
              <MuiTypography className={classes.pageTitle} color="secondary">
                <FormattedMessage id="page.content_management.title" />
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
        </MuiBox>
        <MuiGrid container justifyContent="space-between">
          <MuiGrid item xs={4} lg={2}>
            <ContentTreeViewer
              showHidden
              selectAllPath={PROTECTED_ROUTES_MAP.adminContentManagement}
              rootPath={PROTECTED_ROUTES_MAP.adminContentManagement}
            />
          </MuiGrid>
          <MuiGrid item xs={8} lg={10}>
            <MuiGrid container justifyContent="center">
              <MuiBox width="100%" maxWidth={800} pr={1}>
                <ContentHeader />
                {isContentBodyAvailable && <ContentBody />}
                <ContentActionBar onCancel={handleEditCancel} />
              </MuiBox>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </EditableContentContextProvider>
    </FormProvider>
  );
};

export default ContentManagement;

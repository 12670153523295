import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { updateFAQSectionItem } from '../requests/updateFAQSectionItem';
import {
  FAQSectionItemSchema,
  UpdateFAQSectionItemVariables,
} from '../typings';
import { QUERY_NAME } from '../constants';
import { getQueryKey } from '../utils';

interface UseMutationContext {
  previousData?: FAQSectionItemSchema[];
}

export type UseUpdateFAQSectionItemResult = UseMutationResult<
  FAQSectionItemSchema,
  Error,
  UpdateFAQSectionItemVariables,
  UseMutationContext
>;

export const useUpdateFAQSectionItem = (): UseUpdateFAQSectionItemResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const mutation = useMutation<
    FAQSectionItemSchema,
    Error,
    UpdateFAQSectionItemVariables,
    UseMutationContext
  >(updateFAQSectionItem, {
    onMutate: async ({ section_id }) => {
      await queryClient.cancelQueries(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      const previousData = queryClient.getQueryData<FAQSectionItemSchema[]>(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      return { previousData };
    },

    onSuccess: (data, { id, section_id }, _context) => {
      queryClient.setQueryData<FAQSectionItemSchema[]>(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id),
        (previous = []) =>
          previous.map((item) => (item.id === id ? data : item))
      );

      enqueueSuccessSnackbar({
        message: 'page.faq_management.api.section_item.update',
      });
    },

    onError: ({ message }, { section_id }, context) => {
      queryClient.setQueryData(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id),
        context?.previousData
      );

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 270,
    height: 200,
    marginRight: 50,

    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginBottom: 20,
    },
  },

  imageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.constants.borderRadius,
    overflow: 'hidden',

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  clearIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.grey[100],

    '&[class*=Mui-disabled]': {
      backgroundColor: theme.palette.grey[100],
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },

  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'block',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
  },

  imageIconWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: 80,
      height: 'auto',
      fill: alpha(theme.palette.secondary.main, 0.1),
    },
  },

  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  formErrorsContainer: {
    minHeight: 26,
    paddingTop: 2,

    '& p': {
      margin: 0,
    },
  },
}));

import { FC, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import Parcel from 'single-spa-react/parcel';
import {
  mountRootParcel,
  ParcelConfig,
  start as singleSpaStart,
} from 'single-spa';

import MuiBox from '@material-ui/core/Box';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { useNotFoundContext } from 'src/context/NotFoundContext';
import { importRemoteScript } from 'src/micro-frontend/utils/helpers/importRemoteScript';
import { ServiceConfigData } from 'src/micro-frontend/typings/common';
import { useServicesStore } from 'src/micro-frontend/stores/ServicesStore';
import { useCognito } from 'src/aws/Cognito';

interface AppParcelConfig extends ServiceConfigData {
  baseHref: string;
  assetsUrl: string;
}

const Module: FC = observer(() => {
  const { moduleName, submoduleName } = useParams<{
    moduleName?: string;
    submoduleName?: string;
  }>();

  const { setPageExists } = useNotFoundContext();

  const { isAuthenticated, groups } = useCognito();

  const { availableServices, setGroups, fetchServices } = useServicesStore();

  const [app, setApp] = useState<ServiceConfigData | null>(null);
  const [loading, setLoading] = useState(false);
  const [parcelConfig, setParcelConfig] =
    useState<ParcelConfig<ServiceConfigData> | null>(null);
  const [appConfig, setAppConfig] = useState<AppParcelConfig | null>(null);
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if (isAuthenticated && groups.length > 0) {
      setGroups(groups);
      fetchServices();
    }
  }, [isAuthenticated, groups, setGroups, fetchServices]);

  useEffect(() => {
    const importModule = async () => {
      if (!moduleName || !submoduleName) {
        setPageExists(false);
        return;
      }

      if (!!app && app.name === moduleName) {
        return;
      }

      if (!!availableServices && availableServices.length) {
        const appInstance = availableServices
          .filter((service) => !service.externalUrl)
          .find(
            (service) =>
              service.client === moduleName && service.name === submoduleName
          );

        if (appInstance) {
          singleSpaStart();
          setLoading(true);
          setApp(appInstance);
          setParcelConfig(null);
          setPageTitle(`Module: ${moduleName} - ${appInstance.name}`);

          try {
            const parcelUrl = `${
              appInstance.scriptPath
            }?date=${new Date().getTime()}`;

            const app = await importRemoteScript<ParcelConfig>(parcelUrl);

            setParcelConfig(app);

            setAppConfig({
              assetsUrl: `https://${appInstance.client}.${appInstance.domain}.qtrxservices.com/modules/${appInstance.name}`,
              baseHref: `/${appInstance.name}`,
              ...appInstance,
            });
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        } else {
          setPageExists(false);
        }
      }
    };

    importModule();
  }, [moduleName, submoduleName, app, availableServices, setPageExists]);

  return (
    <ProgressOverlay
      withLoadingDisk
      loading={loading || !parcelConfig}
      size={40}
      opacity={1}
    >
      <MuiBox>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {!loading && !parcelConfig && (
          <div className="page-wrapper">
            <FormattedMessage id="page.module.load.error" />
          </div>
        )}
        {!loading && !!parcelConfig && (
          <div id="app-wrapper">
            <Parcel
              config={parcelConfig}
              mountParcel={mountRootParcel}
              wrapWith="div"
              appConfig={appConfig}
            />
          </div>
        )}
      </MuiBox>
    </ProgressOverlay>
  );
});

export default Module;

import { FC, useMemo } from 'react';

import cx from 'classnames';

import MuiAvatar from '@material-ui/core/Avatar';
import MuiTypography from '@material-ui/core/Typography';

import { useStyles } from './styles';

interface Props {
  givenName?: string;
  familyName?: string;
  size?: 'small' | 'large';
  color?: 'primary' | 'secondary';
}

const ProfileAvatar: FC<Props> = ({
  givenName = '',
  familyName = '',
  size = 'small',
  color = 'primary',
}) => {
  const classes = useStyles();

  const nameInitials = useMemo(() => {
    return `${givenName?.charAt(0)}${familyName?.charAt(0)}`;
  }, [givenName, familyName]);

  return (
    <MuiAvatar className={cx(classes.profileAvatar, size, color)}>
      <MuiTypography className={classes.nameInitials}>
        {nameInitials}
      </MuiTypography>
    </MuiAvatar>
  );
};

export default ProfileAvatar;

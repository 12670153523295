import { FC } from 'react';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiFormatBoldIcon from '@material-ui/icons/FormatBold';
import MuiFormatItalicIcon from '@material-ui/icons/FormatItalic';
import MuiFormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import MuiFormatCodeIcon from '@material-ui/icons/Code';
import MuiFormatQuoteIcon from '@material-ui/icons/FormatQuote';
import MuiFormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MuiFormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import MuiFormatLinkIcon from '@material-ui/icons/Link';

import EditorButton from '../EditorButton';
import { ACTION_TYPE } from '../../constants';

import { useStyles } from './styles';

const EditorToolbar: FC = () => {
  const classes = useStyles();

  return (
    <MuiBox className={classes.root}>
      <MuiGrid container alignItems="center">
        {/* NOTE: Mark buttons */}
        <EditorButton
          variant={ACTION_TYPE.mark}
          icon={<MuiFormatBoldIcon fontSize="small" />}
          format="bold"
        />
        <EditorButton
          variant={ACTION_TYPE.mark}
          icon={<MuiFormatItalicIcon fontSize="small" />}
          format="italic"
        />
        <EditorButton
          variant={ACTION_TYPE.mark}
          icon={<MuiFormatUnderlinedIcon fontSize="small" />}
          format="underline"
        />
        <EditorButton
          variant={ACTION_TYPE.mark}
          icon={<MuiFormatCodeIcon fontSize="small" />}
          format="code"
        />
        {/* NOTE: Block buttons */}
        <EditorButton
          variant={ACTION_TYPE.block}
          icon={<MuiFormatQuoteIcon fontSize="small" />}
          format="block-quote"
        />
        <EditorButton
          variant={ACTION_TYPE.block}
          icon={<MuiFormatListBulletedIcon fontSize="small" />}
          format="bulleted-list"
        />
        <EditorButton
          variant={ACTION_TYPE.block}
          icon={<MuiFormatListNumberedIcon fontSize="small" />}
          format="numbered-list"
        />
        {/* NOTE: custom buttons */}
        <EditorButton
          variant={ACTION_TYPE.link}
          icon={
            <MuiFormatLinkIcon
              fontSize="small"
              style={{ transform: 'rotate(-45deg)' }}
            />
          }
          format="link"
        />
      </MuiGrid>
    </MuiBox>
  );
};

export default EditorToolbar;

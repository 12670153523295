import { FC, MouseEventHandler, useMemo, useState } from 'react';

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Virtuoso } from 'react-virtuoso';

import MuiBox from '@material-ui/core/Box';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiDivider from '@material-ui/core/Divider';
import MuiTypography from '@material-ui/core/Typography';
import MuiBadge from '@material-ui/core/Badge';
import MuiFade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

import {
  NOTIFICATION_STATUS,
  useBatchUpdateNotifications,
  useLiveNotifications,
} from 'src/api/endpoints/notifications';
import VirtuosoCustomScrollbar from 'src/components/elements/VirtuosoCustomScrollbar';

import { TEST_ID } from '../../constants';
import NotificationMenuItem from '../NotificationMenuItem';

import { useStyles } from './styles';

const NotificationsDropdown: FC = () => {
  const classes = useStyles();

  const {
    data: { data: notifications = [] } = {},
    isLoading: isLoadingNotifications,
  } = useLiveNotifications();

  const notificationsCount = notifications.length;

  const { mutate: batchUpdateNotifications } = useBatchUpdateNotifications();

  const unreadNotificationsCount = useMemo(
    () =>
      notificationsCount > 0
        ? notifications.filter(
            (notification) =>
              notification.status === NOTIFICATION_STATUS.available
          ).length
        : 0,
    [notifications, notificationsCount]
  );

  const [optionsMenuAnc, setOptionsMenuAnc] = useState<HTMLElement | null>(
    null
  );

  const [notificationsMenuAnc, setNotificationsMenuAnc] =
    useState<HTMLElement | null>(null);

  const handleOpenOptions: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOptionsMenuAnc(event.currentTarget);
  };

  const handleNotificationsClick: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setNotificationsMenuAnc(event.currentTarget);
  };

  const handleMarkAllNotificationAsRead = () => {
    setOptionsMenuAnc(null);
    if (notificationsCount > 0) {
      batchUpdateNotifications({
        notificationIds: notifications.map((notification) => notification.id),
        params: { status: NOTIFICATION_STATUS.acknowledged },
      });
    }
  };

  const handleClearAllNotification = () => {
    setOptionsMenuAnc(null);
    if (notificationsCount > 0) {
      batchUpdateNotifications({
        notificationIds: notifications.map((notification) => notification.id),
        params: { status: NOTIFICATION_STATUS.deleted },
      });
    }
  };

  return (
    <>
      <MuiIconButton
        color={notificationsMenuAnc ? 'primary' : 'inherit'}
        data-testid={TEST_ID.appHeaderNotifications}
        onClick={handleNotificationsClick}
      >
        <MuiBadge
          variant="dot"
          color="error"
          overlap="circular"
          invisible={unreadNotificationsCount === 0}
        >
          <NotificationsIcon />
        </MuiBadge>
      </MuiIconButton>
      <MuiMenu
        classes={{ list: classes.list, paper: classes.menu }}
        anchorEl={notificationsMenuAnc}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(notificationsMenuAnc)}
        TransitionComponent={MuiFade}
        onClose={() => setNotificationsMenuAnc(null)}
      >
        <MuiBox
          component="li"
          py={3}
          px={4}
          display="flex"
          justifyContent="space-between"
        >
          <MuiTypography className={classes.menuHeader}>
            <FormattedMessage id="app_header.notifications" />
          </MuiTypography>
          <MuiIconButton
            data-testid={TEST_ID.batchNotificationOptionsButton}
            className={classes.kebabButton}
            onClick={handleOpenOptions}
          >
            <MoreVertIcon />
          </MuiIconButton>
          <MuiMenu
            classes={{ paper: cx(classes.menu, classes.optionsMenu) }}
            anchorEl={optionsMenuAnc}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(optionsMenuAnc)}
            onClose={() => setOptionsMenuAnc(null)}
          >
            <MuiMenuItem onClick={handleMarkAllNotificationAsRead}>
              <FormattedMessage id="app_header.notifications.mark_all_as_read" />
            </MuiMenuItem>
            <MuiMenuItem onClick={handleClearAllNotification}>
              <FormattedMessage id="app_header.notifications.clear_all" />
            </MuiMenuItem>
          </MuiMenu>
        </MuiBox>
        <MuiDivider
          variant="middle"
          component="li"
          className={classes.divider}
        />
        {isLoadingNotifications && (
          <MuiBox component="li" p={2} textAlign="center">
            <MuiCircularProgress />
          </MuiBox>
        )}
        <Virtuoso
          components={{
            Scroller: VirtuosoCustomScrollbar,
          }}
          style={{
            height: notificationsCount === 0 ? 0 : 506,
          }}
          data={notifications}
          itemContent={(index, item) => (
            <MuiBox key={index}>
              <NotificationMenuItem notification={item} />
              <MuiDivider
                variant="middle"
                component="li"
                className={classes.divider}
              />
            </MuiBox>
          )}
        />
        {!isLoadingNotifications && notificationsCount === 0 && (
          <MuiBox component="li" py={3} px={4}>
            <MuiTypography variant="body2">
              <FormattedMessage id="app_header.notifications.empty" />
            </MuiTypography>
          </MuiBox>
        )}
      </MuiMenu>
    </>
  );
};

export default NotificationsDropdown;

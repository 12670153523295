import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { fetchCurrentUser } from '../requests/fetchCurrentUser';
import { CurrentUserSchema } from '../typings';
import { QUERY_NAME } from '../constants';

export const defaultOptions = {
  enabled: true,
};

export type UseFetchCurrentUserOptions = UseQueryOptions<CurrentUserSchema>;

export const useFetchCurrentUser = (
  options: UseFetchCurrentUserOptions = defaultOptions
): UseQueryResult<CurrentUserSchema> => {
  const result = useQuery<CurrentUserSchema>(
    [QUERY_NAME.currentUser],
    fetchCurrentUser,
    options
  );

  return result;
};

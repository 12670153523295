import { privateGateway } from '../../../config';

interface DeleteFilesParams {
  file_names: string[];
}

export const deleteFiles = async ({
  file_names,
}: DeleteFilesParams): Promise<void> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).delete('/files', { data: { file_names } });

  return response.data;
};

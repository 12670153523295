import { makeStyles } from '@material-ui/core/styles';

import QuanterixBackgroundImage from 'src/assets/images/quanterix-hero.jpg';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '35px 10px',
    minHeight: '100vh',
    background: `url(${QuanterixBackgroundImage}) no-repeat center center fixed`,
    backgroundSize: 'cover',

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
  },

  contentWrapper: {
    width: '100%',
    maxWidth: 940,
    padding: '100px 20px',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 10,

    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px',
    },
  },

  content: {
    width: '100%',
    maxWidth: 480,
  },

  logoImage: {
    width: '100%',
    maxWidth: 338,
  },
}));

import { privateGateway } from '../../../config';
import { ContentHubObjectSchema } from '../typings';
import { MOVE_DIRECTION } from '../constants';

interface RequestOrderChangeForContentHubPageParams {
  id: number;
  direction: MOVE_DIRECTION;
}

export const requestOrderChangeForContentHubPage = async ({
  id,
  direction,
}: RequestOrderChangeForContentHubPageParams): Promise<ContentHubObjectSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).put(`/pages/${id}/${direction}`);

  return response.data;
};

import { Instrument, InstrumentField } from './typings';

export const INSTRUMENTS: Instrument[] = [
  'spx',
  'srx',
  'hdx',
  'hd1',
  'acceleratorServices',
];

export const DEFAULT_INSTRUMENT_VALUE: InstrumentField = {
  spx: {
    selected: false,
    serialNumber: '',
  },
  srx: {
    selected: false,
    serialNumber: '',
  },
  hdx: {
    selected: false,
    serialNumber: '',
  },
  hd1: {
    selected: false,
    serialNumber: '',
  },
  acceleratorServices: {
    selected: false,
    serialNumber: '',
  },
};

import { FC } from 'react';

import Button from '@material-ui/core/Button';

interface Props {
  mailVerified: boolean;
}

const MailVerifiedInfo: FC<Props> = ({ mailVerified }) => {
  if (!mailVerified) {
    <>
      <span className="warning">Email not verified</span>{' '}
      <Button
        className="QuanButton SmallButton LongButton"
        variant="contained"
        color="primary"
      >
        Send Code
      </Button>{' '}
    </>;
  }

  return <span className="highlighted-text">Email verified</span>;
};

export default MailVerifiedInfo;

import { makeStyles } from '@material-ui/core/styles';

import { NAV_CARD_WIDTH } from '../NavCard';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, ${NAV_CARD_WIDTH}px)`,
    justifyContent: 'space-between',
    columnGap: 50,
  },
}));

import { FAQSectionItemSchema } from '../typings';
import { privateGateway } from '../../../config';

export const fetchFAQSectionItems = async (
  seactionId: number
): Promise<FAQSectionItemSchema[]> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/faq/sections/${seactionId}/items`);

  return response.data;
};

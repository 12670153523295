import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import MuiPaper from '@material-ui/core/Paper';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiTypography from '@material-ui/core/Typography';

import { PRIVATE_ROUTES_MAP } from 'src/router';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props {
  title: string;
}

const SuggestionCard: FC<Props> = ({ title }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleClick = () => {
    history.push(PRIVATE_ROUTES_MAP.contentHubViewerAll, {
      searchString: title,
    });
  };

  return (
    <MuiPaper className={classes.card} data-testid={TEST_ID.suggestionCard}>
      <MuiButtonBase
        component="div"
        className={classes.cardButton}
        data-testid={TEST_ID.cardButton}
        onClick={handleClick}
      >
        <MuiTypography
          variant="button"
          color="secondary"
          className={classes.title}
        >
          {title}
        </MuiTypography>
      </MuiButtonBase>
    </MuiPaper>
  );
};

export default SuggestionCard;

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    width: 380,
    marginTop: 5,
    boxShadow: '0 2px 10px rgba(0 0 0 / 10%)',
    borderRadius: 4,
  },

  list: {
    padding: 0,
  },

  menuHeader: {
    fontFamily: theme.fonts.fontFamily.medium,
    fontSize: 18,
  },

  divider: {
    backgroundColor: theme.palette.grey[300],
  },

  kebabButton: {
    padding: 0,
    left: 17,
  },

  optionsMenu: {
    width: 'unset',
  },
}));

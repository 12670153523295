import { useMemo } from 'react';

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { FILE_STATUS, QUERY_NAME } from '../constants';
import {
  ChangeStatusPageFileVariables,
  ContentHubFileSchema,
} from '../typings';
import { publishContentHubPageFile } from '../requests/publishContentHubPageFile';

interface UseMutationContext {
  previousData?: ContentHubFileSchema[];
}

export type UsePublishContentHubPageFileResult = UseMutationResult<
  void,
  Error,
  ChangeStatusPageFileVariables,
  UseMutationContext
>;

export const usePublishContentHubPageFile = (
  pageId: number
): UsePublishContentHubPageFileResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = useMemo(() => {
    return pageId
      ? [QUERY_NAME.fetchContentHubPageFiles, pageId]
      : [QUERY_NAME.fetchContentHubPageFiles];
  }, [pageId]);

  const mutation = useMutation<
    void,
    Error,
    ChangeStatusPageFileVariables,
    UseMutationContext
  >((variables) => publishContentHubPageFile(pageId, variables), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<ContentHubFileSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (data, { id }, context) => {
      const publishedFile = context?.previousData?.find(
        (file) => file.id === id
      );

      queryClient.setQueryData<ContentHubFileSchema[]>(
        queryKey,
        (previous = []) =>
          previous.map((file) => {
            if (file.id === id) {
              return { ...file, status: FILE_STATUS.published };
            }

            return file;
          })
      );

      if (publishedFile) {
        enqueueSuccessSnackbar({
          message: 'page.content_management.api.files.publish',
          intlValues: {
            name: publishedFile.name,
            extension: publishedFile.extension,
          },
        });
      }
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

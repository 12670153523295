import { ChangeEvent } from 'react';

import { INSTRUMENT_NAME, InstrumentSchema } from 'src/api';
import {
  Instrument,
  InstrumentField,
  INSTRUMENTS,
} from 'src/components/form/ControlledInstrumentSelect';

export const handleChangeWithLengthLimit = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  currentValue: string,
  maxLength: number
): string => {
  const value = event.target.value;
  return value.length <= maxLength ? value : currentValue;
};

export const formatInstrumentRequest = (
  instruments: InstrumentField
): InstrumentSchema[] => {
  return (Object.keys(instruments) as Instrument[])
    .filter((instrument) => instruments[instrument].selected)
    .map((instrument) => ({
      name: INSTRUMENT_NAME[instrument],
      serial_number: instruments[instrument].serialNumber,
    }));
};

export const formatInstrumentResponse = (
  instruments: InstrumentSchema[]
): InstrumentField => {
  const selectedInsturments = instruments.reduce(
    (instruments, instrument) => ({
      ...instruments,
      [instrument.name]: instrument.serial_number,
    }),
    {} as Record<INSTRUMENT_NAME, string>
  );

  return INSTRUMENTS.reduce(
    (instruments, instrument) => ({
      ...instruments,
      [instrument]:
        INSTRUMENT_NAME[instrument] in selectedInsturments
          ? {
              selected: true,
              serialNumber: selectedInsturments[INSTRUMENT_NAME[instrument]],
            }
          : { selected: false, serialNumber: '' },
    }),
    {} as InstrumentField
  );
};

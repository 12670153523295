const pad = (time: number) => {
  return ('0' + time).slice(-2);
};

export const formatSeconds = (seconds: number) => {
  const date = new Date(seconds * 1000);

  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }

  return `${mm}:${ss}`;
};

import { AxiosRequestConfig } from 'axios';

import { privateGateway } from '../../../config';
import { RequestMalwareCheckResult } from '../typings';

export const requestDocumentsMalwareScan = async (
  file_names: string[],
  cancelToken?: AxiosRequestConfig['cancelToken']
): Promise<RequestMalwareCheckResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post(`/files/malware_scan`, { file_names }, { cancelToken });

  return response.data;
};

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  profileAvatar: {
    '&.small': {
      width: 35,
      height: 35,

      '& $nameInitials': {
        marginTop: 3,
        fontSize: 14,
      },
    },

    '&.large': {
      width: 120,
      height: 120,

      '& $nameInitials': {
        marginTop: 7,
        fontSize: 50,
      },
    },

    '&.primary': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
    },

    '&.secondary': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  nameInitials: {
    fontFamily: theme.fonts.fontFamily.bold,
  },
}));

import { FC, useState } from 'react';

import MuiPaper from '@material-ui/core/Paper';

import FileCardStaticContent from '../FileCardStaticContent';
import FileCardEditableContent from '../FileCardEditableContent';

import { useStyles } from './styles';
import { FileProps } from './typings';

const FileCard: FC<FileProps> = ({ file }) => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEnableEdit = () => {
    setIsEditEnabled(true);
  };

  const handleDisableEdit = () => {
    setIsEditEnabled(false);
  };

  return (
    <MuiPaper
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isEditEnabled && (
        <FileCardStaticContent
          file={file}
          isHovered={isHovered}
          onEdit={handleEnableEdit}
        />
      )}
      {isEditEnabled && (
        <FileCardEditableContent file={file} onClose={handleDisableEdit} />
      )}
    </MuiPaper>
  );
};

export default FileCard;

import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import { PRIVATE_ROUTES_MAP, PUBLIC_ROUTES_MAP } from 'src/router';
import PrivateLayout from 'src/layouts/PrivateLayout';
import NotFound from 'src/pages/NotFound';
import { useNotFoundContext } from 'src/context/NotFoundContext';

const ProtectedRoute: FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...restProps
}) => {
  const history = useHistory();
  const location = useLocation();

  const { checkIsAdmin, checkIsAuthenticated, isPasswordExpired } =
    useCognito();

  const { pageExists, setPageExists } = useNotFoundContext();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      setPageExists(true);
      setIsReady(false);

      const isAuthenticated = await checkIsAuthenticated();

      if (isAuthenticated) {
        const isAdmin = await checkIsAdmin();

        if (!isAdmin) {
          setPageExists(false);
        }

        if (
          isPasswordExpired &&
          location.pathname !== PRIVATE_ROUTES_MAP.changePassword
        ) {
          history.push(PRIVATE_ROUTES_MAP.changePassword);
        }
      } else {
        history.push(PUBLIC_ROUTES_MAP.login);
      }

      setIsReady(true);
    };

    checkAuthentication();
  }, [
    history,
    location.pathname,
    isPasswordExpired,
    checkIsAuthenticated,
    checkIsAdmin,
    setPageExists,
  ]);

  return (
    <Route {...restProps}>
      <PrivateLayout>
        {!isReady || pageExists ? children : <NotFound />}
      </PrivateLayout>
    </Route>
  );
};

export default ProtectedRoute;

import { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { useFetchContentHubPages } from 'src/api/endpoints/contentHub';

import NavCard from './components/NavCard';
import { TEST_ID } from './constants';

const ContentHub: FC = () => {
  const { data: contentHubPages, isLoading } = useFetchContentHubPages();

  const pagesToShow = useMemo(
    () =>
      contentHubPages
        ? contentHubPages.filter((page) => page?.total_published_files !== 0)
        : [],
    [contentHubPages]
  );

  return (
    <ProgressOverlay loading={isLoading} opacity={1}>
      <MuiGrid
        container
        justifyContent="center"
        data-testid={TEST_ID.contentHub}
      >
        <MuiGrid item xs={12}>
          <MuiBox mb={6}>
            <MuiTypography variant="h2">
              <FormattedMessage id="page.dashboard.services.content_hub.title" />
            </MuiTypography>
          </MuiBox>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiGrid container spacing={10} justifyContent="space-between">
            <MuiGrid item xs={12} md={6}>
              <NavCard isBookmarks />
            </MuiGrid>
            {pagesToShow.map((contentHubPage) => (
              <MuiGrid key={contentHubPage.id} item xs={12} md={6}>
                <NavCard contentHubPage={contentHubPage} />
              </MuiGrid>
            ))}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </ProgressOverlay>
  );
};

export default ContentHub;

import { FC, MouseEventHandler, PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';

import { useCognito } from 'src/aws/Cognito';
import QHubLogo from 'src/components/elements/QHubLogo';

import { useStyles } from './styles';

const DefaultLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const classes = useStyles();

  const { isPasswordExpired, signOut } = useCognito();

  const handleLogoClick: MouseEventHandler = (event) => {
    if (isPasswordExpired) {
      event.preventDefault();
      signOut({ withDefaultReason: false });
    }
  };

  return (
    <MuiGrid container justifyContent="center" className={classes.container}>
      <MuiGrid
        item
        container
        direction="column"
        alignItems="center"
        className={classes.contentWrapper}
      >
        <MuiBox display="flex" justifyContent="center" mb={6}>
          <Link to="/" onClick={handleLogoClick}>
            <QHubLogo color="secondary" className={classes.logoImage} />
          </Link>
        </MuiBox>
        <MuiGrid item className={classes.content}>
          {children}
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};

export default DefaultLayout;

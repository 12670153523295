import { FC } from 'react';

import MuiGrow from '@material-ui/core/Grow';
import MuiPaper from '@material-ui/core/Paper';
import MuiPopper from '@material-ui/core/Popper';
import MuiMenuList from '@material-ui/core/MenuList';
import MuiMenuItem from '@material-ui/core/MenuItem';

import { SuggestionSchema } from 'src/api/endpoints/contentHub';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  inputRef?: HTMLElement;
  suggestions: SuggestionSchema[];
  onClick: (suggestion: string) => void;
}

const Autosuggestion: FC<Props> = ({
  open,
  inputRef,
  suggestions,
  onClick,
}) => {
  const classes = useStyles();

  const isSuggestionsAvailable = open && suggestions.length > 0;

  return (
    <MuiPopper
      transition
      disablePortal
      open={isSuggestionsAvailable}
      className={classes.popper}
      anchorEl={inputRef}
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <MuiGrow
          {...TransitionProps}
          style={{
            transformOrigin: 'top left',
          }}
        >
          <MuiPaper className={classes.paper}>
            <MuiMenuList>
              {suggestions.map(({ short, full }, index) => {
                const beginning = full.slice(0, -short.length) || full;
                const ending = short;
                return (
                  <MuiMenuItem
                    key={index}
                    className={classes.menuListItem}
                    onClick={() => onClick(full)}
                  >
                    <p>
                      <span className={classes.highlighted}>{beginning}</span>
                      {ending}
                    </p>
                  </MuiMenuItem>
                );
              })}
            </MuiMenuList>
          </MuiPaper>
        </MuiGrow>
      )}
    </MuiPopper>
  );
};

export default Autosuggestion;

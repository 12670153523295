import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    width: '100%',

    '& [class*=MuiStepLabel-label]': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',

      '&[class*=MuiStepLabel-completed]': {
        color: theme.palette.common.white,
      },
    },

    '& [class*=MuiStepConnector-root]': {
      minWidth: 50,
    },
  },

  formLabel: {
    color: theme.palette.text.primary,
  },

  button: {
    width: 320,
  },

  link: {
    fontFamily: theme.fonts.fontFamily.regular,
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },

  fontBold: {
    fontFamily: theme.fonts.fontFamily.bold,
  },

  phoneInput: {
    '& .form-control': {
      width: '100%',
      height: 40,
      paddingTop: 3,
      borderRadius: 10,
      color: theme.palette.text.primary,
      ...theme.typography.body1,

      '&::placeholder': {
        color: '#c3c3c3',
      },
    },

    '& .flag-dropdown, & .selected-flag': {
      borderRadius: '10px 0 0 10px',

      '&.open, & .selected-flag.open': {
        borderRadius: '10px 0 0 10px',
      },
    },
  },

  checkboxes: {
    // TODO: Remove once UI Kit typography is fixed
    '& [class*=MuiTypography-caption]': {
      fontSize: 12,
    },
  },

  cityInputWrapper: {
    '& [class*=MuiInputBase-root]': {
      minWidth: 'unset',
    },
  },

  zipCodeInput: {
    '& [class*=MuiInputBase-input]': {
      textOverflow: 'ellipsis',
    },
  },
}));

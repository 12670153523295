import { FC } from 'react';

import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import { useStyles as useCommonClasses } from '../styles';

import { useStyles } from './styles';
import { PHONE_PATTERN } from './constants';

interface Props extends PhoneInputProps {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
}

const ControlledPhoneInput: FC<Props> = ({
  id,
  name,
  label,
  required = true,
  inputProps,
  ...restProps
}) => {
  const classes = useStyles();
  const commonClasses = useCommonClasses();

  const { formatMessage } = useIntl();

  return (
    <>
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.phone" />}
      </MuiFormLabel>
      <Controller
        name={name}
        rules={{
          required,
          pattern: {
            value: PHONE_PATTERN,
            message: formatMessage({ id: 'app.form.error.invalid_phone' }),
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <PhoneInput
              containerClass={cx(classes.input, { invalid: !!error })}
              inputProps={{ id: id ?? name, ...inputProps }}
              disableCountryGuess={false}
              value={value}
              placeholder={formatMessage({ id: 'app.form.placeholder.phone' })}
              onChange={onChange}
              {...restProps}
            />
            {error?.message && (
              <MuiFormHelperText error variant="filled">
                {error.message}
              </MuiFormHelperText>
            )}
          </>
        )}
      />
    </>
  );
};

export default ControlledPhoneInput;

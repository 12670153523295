import { FC, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { GLOBAL_EVENT } from '@quanterix-ui/utils/constants/globalEvents';

import { history, PRIVATE_ROUTES_MAP } from 'src/router';
import { useServicesStore } from 'src/micro-frontend/stores/ServicesStore';

const GlobalEventHandler: FC = observer(() => {
  const { availableServices } = useServicesStore();

  const [redirectAlertOpen, setRedirectAlertOpen] = useState(false);
  const [redirectAppName, setRedirectAppName] = useState('');

  useEffect(() => {
    const handleRedirect = (event: CustomEvent) => {
      const submoduleName = event.detail;

      const product = availableServices.find(
        (product) => product.name === submoduleName
      );

      if (product) {
        const redirectPathname = PRIVATE_ROUTES_MAP.microFrontendSubmodule
          .replace(':moduleName', product.client!)
          .replace(':submoduleName', product.name!);

        history.push(redirectPathname);
      } else {
        setRedirectAppName(submoduleName);
      }
    };

    if (availableServices.length > 0) {
      document.addEventListener(
        GLOBAL_EVENT.redirect,
        handleRedirect as EventListener
      );
    }

    return () => {
      if (availableServices.length > 0) {
        document.removeEventListener(
          GLOBAL_EVENT.redirect,
          handleRedirect as EventListener
        );
      }
    };
  }, [availableServices]);

  const handleRedirectAlertClose = () => {
    setRedirectAppName('');
    setRedirectAlertOpen(false);
  };

  if (redirectAppName.length === 0) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={redirectAlertOpen}
      autoHideDuration={6000}
      onClose={handleRedirectAlertClose}
    >
      <Alert severity="error">
        <FormattedMessage
          id="page.module.global_event.redirect.error"
          values={{ name: redirectAppName }}
        />
      </Alert>
    </Snackbar>
  );
});

export default GlobalEventHandler;

import { FC, useCallback, useEffect, useMemo } from 'react';

import { useCognito } from 'src/aws/Cognito';
import { Instrument } from 'src/components/form/ControlledInstrumentSelect';
import UserViewerProfileInstruments, {
  InstrumentField,
} from 'src/components/UserViewerProfileInstruments';
import UserViewerProfileInstrumentsContextProvider, {
  useUserViewerProfileInstrumentsContext,
} from 'src/components/UserViewerProfileInstruments/context';
import {
  parseInstruments,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';

const ProfileInstruments: FC = () => {
  const { setInstruments } = useUserViewerProfileInstrumentsContext();

  const {
    updateUser,
    isLoading,
    userData: { attributes },
  } = useCognito();

  const instruments = useMemo(
    () =>
      parseInstruments(
        parseUserAttribute(attributes, 'custom:instrument'),
        parseUserAttribute(attributes, 'custom:serial_number')
      ).reduce(
        (instruments, instrument) => ({
          ...instruments,
          [instrument.Instrument.toLowerCase().replace('-', '')]: {
            name: instrument.Instrument,
            selected: instrument.Selected,
            serialNumber: instrument.Serial,
          },
        }),
        {
          acceleratorServices: {
            selected:
              parseUserAttribute(attributes, 'custom:accelerator_services') ===
              'true',
            serialNumber: '',
          },
        }
      ) as InstrumentField,
    [attributes]
  );

  const handleUpdateUserInstruments = useCallback(
    async (instruments: InstrumentField, onSuccessCallback: () => void) => {
      const instrumentAttributes = Object.entries(instruments!)
        .filter(
          ([key, instrument]) =>
            instrument.selected && key !== 'acceleratorServices'
        )
        .reduce(
          (attributes, [key, instrument]) => ({
            instruments: [
              ...attributes.instruments,
              instruments[key as Instrument]!.name as string,
            ],
            serialNumbers: [
              ...attributes.serialNumbers,
              instrument.serialNumber,
            ],
          }),
          {
            instruments: [],
            serialNumbers: [],
          } as { instruments: string[]; serialNumbers: string[] }
        );

      await updateUser({
        attributes: {
          'custom:instrument': JSON.stringify(instrumentAttributes.instruments),
          'custom:serial_number': JSON.stringify(
            instrumentAttributes.serialNumbers
          ),
          'custom:accelerator_services': `${
            instruments!.acceleratorServices.selected
          }`,
        },
      });

      onSuccessCallback();
    },
    [updateUser]
  );

  useEffect(() => {
    if (instruments) {
      setInstruments(instruments);
    }
  }, [instruments, setInstruments]);

  return (
    <UserViewerProfileInstruments
      isLoading={isLoading}
      isFetched={!!attributes}
      onUpdateUserInstruments={handleUpdateUserInstruments}
    />
  );
};

const ProfileInstrumentsWithContext = () => (
  <UserViewerProfileInstrumentsContextProvider>
    <ProfileInstruments />
  </UserViewerProfileInstrumentsContextProvider>
);

export default ProfileInstrumentsWithContext;

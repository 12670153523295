import { FilterState } from './typings';

export const DEFAULT_STATE: FilterState = {
  status: [],
  studyName: '',
  submissionDate: {
    startDate: null,
    endDate: null,
  },
  organizationName: '',
};

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  formLabel: {
    color: theme.palette.text.primary,
  },

  infoIcon: {
    fontSize: 15,
  },

  button: {
    width: 320,
  },

  tooltip: {
    margin: 2,
    fontSize: 11,
    padding: 6,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.common.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[600],
  },

  link: {
    fontFamily: theme.fonts.fontFamily.bold,
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },

  linkDisabled: {
    color: theme.palette.grey[300],
  },
}));

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { convertToPascalCase } from 'src/utils/helpers/common';

import { updateContentHubPage } from '../requests/updateContentHubPage';
import {
  ContentHubObjectSchema,
  EditableContentHubPageVariables,
} from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: ContentHubObjectSchema;
}

export type UseUpdateContentHubPageResult = UseMutationResult<
  ContentHubObjectSchema,
  Error,
  EditableContentHubPageVariables,
  UseMutationContext
>;

export const useUpdateContentHubPage = (
  pageId: number
): UseUpdateContentHubPageResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = [QUERY_NAME.fetchContentHubPage, pageId];

  const mutation = useMutation<
    ContentHubObjectSchema,
    Error,
    EditableContentHubPageVariables,
    UseMutationContext
  >((data) => updateContentHubPage(pageId, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<ContentHubObjectSchema>(queryKey);

      return { previousData };
    },

    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);

      queryClient.setQueryData<ContentHubObjectSchema[]>(
        [QUERY_NAME.fetchContentHubChildPages, data.parent_id],
        (previous = []) =>
          previous.map((page) => (page.id === pageId ? data : page))
      );

      queryClient.setQueryData<ContentHubObjectSchema[]>(
        [QUERY_NAME.fetchContentHubPages],
        (previous = []) =>
          previous.map((page) => (page.id === pageId ? data : page))
      );

      enqueueSuccessSnackbar({
        message: 'page.content_management.api.pages.update',
        intlValues: {
          type: convertToPascalCase(data.type),
          name: data.name,
        },
      });
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

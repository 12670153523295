import { FC, FocusEvent, MouseEvent, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogActions from '@quanterix-ui/core/DialogActions';
import DialogTitle from '@quanterix-ui/core/DialogTitle';
import DialogContent from '@quanterix-ui/core/DialogContent';
import Button from '@quanterix-ui/core/Button';

import { FAQSectionItemSchema } from 'src/api/endpoints/faq';

import QuestionTextField from '../QuestionTextField';
import AnswerTextField from '../AnswerTextField';

import { DEFAULT_EDITOR_VALUE, FORM_VALUES } from './constants';
import { FormValuesSchema } from './typings';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  loading: boolean;
  titleTranslationId: string;
  submitButtonTranslationId: string;
  item?: FAQSectionItemSchema;
  onClose: () => void;
  onSubmit: (data: FormValuesSchema) => Promise<void>;
}

const SectionItemDialog: FC<Props> = ({
  open,
  loading,
  titleTranslationId,
  submitButtonTranslationId,
  item,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: {
      [FORM_VALUES.question]: '',
      [FORM_VALUES.answer]: DEFAULT_EDITOR_VALUE,
    },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = formMethods;

  const handleCancel = () => {
    onClose();
  };

  const handleStopPropagation = (
    event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>
  ) => {
    event.stopPropagation();
  };

  const handleFormSubmit = async ({ question, answer }: FormValuesSchema) => {
    try {
      await onSubmit({ question, answer });

      reset({
        [FORM_VALUES.question]: '',
        [FORM_VALUES.answer]: DEFAULT_EDITOR_VALUE,
      });

      onClose();
    } catch ({ message }) {
      console.error(message);
    }
  };

  useEffect(() => {
    setValue(FORM_VALUES.question, item?.question || '');
  }, [item?.question, setValue]);

  useEffect(() => {
    setValue(FORM_VALUES.answer, item?.answer || DEFAULT_EDITOR_VALUE);
  }, [item?.answer, setValue]);

  return (
    <Dialog
      disableEscapeKeyDown
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      onClick={handleStopPropagation}
      onFocus={handleStopPropagation}
    >
      <FormProvider {...formMethods}>
        <DialogTitle className={classes.dialogTitle}>
          <FormattedMessage id={titleTranslationId} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <MuiBox mb={3}>
            <QuestionTextField disabled={loading} />
          </MuiBox>
          <MuiBox mt={3}>
            <AnswerTextField disabled={loading || !open} />
          </MuiBox>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={loading} onClick={handleCancel}>
            <FormattedMessage id="app.button.cancel" />
          </Button>
          <Button
            loading={loading}
            disabled={!isDirty || loading}
            onClick={handleSubmit(handleFormSubmit)}
          >
            <FormattedMessage id={submitButtonTranslationId} />
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default SectionItemDialog;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  step: {
    '& [class*=MuiStepIcon-root]': {
      color: theme.palette.grey[200],
    },

    '& [class*=MuiStepIcon-text]': {
      fontSize: 12,
      fontWeight: 600,
    },

    '& [class*=MuiStepIcon-active], & [class*=MuiStepIcon-completed]': {
      color: theme.palette.secondary.main,

      '& [class*=MuiStepIcon-text]': {
        fill: theme.palette.common.white,
      },
    },

    '&.hasWarning [class*=MuiStepIcon-root]': {
      color: theme.palette.warning.main,

      '& [class*=MuiStepIcon-text]': {
        fill: theme.palette.common.white,
      },
    },

    '& [class*=Mui-error]': {
      color: theme.palette.error.main,
    },
  },

  stepLabel: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.grey[300],

    '&[class*=MuiStepLabel-active], &[class*=MuiStepLabel-completed]': {
      color: theme.palette.secondary.main,
    },

    '&.hasWarning': {
      color: theme.palette.warning.main,
    },

    '&[class*=Mui-error]': {
      color: theme.palette.error.main,
    },
  },
}));

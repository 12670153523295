import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: alpha(theme.palette.common.black, 0.26),
    borderRadius: theme.constants.borderRadius,
    paddingBottom: 5,

    '&.isFocused': {
      borderColor: theme.palette.secondary.main,
      boxShadow: `0 0 0 1px ${theme.palette.secondary.main}`,
    },

    '&.withError': {
      borderColor: theme.palette.error.main,
      boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
    },
  },

  editor: {
    position: 'relative',
    flex: 1,
  },

  scrollbars: {
    width: '100%',
    height: '100%',
    paddingLeft: 14,
    paddingRight: 14,

    '& .os-content': {
      display: 'flex !important',
    },
  },
}));

import { FC, useEffect } from 'react';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import 'react-phone-input-2/lib/high-res.css';

import FormLabel from '@material-ui/core/FormLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import ControlledSelect from '@quanterix-ui/core/ControlledSelect';
import SelectOption from '@quanterix-ui/core/SelectOption';

import countries from 'src/utils/countries';
import states from 'src/utils/states';
import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';
import ControlledEmailTextField from 'src/components/form/ControlledEmailTextField';

import {
  validateMaxLength,
  validateSpecialCharacters1,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

import { useDefaultData } from './hooks/useDefaultData';

const Step1: FC<AcceleratorFormStepComponentProps> = ({
  onNext,
  formData,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const defaultData = useDefaultData();

  const formMethods = useForm({ defaultValues: formData });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = formMethods;

  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  useEffect(() => {
    reset({
      ...formData,
      organizationName:
        formData.organizationName || defaultData.organizationName,
      address: formData.address || defaultData.address,
      address2: formData.address2 || defaultData.address2,
      city: formData.city || defaultData.city,
      state: formData.state,
      zip: formData.zip || defaultData.zip,
      country: formData.country || defaultData.country,
      sponsor: formData.sponsor,
      phone: formData.phone || defaultData.phone,
      email: formData.email || defaultData.email,
      sponsor2: formData.sponsor2,
      phone2: formData.phone2,
      email2: formData.email2,
    });
  }, [formData, defaultData, reset]);

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.organization_name.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="organizationName"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.organizationName?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.address.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="address"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(80),
              ...validateSpecialCharacters1,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.address?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.address_2.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="address2"
            control={control}
            rules={{
              ...validateMaxLength(80),
              ...validateSpecialCharacters1,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.address2?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.city.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="city"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(80),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.city?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.state.label" />
          </FormLabel>
          <ControlledSelect
            fullWidth
            displayEmpty
            name="state"
            placeholder="Select state"
            control={control}
            rules={{
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
          >
            <SelectOption value="">Select state</SelectOption>
            {states.map((state) => (
              <SelectOption key={state.name} value={state.name}>
                {state.name}
              </SelectOption>
            ))}
          </ControlledSelect>
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.zip.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="zip"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.zip?.message}
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.country.label" />
          </FormLabel>
          <ControlledSelect
            fullWidth
            displayEmpty
            name="country"
            placeholder="Select country"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(80),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
          >
            <SelectOption value="">Select country</SelectOption>
            {countries.map((country) => (
              <SelectOption key={country.name} value={country.name}>
                {country.name}
              </SelectOption>
            ))}
          </ControlledSelect>
        </div>
        <div className={classes.formControl}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.principal_contact.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="sponsor"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.sponsor?.message}
          />
        </div>
        <div className={cx(classes.formControl, classes.phoneNumberWrapper)}>
          <FormLabel required component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.principal_contact_phone.label" />
          </FormLabel>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: true,
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <PhoneInput
                enableSearch
                disableCountryGuess={false}
                value={value}
                buttonStyle={{ borderColor: error ? 'red' : undefined }}
                inputStyle={{
                  width: '100%',
                  borderRadius: 10,
                  height: 40,
                  borderColor: error ? 'red' : undefined,
                }}
                dropdownStyle={{ borderRadius: 10 }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={classes.formControl}>
          <ControlledEmailTextField
            name="email"
            label={formatMessage({
              id: 'page.accelerator_form.step1.field.principal_contact_email.label',
            })}
            placeholder=""
          />
        </div>
        <div className={classes.formControl}>
          <FormLabel component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.principal_contact_2.label" />
          </FormLabel>
          <ControlledTextField
            fullWidth
            name="sponsor2"
            control={control}
            rules={{
              ...validateMaxLength(40),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            helperText={errors.sponsor2?.message}
          />
        </div>
        <div className={cx(classes.formControl, classes.phoneNumberWrapper)}>
          <FormLabel component="legend">
            <FormattedMessage id="page.accelerator_form.step1.field.principal_contact_phone_2.label" />
          </FormLabel>
          <Controller
            name="phone2"
            control={control}
            rules={{
              ...validateMaxLength(20),
              ...validateSpecialCharacters2,
              validate: {
                validateWhitespaces,
              },
            }}
            render={({ field: { value, onChange } }) => (
              <PhoneInput
                enableSearch
                disableCountryGuess={false}
                value={value}
                inputStyle={{ width: '100%', borderRadius: 10, height: 40 }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={classes.formControl}>
          <ControlledEmailTextField
            name="email2"
            label={formatMessage({
              id: 'page.accelerator_form.step1.field.principal_contact_email_2.label',
            })}
            placeholder=""
            required={false}
          />
        </div>
        <StepperNavigation
          onFormCancelOpen={onFormCancelOpen}
          onFormCancel={onFormCancel}
        >
          <StepperNavButton direction="next" />
        </StepperNavigation>
      </form>
    </FormProvider>
  );
};

export default Step1;

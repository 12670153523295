import { privateGateway } from '../../../config';
import { DeleteFAQSectionVariables, FAQSectionSchema } from '../typings';

export const deleteFAQSection = async ({
  id,
}: DeleteFAQSectionVariables): Promise<FAQSectionSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).delete(`/faq/sections/${id}`);

  return response.data;
};

import { FC } from 'react';

import cx from 'classnames';

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@material-ui/core/IconButton';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

export interface IconButtonProps extends MuiIconButtonProps {
  loading?: boolean;
}

const IconButton: FC<IconButtonProps> = ({
  children,
  loading = false,
  className,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <MuiIconButton
      className={cx(classes.root, className)}
      disabled={loading}
      {...restProps}
    >
      {loading ? (
        <MuiCircularProgress
          size={24}
          thickness={4}
          color={
            (restProps.color !== 'default' && restProps.color) || 'secondary'
          }
        />
      ) : (
        children
      )}
    </MuiIconButton>
  );
};

export default IconButton;

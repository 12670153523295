import { FC, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';

import Button from '@quanterix-ui/core/Button';

import { useSnackbar } from 'src/utils/hooks';
import ControlledCompanyTextField from 'src/components/form/ControlledCompanyTextField';
import ControlledAddressTextField from 'src/components/form/ControlledAddressTextField';
import ControlledZipCodeTextField from 'src/components/form/ControlledZipCodeTextField';
import ControlledCityTextField from 'src/components/form/ControlledCityTextField';
import ControlledStateTextField from 'src/components/form/ControlledStateTextField';
import ControlledCountrySelect from 'src/components/form/ControlledCountrySelect';
import ControlledWebsiteTextField from 'src/components/form/ControlledWebsiteTextField';

import { useStyles } from '../../styles';
import { OrganizationData, UserUpdateParams } from '../../typings';
import { useUserViewerProfileContext } from '../../context';

interface Props {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUserUpdate: (userData: UserUpdateParams) => Promise<any> | undefined;
  onCloseEditMode: () => void;
}

const OrganizationDataEditor: FC<Props> = ({
  isLoading,
  onUserUpdate,
  onCloseEditMode,
}) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { organizationData } = useUserViewerProfileContext();

  const { enqueueSuccessSnackbar } = useSnackbar();

  const organizationDataValues = useMemo(
    () =>
      Object.entries(organizationData).reduce(
        (formData, [key, entry]) => ({
          ...formData,
          [key]: entry.value,
        }),
        {} as OrganizationData
      ),
    [organizationData]
  );

  const formMethods = useForm<OrganizationData>({
    defaultValues: organizationDataValues,
  });

  const { handleSubmit } = formMethods;

  const handleSubmitOrganizationData = async (data: OrganizationData) => {
    await onUserUpdate({ organizationData: data });

    enqueueSuccessSnackbar({
      message: 'page.profile.edit_organization_data.snackbar',
    });

    onCloseEditMode();
  };

  const handleCancelPersonalDataEdit = () => {
    onCloseEditMode();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSubmitOrganizationData)}>
        <MuiBox>
          <MuiBox mb={3}>
            <ControlledCompanyTextField name="company" />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledAddressTextField
              name="address1"
              label={formatMessage({ id: 'app.form.label.address1' })}
            />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledAddressTextField
              required={false}
              name="address2"
              label={formatMessage({ id: 'app.form.label.address2' })}
            />
          </MuiBox>
          <MuiBox mb={3} className={classes.cityInputWrapper}>
            <MuiGrid container spacing={3}>
              <MuiGrid item xs={12} lg={4}>
                <ControlledZipCodeTextField
                  name="zip"
                  className={classes.zipCodeInput}
                />
              </MuiGrid>
              <MuiGrid item xs={12} lg={8}>
                <ControlledCityTextField name="city" />
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledStateTextField name="region" />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledCountrySelect name="country" />
          </MuiBox>
          <MuiBox mb={5}>
            <ControlledWebsiteTextField name="website" />
          </MuiBox>
          <MuiBox display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={handleCancelPersonalDataEdit}>
              <FormattedMessage id="app.button.cancel" />
            </Button>
            <Button type="submit" loading={isLoading} disabled={isLoading}>
              <FormattedMessage id="app.button.save" />
            </Button>
          </MuiBox>
        </MuiBox>
      </form>
    </FormProvider>
  );
};

export default OrganizationDataEditor;

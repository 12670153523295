import { Instrument } from 'src/components/form/ControlledInstrumentSelect';
import Accelerator from 'src/assets/images/instruments/accelerator.jpg';
import Spx from 'src/assets/images/instruments/spx.png';
import Srx from 'src/assets/images/instruments/srx.png';
import Hd1 from 'src/assets/images/instruments/hd1.png';
import Hdx from 'src/assets/images/instruments/hdx.png';

export const INSTRUMENT_IMAGES: Record<Instrument, string> = {
  acceleratorServices: Accelerator,
  spx: Spx,
  srx: Srx,
  hdx: Hdx,
  hd1: Hd1,
};

import { FC, useState } from 'react';

import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import IconAdd from '@material-ui/icons/Add';

import Button from '@quanterix-ui/core/Button';

import { useCreateFAQSection } from 'src/api/endpoints/faq';

import SectionDialog, { FormValuesSchema } from '../SectionDialog';

import { useStyles } from './styles';

const SectionAddButton: FC = () => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: createFAQSection, isLoading } = useCreateFAQSection();

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSectionCreate = async ({ title }: FormValuesSchema) => {
    await createFAQSection({ name: title });
  };

  return (
    <MuiBox>
      <Button
        variant="text"
        color="secondary"
        startIcon={<IconAdd />}
        className={classes.button}
        onClick={handleDialogOpen}
      >
        <MuiBox mt={0.2}>
          <FormattedMessage id="page.faq_management.button.add_section" />
        </MuiBox>
      </Button>
      <div>
        {createPortal(
          <SectionDialog
            open={isOpen}
            loading={isLoading}
            titleTranslationId="page.faq_management.dialog.title.section.add"
            submitButtonTranslationId="app.button.add"
            onClose={handleDialogClose}
            onSubmit={handleSectionCreate}
          />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </MuiBox>
  );
};

export default SectionAddButton;

import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import IconEdit from '@material-ui/icons/Edit';

import Button from '@quanterix-ui/core/Button';

import { useEditableContentContext } from '../../context/EditableContent';

import { useStyles } from './styles';

const EditButton: FC = () => {
  const classes = useStyles();

  const { isEditable, setIsEditable } = useEditableContentContext();

  const handleEditClick = () => {
    setIsEditable(true);
  };

  if (isEditable) {
    return null;
  }

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<IconEdit />}
      className={classes.button}
      onClick={handleEditClick}
    >
      <MuiBox mt={0.5}>
        <FormattedMessage id="app.button.edit" />
      </MuiBox>
    </Button>
  );
};

export default EditButton;

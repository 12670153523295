import { makeStyles } from '@material-ui/core/styles';

import QuanterixBackgroundImage from 'src/assets/images/quanterix-hero.jpg';

export const useStyles = makeStyles((theme) => ({
  sidebar: {
    height: '100vh',
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer,
  },

  sidebarLogoImage: {
    width: 123,
    height: 21,
  },

  sidebarContent: {
    flex: 1,
  },

  contentWrapper: {
    backgroundImage: `url(${QuanterixBackgroundImage})`,
    backgroundPosition: 'center',
    backgroundColor: theme.palette.background.default,
  },

  content: {
    height: '100vh',
    color: theme.palette.common.white,
  },

  boldLink: {
    fontWeight: 600,
  },
}));

import { FC, useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useGetUser } from 'src/aws/UserPool';
import {
  disableUserByAdmin,
  enableUserByAdmin,
  parseAddress,
  parseUserAttribute,
  updateUserAttributesByAdmin,
} from 'src/utils/CognitoIdentityHelper';
import UserViewerProfile, {
  combineUserAttributesFromData,
  UserUpdateParams,
} from 'src/components/UserViewerProfile';
import UserViewerProfileContextProvider, {
  useUserViewerProfileContext,
} from 'src/components/UserViewerProfile/context';

const RegisteredUserProfile: FC = () => {
  const { username } = useParams<{ username: string }>();

  const { user, refetchUser, isLoading } = useGetUser({ username });

  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isUserTogglingAvailability, setIsUserTogglingAvailability] =
    useState(false);

  const { userData, setUserData } = useUserViewerProfileContext();

  const handleUserUpdate = useCallback(
    async ({ personalData, organizationData }: UserUpdateParams) => {
      setIsUserUpdated(true);

      if (personalData) {
        return updateUserAttributesByAdmin(
          username,
          combineUserAttributesFromData({ ...userData, ...personalData })
        );
      }

      if (organizationData) {
        return updateUserAttributesByAdmin(
          username,
          combineUserAttributesFromData({ ...userData, ...organizationData })
        );
      }

      return;
    },
    [username, userData]
  );

  const handleUserToggleAvailability = useCallback(async () => {
    if (!user) {
      return;
    }

    setIsUserTogglingAvailability(true);

    const isEnabled = user.Enabled;
    user.Enabled = !isEnabled;

    if (isEnabled) {
      const request = await disableUserByAdmin(user.Username);

      request.on('error', (response) => {
        console.error('User enabling failed. ' + response.message);
        user.Enabled = isEnabled;
      });
    } else {
      const request = await enableUserByAdmin(user.Username);

      request.on('error', (response) => {
        console.error('User enabling failed. ' + response.message);
        user.Enabled = isEnabled;
      });
    }

    setTimeout(() => {
      refetchUser();
      setIsUserTogglingAvailability(false);
    }, 500);
  }, [user, refetchUser]);

  useEffect(() => {
    if (user && isUserUpdated) {
      setTimeout(() => {
        refetchUser();
        setIsUserUpdated(false);
      }, 500);
    }
  }, [isUserUpdated, user, refetchUser]);

  useEffect(() => {
    if (user && !isUserUpdated) {
      const userAttributes = user.UserAttributes;

      setUserData({
        firstname: parseUserAttribute(userAttributes, 'given_name'),
        lastname: parseUserAttribute(userAttributes, 'family_name'),
        email: parseUserAttribute(userAttributes, 'email'),
        phone: parseUserAttribute(userAttributes, 'phone_number'),
        company: parseUserAttribute(userAttributes, 'custom:company'),
        address1: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'street_address'
        ),
        address2: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'street_address2'
        ),
        zip: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'postal_code'
        ),
        region: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'region'
        ),
        country: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'country'
        ),
        city: parseAddress(
          parseUserAttribute(userAttributes, 'address'),
          'locality'
        ),
        website: parseUserAttribute(userAttributes, 'website'),
      });
    }
  }, [user, isUserUpdated, setUserData]);

  return (
    <UserViewerProfile
      status={user?.UserStatus}
      isFetched={!!user}
      isLoading={isLoading}
      isUserEnabled={user?.Enabled}
      isUserTogglingAvailability={isUserTogglingAvailability}
      onUserUpdate={handleUserUpdate}
      onUserToggleAvailability={handleUserToggleAvailability}
    />
  );
};

const RegisteredUserProfileWithContext = () => (
  <UserViewerProfileContextProvider>
    <RegisteredUserProfile />
  </UserViewerProfileContextProvider>
);

export default RegisteredUserProfileWithContext;

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface BookmarksFilterState {
  extensions: string[];
}

export type BookmarksFilterContextResult = {
  bookmarksFilter: BookmarksFilterState;
  setBookmarksFilter: Dispatch<SetStateAction<BookmarksFilterState>>;
};

export interface BookmarksFilterProviderProps {
  defaultBookmarksFilter?: BookmarksFilterState;
}

export const DEFAULT_STATE: BookmarksFilterState = {
  extensions: [],
};

export const BookmarksFilterContext =
  createContext<BookmarksFilterContextResult>({
    bookmarksFilter: DEFAULT_STATE,
    setBookmarksFilter: () => {
      // void
    },
  });

const BookmarksFilterProvider: FC<
  PropsWithChildren<BookmarksFilterProviderProps>
> = ({ children, defaultBookmarksFilter = DEFAULT_STATE }) => {
  const [bookmarksFilter, setBookmarksFilter] = useState(
    defaultBookmarksFilter
  );

  return (
    <BookmarksFilterContext.Provider
      value={{ bookmarksFilter, setBookmarksFilter }}
    >
      {children}
    </BookmarksFilterContext.Provider>
  );
};

export const useBookmarksFilterContext = (): BookmarksFilterContextResult => {
  const context = useContext(BookmarksFilterContext);

  if (!context) {
    throw new Error(
      'useBookmarksFilterContext must be used within a BookmarksFilterProvider'
    );
  }

  return context;
};

export default BookmarksFilterProvider;

import { QueryBuilder } from 'odata-query-builder';

import { ORDER } from 'src/utils/constants/table';

import { FetchUserRequestsParams, FetchUserRequestsResult } from '../typings';
import { privateGateway } from '../../../config';

export const fetchUserRequests = async ({
  filter,
  pagination,
}: FetchUserRequestsParams = {}): Promise<FetchUserRequestsResult> => {
  const query = new QueryBuilder();

  query.count();
  query.orderBy(`created_at ${ORDER.desc}`);

  if (filter?.value) {
    query.filter((f) =>
      f.filterPhrase(
        `contains(${filter.field}, '${encodeURIComponent(filter.value)}')`
      )
    );
  }

  if (pagination) {
    query.top(pagination.rowsPerPage);
    query.skip(pagination.skip);
  }

  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get(`/user_requests${query.toQuery()}`);

  return response.data;
};

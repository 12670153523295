export const PRIVATE_ROUTES_MAP = {
  dashboard: '/welcome',

  accelerator: '/accelerator',
  acceleratorDownloadManifests: '/accelerator/:formId/manifests',

  downloadFile: '/download/:file',

  passwordExpiry: '/password-expiry',
  changePassword: '/change-password',

  profile: '/profile',
  profileChangePassword: '/profile/change-password',
  profileActivity: '/profile/activity',
  profileInstruments: '/profile/instruments',
  profileCookieManager: '/profile/cookie-manager',

  contentHub: '/content-hub',
  contentHubViewer: '/content-hub/:pageId/:pageName',
  contentHubViewerAll: '/content-hub/all',
  contentHubViewerBookmarks: '/content-hub/bookmarks',

  contactSupport: '/contact-support',
  supportFileUpload: '/support-file-upload',

  faq: '/faq',

  microFrontendSubmodule: '/modules/:moduleName/:submoduleName',
};

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
    }),

    '& svg': {
      width: 50,
      height: 'auto',
    },

    '& p': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 'normal',
    },

    '&.isDragActive, &.isFileDialogActive': {
      borderWidth: 2,
      borderStyle: 'solid',
    },

    '&.hasError': {
      borderColor: theme.palette.error.main + '!important',
      color: theme.palette.error.main + '!important',
    },
  },

  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

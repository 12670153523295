import { FC, useMemo } from 'react';

import MuiBox from '@material-ui/core/Box';

import {
  getFileExtension,
  getFileName,
  getFileStatus,
} from 'src/utils/StringHelper';
import { FileStatuses } from 'src/utils/Constants';
import { isVideo } from 'src/utils/helpers/file';
import File, { CustomFile } from 'src/components/File';

interface Props {
  file: CustomFile;
  isEditMode: boolean;
  onFilePublish?: (name: string) => Promise<void>;
  onRequireApproval?: (name: string) => Promise<void>;
  onFileRemove?: (name: string) => Promise<void>;
}

const UploadedFile: FC<Props> = ({
  file,
  isEditMode = false,
  onFileRemove,
  onFilePublish,
  onRequireApproval,
}) => {
  const fileName = useMemo(() => {
    return file ? getFileName(file.key) : '';
  }, [file]);

  const shortFileName = useMemo(() => {
    const fileNameParts = fileName.split('.');
    return fileNameParts.slice(0, -1).join('');
  }, [fileName]);

  const fileExtension = useMemo(() => {
    return getFileExtension(fileName);
  }, [fileName]);

  const fileStatus = useMemo(() => {
    return file ? getFileStatus(file.key) : '';
  }, [file]);

  if (!isEditMode && fileStatus !== FileStatuses.Published) {
    return null;
  }

  if (!isEditMode && isVideo(fileExtension)) {
    return (
      <MuiBox pb={2}>
        <MuiBox pb={1}>{shortFileName}</MuiBox>
        <video controls width="400">
          <source src={file.urlPath} type={'video/' + fileExtension} />
          Your browser does not support HTML5 video.
        </video>
      </MuiBox>
    );
  }

  return (
    <File
      fileData={file}
      isEditMode={isEditMode}
      onRequireApproval={onRequireApproval}
      onFilePublish={onFilePublish}
      onFileRemove={onFileRemove}
    />
  );
};

export default UploadedFile;

import { privateGateway } from '../../../config';
import { ChangeStatusPageFileVariables } from '../typings';

export const requestReviewContentHubPageFile = async (
  pageId: number,
  { id }: ChangeStatusPageFileVariables
): Promise<void> => {
  await privateGateway(process.env.REACT_APP_DOCUMENTS_API as string).put(
    `/pages/${pageId}/files/${id}/review`
  );
};

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    width: '100%',
    top: '100% !important',
    transform: 'translate3d(0, 12px, 0) !important',
    zIndex: 999,
  },

  paper: {
    boxShadow: '0 2px 10px rgba(0 0 0 / 10%)',
  },

  highlighted: {
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
  },

  menuListItem: {
    '& p': {
      margin: 0,
    },
  },
}));

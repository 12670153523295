import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: 800,
    height: 200,
    backgroundColor: theme.palette.grey[100],
    overflow: 'hidden',
  },

  backgroundImage: {
    position: 'absolute',
    top: -200,
    left: -200,
    width: 800,
    height: 'auto',
    opacity: 0.3,
    rotate: '-60deg',
    zIndex: 0,
  },

  textContainer: {
    position: 'relative',
    display: 'flex',
    width: '75%',
    height: 200,
    alignItems: 'center',
    padding: '0 80px',
    zIndex: 1,
  },

  text: {
    fontSize: 26,
    fontWeight: 600,
    lineHeight: '38px',

    '& a': {
      textDecoration: 'underline',
    },
  },
}));

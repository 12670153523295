import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiTypography from '@material-ui/core/Typography';
import MuiGrid from '@material-ui/core/Grid';

import { INSTRUMENT_NAME } from 'src/api';
import {
  Instrument,
  InstrumentFieldSchema,
} from 'src/components/form/ControlledInstrumentSelect';

import { useStyles } from '../../styles';

import { INSTRUMENT_IMAGES } from './constants';

interface Props {
  name?: Instrument;
  instrument?: InstrumentFieldSchema;
}

const InstrumentCard: FC<Props> = ({ name, instrument }) => {
  const classes = useStyles();

  return (
    <MuiCard variant="outlined" className={classes.instrumentCard}>
      <MuiCardContent className={classes.cardContent}>
        <MuiGrid container spacing={2}>
          <MuiGrid item container xs={4} alignItems="center">
            <img
              src={INSTRUMENT_IMAGES[name!]}
              alt={name}
              width={70}
              height={60}
            />
          </MuiGrid>
          <MuiGrid item xs={8}>
            <MuiTypography gutterBottom className={classes.title}>
              {INSTRUMENT_NAME[name!]}
            </MuiTypography>
            <MuiTypography>
              <FormattedMessage
                id="page.profile.instruments.serial_number"
                values={{ value: instrument!.serialNumber }}
              />
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </MuiCardContent>
    </MuiCard>
  );
};

export default InstrumentCard;

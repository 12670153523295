import { FC } from 'react';

import { useParams } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import { useNotFoundContext } from 'src/context/NotFoundContext';
import {
  useFetchPublicPageBySlug,
  useFetchPublicPageFiles,
} from 'src/api/endpoints/contentHubPublic';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import FileCard from 'src/pages/ContentHubViewer/components/FileCard';

import { useStyles } from './styles';
import { TEST_ID } from './constants';

const ContentHubViewerPublic: FC = () => {
  const classes = useStyles();

  const { setPageExists } = useNotFoundContext();

  const { pageSlug } = useParams<{ pageSlug: string }>();

  const { data: page, isFetching: isPageLoading } = useFetchPublicPageBySlug(
    pageSlug,
    {
      enabled: Boolean(pageSlug),
      onError: () => {
        setPageExists(false);
      },
    }
  );

  const { data: files, isFetching: isFilesLoading } = useFetchPublicPageFiles(
    page?.id as number,
    {
      enabled: Boolean(page?.id),
    }
  );

  const isLoading = isPageLoading || isFilesLoading;

  if (!page) {
    return null;
  }

  return (
    <ProgressOverlay loading={isLoading} opacity={1}>
      <MuiBox data-testid={TEST_ID.contentHubViewerPublic}>
        <MuiBox mb={4}>
          <MuiGrid container>
            <MuiGrid item xs={12}>
              <MuiTypography className={classes.pageTitle} color="secondary">
                {page.name}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
        </MuiBox>
        <MuiBox>
          {files.map((file) => (
            <MuiBox key={file.id} mb={3}>
              <FileCard simpleView file={file} />
            </MuiBox>
          ))}
        </MuiBox>
      </MuiBox>
    </ProgressOverlay>
  );
};

export default ContentHubViewerPublic;

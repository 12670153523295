/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Storage } from 'aws-amplify';

import * as Constants from './Constants';
import { getFileExtension, pathJoin } from './StringHelper';

export const showFileInBrowser = (fileExtension: string): boolean => {
  return (
    ['pdf', 'jpg', 'gif', 'bmp', 'png'].indexOf(fileExtension.toLowerCase()) >=
    0
  );
};

export const readFile = async (
  path: string,
  level = 'public' as any,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<string | null> => {
  try {
    const { Body } = (await Storage.get(path, {
      level,
      download: true,
      bucket,
    })) as any;
    return Body.text();
  } catch (error) {
    return null;
  }
};

export const getLink = async (
  path: string,
  level = 'public',
  bucket = process.env.REACT_APP_BUCKET_NAME,
  expires = 604800 // 1 week
): Promise<string | null> => {
  try {
    const fileExtension = getFileExtension(path);
    const config: any = {
      level,
      expires,
      bucket,
    };
    if (!showFileInBrowser(fileExtension)) {
      config.contentDisposition = 'attachment';
    }
    const data = await Storage.get(path, config);

    return data;
  } catch (error) {
    return null;
  }
};

export const createFolder = async (
  path: string,
  level = 'public' as any,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<boolean> => {
  try {
    // folder name should end with  forward slash ("/") character
    let folderName = path;

    if (folderName[folderName.length - 1] !== '/') {
      folderName += '/';
    }

    await Storage.put(folderName, '', {
      level,
      bucket,
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const updateFolderName = async (
  path: string,
  newPath: string,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<boolean> => {
  try {
    // folder name should end with  forward slash ("/") character
    let folderName = path;
    if (folderName[folderName.length - 1] !== '/') {
      folderName += '/';
    }

    let newFolderName = newPath;
    if (newFolderName[newFolderName.length - 1] !== '/') {
      newFolderName += '/';
    }

    await Storage.copy(
      { key: encodeURIComponent(folderName) },
      {
        key: newFolderName,
      },
      { bucket }
    );

    await Storage.remove(folderName, { bucket });

    return true;
  } catch (error) {
    return false;
  }
};

/**
 *folder should be created explicitly before uploading files, use  createFolder
 */
export const uploadFile = async ({
  path,
  file,
  user_id = '', // should be a string, otherwise AWS SDK will throw an error
  source = '', // should be a string, otherwise AWS SDK will throw an error
  level = 'public',
  bucket = process.env.REACT_APP_BUCKET_NAME,
}: any): Promise<any> => {
  try {
    const result = await Storage.put(path, file, {
      level,
      contentType: file.type,
      bucket,
      metadata: { user_id, source },
    });
    if (result.key) {
      return pathJoin([level, result.key]);
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateFileContent = async (
  path: string,
  data: any,
  level = 'public' as any,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<boolean> => {
  try {
    await Storage.put(path, data, { level, bucket });
    return true;
  } catch (error) {
    return false;
  }
};

export const getLinkIfExists = async (
  path: string,
  level = 'public' as any,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<string | null> => {
  try {
    const list = await Storage.list(path, { level, bucket });
    if (list && list.length > 0) {
      return getLink(path, level, bucket);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const publishFile = async (
  path: string,
  level = 'public' as any,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<string> => {
  const newPath = path
    .replace(Constants.FileStatuses.New, '')
    .replace(Constants.FileStatuses.Pending, '');

  await Storage.copy(
    { key: encodeURIComponent(path) },
    {
      key: newPath,
    },
    { bucket }
  );

  await Storage.remove(path, { bucket });

  return newPath;
};

export const requireApprovalFile = async (
  path: string,
  level = 'public' as any,
  bucket = process.env.REACT_APP_BUCKET_NAME
): Promise<string> => {
  const newPath = path.replace(
    Constants.FileStatuses.New,
    Constants.FileStatuses.Pending
  );

  await Storage.copy(
    { key: encodeURIComponent(path) },
    {
      key: newPath,
    },
    { bucket }
  );

  await Storage.remove(path, { bucket });

  return newPath;
};

import { FC, PropsWithChildren } from 'react';

import { Route, RouteProps } from 'react-router-dom';

import OldDefaultLayout from 'src/layouts/OldDefaultLayout';

const OldPublicRoute: FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...restProps
}) => {
  return (
    <Route {...restProps}>
      <OldDefaultLayout>{children}</OldDefaultLayout>
    </Route>
  );
};

export default OldPublicRoute;

import { FC } from 'react';

import Box from '@material-ui/core/Box';

import { CustomFile } from 'src/components/File';

import FileItem from '../UploadedFile';

interface Props {
  files: CustomFile[];
  isEditMode?: boolean;
  onRemove: (file: string) => Promise<void>;
  onFilePublish?: (path: string) => Promise<void>;
  onRequireApproval?: (path: string) => Promise<void>;
}

const UploadedFilesList: FC<Props> = ({
  files,
  isEditMode = false,
  onRemove,
  onFilePublish,
  onRequireApproval,
}) => {
  return (
    <Box my={1}>
      {files.map((file) => {
        return (
          <FileItem
            key={file.key}
            isEditMode={isEditMode}
            file={file}
            onFileRemove={() => onRemove(file.key)}
            onFilePublish={onFilePublish}
            onRequireApproval={onRequireApproval}
          />
        );
      })}
    </Box>
  );
};

export default UploadedFilesList;

import { FC, PropsWithChildren, ReactNode } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import OldQuanterixLogoImage from 'src/assets/images/old-logo.png';

import { useStyles } from './styles';

const OldDefaultLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const classes = useStyles();

  const location = useLocation();

  return (
    <MuiGrid container justifyContent="flex-start">
      <MuiGrid item xs={12} md={6} lg={5}>
        <OverlayScrollbarsComponent
          options={{ scrollbars: { autoHide: 'move' } }}
          style={{ height: '100vh' }}
        >
          <MuiBox
            px={{ xs: 3, sm: 6, lg: 8 }}
            py={5}
            className={classes.sidebar}
          >
            <MuiBox my={3}>
              <MuiGrid container alignItems="flex-end">
                <MuiGrid item xs={5}>
                  <MuiGrid container direction="column">
                    <Link to="/">
                      <img
                        className={classes.sidebarLogoImage}
                        src={OldQuanterixLogoImage}
                        alt="Logo"
                      />
                    </Link>
                    <MuiTypography>Customer Portal</MuiTypography>
                  </MuiGrid>
                </MuiGrid>
                <MuiGrid item xs={7}>
                  <MuiGrid container justifyContent="flex-end">
                    {location.pathname === '/request-access' && (
                      <MuiTypography>
                        <FormattedMessage
                          id="page.request_access.link.login"
                          values={{
                            link: (msg: ReactNode) => (
                              <Link className={classes.boldLink} to="/login">
                                {msg}
                              </Link>
                            ),
                          }}
                        />
                      </MuiTypography>
                    )}
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
            <div className={classes.sidebarContent}>{children}</div>
          </MuiBox>
        </OverlayScrollbarsComponent>
      </MuiGrid>
      <MuiGrid item xs={12} md={6} lg={7}>
        <MuiBox className={classes.contentWrapper}>
          <MuiGrid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.content}
          >
            <MuiTypography variant="h1">Quanterix</MuiTypography>
            <MuiTypography variant="h4">
              The Science of Precision Health
            </MuiTypography>
          </MuiGrid>
        </MuiBox>
      </MuiGrid>
    </MuiGrid>
  );
};

export default OldDefaultLayout;

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: theme.fonts.fontFamily.bold,
    marginBottom: theme.spacing(2),

    '& svg': {
      verticalAlign: 'middle',
      marginBottom: 3,
      marginRight: 5,
    },
  },

  table: {
    minWidth: 1300,
  },

  tableHead: {
    position: 'relative',
    '& [class*=MuiTableRow-root]:first-child [class*=MuiTableCell-root]': {
      fontSize: 16,
      padding: 12,
    },
  },

  noDataPlaceholderCell: {
    paddingTop: 30,
    paddingBottom: 30,
    borderColor: 'transparent',
  },

  tableLoadingIndicator: {
    position: 'absolute',
    width: '100%',
    height: 4,
  },

  tableLoadingIndicatorCell: {
    padding: 0,
    border: 'none',
  },

  isTableLoading: {
    pointerEvents: 'none',
    opacity: 0.2,
  },

  scrollbars: {
    width: '100%',
    height: '100%',
  },
}));

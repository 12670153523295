import { useMutation } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { postAdminNotification } from '../requests/postAdminNotification';
import { PostAdminNotificationParams } from '../typings';

export const usePostAdminNotification = () => {
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const result = useMutation<void, Error, PostAdminNotificationParams>(
    postAdminNotification,
    {
      onSuccess: () => {
        enqueueSuccessSnackbar({
          message: 'notify_users_modal.snackbar.success',
        });
      },
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
    }
  );

  return result;
};

import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import { useCognito } from 'src/aws/Cognito';

import { TEST_ID } from './constants';
import { useStyles } from './styles';
import Services from './components/Services';
import AdminServices from './components/AdminServices';

const Dashboard: FC = () => {
  const classes = useStyles();

  const { userFullName, isAdmin } = useCognito();

  return (
    <MuiGrid container data-testid={TEST_ID.dashboard}>
      <MuiGrid item xs={12}>
        <MuiBox mb={{ xs: 2, md: 6 }}>
          <MuiTypography variant="h1" className={classes.pageTitle}>
            <FormattedMessage
              id="page.dashboard.title"
              values={{ userFullName }}
            />
          </MuiTypography>
        </MuiBox>
      </MuiGrid>
      {isAdmin && (
        <MuiGrid item xs={12}>
          <AdminServices />
        </MuiGrid>
      )}
      <MuiGrid item xs={12}>
        <Services />
      </MuiGrid>
    </MuiGrid>
  );
};

export default Dashboard;

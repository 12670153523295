import { useMutation, UseMutationResult } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { uploadFile } from '../requests/uploadFile';
import { UploadFileResponse, UploadFileVariables } from '../typings';

export type UseUploadBlobStorageFileResult = UseMutationResult<
  UploadFileResponse,
  Error,
  UploadFileVariables
>;

export const useUploadBlobStorageFile = (): UseUploadBlobStorageFileResult => {
  const { enqueueErrorSnackbar } = useSnackbar();

  const mutation = useMutation<UploadFileResponse, Error, UploadFileVariables>(
    uploadFile,
    {
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
    }
  );

  return mutation;
};

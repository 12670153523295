import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface ContentFilterState {
  extensions: string[];
}

export type ContentFilterContextResult = {
  contentFilter: ContentFilterState;
  setContentFilter: Dispatch<SetStateAction<ContentFilterState>>;
};

export interface ContentFilterProviderProps {
  defaultContentFilter?: ContentFilterState;
}

export const DEFAULT_STATE: ContentFilterState = {
  extensions: [],
};

export const ContentFilterContext = createContext<ContentFilterContextResult>({
  contentFilter: DEFAULT_STATE,
  setContentFilter: () => {
    // void
  },
});

const ContentFilterProvider: FC<
  PropsWithChildren<ContentFilterProviderProps>
> = ({ children, defaultContentFilter = DEFAULT_STATE }) => {
  const [contentFilter, setContentFilter] = useState(defaultContentFilter);

  return (
    <ContentFilterContext.Provider value={{ contentFilter, setContentFilter }}>
      {children}
    </ContentFilterContext.Provider>
  );
};

export const useContentFilterContext = (): ContentFilterContextResult => {
  const context = useContext(ContentFilterContext);

  if (!context) {
    throw new Error(
      'useContentFilterContext must be used within a ContentFilterProvider'
    );
  }

  return context;
};

export default ContentFilterProvider;

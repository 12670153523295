import { makeStyles } from '@material-ui/core/styles';

import BackgroundImage from 'src/assets/images/molecules.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
  },

  content: {
    position: 'relative',
    zIndex: 1,
  },

  typography: {
    backgroundColor: theme.palette.background.paper,
  },

  listItem: {
    paddingTop: 10,
  },

  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },

  dashElement: {
    width: 14,
    height: 4,
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    marginRight: 20,
  },

  background: {
    position: 'fixed',
    top: -20,
    left: '40%',
    width: '100%',
    maxWidth: 1200,
    height: '100%',
    maxHeight: 1200,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,

    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
}));

import { FC } from 'react';

import { CustomElement } from 'src/typings/slate';

import { serialize } from '../../utils/serialize';

interface Props {
  value?: CustomElement[] | null;
}

const Markup: FC<Props> = ({ value }) => {
  if (!value || value.length === 0) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: serialize(value) }} />;
};

export default Markup;

import { FC, ReactNode, useRef } from 'react';

import { Link } from 'react-router-dom';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import MuiButtonBase from '@material-ui/core/ButtonBase';

import { useStyles } from './styles';
import { TEST_ID } from './constants';

interface Props {
  index: number;
  title: string;
  description: string;
  url: string;
  footerLinkText: string;
  footerExtraText?: ReactNode;
  icon?: ReactNode;
}

const NavCard: FC<Props> = ({
  index,
  title,
  description,
  url,
  footerLinkText,
  footerExtraText,
  icon,
}) => {
  const classes = useStyles();

  const externalLinkRef = useRef<HTMLAnchorElement>(null);
  const internalLinkRef = useRef<HTMLAnchorElement>(null);

  const isOdd = index % 2 !== 0 && !icon;
  const isUrlExternal = url.includes('https://');

  const handleCardClick = () => {
    if (isUrlExternal) {
      externalLinkRef.current?.click();
    } else {
      internalLinkRef.current?.click();
    }
  };

  return (
    <MuiBox className={classes.root} data-testid={TEST_ID.navCard}>
      <MuiButtonBase
        className={classes.button}
        data-testid={TEST_ID.navCardButton}
        onClick={handleCardClick}
      >
        <MuiGrid container>
          <MuiGrid item xs={12} className={classes.header}>
            <MuiGrid
              container
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap"
            >
              <MuiBox className={cx(classes.iconWrapper, { isOdd })}>
                {icon}
              </MuiBox>
              <MuiTypography color="secondary" className={classes.title}>
                {title}
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item xs={12} className={classes.content}>
            <MuiTypography>{description}</MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </MuiButtonBase>
      <MuiBox className={classes.footer}>
        <MuiGrid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.footerContent}
        >
          {footerExtraText && (
            <MuiBox className={classes.footerExtraText}>
              {footerExtraText}
            </MuiBox>
          )}
          {isUrlExternal && (
            <MuiLink ref={externalLinkRef} href={url} target="_blank">
              {footerLinkText}
            </MuiLink>
          )}
          {!isUrlExternal && (
            <Link innerRef={internalLinkRef} to={url}>
              {footerLinkText}
            </Link>
          )}
        </MuiGrid>
      </MuiBox>
    </MuiBox>
  );
};

export default NavCard;

import { FC, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';

import { useSnackbar } from 'src/utils/hooks';
import ControlledFirstNameTextField from 'src/components/form/ControlledFirstNameTextField';
import ControlledLastNameTextField from 'src/components/form/ControlledLastNameTextField';
import ControlledEmailTextField from 'src/components/form/ControlledEmailTextField';
import ControlledPhoneInput from 'src/components/form/ControlledPhoneInput';

import { useUserViewerProfileContext } from '../../context';
import { PersonalData, UserUpdateParams } from '../../typings';

interface Props {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUserUpdate: (userData: UserUpdateParams) => Promise<any> | undefined;
  onCloseEditMode: () => void;
}

const PersonalDataEditor: FC<Props> = ({
  isLoading,
  onUserUpdate,
  onCloseEditMode,
}) => {
  const { enqueueSuccessSnackbar } = useSnackbar();

  const { personalData } = useUserViewerProfileContext();

  const personalDataValues = useMemo(
    () =>
      Object.entries(personalData).reduce(
        (formData, [key, entry]) => ({
          ...formData,
          [key]: entry.value,
        }),
        {} as PersonalData
      ),
    [personalData]
  );

  const formMethods = useForm<PersonalData>({
    defaultValues: personalDataValues,
  });

  const { handleSubmit } = formMethods;

  const handleSubmitPersonalData = async (data: PersonalData) => {
    await onUserUpdate({ personalData: data });

    enqueueSuccessSnackbar({
      message: 'page.profile.edit_personal_data.snackbar',
    });

    onCloseEditMode();
  };

  const handleCancelPersonalDataEdit = () => {
    onCloseEditMode();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSubmitPersonalData)}>
        <MuiBox>
          <MuiBox mb={3}>
            <ControlledFirstNameTextField name="firstname" />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledLastNameTextField name="lastname" />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledEmailTextField disabled name="email" />
          </MuiBox>
          <MuiBox mb={5}>
            <ControlledPhoneInput name="phone" />
          </MuiBox>
          <MuiBox display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={handleCancelPersonalDataEdit}>
              <FormattedMessage id="app.button.cancel" />
            </Button>
            <Button type="submit" loading={isLoading} disabled={isLoading}>
              <FormattedMessage id="app.button.save" />
            </Button>
          </MuiBox>
        </MuiBox>
      </form>
    </FormProvider>
  );
};

export default PersonalDataEditor;

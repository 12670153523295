import { KeyboardEvent, useCallback } from 'react';

import { Editor } from 'slate';
import isHotkey from 'is-hotkey';

import { FormattedText } from 'src/typings/slate';

import { HOTKEYS } from '../constants';
import EditorElement, { EditorElementProps } from '../components/EditorElement';
import EditorLeaf, { EditorLeafProps } from '../components/EditorLeaf';
import { toggleMark } from '../utils/mark';

export const useEditorConfig = (editor: Editor) => {
  const renderElement = useCallback(
    (props: EditorElementProps) => <EditorElement {...props} />,
    []
  );

  const renderLeaf = useCallback(
    (props: EditorLeafProps) => <EditorLeaf {...props} />,
    []
  );

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    for (const [key, value] of Object.entries(HOTKEYS)) {
      if (isHotkey(key, event)) {
        event.preventDefault();
        toggleMark(editor, value as keyof FormattedText);
      }
    }
  };

  editor.isInline = (element) => ['link'].includes(element.type);

  return { renderElement, renderLeaf, onKeyDown };
};

export const isForgotPassMessageShown = (() => {
  const date = new Date('10/30/2020');
  const today = new Date();

  return (
    today.getDate() <= date.getDate() &&
    today.getMonth() <= date.getMonth() &&
    today.getFullYear() <= date.getFullYear()
  );
})();

import { useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { ContentHubObjectSchema } from '../typings';
import { QUERY_NAME } from '../constants';

export const useToggleIsPageOpen = () => {
  const queryClient = useQueryClient();

  const toggleIsPageOpen = useCallback(
    (item: ContentHubObjectSchema) => {
      const queryKey = item.parent_id
        ? [QUERY_NAME.fetchContentHubChildPages, item.parent_id]
        : [QUERY_NAME.fetchContentHubPages];

      queryClient.setQueryData<ContentHubObjectSchema[]>(
        queryKey,
        (previous = []) => {
          return previous.map((page) => {
            if (page.id === item.id) {
              return {
                ...page,
                is_open: !item.is_open,
              };
            }

            return page;
          });
        }
      );
    },
    [queryClient]
  );

  return { toggleIsPageOpen };
};

import { useQuery, UseQueryResult } from 'react-query';

import { fetchFilesCount } from '../requests/fetchFilesCount';
import { FetchFilesCountResult } from '../typings';
import { QUERY_NAME } from '../constants';

export const useFetchFilesCount = (): UseQueryResult<FetchFilesCountResult> => {
  const result = useQuery(QUERY_NAME.filesCount, fetchFilesCount);

  return result;
};

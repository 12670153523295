import axios, { AxiosRequestConfig } from 'axios';

import { UPLOAD_SOURCE } from 'src/utils/constants/uploadSource';
import { uploadToBlobStorage } from 'src/utils/helpers/blobStorage';
import { deleteFiles } from 'src/api/endpoints/blobStorage';

import { UploadSupportFilesParams } from '../typings';

interface UploadSupportFilesOptions {
  cancelToken?: AxiosRequestConfig['cancelToken'];
  onUploadProgress?: (progress: number) => void;
}

interface FileProgress {
  progress: number;
  path?: string;
}

export const uploadSupportFiles = async (
  params: UploadSupportFilesParams,
  { cancelToken, onUploadProgress }: UploadSupportFilesOptions
): Promise<string[]> => {
  const filesProgress: FileProgress[] = new Array(params.files.length);

  try {
    const attachments = await Promise.all(
      params.files.map(async (file, index) => {
        const filePath = await uploadToBlobStorage(
          file,
          UPLOAD_SOURCE.supportAttachments,
          {
            cancelToken,
            onUploadProgress: (event) => {
              const percentCompleted = Math.round(
                (event.loaded * 50) / event.total
              );
              filesProgress[index] = { progress: percentCompleted };

              const progressSum = filesProgress.reduce(
                (sum, file) => sum + file.progress,
                0
              );

              const totalPercentCompleted = progressSum / filesProgress.length;

              onUploadProgress?.(totalPercentCompleted);
            },
          }
        );

        filesProgress[index].path = filePath;

        return filePath;
      })
    );

    return attachments;
  } catch (error) {
    if (axios.isCancel(error)) {
      deleteFiles({
        file_names: filesProgress
          .map((file) => file.path)
          .filter(Boolean) as string[],
      });
    }

    throw error;
  }
};

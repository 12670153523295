import { privateGateway } from '../../../config';
import {
  ContentHubObjectSchema,
  CreateContentHubPageVariables,
} from '../typings';

export const createContentHubNestedPage = async (
  parentId: number,
  data: CreateContentHubPageVariables
): Promise<ContentHubObjectSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post(`/pages/${parentId}/pages`, data);

  return response.data;
};

import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import {
  createUserByAdmin,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';

import Editor, { UserAttributesForUpdate } from './components/Editor';

const RegisteredUsersAddNew: FC = () => {
  const history = useHistory();

  const handleCancel = () => {
    history.push('/admin/users/registered');
  };

  const handleSave = async (attributes: UserAttributesForUpdate[]) => {
    const username = parseUserAttribute(attributes, 'email');
    const request = await createUserByAdmin(username, attributes);
    request
      .on('success', (response) => {
        history.push('/admin/users/registered');
      })
      .on('error', (response) => {
        alert('User saving failed. ' + response.message);
      });
  };

  return (
    <Editor
      isNew
      userAttributes={{}}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  );
};

export default RegisteredUsersAddNew;

import { FC, useMemo } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import {
  PAGE_TYPE,
  useFetchContentHubChildPages,
  useFetchContentHubPage,
  useFetchContentHubPages,
} from 'src/api/endpoints/contentHub';
import { PROTECTED_ROUTES_MAP } from 'src/router';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import { useContentManagementContext } from '../../context/ContentManagementContext';
import CreateButton from '../CreateButton';

import NavCard from './components/NavCard';

const ContentBody: FC = () => {
  const location = useLocation();
  const { pageId } = useParams<{ pageId: string }>();

  const isContentRootPage =
    location.pathname === PROTECTED_ROUTES_MAP.adminContentManagement;

  const { isLegalDocumentsCategory } = useContentManagementContext();

  const { data: pages, isFetching: isPagesFetching } = useFetchContentHubPages(
    {
      showHidden: true,
    },
    {
      enabled: isContentRootPage,
    }
  );

  const { data: page, isFetching: isPageFetching } = useFetchContentHubPage(
    Number(pageId),
    { enabled: Boolean(pageId) }
  );

  const { data: childPages, isLoading: isChildPagesFetching } =
    useFetchContentHubChildPages(Number(pageId), {
      enabled: Boolean(pageId),
    });

  const selectedPages = useMemo(() => {
    if (isContentRootPage) {
      return pages;
    }

    if (pageId) {
      return childPages;
    }

    return [];
  }, [isContentRootPage, pages, pageId, childPages]);

  const isLoading = isPagesFetching || isPageFetching || isChildPagesFetching;

  return (
    <ProgressOverlay withLoadingDisk loading={isLoading} opacity={1}>
      <MuiBox>
        <MuiBox mb={2}>
          <MuiGrid container justifyContent="space-between" alignItems="center">
            <MuiTypography variant="subtitle1" color="secondary">
              {!page && (
                <FormattedMessage id="page.content_management.body.title.categories" />
              )}
              {page?.type === PAGE_TYPE.category && (
                <FormattedMessage id="page.content_management.body.title.subcategories" />
              )}
              {page?.type === PAGE_TYPE.subcategory && (
                <FormattedMessage id="page.content_management.body.title.sections" />
              )}
            </MuiTypography>
            {!isLegalDocumentsCategory && <CreateButton item={page} />}
          </MuiGrid>
        </MuiBox>
        {selectedPages.map((item) => (
          <NavCard key={item.id} item={item} />
        ))}
      </MuiBox>
    </ProgressOverlay>
  );
};

export default ContentBody;

import { UserSchema as AWSUserSchema } from 'src/aws/UserPool';
import { UserRequestSchema } from 'src/api/endpoints/userRequests';
import {
  formatEmail,
  formatFullName,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';

import { UserSchema } from './typings';

export interface TransformUsersDataSchemaOptions {
  awsUsers?: AWSUserSchema[];
  requestUsers?: UserRequestSchema[];
}

export const transformUsersDataSchema = ({
  awsUsers,
  requestUsers,
}: TransformUsersDataSchemaOptions): UserSchema[] => {
  if (!awsUsers && !requestUsers) {
    console.error('awsUsers or requestUsers option should be provided');
  }

  if (awsUsers && requestUsers) {
    console.error(
      'should be provided only one option, awsUsers or requestUsers'
    );
  }

  if (awsUsers) {
    return awsUsers.map((user) => {
      const firstname = parseUserAttribute(user?.Attributes, 'given_name');
      const lastname = parseUserAttribute(user?.Attributes, 'family_name');
      const email = parseUserAttribute(user?.Attributes, 'email');

      return {
        firstname,
        lastname,
        email,
        id: parseUserAttribute(user, 'Username'),
        fullname: `${firstname} ${lastname}`,
        formattedEmail: formatEmail(email),
        formattedFullname: formatFullName(firstname, lastname),
        phone: parseUserAttribute(user?.Attributes, 'phone_number'),
        company: parseUserAttribute(user?.Attributes, 'custom:company'),
        enabled: parseUserAttribute(user, 'Enabled'),
        status: parseUserAttribute(user, 'UserStatus'),
      };
    });
  }

  if (requestUsers) {
    return requestUsers.map((user) => {
      const firstname = user.first_name;
      const lastname = user.last_name;
      const email = user.email;

      return {
        firstname,
        lastname,
        email,
        id: user.id,
        fullname: `${firstname} ${lastname}`,
        formattedEmail: formatEmail(email),
        formattedFullname: formatFullName(firstname, lastname),
        phone: user.phone,
        company: user.company,
      };
    });
  }

  return [];
};

import { useQuery } from 'react-query';

import { fetchBookmarksFilters } from '../requests/fetchBookmarksFilters';
import { BookmarksFilters } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseFetchBookmarksFiltersOptions {
  enabled?: boolean;
}

export const useFetchBookmarksFilters = (
  options: UseFetchBookmarksFiltersOptions = defaultOptions
) => {
  const result = useQuery<BookmarksFilters>(
    [QUERY_NAME.fetchBookmarksFilters],
    fetchBookmarksFilters,
    options
  );

  return result;
};

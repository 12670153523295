import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 80,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
    }),

    '&.isDragActive, &.isFileDialogActive': {
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
    },

    '&.hasError': {
      borderColor: theme.palette.error.main + '!important',
      color: theme.palette.error.main + '!important',
    },
  },
}));

export const TEST_ID = {
  videoPlayerContainer: 'videoPlayerContainer',
  spinner: 'spinner',
  header: 'header',
  controls: 'controls',
  playButton: 'playButton',
  pauseButton: 'pauseButton',
  fastForwardButton: 'fastForwardButton',
  volumeContainer: 'volumeContainer',
  volumeButton: 'volumeButton',
  volumeMuteIcon: 'volumeMuteIcon',
  volumeDownIcon: 'volumeDownIcon',
  volumeUpIcon: 'volumeUpIcon',
  volumeSlider: 'volumeSlider',
  progressSlider: 'progressSlider',
  downloadButton: 'downloadButton',
  pipButton: 'pipButton',
  fullscreenEnterButton: 'fullscreenEnterButton',
  fullscreenExitButton: 'fullscreenExitButton',
};

export const VIDEO_PLAYER_WIDTH = 1280;
export const VIDEO_PLAYER_HEIGHT = 720;

export enum VIDEO_PLAYBACK_RATE {
  normal = 1,
  fast = 2,
}

export const VIDEO_PROGRESS_MIN = 0;
export const VIDEO_PROGRESS_MAX = 0.999999;
export const VIDEO_PROGRESS_STEP = 0.000001;

export const VIDEO_VOLUME_MIN = 0;
export const VIDEO_VOLUME_MAX = 1;
export const VIDEO_VOLUME_STEP = 0.1;
export const VIDEO_VOLUME_DEFAULT = VIDEO_VOLUME_MAX;

export const VIDEO_MOUSEMOVE_TIMEOUT = 1000;

export const ZINDEX_OVER_FULLSCREEN = 2147483647;

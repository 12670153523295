import { FC } from 'react';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
import MuiPlayArrowIcon from '@material-ui/icons/PlayArrow';

import { formatSeconds } from 'src/components/VideoPlayer';

import { TEST_ID } from '../../constants';

import { useStyles } from './styles';

interface Props {
  name: string;
  thumbnail: string;
  duration: number;
}

const VideoPreview: FC<Props> = ({ name, thumbnail, duration }) => {
  const classes = useStyles();

  return (
    <MuiBox className={classes.root} data-testid={TEST_ID.videoPreview}>
      <img src={thumbnail} className={classes.imagePreview} alt={name} />
      <MuiBox className={classes.details}>
        <MuiPlayArrowIcon className={classes.playIcon} />
        <MuiTypography component="span">
          {formatSeconds(duration)}
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default VideoPreview;

import { useQuery, UseQueryResult } from 'react-query';

import { useNotFoundContext } from 'src/context/NotFoundContext';

import { fetchUserRequest } from '../requests/fetchUserRequest';
import { UserRequestSchema } from '../typings';
import { QUERY_NAME } from '../constants';

interface UseFetchUserRequestOptions<T> {
  select?: (data: UserRequestSchema) => T;
}

export const useFetchUserRequest = <T = UserRequestSchema>(
  requestId: string,
  options: UseFetchUserRequestOptions<T> = {}
): UseQueryResult<T> => {
  const { setPageExists } = useNotFoundContext();

  const result = useQuery<UserRequestSchema, Error, T>(
    [QUERY_NAME.userRequest, requestId],
    () => fetchUserRequest(requestId),
    {
      ...options,
      onError: () => {
        setPageExists(false);
      },
    }
  );

  return result;
};

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    width: '100%',
    minHeight: 80,
    borderRadius: theme.constants.borderRadius,
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    marginBottom: 15,
    overflow: 'hidden',

    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

import { NavSchema } from './typings';

export const generateNavSchema = (navSchema: NavSchema[], userId: string) => {
  return navSchema.map((schema) => ({
    ...schema,
    route: schema.route
      .replace(':username', userId)
      .replace(':requestId', userId),
  }));
};

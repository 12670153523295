import { useMemo } from 'react';

import { PAGE_TYPE } from 'src/api/endpoints/contentHub';

interface UseRelatedPageTypeOptions {
  type?: PAGE_TYPE;
}

export const useRelatedPageType = ({
  type,
}: UseRelatedPageTypeOptions = {}) => {
  const relatedType = useMemo(() => {
    if (type === PAGE_TYPE.category) {
      return {
        single: 'Subcategory',
        plural: 'Subcategories',
        constant: PAGE_TYPE.subcategory,
      };
    }

    if (type === PAGE_TYPE.subcategory) {
      return {
        single: 'Section',
        plural: 'Sections',
        constant: PAGE_TYPE.section,
      };
    }

    return {
      single: 'Category',
      plural: 'Categories',
      constant: PAGE_TYPE.category,
    };
  }, [type]);

  return relatedType;
};

import { useMemo, useState } from 'react';

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { requestOrderChangeForContentHubPage } from '../requests/requestOrderChangeForContentHubPage';
import { ContentHubObjectSchema } from '../typings';
import { MOVE_DIRECTION, QUERY_NAME } from '../constants';

interface UseOrderChangeForContentHubPageOptions {
  parentId?: ContentHubObjectSchema['parent_id'];
}

interface UseMutationVariables {
  id: ContentHubObjectSchema['id'];
  direction: MOVE_DIRECTION;
}

interface UseMutationContext {
  previousData?: ContentHubObjectSchema[];
}

export type UseOrderChangeForContentHubPageResult = UseMutationResult<
  ContentHubObjectSchema,
  Error,
  UseMutationVariables,
  UseMutationContext
> & {
  isMoveUpLoading: boolean;
  isMoveDownLoading: boolean;
};

export const useOrderChangeForContentHubPage = ({
  parentId,
}: UseOrderChangeForContentHubPageOptions = {}): UseOrderChangeForContentHubPageResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const [currentDirection, setCurrentDirection] =
    useState<MOVE_DIRECTION | null>(null);

  const queryKey = useMemo(() => {
    return parentId
      ? [QUERY_NAME.fetchContentHubChildPages, parentId]
      : [QUERY_NAME.fetchContentHubPages];
  }, [parentId]);

  const mutation = useMutation<
    ContentHubObjectSchema,
    Error,
    UseMutationVariables,
    UseMutationContext
  >(
    ({ id, direction }) =>
      requestOrderChangeForContentHubPage({ id, direction }),
    {
      onMutate: async ({ direction }) => {
        await queryClient.cancelQueries(queryKey);

        setCurrentDirection(direction);

        const previousData =
          queryClient.getQueryData<ContentHubObjectSchema[]>(queryKey);

        return { previousData };
      },

      onError: ({ message }, _variables, context) => {
        queryClient.setQueryData(queryKey, context?.previousData);

        enqueueErrorSnackbar({ message });
      },

      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
        setCurrentDirection(null);
      },
    }
  );

  return {
    ...mutation,
    isMoveUpLoading:
      mutation.isLoading && currentDirection === MOVE_DIRECTION.up,
    isMoveDownLoading:
      mutation.isLoading && currentDirection === MOVE_DIRECTION.down,
  };
};

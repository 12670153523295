import { FC, MouseEvent, useMemo, useRef } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import cx from 'classnames';

import { useTheme } from '@material-ui/core';
import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiLink from '@material-ui/core/Link';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiIconDelete from '@material-ui/icons/Close';
import MuiIconCheck from '@material-ui/icons/Check';
import MuiIconCloudUpload from '@material-ui/icons/CloudUploadOutlined';
import MuiIconVisibility from '@material-ui/icons/VisibilityOutlined';
import MuiIconEdit from '@material-ui/icons/EditOutlined';

import {
  ContentHubFileSchema,
  FILE_STATUS,
  useDeleteContentHubPageFile,
  usePublishContentHubPageFile,
  useReviewContentHubPageFile,
} from 'src/api/endpoints/contentHub';
import { getFileTypeIcon } from 'src/utils/helpers/file';
import IconButton from 'src/components/elements/IconButton';

import { useStyles } from './styles';

interface Props {
  file: ContentHubFileSchema;
  isHovered: boolean;
  onEdit: () => void;
}

const FileCardStaticContent: FC<Props> = ({ file, isHovered, onEdit }) => {
  const classes = useStyles();

  const theme = useTheme();

  const { formatMessage } = useIntl();

  const { pageId } = useParams<{ pageId: string }>();

  const linkRef = useRef<HTMLAnchorElement>(null);

  const { mutateAsync: deletePageFile, isLoading: isDeleting } =
    useDeleteContentHubPageFile(Number(pageId));

  const { mutateAsync: publishPageFile, isLoading: isPublishing } =
    usePublishContentHubPageFile(Number(pageId));

  const { mutateAsync: reviewPageFile, isLoading: isReviewing } =
    useReviewContentHubPageFile(Number(pageId));

  const parsedDate = useMemo(
    () => format(new Date(file.updated_at), 'dd MMM yyyy, HH:mm'),
    [file.updated_at]
  );

  const authorShortCut = useMemo(() => {
    if (!file.author || file.author.length === 0) {
      return '';
    }

    const nameParts = file.author.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[1] || firstName.charAt(1);

    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }, [file.author]);

  const handleFileReview = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await reviewPageFile({ id: file.id });
  };

  const handleFilePublish = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await publishPageFile({ id: file.id });
  };

  const handleFileEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onEdit();
  };

  const handleFileDelete = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await deletePageFile({ id: file.id });
  };

  const handleLinkClick = () => {
    linkRef.current?.click();
  };

  const isActionsLoading = isDeleting || isPublishing || isReviewing;
  const isActionsVisible = isHovered || isActionsLoading;

  const isStatusNew = file.status === FILE_STATUS.new;
  const isStatusPending = file.status === FILE_STATUS.pending;
  const isStatusPublished = file.status === FILE_STATUS.published;

  return (
    <MuiButtonBase
      className={classes.buttonBase}
      component="div"
      onClick={handleLinkClick}
    >
      <MuiBox className={classes.fileExtensionIcon}>
        {getFileTypeIcon(file.extension, {
          color: theme.palette.secondary.main,
        })}
      </MuiBox>
      <MuiGrid container alignItems="center" className={classes.fileContent}>
        <MuiGrid item xs={12}>
          <MuiGrid container justifyContent="space-between" alignItems="center">
            <MuiGrid item xs={7} md={8} lg={9}>
              <MuiLink
                ref={linkRef}
                variant="h6"
                color="secondary"
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.fileName}
                onClick={(event) => event.stopPropagation()}
              >
                {file.name}.{file.extension}
              </MuiLink>
            </MuiGrid>
            {isActionsVisible && (
              <MuiGrid item xs={5} md={4} lg={3}>
                <MuiGrid container justifyContent="flex-end">
                  {isStatusNew && (
                    <MuiBox ml={2}>
                      <MuiTooltip title="Review">
                        <div>
                          <IconButton
                            size="small"
                            loading={isReviewing}
                            disabled={isActionsLoading}
                            onClick={handleFileReview}
                          >
                            <MuiIconVisibility color="secondary" />
                          </IconButton>
                        </div>
                      </MuiTooltip>
                    </MuiBox>
                  )}
                  {isStatusPending && (
                    <MuiBox ml={1}>
                      <MuiTooltip title="Approve">
                        <div>
                          <IconButton
                            size="small"
                            loading={isPublishing}
                            disabled={isActionsLoading}
                            onClick={handleFilePublish}
                          >
                            <MuiIconCheck color="secondary" />
                          </IconButton>
                        </div>
                      </MuiTooltip>
                    </MuiBox>
                  )}
                  {isStatusNew && (
                    <MuiBox ml={1}>
                      <MuiTooltip title="Publish">
                        <div>
                          <IconButton
                            size="small"
                            loading={isPublishing}
                            disabled={isActionsLoading}
                            onClick={handleFilePublish}
                          >
                            <MuiIconCloudUpload color="secondary" />
                          </IconButton>
                        </div>
                      </MuiTooltip>
                    </MuiBox>
                  )}
                  <MuiBox ml={1}>
                    <MuiTooltip
                      title={formatMessage({ id: 'app.button.edit' })}
                    >
                      <div>
                        <IconButton
                          size="small"
                          disabled={isActionsLoading}
                          onClick={handleFileEdit}
                        >
                          <MuiIconEdit color="secondary" />
                        </IconButton>
                      </div>
                    </MuiTooltip>
                  </MuiBox>
                  <MuiBox ml={1}>
                    <MuiTooltip
                      title={formatMessage({ id: 'app.button.delete' })}
                    >
                      <div>
                        <IconButton
                          size="small"
                          loading={isDeleting}
                          disabled={isActionsLoading}
                          onClick={handleFileDelete}
                        >
                          <MuiIconDelete color="secondary" />
                        </IconButton>
                      </div>
                    </MuiTooltip>
                  </MuiBox>
                </MuiGrid>
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item>
          <MuiGrid container alignItems="center">
            <MuiTypography variant="subtitle2" component="span">
              <FormattedMessage
                id="page.content_management.nav_card.last_update"
                values={{ date: <strong>{parsedDate}</strong> }}
              />
            </MuiTypography>
            <MuiTooltip title={file.author}>
              <MuiAvatar className={classes.avatar}>
                <MuiTypography variant="body1">{authorShortCut}</MuiTypography>
              </MuiAvatar>
            </MuiTooltip>
            {!isStatusPublished && (
              <MuiBox
                className={cx(classes.fileStatus, {
                  isStatusNew,
                  isStatusPending,
                })}
              >
                <MuiTypography variant="caption">
                  {isStatusNew && (
                    <FormattedMessage id="page.content_management.files.status.helper_text.new" />
                  )}
                  {isStatusPending && (
                    <FormattedMessage id="page.content_management.files.status.helper_text.pending" />
                  )}
                </MuiTypography>
              </MuiBox>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiButtonBase>
  );
};

export default FileCardStaticContent;

import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import RemoveIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

import Button from '@quanterix-ui/core/Button';

import { UseMalwareScanResult } from 'src/utils/hooks';
import DNALoader from 'src/components/elements/DNALoader';
import { getFileName } from 'src/utils/StringHelper';

import { useStyles } from './styles';

interface Props {
  isMalwareScanInProgress: UseMalwareScanResult['inProgress'];
  isMalwareScanCompleted: UseMalwareScanResult['isCompleted'];
  totalScannedFilesCount: UseMalwareScanResult['totalScannedFilesCount'];
  filesWithMalware: UseMalwareScanResult['temporaryFilesWithMalware'];
  cleanFilesWithMalware: () => void;
}

const MalwareScanDialog: FC<Props> = ({
  isMalwareScanInProgress,
  isMalwareScanCompleted,
  filesWithMalware,
  cleanFilesWithMalware,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const modalRootElement = useRef<HTMLElement | null>(null);

  const hasFilesWithMalware = useMemo(
    () => isMalwareScanCompleted && filesWithMalware.length > 0,
    [filesWithMalware, isMalwareScanCompleted]
  );

  const hasNoFilesWithMalware = useMemo(
    () => isMalwareScanCompleted && filesWithMalware.length === 0,
    [filesWithMalware, isMalwareScanCompleted]
  );

  const filesWithMalwareToShow = useMemo(() => {
    if (hasFilesWithMalware) {
      const filesWithCorrectNaming = filesWithMalware.reduce<string[]>(
        (base, nextFile) => [...base, getFileName(nextFile)],
        []
      );

      return filesWithCorrectNaming;
    }

    return undefined;
  }, [hasFilesWithMalware, filesWithMalware]);

  useEffect(() => {
    // NOTE: document.createElement('div') - is for testing purpose
    modalRootElement.current =
      (document.getElementById('modal-root') as HTMLDivElement) ||
      document.createElement('div');
  }, []);

  useEffect(() => {
    if (isMalwareScanInProgress && !isOpen) {
      setIsOpen(true);
    }
  }, [isMalwareScanInProgress, isOpen]);

  const handleClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      cleanFilesWithMalware();
    }, 500);
  };

  if (!modalRootElement.current) {
    return null;
  }

  return createPortal(
    <Dialog
      classes={{
        paper: cx(classes.paper, {
          withMalware: hasFilesWithMalware,
          withoutMalware: hasNoFilesWithMalware,
        }),
      }}
      open={isOpen}
    >
      <DialogContent className={classes.content}>
        <Box mb={3}>
          <Grid container justifyContent="center">
            {isMalwareScanInProgress && (
              <Box mt={1} mb={2}>
                <DNALoader />
              </Box>
            )}
            {hasFilesWithMalware && (
              <RemoveIcon className={cx(classes.icon, 'withMalware')} />
            )}
            {hasNoFilesWithMalware && (
              <CheckIcon className={cx(classes.icon, 'withoutMalware')} />
            )}
          </Grid>
        </Box>
        {isMalwareScanInProgress && (
          <Grid container direction="column" alignItems="center">
            <Typography variant="h6">
              <FormattedMessage id="file_list.malware_scan.dialog.in_progress" />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id="file_list.malware_scan.dialog.wait" />
            </Typography>
          </Grid>
        )}
        {hasNoFilesWithMalware && (
          <Grid container direction="column" alignItems="center">
            <Typography variant="h6">
              <FormattedMessage id="file_list.malware_scan.dialog.completed" />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id="file_list.malware_scan.dialog.completed_successfully" />
            </Typography>
          </Grid>
        )}
        {hasFilesWithMalware && (
          <Grid container direction="column" alignItems="center">
            <Typography variant="h6">
              <FormattedMessage id="file_list.malware_scan.dialog.completed" />
            </Typography>
            <Typography variant="h6">
              <FormattedMessage id="file_list.malware_scan.dialog.completed_unsuccessfully" />
            </Typography>
            {filesWithMalwareToShow?.map((file) => (
              <Typography key={file} variant="button" color="error">
                {file}
              </Typography>
            ))}
            <Box my={2} textAlign="center">
              <Typography variant="h4">
                <FormattedMessage id="file_list.malware_scan.dialog.contact_it_support" />
              </Typography>
            </Box>
          </Grid>
        )}
      </DialogContent>
      {isMalwareScanCompleted && (
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose}>
            <FormattedMessage id="app.button.ok" />
          </Button>
        </DialogActions>
      )}
    </Dialog>,
    modalRootElement.current
  );
};

export default memo(MalwareScanDialog);

import { FC, useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiTypography from '@material-ui/core/Typography';

import {
  ContentHubFileSchema,
  QUERY_NAME,
  useFetchContentHubPageFiles,
  useUploadContentHubPageFile,
} from 'src/api/endpoints/contentHub';
import { UPLOAD_SOURCE } from 'src/utils/constants/uploadSource';
import {
  MALWARE_SCAN_SOURCE,
  useMalwareScan,
  useSnackbar,
} from 'src/utils/hooks';

import MalwareScanDialog from './components/MalwareScanDialog';
import FileCard from './components/FileCard';
import {
  DROPZONE_ACCEPTABLE_SCHEMA,
  DROPZONE_ERROR_CODES,
  MAX_FILES_COUNT,
  MAX_FILES_COUNT_PER_UPLOAD,
  MAX_FILES_SIZE_PER_UPLOAD,
  READABLE_MAX_FILES_SIZE,
} from './constants';
import { useStyles } from './styles';

const FilesUploader: FC = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const { formatMessage } = useIntl();

  const errorMessagePrefix = formatMessage({
    id: 'page.content_management.files.uploader.error.prefix',
  });

  const { pageId } = useParams<{ pageId: string }>();

  const { data: pageFiles, isLoading: isPageFilesLoading } =
    useFetchContentHubPageFiles(Number(pageId), { enabled: Boolean(pageId) });

  const { mutateAsync: uploadPageFile, isLoading: isUploading } =
    useUploadContentHubPageFile(Number(pageId));

  const {
    addFilesToScanQueue,
    inProgress: isMalwareScanInProgress,
    isCompleted: isMalwareScanCompleted,
    filesWithMalware,
    temporaryFilesWithMalware,
    totalScannedFilesCount,
    cleanTemporaryFilesWithMalware,
    removeFileFromScanQueue,
  } = useMalwareScan(MALWARE_SCAN_SOURCE.documents);

  const handleFilesUpload = useCallback(
    async (files: File[]) => {
      Promise.all(
        files.map((file) =>
          uploadPageFile({
            file,
            source: UPLOAD_SOURCE.documents,
          })
        )
      ).then((data) => {
        addFilesToScanQueue(data.map((file) => file.blob_storage_key));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadPageFile]
  );

  const handleDropAllFiles = async (droppedFiles: File[]) => {
    const droppedFilesCount = droppedFiles.length;
    const totalSizeOfDroppedFiles = droppedFiles.reduce(
      (totalSize, nextFile) => totalSize + nextFile.size,
      0
    );

    if (pageFiles.length + droppedFilesCount > MAX_FILES_COUNT) {
      enqueueErrorSnackbar({
        message: 'page.content_management.files.uploader.error.max_files',
        intlValues: {
          prefix: errorMessagePrefix,
          maxFilesCount: String(MAX_FILES_COUNT),
        },
      });
    } else if (totalSizeOfDroppedFiles > MAX_FILES_SIZE_PER_UPLOAD) {
      enqueueErrorSnackbar({
        message:
          'page.content_management.files.uploader.error.max_files_size_per_upload',
        intlValues: {
          prefix: errorMessagePrefix,
          maxFilesSize: String(READABLE_MAX_FILES_SIZE),
        },
      });
    } else if (droppedFilesCount > MAX_FILES_COUNT_PER_UPLOAD) {
      enqueueErrorSnackbar({
        message:
          'page.content_management.files.uploader.error.max_files_per_upload',
        intlValues: {
          prefix: errorMessagePrefix,
          maxFilesCount: String(MAX_FILES_COUNT_PER_UPLOAD),
        },
      });
    } else if (droppedFilesCount > 0) {
      handleFilesUpload(droppedFiles);
    }
  };

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isFocused,
    isFileDialogActive,
  } = useDropzone({
    multiple: true,
    useFsAccessApi: false,
    accept: DROPZONE_ACCEPTABLE_SCHEMA,
    maxSize: MAX_FILES_SIZE_PER_UPLOAD,
    maxFiles: MAX_FILES_COUNT_PER_UPLOAD,
    onDrop: handleDropAllFiles,
  });

  const isMaxFilesError = useMemo(() => {
    return !!fileRejections.find((f) =>
      f.errors.find((e) => e.code === DROPZONE_ERROR_CODES.tooManyFiles)
    );
  }, [fileRejections]);

  const filesWithMaxSizeError = useMemo(() => {
    return fileRejections.filter((f) =>
      f.errors.find((e) => e.code === DROPZONE_ERROR_CODES.fileTooLarge)
    );
  }, [fileRejections]);

  const filesWithInvalidTypeError = useMemo(() => {
    return fileRejections.filter((f) =>
      f.errors.find((e) => e.code === DROPZONE_ERROR_CODES.fileInvalidType)
    );
  }, [fileRejections]);

  useEffect(() => {
    if (isMaxFilesError) {
      enqueueErrorSnackbar({
        message:
          'page.content_management.files.uploader.error.max_files_per_upload',
        intlValues: {
          prefix: errorMessagePrefix,
          maxFilesCount: String(MAX_FILES_COUNT_PER_UPLOAD),
        },
      });
    }
  }, [isMaxFilesError, errorMessagePrefix, enqueueErrorSnackbar]);

  useEffect(() => {
    if (filesWithMaxSizeError.length > 0) {
      enqueueErrorSnackbar({
        message: 'page.content_management.files.uploader.error.max_file_size',
        intlValues: {
          prefix: errorMessagePrefix,
          maxFileSize: String(READABLE_MAX_FILES_SIZE),
        },
      });
    }
  }, [filesWithMaxSizeError, errorMessagePrefix, enqueueErrorSnackbar]);

  useEffect(() => {
    if (filesWithInvalidTypeError.length > 0) {
      enqueueErrorSnackbar({
        message:
          'page.content_management.files.uploader.error.files_type_invalid',
        intlValues: {
          prefix: errorMessagePrefix,
        },
      });
    }
  }, [filesWithInvalidTypeError, errorMessagePrefix, enqueueErrorSnackbar]);

  useEffect(() => {
    // automatically remove files with malware
    if (isMalwareScanCompleted && filesWithMalware.length > 0) {
      for (const fileWithMalware of filesWithMalware) {
        queryClient.setQueryData<ContentHubFileSchema[]>(
          [QUERY_NAME.fetchContentHubPageFiles, Number(pageId)],
          (previous = []) =>
            previous.filter((file) => file.blob_storage_key !== fileWithMalware)
        );
        queryClient.invalidateQueries([QUERY_NAME.filesCount]);

        removeFileFromScanQueue(fileWithMalware);
      }
    }
  }, [
    pageId,
    queryClient,
    filesWithMalware,
    isMalwareScanCompleted,
    removeFileFromScanQueue,
  ]);

  const isLoading = isPageFilesLoading || isUploading;

  return (
    <MuiBox mt={3}>
      <MuiBox
        {...getRootProps({
          className: cx(classes.dropzone, {
            isFocused,
            isFileDialogActive,
          }),
        })}
        mb={2}
      >
        <input {...getInputProps()} />
        {isLoading && (
          <MuiCircularProgress size={24} thickness={4} color="secondary" />
        )}
        {!isLoading && (
          <MuiTypography variant="button" component="span" color="secondary">
            <FormattedMessage id="page.content_management.files.uploader.placeholder" />
          </MuiTypography>
        )}
      </MuiBox>
      {pageFiles.map((file) => (
        <FileCard key={file.id} file={file} />
      ))}
      <MalwareScanDialog
        isMalwareScanInProgress={isMalwareScanInProgress}
        isMalwareScanCompleted={isMalwareScanCompleted}
        filesWithMalware={temporaryFilesWithMalware}
        cleanFilesWithMalware={cleanTemporaryFilesWithMalware}
        totalScannedFilesCount={totalScannedFilesCount}
      />
    </MuiBox>
  );
};

export default FilesUploader;

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    maxWidth: 400,
    width: '100%',
    padding: 10,
    borderRadius: theme.constants.borderRadius,
    zIndex: theme.zIndex.modal,
  },

  button: {
    minWidth: `80px !important`,
  },
}));

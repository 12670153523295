import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import ControlledTextField, {
  ControlledTextFieldProps,
} from '@quanterix-ui/core/ControlledTextField';

import { useStyles as useCommonStyles } from '../styles';

import { EMAIL_PATTERN } from './constants';

interface Props extends ControlledTextFieldProps {
  label?: string;
}

const ControlledEmailTextField: FC<Props> = ({
  id,
  name,
  label,
  required = true,
  ...restProps
}) => {
  const commonClasses = useCommonStyles();

  const { formatMessage } = useIntl();

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.email" />}
      </MuiFormLabel>
      <ControlledTextField
        fullWidth
        id={id ?? name}
        name={name}
        rules={{
          required: {
            value: required,
            message: 'This field is required.',
          },
          pattern: {
            value: EMAIL_PATTERN,
            message: formatMessage({ id: 'app.form.error.invalid_email' }),
          },
        }}
        placeholder={formatMessage({ id: 'app.form.placeholder.email' })}
        {...restProps}
      />
      {errors[name]?.message && (
        <MuiFormHelperText error>{errors[name].message}</MuiFormHelperText>
      )}
    </>
  );
};

export default ControlledEmailTextField;

import { FC, useMemo } from 'react';

import cx from 'classnames';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import {
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiCheckCircleOutline,
} from '@mdi/js';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiTypography from '@material-ui/core/Typography';

import IconButton from 'src/components/elements/IconButton';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import { UserStatuses } from 'src/utils/Constants';

import { useStyles } from './styles';
import {
  PROFILE_NAV_SCHEMA,
  REGISTERED_USER_PROFILE_NAV_SCHEMA,
  USER_REQUEST_PROFILE_NAV_SCHEMA,
} from './constants';
import { generateNavSchema } from './utils';

interface Props {
  status?: string;
  isUserEnabled?: boolean;
  isUserTogglingAvailability?: boolean;
  isUserConfirming?: boolean;
  onUserConfirm?: () => void;
  onUserToggleAvailability?: () => Promise<void | undefined>;
}

const UserViewerProfileNavigation: FC<Props> = ({
  status,
  isUserEnabled,
  isUserTogglingAvailability,
  isUserConfirming,
  onUserToggleAvailability,
  onUserConfirm,
}) => {
  const classes = useStyles();

  const location = useLocation();

  const isProfilePages = location.pathname.startsWith(
    PRIVATE_ROUTES_MAP.profile
  );

  const { username, requestId } = useParams<{
    username: string;
    requestId: string;
  }>();

  const userStatus = status ? (status as keyof typeof UserStatuses) : null;
  const readableStatus = userStatus && UserStatuses[userStatus];

  const routeSchema = useMemo(() => {
    if (location.pathname.startsWith('/profile')) {
      return PROFILE_NAV_SCHEMA;
    }

    if (location.pathname.startsWith('/admin/users/registered')) {
      return generateNavSchema(REGISTERED_USER_PROFILE_NAV_SCHEMA, username);
    }

    if (location.pathname.startsWith('/admin/users/requests')) {
      return generateNavSchema(USER_REQUEST_PROFILE_NAV_SCHEMA, requestId);
    }

    return null;
  }, [location.pathname, username, requestId]);

  const checkIfLinkActive = (route: string) => {
    return route === location.pathname;
  };

  return (
    <MuiBox width="100%">
      <MuiGrid
        container
        className={classes.tabsSection}
        alignItems="center"
        justifyContent="space-between"
      >
        <MuiGrid item xs={12} lg={6}>
          <MuiGrid container alignItems="center">
            <MuiTypography variant="h2" className={classes.title}>
              {isProfilePages && (
                <FormattedMessage id="page.profile.title.my_profile" />
              )}
              {!isProfilePages && (
                <FormattedMessage id="page.profile.title.user_profile" />
              )}
              {!isProfilePages && status && (
                <>
                  {` | `}
                  <MuiTypography
                    component="span"
                    className={cx(
                      classes.status,
                      status === 'CONFIRMED' ? 'confirmed' : 'not-confirmed'
                    )}
                  >
                    {readableStatus}
                  </MuiTypography>
                </>
              )}
            </MuiTypography>
            <MuiBox ml={3}>
              {onUserToggleAvailability && !isUserEnabled && (
                <MuiTooltip title="Enable">
                  <div>
                    <IconButton
                      color="secondary"
                      className={classes.actionButton}
                      loading={isUserTogglingAvailability}
                      onClick={onUserToggleAvailability}
                    >
                      <Icon path={mdiAccountCheckOutline} size={1.2} />
                    </IconButton>
                  </div>
                </MuiTooltip>
              )}
              {onUserToggleAvailability && isUserEnabled && (
                <MuiTooltip title="Disable">
                  <div>
                    <IconButton
                      color="secondary"
                      className={classes.actionButton}
                      loading={isUserTogglingAvailability}
                      onClick={onUserToggleAvailability}
                    >
                      <Icon path={mdiAccountCancelOutline} size={1.2} />
                    </IconButton>
                  </div>
                </MuiTooltip>
              )}
              {onUserConfirm && (
                <MuiTooltip title="Confirm">
                  <div>
                    <IconButton
                      color="secondary"
                      className={classes.actionButton}
                      loading={isUserConfirming}
                      onClick={onUserConfirm}
                    >
                      <Icon path={mdiCheckCircleOutline} size={1.2} />
                    </IconButton>
                  </div>
                </MuiTooltip>
              )}
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item xs={12} lg={6}>
          <MuiGrid container justifyContent="flex-end">
            {routeSchema?.map((schema) => (
              <Link
                key={schema.title}
                to={schema.route}
                className={cx({
                  [classes.linkActive]: checkIfLinkActive(
                    schema.route.replace(':username', username)
                  ),
                })}
              >
                <FormattedMessage id={schema.title} />
              </Link>
            ))}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

export default UserViewerProfileNavigation;

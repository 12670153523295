import { ChangeEvent, FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTypography from '@material-ui/core/Typography';
import MuiFormGroup from '@material-ui/core/FormGroup';

import Checkbox from '@quanterix-ui/core/Checkbox';

import { useFetchBookmarksFilters } from 'src/api/endpoints/bookmarks';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import { useBookmarksFilterContext } from '../../context/BookmarksFilterContext';
import { useStyles } from '../../styles';

import { TEST_ID } from './constants';

const BookmarksFilter: FC = () => {
  const classes = useStyles();

  const { bookmarksFilter, setBookmarksFilter } = useBookmarksFilterContext();

  const { data: filters, isLoading: isLoadingFilters } =
    useFetchBookmarksFilters();

  const handleChangeExtension = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      if (!bookmarksFilter.extensions.includes(value)) {
        setBookmarksFilter({
          ...bookmarksFilter,
          extensions: [...bookmarksFilter.extensions, value],
        });
      }
    } else {
      setBookmarksFilter({
        ...bookmarksFilter,
        extensions: bookmarksFilter.extensions.filter((ext) => ext !== value),
      });
    }
  };

  useEffect(() => {
    if (filters) {
      setBookmarksFilter((otherFilters) => ({
        ...otherFilters,
        extensions: filters.extensions,
      }));
    }
  }, [filters, setBookmarksFilter]);

  useEffect(() => {
    for (const extension of bookmarksFilter.extensions) {
      if (
        filters &&
        filters?.extensions &&
        !filters.extensions.includes(extension)
      ) {
        setBookmarksFilter({
          ...bookmarksFilter,
          extensions: bookmarksFilter.extensions.filter(
            (ext) => ext !== extension
          ),
        });
      }
    }
  }, [
    filters,
    filters?.extensions,
    bookmarksFilter,
    bookmarksFilter.extensions,
    setBookmarksFilter,
  ]);

  if (filters?.extensions.length === 0) {
    return null;
  }

  return (
    <MuiBox data-testid={TEST_ID.bookmarksFilter}>
      <ProgressOverlay withLoadingDisk loading={isLoadingFilters} opacity={1}>
        <MuiTypography className={classes.sidebarLabel}>
          <FormattedMessage id="page.bookmarks.filter.extensions.title" />
        </MuiTypography>
        <MuiFormGroup className={classes.checkboxGroup}>
          {filters?.extensions.map((ext) => (
            <MuiFormControlLabel
              key={ext}
              control={
                <Checkbox
                  value={ext}
                  checked={bookmarksFilter.extensions.includes(ext)}
                  onChange={handleChangeExtension}
                />
              }
              label={ext}
            />
          ))}
        </MuiFormGroup>
      </ProgressOverlay>
    </MuiBox>
  );
};

export default BookmarksFilter;

import { FC, MouseEvent } from 'react';

import { createPortal } from 'react-dom';

import MuiBox from '@material-ui/core/Box';
import MuiIconEdit from '@material-ui/icons/EditOutlined';

import IconButton from 'src/components/elements/IconButton';
import {
  FAQSectionItemSchema,
  useUpdateFAQSectionItem,
} from 'src/api/endpoints/faq';

import { useDialogContext } from '../../context/Dialog';
import SectionItemDialog, { FormValuesSchema } from '../SectionItemDialog';

interface Props {
  item: FAQSectionItemSchema;
}

const SectionItemEditButton: FC<Props> = ({ item }) => {
  const { id, section_id } = item;

  const { isOpen, setIsOpen } = useDialogContext();

  const { mutateAsync: updateFAQSectionItem, isLoading } =
    useUpdateFAQSectionItem();

  const handleDialogOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSectionItemUpdate = async ({
    question,
    answer,
  }: FormValuesSchema) => {
    await updateFAQSectionItem({ id, section_id, question, answer });
  };

  return (
    <MuiBox>
      <IconButton color="secondary" size="small" onClick={handleDialogOpen}>
        <MuiIconEdit />
      </IconButton>
      <div>
        {createPortal(
          <SectionItemDialog
            open={isOpen}
            loading={isLoading}
            titleTranslationId="page.faq_management.dialog.title.section_item.update"
            submitButtonTranslationId="app.button.update"
            item={item}
            onClose={handleDialogClose}
            onSubmit={handleSectionItemUpdate}
          />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </MuiBox>
  );
};

export default SectionItemEditButton;

import { lighten, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 480,
    borderRadius: 10,
    padding: 40,

    '&.withoutMalware': {
      backgroundColor: lighten(theme.palette.success.main, 0.95),
    },

    '&.withMalware': {
      backgroundColor: lighten(theme.palette.error.main, 0.95),
    },
  },

  content: {
    width: '100%',
    padding: 0,
    paddingBottom: 30,
  },

  actions: {
    justifyContent: 'flex-end',
    padding: 0,
  },

  icon: {
    width: 80,
    height: 80,

    '&.withoutMalware': {
      color: theme.palette.success.main,
    },

    '&.withMalware': {
      color: theme.palette.error.main,
    },
  },
}));

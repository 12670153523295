import { privateGateway } from '../../../config';
import { ContentHubObjectSchema, ContentHubPagesFilters } from '../typings';

interface FetchContentHubPagesParams {
  filters?: ContentHubPagesFilters;
}

export const fetchContentHubPages = async ({
  filters,
}: FetchContentHubPagesParams): Promise<ContentHubObjectSchema[]> => {
  let filtersQuery = '';

  if (filters) {
    filtersQuery = `show_hidden=${filters.showHidden}`;
  }

  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/pages?${filtersQuery}`);

  return response.data.data;
};

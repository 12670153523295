import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  instrumentCard: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
  },

  cardContent: {
    padding: 21,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    color: theme.palette.secondary.main,
    fontFamily: theme.fonts.fontFamily.bold,
    fontSize: 16,
  },

  addInstruments: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    textDecoration: 'underline',
  },
}));

import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { differenceInMinutes, differenceInSeconds, parseJSON } from 'date-fns';
import { useIntl } from 'react-intl';
import { SnackbarKey } from 'notistack';

import MuiBox from '@material-ui/core/Box';

import { useCognito } from 'src/aws/Cognito';
import { useDebouncedCallback } from 'src/utils/hooks';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { useUploadProgressContext } from 'src/context/UploadProgressContext';
import { useSnackbar } from 'src/utils/hooks/useSnackbar';

import {
  ACTIVITY_TRACKER_DEBOUNCE_THRESHOLD,
  ACTIVITY_TRACKER_THRESHOLD,
  INACTIVITY_WARNING_THRESHOLD,
  SESSION_CHECK_INTERVAL,
  SESSION_TIMEOUT_MINUTES,
  SESSION_WARNING_DURATION_MINUTES,
  TEST_ID,
} from './constants';
import { IntervalRef, TimerRef } from './typings';

const ActivityTracker: FC = () => {
  const { formatMessage } = useIntl();

  const { isAuthenticated, signOut } = useCognito();

  const { enqueueIntlSnackbar, closeSnackbar } = useSnackbar();

  const { uploadProgress: globalFileUploadProgress } =
    useUploadProgressContext();

  const isGlobalFileUploadInProgress = globalFileUploadProgress !== null;

  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey>();
  const [isUserActive, setIsUserActive] = useState(false);
  const [isSessionChecked, setIsSessionChecked] = useState(false);

  const userActivityTimeout = useRef<TimerRef>(null);
  const userActivityThrottlerTimeout = useRef<TimerRef>(null);
  const timeComparatorInterval = useRef<IntervalRef>(null);

  const [resetUserActivityTimeout] = useDebouncedCallback(() => {
    if (userActivityTimeout.current) {
      clearTimeout(userActivityTimeout.current);
    }

    userActivityTimeout.current = setTimeout(() => {
      setIsUserActive(false);
    }, ACTIVITY_TRACKER_THRESHOLD);
  }, ACTIVITY_TRACKER_DEBOUNCE_THRESHOLD);

  const userActivityThrottler = useCallback(() => {
    setIsUserActive(true);
    resetUserActivityTimeout();
  }, [resetUserActivityTimeout]);

  const beforeWindowUnload = useCallback(() => {
    const lastActivityDateFromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_KEY.lastActivity
    );
    const lastActivityDateCurrent = new Date();

    if (!lastActivityDateFromLocalStorage) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.lastActivity,
        JSON.stringify(lastActivityDateCurrent)
      );
    }
  }, []);

  const activateActivityTracker = useCallback(() => {
    window.addEventListener('mousemove', userActivityThrottler);
    window.addEventListener('mousedown', userActivityThrottler);
    window.addEventListener('scroll', userActivityThrottler);
    window.addEventListener('keydown', userActivityThrottler);
    window.addEventListener('resize', userActivityThrottler);

    window.addEventListener('beforeunload', beforeWindowUnload);
  }, [userActivityThrottler, beforeWindowUnload]);

  const deactivateActivityTracker = useCallback(() => {
    window.removeEventListener('mousemove', userActivityThrottler);
    window.removeEventListener('mousedown', userActivityThrottler);
    window.removeEventListener('scroll', userActivityThrottler);
    window.removeEventListener('keydown', userActivityThrottler);
    window.removeEventListener('resize', userActivityThrottler);

    window.removeEventListener('beforeunload', beforeWindowUnload);

    clearTimeout(userActivityTimeout.current!);
    clearTimeout(userActivityThrottlerTimeout.current!);
    clearInterval(timeComparatorInterval.current!);
  }, [userActivityThrottler, beforeWindowUnload]);

  useEffect(() => {
    if (isAuthenticated) {
      activateActivityTracker();
    } else {
      deactivateActivityTracker();
    }
  }, [isAuthenticated, activateActivityTracker, deactivateActivityTracker]);

  useEffect(() => {
    return () => {
      deactivateActivityTracker();
    };
  }, [deactivateActivityTracker]);

  useEffect(() => {
    if (isAuthenticated && isSessionChecked) {
      if (isUserActive) {
        clearInterval(timeComparatorInterval.current!);

        localStorage.removeItem(LOCAL_STORAGE_KEY.lastActivity);

        if (snackbarKey) {
          closeSnackbar(snackbarKey);
        }
      } else {
        clearInterval(timeComparatorInterval.current!);

        const lastActivityDateFromLocalStorage = localStorage.getItem(
          LOCAL_STORAGE_KEY.lastActivity
        );

        if (!lastActivityDateFromLocalStorage) {
          localStorage.setItem(
            LOCAL_STORAGE_KEY.lastActivity,
            JSON.stringify(new Date())
          );
        }

        timeComparatorInterval.current = setInterval(() => {
          if (!isGlobalFileUploadInProgress) {
            const now = new Date();
            const lastActivityDateFromLS = localStorage.getItem(
              LOCAL_STORAGE_KEY.lastActivity
            );

            const lastActivityDate = lastActivityDateFromLS
              ? parseJSON(lastActivityDateFromLS!)
              : now;

            const diffInSeconds = differenceInSeconds(now, lastActivityDate);

            if (diffInSeconds === INACTIVITY_WARNING_THRESHOLD) {
              const snackbarKey = enqueueIntlSnackbar({
                message: 'auth.activity.timeout_warning',
                intlValues: {
                  minutes: String(SESSION_WARNING_DURATION_MINUTES),
                },
                autoHideDuration: SESSION_WARNING_DURATION_MINUTES * 60 * 1000,
                variant: 'warning',
              });

              setSnackbarKey(snackbarKey);
            }

            if (
              !isAuthenticated ||
              diffInSeconds >= SESSION_TIMEOUT_MINUTES * 60
            ) {
              closeSnackbar(snackbarKey);
              signOut({
                reason: formatMessage({
                  id: 'auth.activity.session_timeout',
                }),
              });
            }
          }
        }, SESSION_CHECK_INTERVAL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isSessionChecked, isUserActive, formatMessage]);

  useEffect(() => {
    const lastActivityDateFromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_KEY.lastActivity
    );

    const now = new Date();

    if (lastActivityDateFromLocalStorage) {
      const diffInMinutes = differenceInMinutes(
        now,
        parseJSON(lastActivityDateFromLocalStorage!)
      );

      if (diffInMinutes >= SESSION_TIMEOUT_MINUTES) {
        signOut({
          withDefaultReason: false,
        });
      }
    }

    setIsSessionChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatMessage]);

  return <MuiBox display="none" data-testid={TEST_ID.activityTracker} />;
};

export default ActivityTracker;

import axios, { AxiosInstance } from 'axios';

import { includeAccessToken, transformErrorResponse } from './interceptors';

const privateGateway = (apiGateway: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: apiGateway,
  });

  if (process.env.NODE_ENV !== 'test') {
    axiosInstance.interceptors.request.use(includeAccessToken);
  }

  axiosInstance.interceptors.response.use((res) => res, transformErrorResponse);

  return axiosInstance;
};

export { privateGateway };

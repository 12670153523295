import { useMemo } from 'react';

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { deleteContentHubPageFile } from '../requests/deleteContentHubPageFile';
import { ContentHubFileSchema, DeletePageFileVariables } from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: ContentHubFileSchema[];
}

export type UseDeleteContentHubPageFileResult = UseMutationResult<
  void,
  Error,
  DeletePageFileVariables,
  UseMutationContext
>;

export const useDeleteContentHubPageFile = (
  pageId: number
): UseDeleteContentHubPageFileResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = useMemo(() => {
    return pageId
      ? [QUERY_NAME.fetchContentHubPageFiles, pageId]
      : [QUERY_NAME.fetchContentHubPageFiles];
  }, [pageId]);

  const mutation = useMutation<
    void,
    Error,
    DeletePageFileVariables,
    UseMutationContext
  >(({ id }) => deleteContentHubPageFile(pageId, { id }), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<ContentHubFileSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (data, { id }, context) => {
      const deletedFile = context?.previousData?.find((file) => file.id === id);

      queryClient.setQueryData<ContentHubFileSchema[]>(
        queryKey,
        (previous = []) => previous.filter((file) => file.id !== id)
      );
      queryClient.invalidateQueries([QUERY_NAME.filesCount]);

      if (deletedFile) {
        enqueueSuccessSnackbar({
          message: 'page.content_management.api.files.delete',
          intlValues: {
            name: deletedFile.name,
            extension: deletedFile.extension,
          },
        });
      }
    },

    onError: ({ message }, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

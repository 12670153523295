import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    fontSize: 28,
    fontFamily: theme.fonts.fontFamily.bold,
  },

  card: {
    borderRadius: 10,
    padding: '50px 150px',
    boxShadow: `0px 1px 11px ${alpha(theme.palette.common.black, 0.16)}`,
  },

  formLabel: {
    color: theme.palette.text.primary,
  },
}));

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface ContextResult {
  isEditable: boolean;
  setIsEditable: Dispatch<SetStateAction<boolean>>;

  imageKeyToBeDeleted: string | null;
  setImageKeyToBeDeleted: Dispatch<SetStateAction<string | null>>;
}

const EditableContentContext = createContext<ContextResult>({
  isEditable: false,
  setIsEditable: () => {
    // void
  },

  imageKeyToBeDeleted: null,
  setImageKeyToBeDeleted: () => {
    // void
  },
});

const EditableContentContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isEditable, setIsEditable] = useState(false);

  const [imageKeyToBeDeleted, setImageKeyToBeDeleted] = useState<string | null>(
    null
  );

  return (
    <EditableContentContext.Provider
      value={{
        isEditable,
        setIsEditable,

        imageKeyToBeDeleted,
        setImageKeyToBeDeleted,
      }}
    >
      {children}
    </EditableContentContext.Provider>
  );
};

export const useEditableContentContext = (): ContextResult => {
  const context = useContext(EditableContentContext);

  if (!context) {
    throw new Error(
      'useEditableContentContext must be used within a EditableContentContextProvider'
    );
  }

  return context;
};

export default EditableContentContextProvider;

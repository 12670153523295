import { useIntl } from 'react-intl';

import {
  ZIP_CODE_MAX_LENGTH,
  ZIP_CODE_MIN_LENGTH,
  ZIP_CODE_PATTERN,
} from '../constants';

type UseValidateZipCodeResult = (value: string) => string | boolean;

export const useValidateZipCode = (): UseValidateZipCodeResult => {
  const { formatMessage } = useIntl();

  const validateZipCode = (value: string): string | boolean => {
    const trimmedValue = value.split(' ').join('');

    if (trimmedValue.length < ZIP_CODE_MIN_LENGTH)
      return formatMessage(
        { id: 'app.form.error.minLength' },
        { count: ZIP_CODE_MIN_LENGTH }
      );

    if (trimmedValue.length > ZIP_CODE_MAX_LENGTH)
      return formatMessage(
        { id: 'app.form.error.maxLength' },
        { count: ZIP_CODE_MAX_LENGTH }
      );

    return (
      ZIP_CODE_PATTERN.test(trimmedValue) ||
      formatMessage({ id: 'app.form.error.invalid_entry' })
    );
  };

  return validateZipCode;
};

import { ChangeEvent, FC, MouseEvent } from 'react';

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import MuiBox from '@material-ui/core/Box';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTypography from '@material-ui/core/Typography';
import HistoryIcon from '@material-ui/icons/History';

import Pagination from '@quanterix-ui/core/Pagination';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { UserActivitySchema } from 'src/api/endpoints/users';
import { usePaginationContext } from 'src/context/PaginationContext';
import UserViewerProfileNavigation from 'src/components/UserViewerProfileNavigation';

import { useStyles } from './styles';
import {
  DEFAULT_PAGE,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_COLUMNS_COUNT,
  TEST_ID,
} from './constants';

interface Props {
  userActivity: UserActivitySchema[];
  userActivityCount: number;
  isPreviousData: boolean;
  isLoading: boolean;
}

const UserViewerProfileActivity: FC<Props> = ({
  userActivity,
  userActivityCount,
  isPreviousData,
  isLoading,
}) => {
  const classes = useStyles();

  const { pagination, setPagination } = usePaginationContext();

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination({
      ...pagination,
      page: newPage,
      skip: newPage * pagination.rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination({
      page: DEFAULT_PAGE,
      skip: DEFAULT_PAGE * DEFAULT_PAGE,
      rowsPerPage: Number(event.target.value),
    });
  };

  const isDataEmpty = !userActivityCount || userActivityCount === 0;

  return (
    <ProgressOverlay withLoadingDisk loading={isLoading} opacity={1} size={40}>
      <MuiBox data-testid={TEST_ID.userViewerProfileActivity}>
        <UserViewerProfileNavigation />
        <MuiTypography variant="h2" color="secondary" className={classes.title}>
          <HistoryIcon />
          <FormattedMessage id="page.profile.activity.title" />
        </MuiTypography>
        <MuiTableContainer>
          <OverlayScrollbarsComponent
            options={{ scrollbars: { autoHide: 'move' } }}
            className={classes.scrollbars}
          >
            <MuiTable className={classes.table}>
              <MuiTableHead className={classes.tableHead}>
                <MuiTableRow>
                  <MuiTableCell>
                    <FormattedMessage id="page.profile.activity.table.column.date" />
                  </MuiTableCell>
                  <MuiTableCell>
                    <FormattedMessage id="page.profile.activity.table.column.time" />
                  </MuiTableCell>
                  <MuiTableCell>
                    <FormattedMessage id="page.profile.activity.table.column.location" />
                  </MuiTableCell>
                  <MuiTableCell>
                    <FormattedMessage id="page.profile.activity.table.column.browser" />
                  </MuiTableCell>
                  <MuiTableCell>
                    <FormattedMessage id="page.profile.activity.table.column.device" />
                  </MuiTableCell>
                </MuiTableRow>
              </MuiTableHead>
              <MuiTableBody
                className={cx({ [classes.isTableLoading]: isPreviousData })}
              >
                {isDataEmpty && (
                  <MuiTableRow>
                    <MuiTableCell
                      align="center"
                      colSpan={TABLE_COLUMNS_COUNT}
                      className={classes.noDataPlaceholderCell}
                    >
                      <MuiTypography variant="h3">
                        <FormattedMessage id="page.profile.activity.table.no_data" />
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
                {userActivity.map((activity, index) => (
                  <MuiTableRow key={index}>
                    <MuiTableCell scope="row">
                      {new Date(activity.created_at).toLocaleDateString('en')}
                    </MuiTableCell>
                    <MuiTableCell>
                      {new Date(activity.created_at).toLocaleTimeString('en', {
                        hour12: true,
                        timeStyle: 'short',
                      })}
                    </MuiTableCell>
                    <MuiTableCell>{activity.location}</MuiTableCell>
                    <MuiTableCell>{activity.browser}</MuiTableCell>
                    <MuiTableCell>{activity.device}</MuiTableCell>
                  </MuiTableRow>
                ))}
              </MuiTableBody>
            </MuiTable>
          </OverlayScrollbarsComponent>
          <Pagination
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            count={userActivityCount ?? 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </MuiTableContainer>
      </MuiBox>
    </ProgressOverlay>
  );
};

export default UserViewerProfileActivity;

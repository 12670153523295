import { FC } from 'react';

import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogActions from '@quanterix-ui/core/DialogActions';
import DialogTitle from '@quanterix-ui/core/DialogTitle';
import DialogContent from '@quanterix-ui/core/DialogContent';
import Button from '@quanterix-ui/core/Button';

import {
  CreateContentHubPageVariables,
  PAGE_TYPE,
  useCreateContentHubNestedPage,
  useCreateContentHubRootPage,
} from 'src/api/endpoints/contentHub';
import { useUploadBlobStorageFile } from 'src/api/endpoints/blobStorage';
import { UPLOAD_SOURCE } from 'src/utils/constants/uploadSource';

import { FormValuesSchema } from '../../typings';
import { FORM_VALUES } from '../../constants';
import { useRelatedPageType } from '../../hooks/useRelatedPageType';
import TitleTextField from '../ContentHeader/components/TitleTextField';
import DescriptionTextField from '../ContentHeader/components/DescriptionTextField';
import ImageHolder from '../ContentHeader/components/ImageHolder';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  type?: PAGE_TYPE;
  onClose: () => void;
}

const ContentCreationDialog: FC<Props> = ({ open, type, onClose }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { pageId: parentId } = useParams<{ pageId: string }>();

  const { single: relatedType, constant: relatedTypeAsConstant } =
    useRelatedPageType({ type });
  const lowerCasedRelatedType = relatedType.toLowerCase();
  const upperCasedRelatedType = relatedType.toUpperCase();

  const { mutateAsync: uploadBlobStorageFile, isLoading: isUploading } =
    useUploadBlobStorageFile();

  const { mutateAsync: createRootPage, isLoading: isCreateRootPageLoading } =
    useCreateContentHubRootPage();

  const {
    mutateAsync: createNestedPage,
    isLoading: isCreateNestedPageLoading,
  } = useCreateContentHubNestedPage(Number(parentId));

  const isLoading =
    isCreateRootPageLoading || isCreateNestedPageLoading || isUploading;

  const formMethods = useForm<FormValuesSchema>({
    defaultValues: { title: '', description: '', image: null },
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods;

  const handleCancel = () => {
    reset({
      [FORM_VALUES.title]: '',
      [FORM_VALUES.description]: '',
      [FORM_VALUES.image]: null,
    });
    onClose();
  };

  const handleFormSubmit = async ({
    title,
    description,
    image,
  }: FormValuesSchema) => {
    const pageBody: CreateContentHubPageVariables = {
      name: title,
      text: description,
      img_blob_storage_key: null,
      type: relatedTypeAsConstant,
    };

    if (image) {
      const { blobStorageKey } = await uploadBlobStorageFile({
        file: image,
        source: UPLOAD_SOURCE.documents,
      });

      if (blobStorageKey) {
        pageBody.img_blob_storage_key = blobStorageKey;
      }
    }

    if (parentId) {
      await createNestedPage(pageBody);
    } else {
      await createRootPage(pageBody);
    }

    reset({
      [FORM_VALUES.title]: '',
      [FORM_VALUES.description]: '',
      [FORM_VALUES.image]: null,
    });

    onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      data-testid={TEST_ID.confirmDialog}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <DialogTitle className={classes.dialogTitle}>
          <FormattedMessage
            id="page.content_management.creation_dialog.title"
            values={{
              type: formatMessage({
                id: `page.content_management.type.${lowerCasedRelatedType}`,
              }),
            }}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TitleTextField />
          {upperCasedRelatedType !== PAGE_TYPE.section && (
            <MuiBox pt={2}>
              <MuiGrid
                container
                justifyContent="space-between"
                className={classes.flexibleContainer}
              >
                <ImageHolder />
                <DescriptionTextField />
              </MuiGrid>
            </MuiBox>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoading}
            data-testid={TEST_ID.cancelButton}
            onClick={handleCancel}
          >
            <FormattedMessage id="app.button.cancel" />
          </Button>
          <Button
            loading={isLoading}
            disabled={!isDirty || isLoading}
            data-testid={TEST_ID.acceptButton}
            onClick={handleSubmit(handleFormSubmit)}
          >
            <FormattedMessage id="app.button.add" />
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default ContentCreationDialog;

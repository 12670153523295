import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 320,
  },

  link: {
    fontFamily: theme.fonts.fontFamily.bold,
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },
}));

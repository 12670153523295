export enum TIME_PERIOD {
  beginning = 'beginning',
  middle = 'middle',
  ending = 'ending',
}

interface GetTimePeriodOptions {
  seconds: number;
  defaultSeconds: number;
}

export const getTimePeriod = ({
  seconds,
  defaultSeconds,
}: GetTimePeriodOptions): TIME_PERIOD => {
  const time3dPart = defaultSeconds / 3;

  if (seconds <= time3dPart * 3 && seconds >= time3dPart * 2) {
    return TIME_PERIOD.beginning;
  } else if (seconds < time3dPart * 2 && seconds >= time3dPart) {
    return TIME_PERIOD.middle;
  } else {
    return TIME_PERIOD.ending;
  }
};

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: (theme.zIndex.modal + 1 + ' !important') as unknown as number,
  },

  content: {
    height: '100vh',
    maxHeight: 800,
    overflow: 'hidden',
    textAlign: 'left',
    padding: 0,
    marginBottom: 20,
  },

  paper: {
    padding: '40px 17px !important',
  },

  scrollbars: {
    width: '100%',
    height: '100%',
    padding: '0 43px 25px',
  },

  title: {
    fontFamily: theme.fonts.fontFamily.bold,
    fontWeight: 900,
    fontSize: 30,
  },
}));

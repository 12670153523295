import { FC, PropsWithChildren } from 'react';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { useStyles } from './styles';

interface Props {
  progress?: number;
  loadingVariant?: LinearProgressProps['variant'];
  color?: 'primary' | 'secondary';
  isLoading?: boolean;
  onDelete?: () => void;
}

const FileUploadItem: FC<PropsWithChildren<Props>> = ({
  children,
  progress,
  loadingVariant = 'indeterminate',
  color = 'primary',
  isLoading = false,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <MuiGrid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.fileUpload}
    >
      <MuiGrid item xs={isLoading ? 9 : 11}>
        <MuiBox className={classes.content}>{children}</MuiBox>
      </MuiGrid>
      {isLoading && (
        <MuiGrid item xs={3}>
          <LinearProgress
            variant={loadingVariant}
            value={progress}
            color={color}
            className={classes.loadingIndicator}
          />
        </MuiGrid>
      )}
      {onDelete && !isLoading && (
        <MuiGrid item xs={1} style={{ textAlign: 'right' }}>
          <IconButton
            size="small"
            className={classes.deleteButton}
            onClick={onDelete}
          >
            <ClearOutlinedIcon color="secondary" fontSize="small" />
          </IconButton>
        </MuiGrid>
      )}
    </MuiGrid>
  );
};

export default FileUploadItem;

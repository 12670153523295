import {
  ChangeEvent,
  FC,
  memo,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import Pagination from '@quanterix-ui/core/Pagination';
import Select from '@quanterix-ui/core/Select';
import SelectOption from '@quanterix-ui/core/SelectOption';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { usePaginationContext } from 'src/context/PaginationContext';
import { useSortingContext } from 'src/context/SortingContext';
import { useSearchContentHubFiles } from 'src/api/endpoints/contentHub';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import { useFetchBookmarks } from 'src/api/endpoints/bookmarks';

import FileCard from '../FileCard';
import Suggestions from '../Suggestions';
import { useSearchFilterContext } from '../../context/SearchFilterContext';
import { useBookmarksFilterContext } from '../../context/BookmarksFilterContext';

import {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_SORTING_OPTION_INDEX,
  DEFAULT_SORTING_OPTION_WITH_SEARCH_INDEX,
  ROWS_PER_PAGE_OPTIONS,
  SORTING_OPTIONS,
} from './constants';
import { useStyles } from './styles';

interface Props {
  searchString: string;
}

const ContentList: FC<Props> = ({ searchString }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { pageId } = useParams<{ pageId: string }>();

  const allCategoriesPageMatch = useRouteMatch({
    path: PRIVATE_ROUTES_MAP.contentHubViewerAll,
    exact: true,
    strict: true,
  });
  const isAllCategoriesPage = Boolean(allCategoriesPageMatch);

  const bookmarksPageMatch = useRouteMatch({
    path: PRIVATE_ROUTES_MAP.contentHubViewerBookmarks,
    exact: true,
    strict: true,
  });
  const isBookmarksPage = Boolean(bookmarksPageMatch);

  const { pagination, setPagination } = usePaginationContext();
  const { setSorting } = useSortingContext();
  const { searchFilter } = useSearchFilterContext();
  const { bookmarksFilter } = useBookmarksFilterContext();

  const isSearchMode = searchString.length > 0;

  const [sortingOptionIndex, setSortingOptionIndex] = useState(
    DEFAULT_SORTING_OPTION_INDEX
  );

  const searchParams = useMemo(() => {
    return {
      searchString,
      ...(isSearchMode
        ? searchFilter
        : { pagesIds: isAllCategoriesPage ? [] : [Number(pageId)] }),
    };
  }, [isSearchMode, searchString, searchFilter, isAllCategoriesPage, pageId]);

  const {
    data: searchedFiles = { data: [], count: 0 },
    isFetching: isSearchedFilesLoading,
    refetch: refetchSearchFiles,
  } = useSearchContentHubFiles(searchParams, { enabled: !isBookmarksPage });

  const {
    data: bookmarkedFiles = { data: [], count: 0 },
    isFetching: isBookmarkedFilesLoading,
    refetch: refetchBookmarksFiles,
  } = useFetchBookmarks(bookmarksFilter, {
    enabled: isBookmarksPage,
  });

  const files = isBookmarksPage ? bookmarkedFiles : searchedFiles;
  const refetchFiles = isBookmarksPage
    ? refetchBookmarksFiles
    : refetchSearchFiles;
  const isLoading = isSearchedFilesLoading || isBookmarkedFilesLoading;

  useEffect(() => {
    if (isSearchMode) {
      setSortingOptionIndex(DEFAULT_SORTING_OPTION_WITH_SEARCH_INDEX);
      setSorting(
        SORTING_OPTIONS[DEFAULT_SORTING_OPTION_WITH_SEARCH_INDEX].value
      );
      setPagination({
        page: 0,
        skip: 0,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      });
    } else {
      setSortingOptionIndex(DEFAULT_SORTING_OPTION_INDEX);
      setSorting(SORTING_OPTIONS[DEFAULT_SORTING_OPTION_INDEX].value);
    }
  }, [isSearchMode, setSorting, setPagination]);

  useEffect(() => {
    setPagination({
      page: 0,
      skip: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    });
  }, [pageId, setPagination]);

  useEffect(() => {
    const pagesCount = Math.ceil(files.count / pagination.rowsPerPage);
    const possiblePageNumber = pagesCount - 1;

    if (files.count !== 0 && pagination.page > possiblePageNumber) {
      setPagination((pagination) => ({
        ...pagination,
        page: possiblePageNumber,
        skip: possiblePageNumber * pagination.rowsPerPage,
      }));
      refetchFiles();
    }
  }, [
    pagination.page,
    pagination.rowsPerPage,
    files.count,
    setPagination,
    refetchFiles,
  ]);

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((pagination) => ({
      ...pagination,
      page: newPage,
      skip: newPage * pagination.rowsPerPage,
    }));
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination({
      page: DEFAULT_PAGE,
      skip: DEFAULT_PAGE * DEFAULT_PAGE,
      rowsPerPage: Number(event.target.value),
    });
  };

  const handleChangeSorting = (event: ChangeEvent<{ value: unknown }>) => {
    const sortingOptionIndex = event.target.value as number;

    setSortingOptionIndex(sortingOptionIndex);
    setSorting(SORTING_OPTIONS[sortingOptionIndex].value);
    setPagination({
      page: 0,
      skip: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    });
  };

  return (
    <ProgressOverlay withLoadingDisk loading={isLoading} opacity={1}>
      {files.count > 0 && (
        <MuiBox
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MuiBox>
            <FormattedMessage
              id="page.content_hub.table.result_count"
              values={{ count: files.count }}
            />
          </MuiBox>
          <MuiBox>
            <Select
              fullWidth
              placeholder={formatMessage({
                id: 'page.content_hub.table.sorting.placeholder',
              })}
              value={sortingOptionIndex}
              classes={{ select: classes.sortBySelect }}
              renderValue={(value) => (
                <FormattedMessage
                  id="page.content_hub.table.sorting.sort_by"
                  values={{
                    value: formatMessage({
                      id: SORTING_OPTIONS[value as number].labelId,
                    }),
                  }}
                />
              )}
              onChange={handleChangeSorting}
            >
              <SelectOption
                disabled
                isPlaceholder
                value=""
                className={classes.sortByPlaceholder}
              >
                <FormattedMessage id="page.content_hub.table.sorting.placeholder" />
              </SelectOption>
              {SORTING_OPTIONS.map((option, index) => {
                if (!isSearchMode && option.labelId.includes('best_match')) {
                  return null;
                }

                return (
                  <SelectOption key={option.labelId} value={index}>
                    <FormattedMessage id={option.labelId} />
                  </SelectOption>
                );
              })}
            </Select>
          </MuiBox>
        </MuiBox>
      )}
      {files.data.map((file) => (
        <MuiBox key={file.id} mb={3}>
          <FileCard
            file={file}
            searchParams={searchParams}
            bookmarksFilter={bookmarksFilter}
          />
        </MuiBox>
      ))}
      {files.data.length === 0 && (
        <>
          <MuiBox mt={0.2} mb={9} pl={0}>
            <MuiTypography className={classes.title}>
              {isBookmarksPage && (
                <FormattedMessage id="page.bookmarks.files.not_found" />
              )}
              {!isBookmarksPage && (
                <FormattedMessage id="page.content_hub.table.no_data" />
              )}
            </MuiTypography>
          </MuiBox>
          {!isBookmarksPage && (
            <MuiBox mb={2}>
              <Suggestions />
            </MuiBox>
          )}
        </>
      )}
      {files.count > 0 && (
        <Pagination
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          count={files.count}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </ProgressOverlay>
  );
};

const MemoizedContentList = memo(ContentList);

export default MemoizedContentList;

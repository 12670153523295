import { useQuery } from 'react-query';

import { fetchSuggestions } from '../requests/fetchSuggestions';
import { QUERY_NAME } from '../constants';
import { SuggestionSchema } from '../typings';

const defaultOptions = {
  enabled: true,
};

interface UseQueryOptions {
  enabled?: boolean;
}

export const useFetchSuggestions = (
  searchString: string,
  options: UseQueryOptions = defaultOptions
) => {
  const result = useQuery<SuggestionSchema[]>(
    [QUERY_NAME.fetchSuggestions, searchString],
    () => fetchSuggestions(searchString),
    options
  );

  return result;
};

import { FC } from 'react';

import PaginationProvider from 'src/context/PaginationContext';
import UserViewerProfileActivity, {
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from 'src/components/UserViewerProfileActivity';
import { useFetchUserActivity } from 'src/api/endpoints/users';

const ProfileActivity: FC = () => {
  const {
    data: userActivity,
    isPreviousData,
    isLoading,
  } = useFetchUserActivity({ keepPreviousData: true });

  return (
    <UserViewerProfileActivity
      userActivity={userActivity?.data || []}
      userActivityCount={userActivity?.count || 0}
      isPreviousData={isPreviousData}
      isLoading={isLoading}
    />
  );
};

const ProfileActivityWithContext = () => (
  <PaginationProvider
    defaultPagination={{
      page: DEFAULT_PAGE,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      skip: DEFAULT_PAGE * DEFAULT_ROWS_PER_PAGE,
    }}
  >
    <ProfileActivity />
  </PaginationProvider>
);

export default ProfileActivityWithContext;

import { privateGateway } from 'src/api/config';

import { FilesFiltersResult } from '../typings';

export const fetchFilesFilters = async (
  pageId?: string
): Promise<FilesFiltersResult> => {
  let seacrhQuery = '';

  if (pageId) {
    seacrhQuery = `?page_id=${pageId}`;
  }

  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/files/search/filters${seacrhQuery}`);

  return response.data;
};

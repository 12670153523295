import { privateGateway } from '../../../config';
import {
  CreateFAQSectionItemVariables,
  FAQSectionItemSchema,
} from '../typings';

export const createFAQSectionItem = async ({
  question,
  answer,
  section_id,
}: CreateFAQSectionItemVariables): Promise<FAQSectionItemSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post(`/faq/sections/${section_id}/items`, { question, answer });

  return response.data;
};

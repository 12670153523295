import { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiTypography from '@material-ui/core/Typography';

import { useStyles } from '../../styles';

interface Props {
  labelId: string;
  value: string | ReactNode;
}

const AttributeViewer: FC<Props> = ({ labelId, value }) => {
  const classes = useStyles();

  if (!value) {
    return null;
  }

  return (
    <MuiBox mb={2}>
      <MuiFormLabel className={classes.label}>
        <FormattedMessage id={labelId} />
      </MuiFormLabel>
      <MuiTypography variant="body2" className={classes.attributeValue}>
        {value}
      </MuiTypography>
    </MuiBox>
  );
};

export default AttributeViewer;

import { FC, memo, useCallback, useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getTimePeriod, TIME_PERIOD } from './utils';
import { useStyles } from './styles';

interface Props {
  seconds: number;
  onTimerEnds: () => void;
}

const TimeLimitation: FC<Props> = ({
  seconds: defaultSeconds,
  onTimerEnds,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [timer, setTimer] = useState<unknown>();
  const [timerColor, setTimerColor] = useState(theme.palette.info.main);

  const [seconds, setSeconds] = useState(defaultSeconds);

  const [minutesToShow, setMinutesToShow] = useState(0);
  const [secondsToShow, setSecondsToShow] = useState(0);

  const runTimerTick = useCallback(() => {
    setMinutesToShow(Math.floor(seconds / 60));
    setSecondsToShow(seconds % 60);

    switch (getTimePeriod({ seconds, defaultSeconds })) {
      case TIME_PERIOD.beginning:
      case TIME_PERIOD.middle:
        setTimerColor(theme.palette.info.main);
        break;

      default:
        setTimerColor(theme.palette.error.main);
        break;
    }

    setSeconds(seconds - 1);
  }, [seconds, defaultSeconds, theme]);

  useEffect(() => {
    if (seconds !== -1) {
      setTimer(setTimeout(runTimerTick, 1000) as unknown);
    } else {
      onTimerEnds();
    }
  }, [seconds, runTimerTick, onTimerEnds]);

  useEffect(() => {
    if (!timer && seconds > 0) runTimerTick();

    return () => {
      clearTimeout(timer as number);
    };
  }, [timer, seconds, runTimerTick]);

  const correctedSecondsToShow =
    secondsToShow <= 9 ? '0' + secondsToShow : secondsToShow;

  return (
    <Typography
      component="span"
      className={classes.span}
      style={{ color: timerColor }}
    >
      {minutesToShow}:{correctedSecondsToShow}
    </Typography>
  );
};

export default memo(TimeLimitation);

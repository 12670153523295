import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import MuiCardActionArea from '@material-ui/core/CardActionArea';

import Button from '@quanterix-ui/core/Button';

import NavCardPlaceholderImage from 'src/assets/images/navcard-placeholder-image.png';
import NavCardBookmarksImage from 'src/assets/images/navcard-boomarks-image.png';
import { Markup } from 'src/components/RichTextEditor';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import { ContentHubObjectSchema } from 'src/api/endpoints/contentHub';

import { TEST_ID } from '../../constants';

import { useStyles } from './styles';

interface Props {
  contentHubPage?: ContentHubObjectSchema;
  isBookmarks?: boolean;
}

const NavCard: FC<Props> = ({ contentHubPage, isBookmarks = false }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleCardClick = () => {
    if (isBookmarks && !contentHubPage) {
      history.push(PRIVATE_ROUTES_MAP.contentHubViewerBookmarks);
    }

    if (!isBookmarks && contentHubPage) {
      const path = PRIVATE_ROUTES_MAP.contentHubViewer
        .replace(':pageId', encodeURI(String(contentHubPage.id)))
        .replace(':pageName', encodeURI(contentHubPage.name));
      history.push(path);
    }
  };

  return (
    <MuiBox
      className={classes.root}
      data-testid={TEST_ID.navCard}
      onClick={handleCardClick}
    >
      <MuiCardActionArea component="div" className={classes.cardActionArea}>
        <MuiGrid container className={classes.container}>
          <MuiGrid item xs={6}>
            <MuiGrid
              container
              justifyContent="space-between"
              alignContent="space-between"
              className={classes.cardDetails}
            >
              <MuiGrid item xs={12}>
                <MuiGrid container>
                  <MuiGrid item xs={12}>
                    <MuiGrid container alignItems="center">
                      {isBookmarks && (
                        <BookmarkIcon
                          color="primary"
                          className={classes.bookmarkIcon}
                        />
                      )}
                      <MuiTypography
                        variant="h3"
                        component="span"
                        color="secondary"
                        className={classes.cardTitle}
                      >
                        {isBookmarks && (
                          <FormattedMessage id="page.bookmarks.nav_card.title" />
                        )}
                        {!isBookmarks && contentHubPage?.name}
                      </MuiTypography>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid
                    item
                    xs={12}
                    className={classes.descriptionContainer}
                  >
                    {isBookmarks && (
                      <MuiTypography
                        className={cx(
                          classes.commonDescription,
                          classes.bookmarksDescription
                        )}
                      >
                        <FormattedMessage id="page.bookmarks.nav_card.description" />
                      </MuiTypography>
                    )}
                    {!isBookmarks && (
                      <MuiTypography
                        component="div"
                        className={classes.commonDescription}
                      >
                        <Markup
                          value={
                            contentHubPage?.text
                              ? JSON.parse(contentHubPage.text)
                              : null
                          }
                        />
                      </MuiTypography>
                    )}
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs={12}>
                <MuiGrid container className={classes.cardActionContainer}>
                  <Button
                    data-testid={TEST_ID.moreButton}
                    className={classes.cardAction}
                    color="secondary"
                  >
                    <FormattedMessage id="app.link.more" />
                  </Button>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item xs={6}>
            <MuiBox className={classes.imageContainer}>
              <img
                alt={contentHubPage?.name}
                className={classes.image}
                src={
                  contentHubPage?.image ??
                  (isBookmarks
                    ? NavCardBookmarksImage
                    : NavCardPlaceholderImage)
                }
              />
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
      </MuiCardActionArea>
    </MuiBox>
  );
};

export default NavCard;

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    width: '100%',
    maxWidth: '100%',
  },

  formErrorsContainer: {
    minHeight: 26,
    paddingTop: 2,

    '& p': {
      margin: 0,
    },
  },
}));

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  profileCompletion: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 48,
    paddingLeft: 8,
    paddingBottom: 8,
    marginBottom: 28,
    borderBottom: `2px solid ${theme.palette.primary.main}`,

    '& p': {
      marginLeft: 10,
    },
  },

  title: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.fonts.fontFamily.bold,
    color: theme.palette.secondary.main,

    '& button': {
      marginLeft: 10,
      marginBottom: 3,
    },
  },

  label: {
    color: theme.palette.text.primary,
  },

  attributeValue: {
    fontSize: 16,
  },

  editButton: {
    textDecoration: 'underline',
  },

  hidden: {
    display: 'none',
  },

  cityInputWrapper: {
    '& [class*=MuiInputBase-root]': {
      minWidth: 'unset',
    },
  },

  zipCodeInput: {
    '& [class*=MuiInputBase-root]': {
      textOverflow: 'ellipsis',
    },
  },

  actionButton: {
    '& [class*=MuiButton-label]': {
      fontSize: 18,
      marginTop: 3,
    },

    '& [class*=MuiButton-startIcon]': {
      marginTop: -3,
    },
  },
}));

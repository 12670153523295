import { FC, useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useGetUser } from 'src/aws/UserPool';
import { Instrument } from 'src/components/form/ControlledInstrumentSelect';
import UserViewerProfileInstruments, {
  combineUserInstrumentsFromData,
  InstrumentAttributes,
  InstrumentField,
} from 'src/components/UserViewerProfileInstruments';
import UserViewerProfileInstrumentsContextProvider, {
  DEFAULT_STATE as DEFAULT_INSTRUMENTS,
  useUserViewerProfileInstrumentsContext,
} from 'src/components/UserViewerProfileInstruments/context';
import {
  parseInstruments,
  parseUserAttribute,
  updateUserAttributesByAdmin,
} from 'src/utils/CognitoIdentityHelper';

const RegisteredUserProfileInstruments: FC = () => {
  const { setInstruments } = useUserViewerProfileInstrumentsContext();

  const { username } = useParams<{ username: string }>();

  const { user, refetchUser, isLoading } = useGetUser({ username });

  const instruments = useMemo(() => {
    if (!user) {
      return DEFAULT_INSTRUMENTS;
    }

    return parseInstruments(
      parseUserAttribute(user.UserAttributes, 'custom:instrument'),
      parseUserAttribute(user.UserAttributes, 'custom:serial_number')
    ).reduce(
      (instruments, instrument) => ({
        ...instruments,
        [instrument.Instrument.toLowerCase().replace('-', '')]: {
          name: instrument.Instrument,
          selected: instrument.Selected,
          serialNumber: instrument.Serial,
        },
      }),
      {
        acceleratorServices: {
          selected:
            parseUserAttribute(
              user.UserAttributes,
              'custom:accelerator_services'
            ) === 'true',
          serialNumber: '',
        },
      }
    ) as InstrumentField;
  }, [user]);

  const handleUpdateUserInstruments = useCallback(
    async (instruments: InstrumentField, onSuccessCallback: () => void) => {
      const instrumentAttributes = Object.entries(instruments)
        .filter(
          ([key, instrument]) =>
            instrument.selected && key !== 'acceleratorServices'
        )
        .reduce(
          (attributes, [key, instrument]) => ({
            instruments: [
              ...attributes.instruments,
              instruments[key as Instrument]!.name as string,
            ],
            serialNumbers: [
              ...attributes.serialNumbers,
              instrument.serialNumber,
            ],
          }),
          {
            instruments: [],
            serialNumbers: [],
          } as Omit<InstrumentAttributes, 'accelerator_services'>
        );

      await updateUserAttributesByAdmin(
        username,
        combineUserInstrumentsFromData({
          ...instrumentAttributes,
          accelerator_services: instruments.acceleratorServices.selected,
        })
      );

      setTimeout(() => {
        refetchUser();
        onSuccessCallback();
      }, 500);
    },
    [username, refetchUser]
  );

  useEffect(() => {
    if (instruments) {
      setInstruments(instruments);
    }
  }, [instruments, setInstruments]);

  return (
    <UserViewerProfileInstruments
      isLoading={isLoading}
      isFetched={!!user}
      onUpdateUserInstruments={handleUpdateUserInstruments}
    />
  );
};

const RegisteredUserProfileInstrumentsWithContext = () => (
  <UserViewerProfileInstrumentsContextProvider>
    <RegisteredUserProfileInstruments />
  </UserViewerProfileInstrumentsContextProvider>
);

export default RegisteredUserProfileInstrumentsWithContext;

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  userGuideLinks: {
    display: 'flex',
    width: '100%',
    height: 200,
    borderRadius: theme.constants.borderRadius,
    boxShadow: `0 1px 11px ${alpha(theme.palette.common.black, 0.16)}`,
  },

  textSection: {
    padding: theme.spacing(2),
  },

  links: {
    color: theme.palette.primary.main,
  },
}));

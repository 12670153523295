import { forwardRef, useCallback, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { SnackbarContent, SnackbarKey } from 'notistack';

import MuiIconButton from '@material-ui/core/IconButton';
import MuiCloseIcon from '@material-ui/icons/Close';
import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';

import Button from '@quanterix-ui/core/Button';
import Dialog from '@quanterix-ui/core/Dialog';
import DialogTitle from '@quanterix-ui/core/DialogTitle';
import DialogActions from '@quanterix-ui/core/DialogActions';

import { useSnackbar } from 'src/utils/hooks';
import FileUploadItem from 'src/components/elements/FileUploadItem';
import { useUploadProgressContext } from 'src/context/UploadProgressContext';

import { useStyles } from './styles';
import { TEST_ID } from './constants';

interface Props {
  id: SnackbarKey;
  showUploadWarning?: boolean;
  onClose?: () => void;
}

const UploadProgressSnackbar = forwardRef<HTMLDivElement, Props>(
  ({ id, onClose, showUploadWarning }, ref) => {
    const classes = useStyles();

    const { closeSnackbar, enqueueSuccessSnackbar, enqueueErrorSnackbar } =
      useSnackbar();

    const {
      isMalwareScanCompleted,
      uploadProgress,
      onMalwareScanCompleted,
      setUploadProgress,
    } = useUploadProgressContext();

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const handleOpenCancelDialog = () => {
      setCancelDialogOpen(true);
    };

    const handleCloseCancelDialog = () => {
      setCancelDialogOpen(false);
    };

    const handleCancelUpload = useCallback(() => {
      setCancelDialogOpen(false);
      closeSnackbar(id);
      setUploadProgress(null);
      onClose?.();
    }, [id, closeSnackbar, setUploadProgress, onClose]);

    useEffect(() => {
      if (isMalwareScanCompleted && uploadProgress === 100) {
        setTimeout(() => {
          closeSnackbar(id);
          setUploadProgress(null);
        }, 1000);
      }
    }, [
      id,
      isMalwareScanCompleted,
      uploadProgress,
      setUploadProgress,
      closeSnackbar,
    ]);

    useEffect(() => {
      onMalwareScanCompleted('success', () => {
        enqueueSuccessSnackbar({
          message: 'page.support_file_upload.form.snackbar.success',
        });
      });
      onMalwareScanCompleted('error', (fileName?: string) => {
        enqueueErrorSnackbar({
          message: 'page.support_file_upload.form.snackbar.malware_found',
          intlValues: { fileName: fileName || '' },
          persist: true,
          action: <MuiCloseIcon />,
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <SnackbarContent
          ref={ref}
          className={classes.root}
          data-testid={TEST_ID.uploadProgressSnackbar}
        >
          <MuiBox className={classes.container}>
            <MuiBox className={classes.content}>
              <FileUploadItem
                isLoading
                progress={uploadProgress ?? 0}
                loadingVariant="determinate"
                color="secondary"
              >
                <FormattedMessage id="page.support_file_upload.form.snackbar.upload_progress" />
              </FileUploadItem>
              <MuiIconButton
                className={classes.close}
                data-testid={TEST_ID.cancelButton}
                onClick={handleOpenCancelDialog}
              >
                <MuiCloseIcon />
              </MuiIconButton>
            </MuiBox>
            {showUploadWarning && (
              <MuiGrid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.additionalMessage}
              >
                <MuiGrid item xs={11}>
                  <MuiBox>
                    <FormattedMessage id="page.support_file_upload.form.snackbar.upload_progress_warning" />
                  </MuiBox>
                </MuiGrid>
              </MuiGrid>
            )}
          </MuiBox>
        </SnackbarContent>
        <Dialog
          fullWidth
          open={cancelDialogOpen}
          onClose={handleCloseCancelDialog}
        >
          <DialogTitle>
            <FormattedMessage id="page.support_file_upload.form.dialog.cancel" />
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseCancelDialog}>
              <FormattedMessage id="app.button.no" />
            </Button>
            <Button onClick={handleCancelUpload}>
              <FormattedMessage id="app.button.yes" />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default UploadProgressSnackbar;

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LAYOUT_DIMENSIONS } from 'src/utils/constants/app';

const { header, footer, content } = LAYOUT_DIMENSIONS;

interface Props {
  isContentHubPage: boolean;
  isUserViewerPage: boolean;
  isMicroFEPage: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'hidden',
  },

  header: {
    height: header.height.desktop,
    zIndex: 2,
  },

  scrollbars: {
    height: `calc(100vh - ${header.height.desktop}px) !important`,

    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${header.height.mobile}px) !important`,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: ({ isContentHubPage, isMicroFEPage }) => {
      if (isMicroFEPage) {
        return '100vw !important';
      }

      const pageWidth = isContentHubPage ? 1650 : 1480;
      return pageWidth + content.spacing.horizontal.desktop * 2;
    },
    minHeight: `calc(100vh - ${header.height.desktop}px - ${footer.height.desktop}px)`,
    padding: ({ isUserViewerPage, isMicroFEPage }) => {
      if (isMicroFEPage) {
        return '0 !important';
      }

      const paddingTop = isUserViewerPage
        ? 0
        : content.spacing.vertical.desktop;

      return `${paddingTop}px ${content.spacing.horizontal.desktop}px ${content.spacing.vertical.desktop}px ${content.spacing.horizontal.desktop}px`;
    },

    [theme.breakpoints.down('sm')]: {
      padding: ({ isUserViewerPage, isMicroFEPage }) => {
        if (isMicroFEPage) {
          return '0 !important';
        }

        const paddingTop = isUserViewerPage
          ? 0
          : content.spacing.vertical.mobile;

        return `${paddingTop}px ${content.spacing.horizontal.mobile}px ${content.spacing.vertical.mobile}px ${content.spacing.horizontal.mobile}px`;
      },
    },
  },
}));

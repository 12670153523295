import { FC } from 'react';

import { useParams } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import { useFetchPublicPageBySlug } from 'src/api/endpoints/contentHubPublic';
import { Markup } from 'src/components/RichTextEditor';

import { LEGAL_DOCUMENT_ROUTES } from './constants';
import { useStyles } from './styles';

const LegalDocument: FC = () => {
  const classes = useStyles();

  const params = useParams<{ name: keyof typeof LEGAL_DOCUMENT_ROUTES }>();

  const { data: legalDocument } = useFetchPublicPageBySlug(
    LEGAL_DOCUMENT_ROUTES[params.name]
  );

  if (!legalDocument) {
    return null;
  }

  return (
    <MuiBox pt={2} pb={5}>
      <MuiTypography variant="h1" className={classes.title}>
        {legalDocument.name}
      </MuiTypography>
      <Markup value={JSON.parse(legalDocument.text)} />
    </MuiBox>
  );
};

export default LegalDocument;

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    width: 36,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: 'transparent',
    transition: theme.transitions.create('width'),

    '&.isActive': {
      width: 200,
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },

  searchButton: {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translate(15%, -50%)',
  },

  inputRoot: {
    color: 'inherit',
  },

  inputInput: {
    width: '100%',
    height: 20,
    paddingTop: 7,
    paddingLeft: 10,
    paddingRight: 48,
  },
}));

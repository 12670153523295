import { useMemo } from 'react';

import { useCognito } from 'src/aws/Cognito';
import {
  parseAddress,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';

export const useDefaultData = () => {
  const {
    userData: { attributes },
  } = useCognito();

  const defaultData = useMemo(
    () => ({
      organizationName: parseUserAttribute(attributes, 'custom:company'),
      address: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'street_address'
      ),
      address2: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'street_address2'
      ),
      city: parseAddress(parseUserAttribute(attributes, 'address'), 'locality'),
      zip: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'postal_code'
      ),
      country: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'country'
      ),
      phone: parseUserAttribute(attributes, 'phone_number'),
      email: parseUserAttribute(attributes, 'email'),
    }),
    [attributes]
  );

  return defaultData;
};

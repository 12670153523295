import { FC } from 'react';

import { useLocation } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import List from '@material-ui/core/List';

import { useFetchFAQSections } from 'src/api/endpoints/faq';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import NavLink from './components/NavLink';

const ContentTreeViewer: FC = () => {
  const location = useLocation();

  const { data: faqSections = [], isLoading } = useFetchFAQSections();

  return (
    <ProgressOverlay withLoadingDisk loading={isLoading} opacity={1}>
      <MuiBox>
        <List>
          <NavLink isForAllSections href={location.pathname} />
          {faqSections.map((section) => (
            <NavLink
              key={section.id}
              section={section}
              href={`#faq-section-${section.id}`}
            />
          ))}
        </List>
      </MuiBox>
    </ProgressOverlay>
  );
};

export default ContentTreeViewer;

import { FC } from 'react';

import cx from 'classnames';

import Grid from '@material-ui/core/Grid';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props extends CircularProgressProps {
  isVisible: boolean;
  marginTop?: number;
}

const ProgressIndicator: FC<Props> = ({
  className,
  isVisible,
  marginTop = 0,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Fade in={isVisible}>
      <Grid
        container
        justifyContent="center"
        alignContent={marginTop === 0 ? 'center' : 'flex-start'}
        className={cx(classes.container, className)}
      >
        <Grid item style={{ marginTop }}>
          <CircularProgress
            {...restProps}
            data-testid={TEST_ID.progressIndicator}
          />
        </Grid>
      </Grid>
    </Fade>
  );
};

export default ProgressIndicator;

import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LAYOUT_DIMENSIONS } from 'src/utils/constants/app';

const { header, footer, content } = LAYOUT_DIMENSIONS;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'hidden',
  },

  header: {
    height: header.height.desktop,
    zIndex: 2,
  },

  scrollbars: {
    height: `calc(100vh - ${header.height.desktop}px) !important`,

    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${header.height.mobile}px) !important`,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1160,
    minHeight: `calc(100vh - ${header.height.desktop}px - ${footer.height.desktop}px)`,
    paddingTop: content.spacing.vertical.desktop,
    paddingRight: content.spacing.horizontal.desktop,
    paddingBottom: content.spacing.vertical.desktop,
    paddingLeft: content.spacing.horizontal.desktop,
  },
}));

import { FC, useEffect } from 'react';

import MuiBox from '@material-ui/core/Box';

import { useCognito } from 'src/aws/Cognito';

import { TEST_ID } from './constants';

const Logout: FC = () => {
  const { signOut } = useCognito();

  useEffect(() => {
    signOut({ withDefaultReason: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MuiBox display="none" data-testid={TEST_ID.logout} />;
};

export default Logout;

import axios, { CancelTokenSource } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { SnackbarKey } from 'notistack';

import { useSnackbar } from 'src/utils/hooks';
import { useUploadProgressContext } from 'src/context/UploadProgressContext';

import { uploadSupportFiles } from '../requests/uploadSupportFiles';
import { UploadSupportFilesParams } from '../typings';

type UseUploadSupportFilesResult = UseMutationResult<
  string[],
  Error,
  UploadSupportFilesParams,
  UseUploadSupportFilesContext
>;

interface UseUploadSupportFilesContext {
  uploadProgressSnackbarKey: SnackbarKey;
}

interface UseUploadSupportFileOptions {
  cancelTokenSource: CancelTokenSource;
  onSuccess: (data: string[], uploadProgressSnackbarKey: SnackbarKey) => void;
  onCancel: () => void;
}

export const useUploadSupportFiles = ({
  onSuccess,
  onCancel,
  cancelTokenSource,
}: UseUploadSupportFileOptions): UseUploadSupportFilesResult => {
  const {
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar,
    enqueueUploadProgressSnackbar,
    closeSnackbar,
  } = useSnackbar();

  const { setUploadProgress } = useUploadProgressContext();

  const result = useMutation<
    string[],
    Error,
    UploadSupportFilesParams,
    UseUploadSupportFilesContext
  >(
    (params) =>
      uploadSupportFiles(params, {
        cancelToken: cancelTokenSource.token,
        onUploadProgress: setUploadProgress,
      }),
    {
      onMutate: () => {
        const uploadProgressSnackbarKey = enqueueUploadProgressSnackbar({
          showUploadWarning: true,
          onClose: () => {
            cancelTokenSource.cancel();
            onCancel();
          },
        });

        enqueueSuccessSnackbar({
          message: 'page.support_file_upload.form.snackbar.upload_in_progress',
        });

        return { uploadProgressSnackbarKey };
      },

      onSuccess: (attachments, _variables, context) => {
        onSuccess(attachments, context!.uploadProgressSnackbarKey);
      },

      onError: (error, _variables, context) => {
        closeSnackbar(context!.uploadProgressSnackbarKey);

        if (!axios.isCancel(error)) {
          enqueueErrorSnackbar({
            message: 'page.support_file_upload.form.snackbar.error',
          });
        }
      },
    }
  );

  return result;
};

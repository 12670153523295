import { FC, FocusEvent, MouseEvent, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogActions from '@quanterix-ui/core/DialogActions';
import DialogTitle from '@quanterix-ui/core/DialogTitle';
import DialogContent from '@quanterix-ui/core/DialogContent';
import Button from '@quanterix-ui/core/Button';

import { FAQSectionSchema } from 'src/api/endpoints/faq';

import SectionTitleTextField from '../SectionTitleTextField';

import { FORM_VALUES } from './constants';
import { FormValuesSchema } from './typings';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  loading: boolean;
  titleTranslationId: string;
  submitButtonTranslationId: string;
  section?: FAQSectionSchema;
  onClose: () => void;
  onSubmit: (data: FormValuesSchema) => Promise<void>;
}

const SectionDialog: FC<Props> = ({
  open,
  loading,
  titleTranslationId,
  submitButtonTranslationId,
  section,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: { [FORM_VALUES.title]: '' },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = formMethods;

  const handleCancel = () => {
    onClose();
  };

  const handleStopPropagation = (
    event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>
  ) => {
    event.stopPropagation();
  };

  const handleFormSubmit = async ({ title }: FormValuesSchema) => {
    try {
      await onSubmit({ title });

      reset({
        [FORM_VALUES.title]: '',
      });

      onClose();
    } catch ({ message }) {
      console.error(message);
    }
  };

  useEffect(() => {
    setValue(FORM_VALUES.title, section?.name || '');
  }, [section?.name, setValue]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      onClick={handleStopPropagation}
      onFocus={handleStopPropagation}
    >
      <FormProvider {...formMethods}>
        <DialogTitle className={classes.dialogTitle}>
          <FormattedMessage id={titleTranslationId} />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <SectionTitleTextField disabled={loading} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={loading} onClick={handleCancel}>
            <FormattedMessage id="app.button.cancel" />
          </Button>
          <Button
            loading={loading}
            disabled={!isDirty || loading}
            onClick={handleSubmit(handleFormSubmit)}
          >
            <FormattedMessage id={submitButtonTranslationId} />
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default SectionDialog;

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 60,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
}));

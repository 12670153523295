import { FC, memo, useEffect, useMemo } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import PasswordTextField from 'src/components/form/PasswordTextField';

import PasswordHelpText from './components/PasswordHelpText';
import {
  PASSWORD_ACCEPTABLE_SYMBOLS,
  PASSWORD_FIELD_NAME,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_PATTERN,
} from './constants';
import { useStyles } from './styles';

interface Props {
  name: PASSWORD_FIELD_NAME;
  id?: string;
  equalTo?: string;
  notEqualTo?: string;
  withPattern?: boolean;
  placeholder?: string;
}

const ControlledPasswordTextField: FC<Props> = ({
  id = '',
  name,
  equalTo,
  notEqualTo,
  withPattern = false,
  placeholder = '',
}) => {
  const classes = useStyles();

  const {
    control,
    getValues,
    trigger,
    formState: { errors, dirtyFields, isSubmitted },
  } = useFormContext();
  const isCurrentFieldDirty = dirtyFields[name];

  const currentFieldValue = useWatch({ name });
  const equalToFieldValue = useWatch({ name: equalTo || '' });

  useEffect(() => {
    if (currentFieldValue.length > 0) {
      if (isSubmitted) {
        trigger(name);
      }

      if (
        name === PASSWORD_FIELD_NAME.newPassword &&
        equalToFieldValue?.length > 0
      ) {
        trigger(PASSWORD_FIELD_NAME.repeatPassword);
      }

      if (name === PASSWORD_FIELD_NAME.repeatPassword) {
        trigger(name);
      }
    }
  }, [
    name,
    currentFieldValue,
    equalTo,
    equalToFieldValue,
    isSubmitted,
    trigger,
  ]);

  const hasCorrectMinMaxLength = useMemo(
    () =>
      currentFieldValue.length >= PASSWORD_MIN_LENGTH &&
      currentFieldValue.length <= PASSWORD_MAX_LENGTH,
    [currentFieldValue]
  );

  const hasAtLeastOneDigit = useMemo(
    () =>
      new RegExp(`^${PASSWORD_PATTERN.atLeastOneDigit}`).test(
        currentFieldValue
      ),
    [currentFieldValue]
  );

  const hasAtLeastOneLowercase = useMemo(
    () =>
      new RegExp(`^${PASSWORD_PATTERN.atLeastOneLowercase}`).test(
        currentFieldValue
      ),
    [currentFieldValue]
  );

  const hasAtLeastOneUppercase = useMemo(
    () =>
      new RegExp(`^${PASSWORD_PATTERN.atLeastOneUppercase}`).test(
        currentFieldValue
      ),
    [currentFieldValue]
  );

  const hasAtLeastOneSymbol = useMemo(
    () =>
      new RegExp(`^${PASSWORD_PATTERN.atLeastOneSymbol}`).test(
        currentFieldValue
      ),
    [currentFieldValue]
  );

  const hasNoSpace = useMemo(() => {
    const result = new RegExp(`^${PASSWORD_PATTERN.hasNoSpace}`).test(
      currentFieldValue
    );
    return !result;
  }, [currentFieldValue]);

  const isPasswordsEqual = useMemo(() => {
    if (!equalTo) return true;
    if (currentFieldValue.length === 0) return false;

    return currentFieldValue === equalToFieldValue;
  }, [equalTo, currentFieldValue, equalToFieldValue]);

  const errorMessage = errors[name]?.message;

  return (
    <MuiGrid container direction="column">
      <MuiGrid item>
        <Controller
          name={name}
          control={control}
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => (
            <PasswordTextField
              fullWidth
              id={id || name}
              name={name}
              value={value}
              inputRef={ref}
              error={!!error}
              placeholder={placeholder}
              onChange={onChange}
            />
          )}
          rules={{
            required: {
              value: true,
              message: 'This field is required.',
            },

            validate: {
              shouldFollowPattern: () => {
                if (!withPattern) return true;

                return (
                  (hasCorrectMinMaxLength &&
                    hasAtLeastOneDigit &&
                    hasAtLeastOneLowercase &&
                    hasAtLeastOneUppercase &&
                    hasAtLeastOneSymbol &&
                    hasNoSpace) ||
                  `Password hasn't met all requirements. Please, try again.`
                );
              },

              shouldBeEqualTo: (value) => {
                if (!equalTo) return true;

                return value === equalToFieldValue || 'Password not matches.';
              },

              shouldNotBeEqualTo: (value) => {
                if (!notEqualTo) return true;

                const notEqualToValue = getValues(notEqualTo);
                return (
                  value !== notEqualToValue ||
                  'Should be different from the current password.'
                );
              },
            },
          }}
        />
      </MuiGrid>
      {withPattern && (
        <MuiGrid item>
          <MuiBox mt={1}>
            <MuiGrid container direction="column">
              <MuiGrid item>
                <PasswordHelpText
                  isDirty={isCurrentFieldDirty}
                  hasError={!hasCorrectMinMaxLength}
                >
                  {`Between ${PASSWORD_MIN_LENGTH} and ${PASSWORD_MAX_LENGTH} characters`}
                </PasswordHelpText>
              </MuiGrid>
              <MuiGrid item>
                <PasswordHelpText
                  isDirty={isCurrentFieldDirty}
                  hasError={!hasAtLeastOneUppercase}
                >
                  Uppercase letters [A-Z]
                </PasswordHelpText>
              </MuiGrid>
              <MuiGrid item>
                <PasswordHelpText
                  isDirty={isCurrentFieldDirty}
                  hasError={!hasAtLeastOneLowercase}
                >
                  Lowercase letters [a-z]
                </PasswordHelpText>
              </MuiGrid>
              <MuiGrid item>
                <PasswordHelpText
                  isDirty={isCurrentFieldDirty}
                  hasError={!hasAtLeastOneDigit}
                >
                  Numbers [0-9]
                </PasswordHelpText>
              </MuiGrid>
              <MuiGrid item>
                <PasswordHelpText
                  isDirty={isCurrentFieldDirty}
                  hasError={!hasAtLeastOneSymbol}
                >
                  Special characters {PASSWORD_ACCEPTABLE_SYMBOLS}
                </PasswordHelpText>
              </MuiGrid>
              <MuiGrid item>
                <PasswordHelpText
                  isDirty={isCurrentFieldDirty}
                  hasError={!hasNoSpace}
                >
                  No space
                </PasswordHelpText>
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </MuiGrid>
      )}
      {errorMessage && (
        <MuiGrid item>
          <MuiFormHelperText error>{errorMessage}</MuiFormHelperText>
        </MuiGrid>
      )}
      {!!equalTo && isCurrentFieldDirty && isPasswordsEqual && (
        <MuiGrid item>
          <MuiFormHelperText className={classes.formHelperTextSuccess}>
            Password matches!
          </MuiFormHelperText>
        </MuiGrid>
      )}
    </MuiGrid>
  );
};

export default memo(ControlledPasswordTextField);

import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    zIndex: 9999,
  },

  container: {
    width: '100%',
    maxWidth: 1640,
    padding: '50px 80px',
    margin: '0 auto',
  },

  title: {
    color: theme.palette.common.white,
  },

  text: {
    color: theme.palette.common.white,

    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
  },

  iconButton: {
    color: theme.palette.common.white,
  },
}));

import { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { format } from 'date-fns';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogContent from '@quanterix-ui/core/DialogContent';
import DialogActions from '@quanterix-ui/core/DialogActions';
import Button from '@quanterix-ui/core/Button';

import { Markup } from 'src/components/RichTextEditor';
import { useFetchPublicPageBySlug } from 'src/api/endpoints/contentHubPublic';
import { LegalDocument } from 'src/api/endpoints/contentHub/typings';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props {
  legalDocumentPath: LegalDocument;
  open: boolean;
  onClose: () => void;
}

const LegalDocumentModal: FC<Props> = ({
  legalDocumentPath,
  open,
  onClose,
}) => {
  const classes = useStyles();

  const { data: legalDocument } = useFetchPublicPageBySlug(legalDocumentPath, {
    enabled: open,
  });

  const lastUpdated = useMemo(
    () =>
      legalDocument
        ? format(new Date(legalDocument.updated_at), 'dd MMM yyyy, HH:mm')
        : undefined,
    [legalDocument]
  );

  if (!legalDocument) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      classes={{ root: classes.root, paper: classes.paper }}
      data-testid={TEST_ID.legalDocumentModal}
      onClose={onClose}
    >
      <DialogContent className={classes.content}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: { autoHide: 'move' },
          }}
          className={classes.scrollbars}
        >
          <MuiBox mb={3}>
            <MuiTypography variant="h1" className={classes.title}>
              {legalDocument.name}
            </MuiTypography>
            <MuiTypography variant="subtitle2">
              Last updated: {lastUpdated}
            </MuiTypography>
          </MuiBox>
          <Markup value={JSON.parse(legalDocument.text)} />
        </OverlayScrollbarsComponent>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="secondary" onClick={onClose}>
          <FormattedMessage id="app.button.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LegalDocumentModal;

import { ReactNode } from 'react';

import Icon from '@mdi/react';
import {
  mdiFileCogOutline,
  mdiFileImageOutline,
  mdiFileOutline,
  mdiFilePdfBox,
  mdiFilePowerpointOutline,
  mdiFileVideoOutline,
  mdiFolderZipOutline,
  mdiMicrosoftExcel,
  mdiMicrosoftWord,
  mdiXml,
} from '@mdi/js';

export const isVideo = (ext: string): boolean => {
  switch (ext) {
    case 'avi':
    case 'mp4':
    case 'm4v':
      return true;
    default:
      return false;
  }
};

export const getFileTypeIcon = (
  extension: string,
  {
    size = 1,
    ...restProps
  }: {
    [dataAttribute: `data-${string}`]: string;
    className?: string;
    size?: number;
    color?: string;
  } = {}
): ReactNode => {
  const props = { ...restProps, size };

  switch (extension) {
    case 'pdf':
      return <Icon path={mdiFilePdfBox} {...props} />;
    case 'xml':
      return <Icon path={mdiXml} {...props} />;
    case 'doc':
    case 'docx':
    case 'odt':
      return <Icon path={mdiMicrosoftWord} {...props} />;
    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'ods':
      return <Icon path={mdiMicrosoftExcel} {...props} />;
    case 'odp':
    case 'ppt':
    case 'pptx':
      return <Icon path={mdiFilePowerpointOutline} {...props} />;
    case 'jpg':
    case 'png':
    case 'gif':
    case 'bmp':
      return <Icon path={mdiFileImageOutline} {...props} />;
    case 'avi':
    case 'mp4':
    case 'm4v':
      return <Icon path={mdiFileVideoOutline} {...props} />;
    case 'zip':
    case 'rar':
    case '7z':
      return <Icon path={mdiFolderZipOutline} {...props} />;
    case 'exe':
      return <Icon path={mdiFileCogOutline} {...props} />;
    default:
      return <Icon path={mdiFileOutline} {...props} />;
  }
};

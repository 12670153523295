import { useCallback, useEffect, useState } from 'react';

import { useCognito } from 'src/aws/Cognito';
import { useFilterContext } from 'src/context/FilterContext';
import { useSnackbar } from 'src/utils/hooks';

import { USER_POOL_ID } from '../constants';
import { UserSchema } from '../typings';

import { useIdentityService } from './useIdentityService';

export interface RefetchUsersOptions {
  paginationToken: string;
}

interface UseGetUsersResult {
  users: UserSchema[];
  isLoading: boolean;
  isLoaded: boolean;
  paginationToken: string;
  refetchUsers: (options: RefetchUsersOptions) => void;
}

export const useGetUsers = (): UseGetUsersResult => {
  const { enqueueErrorSnackbar } = useSnackbar();
  const { identityService } = useIdentityService();

  const { isAdmin } = useCognito();

  const { filter } = useFilterContext();

  const [users, setUsers] = useState<UserSchema[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [nextPaginationToken, setNextPaginationToken] = useState('');

  const fetchUsers = useCallback(
    ({ paginationToken }: RefetchUsersOptions) => {
      if (identityService && isAdmin) {
        setIsLoading(true);

        const params = {
          UserPoolId: USER_POOL_ID as string,
          Limit: 0,
          PaginationToken:
            paginationToken.length > 0 ? paginationToken : undefined,
          Filter: filter.value
            ? `${filter.field} ${filter.operator || ''} "${filter.value}"`
            : undefined,
        };

        identityService.listUsers(params, (error, data = {}) => {
          if (error) {
            enqueueErrorSnackbar({ message: error.message });

            setIsLoading(false);
            setIsLoaded(true);

            throw new Error(error.message);
          }

          const { Users = [], PaginationToken } = data;

          if (paginationToken.length > 0) {
            setUsers((users) => [...users, ...Users]);
          } else {
            setUsers(Users);
          }

          setNextPaginationToken(PaginationToken || '');

          setIsLoading(false);
          setIsLoaded(true);
        });
      } else {
        setUsers([]);
      }
    },
    [
      identityService,
      isAdmin,
      filter.field,
      filter.operator,
      filter.value,
      enqueueErrorSnackbar,
    ]
  );

  useEffect(() => {
    if (!isLoaded) {
      fetchUsers({ paginationToken: '' });
    }
  }, [isLoaded, fetchUsers]);

  return {
    users,
    isLoading,
    isLoaded,
    paginationToken: nextPaginationToken,
    refetchUsers: fetchUsers,
  };
};

import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import Button from '@quanterix-ui/core/Button';

import { CSV_FILE_EXTENTIONS } from 'src/utils/constants/file';
import { SNACKBAR } from 'src/utils/constants/app';
import { uploadTemplateToBlobStorage } from 'src/utils/helpers/blobStorage';

import { TEMPLATES } from '../../constants';

import { useStyles } from './styles';

const UploadManifestButton: FC = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (file) {
      const uploadManifestTemplate = async () => {
        setUploading(true);
        try {
          await uploadTemplateToBlobStorage(file, TEMPLATES.manifest);
          enqueueSnackbar('Manifest Template was upload successfully!', {
            ...SNACKBAR.defaultOptions,
            variant: 'success',
            persist: false,
            resumeHideDuration: SNACKBAR.resumeHideDuration.success,
          });
        } catch (error) {
          console.error(error.message);
          enqueueSnackbar(`Manifest Template wasn't uploaded!`, {
            ...SNACKBAR.defaultOptions,
            variant: 'error',
            persist: false,
            resumeHideDuration: SNACKBAR.resumeHideDuration.error,
          });
        } finally {
          setUploading(false);
        }
      };

      uploadManifestTemplate();
    }
  }, [file, enqueueSnackbar]);

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    inputRef?.current?.click();
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFile(event.target.files?.[0]);
    event.target.value = '';
  };

  return (
    <>
      <Button
        variant="outlined"
        loading={uploading}
        disabled={uploading}
        onClick={handleButtonClick}
      >
        <FormattedMessage id="page.accelerator_form.button.upload_manifest_template" />
      </Button>
      <input
        ref={inputRef}
        accept={CSV_FILE_EXTENTIONS.join(', ')}
        className={classes.input}
        type="file"
        onChange={handleInputChange}
      />
    </>
  );
};

export default UploadManifestButton;

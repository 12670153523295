import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import { useCognito } from 'src/aws/Cognito';
import ProfileNavigation from 'src/components/UserViewerProfileNavigation';
import ChangePasswordForm from 'src/components/ChangePasswordForm';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import { useStyles } from './styles';

const ProfileChangePassword: FC = () => {
  const classes = useStyles();

  const { isLoading, daysTillPasswordExpiry } = useCognito();

  return (
    <ProgressOverlay withLoadingDisk loading={isLoading} opacity={1} size={40}>
      <ProfileNavigation />
      <MuiGrid container>
        <MuiGrid item xs={12} lg={5}>
          <MuiBox className={classes.passwordExpiration}>
            <MuiTypography variant="body2">
              <FormattedMessage
                id="page.profile.password_expiration"
                values={{ value: daysTillPasswordExpiry }}
              />
            </MuiTypography>
          </MuiBox>
          <ChangePasswordForm />
        </MuiGrid>
      </MuiGrid>
    </ProgressOverlay>
  );
};

export default ProfileChangePassword;

import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { useCognito } from 'src/aws/Cognito';

import { exportUserRequests } from '../requests/exportUserRequests';
import { QUERY_NAME } from '../constants';

type UseExportUserRequestsOptions = {
  enabled?: boolean;
};

export const useExportUserRequests = (
  options: UseExportUserRequestsOptions = {}
): UseQueryResult => {
  const { userData } = useCognito();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const result = useQuery<void, Error>(
    [QUERY_NAME.exportUserRequests],
    () => exportUserRequests(),
    {
      onSuccess: () => {
        enqueueSuccessSnackbar({
          message: 'page.users.snackbar.export_sent',
          intlValues: { email: userData.email },
        });
      },
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
      ...options,
    }
  );

  return result;
};

import { FC } from 'react';

import { RenderElementProps } from 'slate-react';

import { blockquoteStyle, listStyle, paragraphStyle } from './styles';

export type EditorElementProps = RenderElementProps;

const EditorElement: FC<EditorElementProps> = ({
  attributes,
  children,
  element,
}) => {
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={blockquoteStyle} {...attributes}>
          {children}
        </blockquote>
      );

    case 'bulleted-list':
      return (
        <ul style={listStyle} {...attributes}>
          {children}
        </ul>
      );

    case 'numbered-list':
      return (
        <ol style={listStyle} {...attributes}>
          {children}
        </ol>
      );

    case 'list-item':
      return <li {...attributes}>{children}</li>;

    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;

    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;

    case 'link':
      return (
        <a {...attributes} target="_blank" rel="noreferrer" href={element.href}>
          {children}
        </a>
      );

    default:
      return (
        <p style={paragraphStyle} {...attributes}>
          {children}
        </p>
      );
  }
};

export default EditorElement;

import { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { useQuery, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { NEW_NOTIFICATION_ACTION, QUERY_NAME } from '../constants';
import { FetchNotificationsResult } from '../typings';
import { fetchNotifications } from '../requests/fetchNotifications';

export const useLiveNotifications = () => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const result = useQuery<FetchNotificationsResult, Error>(
    QUERY_NAME.notifications,
    fetchNotifications,
    {
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
    }
  );

  useEffect(() => {
    const getAccessToken = async () => {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();

      document.cookie = `access_token=${accessToken}; SameSite=Strict; Domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
    };

    getAccessToken();
  }, []);

  useEffect(() => {
    const websocket = new WebSocket(
      process.env.REACT_APP_NOTIFICATIONS_WSS_API as string
    );

    websocket.onmessage = (event) => {
      const { action, message: newNotification } = JSON.parse(event.data);

      if (action === NEW_NOTIFICATION_ACTION && newNotification) {
        queryClient.setQueryData<FetchNotificationsResult>(
          QUERY_NAME.notifications,
          (notifications) => ({
            count: notifications!.count + 1,
            data: [newNotification, ...notifications!.data],
          })
        );
      }

      queryClient.invalidateQueries(QUERY_NAME.notifications);
    };

    websocket.onerror = () => {
      websocket.close();
    };

    return () => {
      websocket.close();
    };
  }, [queryClient]);

  return result;
};

import { ChangeEvent, FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTypography from '@material-ui/core/Typography';
import MuiFormGroup from '@material-ui/core/FormGroup';

import Checkbox from '@quanterix-ui/core/Checkbox';

import { useFetchContentHubPages } from 'src/api/endpoints/contentHub';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import { useSearchFilterContext } from '../../context/SearchFilterContext';
import { useStyles } from '../../styles';

import { TEST_ID } from './constants';

const ContentSearchFilter: FC = () => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { searchFilter, setSearchFilter } = useSearchFilterContext();

  const { data: contentHubPages, isLoading: isLoadingPages } =
    useFetchContentHubPages(
      { showHidden: false },
      {
        onSuccess: (pages) => {
          setSearchFilter({
            ...searchFilter,
            pagesIds: pages
              .filter((page) => page.total_published_files > 0)
              .map((page) => page.id),
          });
        },
      }
    );

  const availablePages = contentHubPages.filter(
    (page) => page.total_published_files > 0
  );

  const handleChangeSearchByTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter({ ...searchFilter, searchByTitle: event.target.checked });
  };

  const handleChangeSearchByBody = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter({ ...searchFilter, searchByBody: event.target.checked });
  };

  const handleChangeSearchByCategory = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const checkedPageId = Number(event.target.value);

    if (event.target.checked) {
      if (!searchFilter.pagesIds.includes(checkedPageId)) {
        setSearchFilter({
          ...searchFilter,
          pagesIds: [...searchFilter.pagesIds, checkedPageId],
        });
      }
    } else {
      setSearchFilter({
        ...searchFilter,
        pagesIds: searchFilter.pagesIds.filter((id) => id !== checkedPageId),
      });
    }
  };

  const handleToggleSelectAllPages = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilter({
      ...searchFilter,
      pagesIds: event.target.checked
        ? availablePages.map((page) => page.id)
        : [availablePages[0].id],
    });
  };

  const checkIsCheckboxWithPagesDisabled = (id: number) => {
    return (
      searchFilter.pagesIds.includes(id) && searchFilter.pagesIds.length === 1
    );
  };

  const allPagesSelected =
    searchFilter.pagesIds.length === availablePages.length;

  return (
    <MuiBox data-testid={TEST_ID.contentSearchFilter}>
      <MuiBox mb={2}>
        <MuiTypography className={classes.sidebarLabel}>
          <FormattedMessage id="page.content_hub.search_by" />
        </MuiTypography>
        <MuiFormGroup className={classes.checkboxGroup}>
          <MuiFormControlLabel
            control={
              <Checkbox
                checked={searchFilter.searchByTitle}
                disabled={
                  searchFilter.searchByTitle && !searchFilter.searchByBody
                }
                onChange={handleChangeSearchByTitle}
              />
            }
            label={formatMessage({ id: 'page.content_hub.search_by.title' })}
          />
          <MuiFormControlLabel
            control={
              <Checkbox
                checked={searchFilter.searchByBody}
                disabled={
                  searchFilter.searchByBody && !searchFilter.searchByTitle
                }
                onChange={handleChangeSearchByBody}
              />
            }
            label={formatMessage({ id: 'page.content_hub.search_by.content' })}
          />
        </MuiFormGroup>
      </MuiBox>
      <MuiBox mb={2}>
        <ProgressOverlay loading={isLoadingPages}>
          <MuiTypography className={classes.sidebarLabel}>
            <FormattedMessage id="page.content_hub.search_category" />
          </MuiTypography>
          <MuiFormGroup className={classes.checkboxGroup}>
            <MuiFormControlLabel
              control={
                <Checkbox
                  checked={allPagesSelected}
                  onChange={handleToggleSelectAllPages}
                />
              }
              label={formatMessage({
                id: 'page.content_hub.search_category.all',
              })}
            />
            {availablePages.map((page) => (
              <MuiFormControlLabel
                key={page.id}
                control={
                  <Checkbox
                    value={page.id}
                    checked={searchFilter.pagesIds.includes(page.id)}
                    disabled={checkIsCheckboxWithPagesDisabled(page.id)}
                    onChange={handleChangeSearchByCategory}
                  />
                }
                label={page.name}
              />
            ))}
          </MuiFormGroup>
        </ProgressOverlay>
      </MuiBox>
    </MuiBox>
  );
};

export default ContentSearchFilter;

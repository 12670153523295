import { ChangeEvent, FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTypography from '@material-ui/core/Typography';
import MuiFormGroup from '@material-ui/core/FormGroup';

import Checkbox from '@quanterix-ui/core/Checkbox';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { useFetchFilesFilters } from 'src/api/endpoints/contentHub';

import { useContentFilterContext } from '../../context/ContentFilterContext';
import { useStyles } from '../../styles';

import { TEST_ID } from './constants';

const ContentFilter: FC = () => {
  const classes = useStyles();

  const { pageId } = useParams<{ pageId?: string }>();

  const { contentFilter, setContentFilter } = useContentFilterContext();

  const { data: filters, isLoading: isLoadingFilters } =
    useFetchFilesFilters(pageId);

  const handleChangeExtension = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      if (!contentFilter.extensions.includes(value)) {
        setContentFilter({
          ...contentFilter,
          extensions: [...contentFilter.extensions, value],
        });
      }
    } else {
      setContentFilter({
        ...contentFilter,
        extensions: contentFilter.extensions.filter((ext) => ext !== value),
      });
    }
  };

  useEffect(() => {
    setContentFilter((otherFilters) => ({
      ...otherFilters,
      extensions: [],
    }));

    if (filters) {
      setContentFilter((otherFilters) => ({
        ...otherFilters,
        extensions: filters.extensions,
      }));
    }
  }, [pageId, filters, setContentFilter]);

  useEffect(() => {
    for (const extension of contentFilter.extensions) {
      if (
        filters &&
        filters?.extensions &&
        !filters.extensions.includes(extension)
      ) {
        setContentFilter({
          ...contentFilter,
          extensions: contentFilter.extensions.filter(
            (ext) => ext !== extension
          ),
        });
      }
    }
  }, [
    filters,
    filters?.extensions,
    contentFilter,
    contentFilter.extensions,
    setContentFilter,
  ]);

  if (filters?.extensions.length === 0) {
    return null;
  }

  return (
    <MuiBox data-testid={TEST_ID.contentFilter}>
      <ProgressOverlay withLoadingDisk loading={isLoadingFilters} opacity={1}>
        <MuiTypography className={classes.sidebarLabel}>
          <FormattedMessage id="page.bookmarks.filter.extensions.title" />
        </MuiTypography>
        <MuiFormGroup className={classes.checkboxGroup}>
          {filters?.extensions.map((ext) => (
            <MuiFormControlLabel
              key={ext}
              control={
                <Checkbox
                  value={ext}
                  checked={contentFilter.extensions.includes(ext)}
                  onChange={handleChangeExtension}
                />
              }
              label={ext}
            />
          ))}
        </MuiFormGroup>
      </ProgressOverlay>
    </MuiBox>
  );
};

export default ContentFilter;

import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Hub } from '@aws-amplify/core';
import { HubCapsule } from '@aws-amplify/core/lib/Hub';
import { Auth } from '@aws-amplify/auth';
import { SnackbarOrigin } from 'notistack';
import { differenceInDays } from 'date-fns';
import { useIntl } from 'react-intl';
import Cookie from 'js-cookie';

import { history, PRIVATE_ROUTES_MAP, PUBLIC_ROUTES_MAP } from 'src/router';
import { SNACKBAR } from 'src/utils/constants/app';
import { createUserRequest } from 'src/api/endpoints/userRequests';
import {
  requestChangePassword,
  requestChangeTemporaryPassword,
  requestConfirmForgotPassword,
  requestForgotPassword,
  storeLoginActivity,
} from 'src/api/endpoints/users';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { capitalize } from 'src/utils/StringHelper';
import { useSnackbar } from 'src/utils/hooks';

import { PASSWORD_EXPIRY_LAST_DAYS } from './constants';
import {
  checkIsPasswordExpiryChecked,
  checkUserAccess,
  getAttributeFromAccessToken,
  getAttributeFromIdToken,
  getDateToday,
} from './utils';
import {
  ChangePasswordOptions,
  CognitoConfig,
  CognitoConfigExtended,
  CognitoEvents,
  CognitoOptions,
  CognitoState,
  CognitoUserEntity,
  CompleteNewPasswordOptions,
  ExternalService,
  ForgotPasswordOptions,
  ResetPasswordOptions,
  SignInOptions,
  SignOutOptions,
  SignUpOptions,
  UpdateUserOptions,
} from './typings';

const defaultCognitoConfig: CognitoConfig = {
  user: null,
  signIn: () => Auth.federatedSignIn(),
  signOut: () => Auth.signOut(),
  refreshUser: () => {
    return;
  },
};

export const CognitoContext = createContext(defaultCognitoConfig);

const CognitoProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { formatMessage } = useIntl();

  const [user, setUser] = useState<CognitoUserEntity>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoutRunning, setIsLogoutRunning] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const userEmail = user?.attributes?.email;

  const isPasswordExpiryChecked = useRef(false);

  const [snackbarAnchor, setSnackbarAnchor] = useState<SnackbarOrigin>(
    SNACKBAR.defaultOptions.anchorOrigin as SnackbarOrigin
  );

  const { enqueueIntlSnackbar, enqueueErrorSnackbar, enqueueSuccessSnackbar } =
    useSnackbar(snackbarAnchor);

  const isEmailVerified = useMemo(() => {
    const emailVerified = user?.attributes?.email_verified;

    return typeof emailVerified === 'string'
      ? emailVerified === 'true'
      : !!emailVerified;
  }, [user]);

  const daysTillPasswordExpiry = useMemo(() => {
    if (!user) return NaN;

    const passwordExpiresAt = getAttributeFromIdToken(
      user,
      'password_expires_at'
    );

    const daysLeft = passwordExpiresAt
      ? differenceInDays(new Date(passwordExpiresAt), new Date())
      : 0;

    return daysLeft;
  }, [user]);

  const isPasswordExpired = useMemo(
    () => daysTillPasswordExpiry <= 0,
    [daysTillPasswordExpiry]
  );

  const groups = useMemo(() => {
    return (
      (getAttributeFromAccessToken(user, 'cognito:groups') as string[]) || []
    );
  }, [user]);

  const availableExternalServices = useMemo<ExternalService[]>(() => {
    if (!user) return [];

    const groupPrefix = process.env.REACT_APP_MODULE_GROUP_PREFIX as string;

    const result = groups
      .filter((group) => group.startsWith(groupPrefix))
      .map((group) => group.replace(groupPrefix, '').split(':'))
      .filter((parts) => parts.length === 2) // q:dev:arc will be included, q:dev:arc:summary-report will be excluded
      .map(([customer, module]) => {
        let description = '';

        if (module.includes('arc')) {
          description =
            'Generate reports based on experiment data captured from the instrument.';
        }

        if (
          module.includes('instrumentation') ||
          module.includes('apia') ||
          module.includes('layout')
        ) {
          description =
            'Create plate and rack layouts remotely on your device and use it on the instrument for experiments.';
        }

        return {
          description,
          title: `${capitalize(module)} (${capitalize(customer)})`,
          footerLinkText: formatMessage({
            id: 'app.link.discover_more',
          }),
          pathname: `/modules/${module}/landing-page`,
        };
      });

    return result;
  }, [user, groups, formatMessage]);

  useEffect(() => {
    const { pathname } = window.location;

    const isPasswordExpirySoon =
      daysTillPasswordExpiry <= PASSWORD_EXPIRY_LAST_DAYS;

    if (userEmail) {
      isPasswordExpiryChecked.current = checkIsPasswordExpiryChecked(userEmail);

      if (
        !isPasswordExpiryChecked.current &&
        isPasswordExpirySoon &&
        pathname !== '/'
      ) {
        isPasswordExpiryChecked.current = true;

        localStorage.setItem(
          LOCAL_STORAGE_KEY.passwordExpiryLastCheck,
          JSON.stringify({
            email: userEmail,
            date: getDateToday(),
          })
        );

        history.push(PRIVATE_ROUTES_MAP.changePassword, {
          from: pathname,
        });
      }
    }
  }, [userEmail, daysTillPasswordExpiry]);

  useEffect(() => {
    const userInit = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        const idToken = currentSession.getIdToken().getJwtToken();
        const accessToken = currentSession.getAccessToken().getJwtToken();
        const refreshToken = currentSession.getRefreshToken().getToken();

        setUser(user);

        setIdToken(idToken);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);

        Cookie.remove('id_token');
        Cookie.remove('access_token');
        Cookie.remove('refresh_token');
        Cookie.set('id_token', idToken, { domain: '.qtrxservices.com' });
        Cookie.set('access_token', accessToken, {
          domain: '.qtrxservices.com',
        });
        Cookie.set('refresh_token', refreshToken, {
          domain: '.qtrxservices.com',
        });

        localStorage.setItem(LOCAL_STORAGE_KEY.isCognitoInitialized, 'true');
      } catch (error) {
        setUser(null);
        localStorage.setItem(LOCAL_STORAGE_KEY.isCognitoInitialized, 'false');
      }
    };

    userInit();
  }, []);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }: HubCapsule) => {
      switch (event) {
        case CognitoEvents.SIGN_IN:
          setUser(data);
          break;

        case CognitoEvents.SIGN_OUT:
          setUser(null);
          Cookie.remove('id_token');
          Cookie.remove('access_token');
          Cookie.remove('refresh_token');
          break;

        case CognitoEvents.ERROR:
          setUser(null);
          break;

        default:
          break;
      }
    });
  }, [user]);

  const cognitoConfig = useMemo(() => {
    const checkIsAuthenticated = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

        return Boolean(user);
      } catch (error) {
        Auth.signOut();

        setUser(null);
        localStorage.setItem(LOCAL_STORAGE_KEY.isCognitoInitialized, 'false');

        return false;
      }
    };

    const checkIsAdmin = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const isAdmin = checkUserAccess(user, 'admins');

        return isAdmin;
      } catch (error) {
        setUser(null);
        throw new Error(error.message);
      }
    };

    const refreshUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        setUser(user);
      } catch (error) {
        setUser(null);
      }
    };

    const signIn = async ({
      email,
      password,
      redirectTo,
      isRefreshDisabled = false,
    }: SignInOptions) => {
      setIsLoading(true);

      try {
        const user = await Auth.signIn(email, password, {
          email,
          password,
        });

        setUser(user);
        setIsLoading(false);
        localStorage.setItem(LOCAL_STORAGE_KEY.isCognitoInitialized, 'true');

        if (!user?.challengeName && user?.attributes?.email_verified) {
          await storeLoginActivity();

          if (redirectTo) {
            history.push(redirectTo);
          } else if (!isRefreshDisabled) {
            window.location.reload();
          }
        }
      } catch (error) {
        setUser(null);
        console.error(error.message);

        if (error.message === 'Password attempts exceeded') {
          error.message = 'page.login.error.attempts_limit';
        } else if (error.message === 'User is disabled.') {
          error.message = 'page.login.error.user_disabled';
        } else if (
          error.message ===
          'PreAuthentication failed with error FAILED_LOGIN_ATTEMPTS_LIMIT.'
        ) {
          error.message = 'page.login.error.account_lock';
        }

        enqueueErrorSnackbar({ message: error.message });
        setIsLoading(false);
      }
    };

    const signUp = async (options: SignUpOptions) => {
      setIsLoading(true);

      try {
        await createUserRequest(options);

        history.push('/login');
      } catch (error) {
        setUser(null);

        const errorMessage = error.message.message || error.message;

        enqueueErrorSnackbar({ message: errorMessage });
        throw new Error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    const signOut = async ({
      global = false,
      withDefaultReason = false,
      reason,
    }: SignOutOptions = {}) => {
      const signOutReason =
        reason || (withDefaultReason && 'auth.signout.reason.default');

      setIsLogoutRunning(true);
      setUser(null);

      try {
        await Auth.signOut({ global });

        if (signOutReason) {
          enqueueIntlSnackbar({
            message: signOutReason,
            variant: 'info',
            persist: true,
            action: 'app.button.ok',
          });
        }

        history.push(PUBLIC_ROUTES_MAP.login);
      } catch (error) {
        console.error(error.message);

        if (error.name !== 'NotAuthorizedException') {
          enqueueErrorSnackbar({ message: error.message });
        } else {
          Auth.signOut();
        }
      } finally {
        setIsLogoutRunning(false);
        localStorage.setItem(LOCAL_STORAGE_KEY.isCognitoInitialized, 'false');
      }
    };

    const forgotPassword = async ({ email }: ForgotPasswordOptions) => {
      setIsLoading(true);

      try {
        await requestForgotPassword({ email });
      } catch (error) {
        enqueueErrorSnackbar({
          message: error.message.message || error.message,
          intlValues: {
            email,
            b: (chunks: ReactNode) => chunks,
          },
        });

        throw error.message.message ? error.message : error;
      } finally {
        setIsLoading(false);
      }
    };

    const resetPassword = async ({
      email,
      confirmationCode,
      password,
      redirectTo = '/login',
    }: ResetPasswordOptions) => {
      setIsLoading(true);

      try {
        await requestConfirmForgotPassword({
          email,
          password,
          confirmationCode,
        });

        history.push(redirectTo);
      } catch (error) {
        enqueueErrorSnackbar({
          message: error.message.message || error.message,
          intlValues: {
            intlValues: {
              email,
              b: (chunks: ReactNode) => chunks,
            },
          },
        });

        throw error.message.message ? error.message : error;
      } finally {
        setIsLoading(false);
      }
    };

    const changePassword = async ({
      currentPassword,
      newPassword,
      redirectTo,
    }: ChangePasswordOptions) => {
      setIsLoading(true);

      try {
        await requestChangePassword({ currentPassword, newPassword });
        await refreshUser();

        enqueueSuccessSnackbar({
          message: 'app.profile.change_password.success',
        });

        if (redirectTo) {
          history.push(redirectTo);
        }
      } catch (error) {
        enqueueErrorSnackbar({
          message: error.message.message || error.message,
        });
      } finally {
        setIsLoading(false);
      }
    };

    const completeNewPassword = async ({
      user,
      newPassword,
    }: CompleteNewPasswordOptions) => {
      setIsLoading(true);

      try {
        await requestChangeTemporaryPassword({
          email: user?.username,
          newPassword,
          session: user?.Session,
        });
        localStorage.removeItem(LOCAL_STORAGE_KEY.isChangePasswordInProgress);
        await signIn({
          email: user?.username,
          password: newPassword,
          redirectTo: PRIVATE_ROUTES_MAP.dashboard,
        });
      } catch (error) {
        enqueueErrorSnackbar({
          message: error.message.message || error.message,
          intlValues: {
            email: user?.username,
            b: (chunks: ReactNode) => chunks,
          },
        });

        throw error.message.message ? error.message : error;
      } finally {
        setIsLoading(false);
      }
    };

    const updateUser = async ({ attributes }: UpdateUserOptions) => {
      setIsLoading(true);

      try {
        await Auth.updateUserAttributes(user, attributes);
        await refreshUser();
      } catch (error) {
        enqueueErrorSnackbar({ message: error.message });
        throw new Error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    return {
      ...defaultCognitoConfig,
      user,
      isLoading,
      isLogoutRunning,
      accessToken,
      idToken,
      refreshToken,
      isEmailVerified,
      isPasswordExpired,
      daysTillPasswordExpiry,
      groups,
      availableExternalServices,
      changePassword,
      checkIsAuthenticated,
      checkIsAdmin,
      forgotPassword,
      signIn,
      signUp,
      signOut,
      refreshUser,
      resetPassword,
      completeNewPassword,
      updateUser,
      setSnackbarAnchor,
    };
  }, [
    user,
    accessToken,
    idToken,
    refreshToken,
    isLoading,
    isLogoutRunning,
    isEmailVerified,
    isPasswordExpired,
    daysTillPasswordExpiry,
    groups,
    availableExternalServices,
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar,
    setSnackbarAnchor,
    enqueueIntlSnackbar,
  ]);

  return (
    <CognitoContext.Provider value={cognitoConfig}>
      {children}
    </CognitoContext.Provider>
  );
};

const useCognito = ({ snackbarAnchor }: CognitoOptions = {}): CognitoState => {
  const { user, setSnackbarAnchor, ...restCognitoContext } = useContext(
    CognitoContext
  ) as CognitoConfigExtended;

  const userAttributes = user?.attributes;

  const isAuthenticated = Boolean(user);

  const userFullName = useMemo(() => {
    return isAuthenticated
      ? `${userAttributes?.given_name} ${userAttributes?.family_name}`
      : '';
  }, [
    isAuthenticated,
    userAttributes?.given_name,
    userAttributes?.family_name,
  ]);

  const checkAccess = (group: string) => checkUserAccess(user, group);

  const isAdmin = checkAccess('admins');

  useEffect(() => {
    setSnackbarAnchor(
      snackbarAnchor || (SNACKBAR.defaultOptions.anchorOrigin as SnackbarOrigin)
    );
  }, [snackbarAnchor, setSnackbarAnchor]);

  useEffect(() => {
    const storageChangeListener = (event: StorageEvent) => {
      if (
        event.key === LOCAL_STORAGE_KEY.isCognitoInitialized &&
        event.newValue === 'false'
      ) {
        restCognitoContext.signOut({ withDefaultReason: false });
      }
    };

    window.addEventListener('storage', storageChangeListener);

    return () => {
      window.removeEventListener('storage', storageChangeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...restCognitoContext,
    user,
    userFullName,
    isAuthenticated,
    isAdmin,
    checkAccess,
    userData: {
      id: userAttributes?.sub,
      name: userAttributes?.name,
      email: userAttributes?.email,
      emailVerified: userAttributes?.email_verified,
      attributes: userAttributes,
    },
  };
};

export { useCognito };

export default CognitoProvider;

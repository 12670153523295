import { privateGateway } from '../../../config';
import { UpdateUserRequestParams, UserRequestSchema } from '../typings';

export const updateUserRequest = async ({
  requestId,
  data,
}: UpdateUserRequestParams): Promise<UserRequestSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).patch(`/user_requests/${requestId}`, data);

  return response.data;
};

import { FC, PropsWithChildren } from 'react';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiCheckIcon from '@material-ui/icons/Check';
import MuiCloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

interface Props {
  isDirty?: boolean;
  hasError?: boolean;
}

const PasswordHelpText: FC<PropsWithChildren<Props>> = ({
  children,
  isDirty,
  hasError,
}) => {
  const classes = useStyles();

  return (
    <MuiGrid container wrap="nowrap">
      <MuiFormHelperText className={classes.formHelperText}>
        {children}
      </MuiFormHelperText>
      <MuiBox ml={0.5} height={20}>
        {isDirty && !hasError && (
          <MuiCheckIcon fontSize="small" color="primary" />
        )}
        {isDirty && hasError && <MuiCloseIcon fontSize="small" color="error" />}
      </MuiBox>
    </MuiGrid>
  );
};

export default PasswordHelpText;

export const CSV_FILE_MIMETYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.ms-excel',
  'text/csv',
  'text/plain',
];

export const DOC_FILE_MIMETYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/msword',
];

export const CSV_FILE_EXTENTIONS = ['.xlsx', '.xlsm', '.csv', '.xls', '.ods'];

export const DOC_FILE_EXTENTIONS = ['.docx', '.odt', '.odp', '.doc'];

export const DROPZONE_ACCEPT_CSV_SCHEMA = CSV_FILE_MIMETYPES.reduce(
  (schema, mimeType) => ({ ...schema, [mimeType]: CSV_FILE_EXTENTIONS }),
  {}
);

export const DROPZONE_ACCEPT_DOC_SCHEMA = DOC_FILE_MIMETYPES.reduce(
  (schema, mimeType) => ({ ...schema, [mimeType]: DOC_FILE_EXTENTIONS }),
  {}
);

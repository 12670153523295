import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

import { LAYOUT_DIMENSIONS } from 'src/utils/constants/app';

const { header } = LAYOUT_DIMENSIONS;

export const useStyles = makeStyles((theme: Theme) => ({
  appHeader: {
    height: header.height.desktop,
    paddingLeft: header.spacing.horizontal.desktop,
    paddingRight: header.spacing.horizontal.desktop,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    zIndex: 100,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: header.spacing.horizontal.mobile,
      paddingRight: header.spacing.horizontal.mobile,
    },
  },

  headerLogo: {
    width: 123,
    height: 40,
    verticalAlign: -6,
  },

  appHeaderRoutes: {
    '& > a': {
      marginLeft: 10,
      marginRight: 10,
      textDecoration: 'none',
      fontSize: 15,
    },

    '& > a.regular': {
      color: theme.palette.common.white,
      textDecoration: 'none',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },

    '& > a.selected': {
      fontWeight: 600,
      color: theme.palette.primary.main,
      borderBotton: `4px ${theme.palette.primary.main} solid`,
      paddingBottom: 15,
      textDeecoration: 'none',
    },
  },

  adminHeader: {
    verticalAlign: 2,
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },

  profileMenuPaper: {
    minWidth: 140,
    boxShadow: '0 2px 10px rgba(0 0 0 / 10%)',
  },

  logoLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

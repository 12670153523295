import { FAQSectionSchema } from '../typings';
import { privateGateway } from '../../../config';

export const fetchFAQSections = async (): Promise<FAQSectionSchema[]> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get('/faq/sections');

  return response.data;
};

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { AttributeEntry, OrganizationData, PersonalData } from '../typings';

export interface UserDataState {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  company: string;
  address1: string;
  address2: string;
  zip: string;
  region: string;
  country: string;
  city: string;
  website: string;
}

export type PersonalDataSchema = Record<keyof PersonalData, AttributeEntry>;
export type OrganizationDataSchema = Record<
  keyof OrganizationData,
  AttributeEntry
>;

export type UserViewerProfileContextResult = {
  userData: UserDataState;
  personalData: PersonalDataSchema;
  organizationData: OrganizationDataSchema;
  profileCompletionPercentge: number;
  setUserData: Dispatch<SetStateAction<UserDataState>>;
};

export const DEFAULT_STATE: UserDataState = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company: '',
  address1: '',
  address2: '',
  zip: '',
  region: '',
  country: '',
  city: '',
  website: '',
};

export const UserViewerProfileContext =
  createContext<UserViewerProfileContextResult>({
    userData: {} as UserDataState,
    personalData: {} as PersonalDataSchema,
    organizationData: {} as OrganizationDataSchema,
    profileCompletionPercentge: 0,
    setUserData: () => {
      // void
    },
  });

const UserViewerProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userData, setUserData] = useState(DEFAULT_STATE);

  const personalData = useMemo(
    () => ({
      firstname: {
        hideInList: true,
        labelId: 'app.form.label.first_name',
        value: userData.firstname,
      },
      lastname: {
        hideInList: true,
        labelId: 'app.form.label.last_name',
        value: userData.lastname,
      },
      email: {
        labelId: 'app.form.label.email',
        value: userData.email,
      },
      phone: {
        labelId: 'app.form.label.phone',
        value: userData.phone,
      },
    }),
    [userData]
  );

  const organizationData = useMemo(
    () => ({
      company: {
        labelId: 'app.form.label.company',
        value: userData.company,
      },
      address1: {
        labelId: 'app.form.label.address1',
        value: userData.address1,
      },
      address2: {
        labelId: 'app.form.label.address2',
        value: userData.address2,
      },
      zip: {
        labelId: 'app.form.label.zip_code',
        value: userData.zip,
      },
      city: {
        labelId: 'app.form.label.city',
        value: userData.city,
      },
      region: {
        labelId: 'app.form.label.state',
        value: userData.region,
      },
      country: {
        labelId: 'app.form.label.country',
        value: userData.country,
      },
      website: {
        labelId: 'app.form.label.website',
        value: userData.website,
      },
    }),
    [userData]
  );

  const profileCompletionPercentge = useMemo(() => {
    const data = { ...personalData, ...organizationData };
    const values = Object.values(data).map((entry) => entry.value);
    const emptyValuesCount = values.filter((value) => value === '').length;

    const completion = 100 - (emptyValuesCount * 100) / values.length;

    return Math.round(completion);
  }, [personalData, organizationData]);

  return (
    <UserViewerProfileContext.Provider
      value={{
        userData,
        personalData,
        organizationData,
        profileCompletionPercentge,
        setUserData,
      }}
    >
      {children}
    </UserViewerProfileContext.Provider>
  );
};

export const useUserViewerProfileContext =
  (): UserViewerProfileContextResult => {
    const context = useContext(UserViewerProfileContext);

    if (!context) {
      throw new Error(
        'useUserViewerProfileContext must be used within a UserViewerProfileProvider'
      );
    }

    return context;
  };

export default UserViewerProfileProvider;

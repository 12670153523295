import { OrganizationFormSchema, PersonalFormSchema } from './typings';

export const TEST_ID = {
  firstName: 'firstName',
  lastName: 'lastName',
  company: 'company',
  email: 'email',
  phone: 'phone',
  address1: 'address1',
  city: 'city',
  state: 'state',
  zip: 'zip',
  country: 'country',
};

export const DEFAULT_PERSONAL_FORM_VALUES: PersonalFormSchema = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  confirmAge: false,
  acceptTerms: false,
  acceptNewsletter: false,
  legal: {
    privacy_policy_version: '',
    terms_of_use_version: '',
  },
};

export const DEFAULT_ORGANIZATION_FORM_VALUES: OrganizationFormSchema = {
  company: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  state: '',
  country: '',
  instruments: {
    spx: {
      selected: false,
      serialNumber: '',
    },
    srx: {
      selected: false,
      serialNumber: '',
    },
    hdx: {
      selected: false,
      serialNumber: '',
    },
    hd1: {
      selected: false,
      serialNumber: '',
    },
    acceleratorServices: {
      selected: false,
      serialNumber: '',
    },
  },
};

export const FILTER_FIELDS = {
  companyName: 'name',
};

export const DEFAULT_FILTER = {
  field: FILTER_FIELDS.companyName,
  value: '',
};

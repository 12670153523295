import { Editor, Element, Point, Range, Text, Transforms } from 'slate';
import isUrl from 'is-url';

import { CustomElement } from 'src/typings/slate';

import { ACTION_TYPE } from '../constants';

export const isLinkActive = (
  editor: Editor,
  format: CustomElement['type'] = ACTION_TYPE.link
) => {
  const { selection } = editor;

  if (!selection || format !== ACTION_TYPE.link) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

export const toggleLink = (editor: Editor) => {
  if (!isLinkActive(editor, ACTION_TYPE.link)) {
    const { selection } = editor;

    if (!selection) return false;

    const isSelectionCollapsed = Range.isCollapsed(selection);

    if (isSelectionCollapsed) {
      Transforms.insertNodes(
        editor,
        {
          type: ACTION_TYPE.link,
          href: '#',
          children: [{ text: ACTION_TYPE.link }],
        },
        { at: selection }
      );
    } else {
      Transforms.wrapNodes(
        editor,
        { type: ACTION_TYPE.link, href: '#', children: [{ text: '' }] },
        { split: true, at: selection }
      );
    }
  } else {
    Transforms.unwrapNodes(editor, {
      match: (n) => Element.isElement(n) && n.type === ACTION_TYPE.link,
    });
  }
};

export const identifyLinksInTextIfAny = (editor: Editor) => {
  // if selection is not collapsed, we do not proceed with the link detection.
  if (!editor.selection || !Range.isCollapsed(editor.selection)) {
    return;
  }

  const [node] = Editor.parent(editor, editor.selection);

  // if we are already inside a link, exit early.
  if (Element.isElement(node) && node.type === ACTION_TYPE.link) {
    return;
  }

  const [currentNode, currentNodePath] = Editor.node(editor, editor.selection);

  // if we are not inside a text node, exit early.
  if (!Text.isText(currentNode)) {
    return;
  }

  let [start] = Range.edges(editor.selection);
  const cursorPoint = start;

  const startPointOfLastCharacter = Editor.before(editor, editor.selection, {
    unit: 'character',
  });

  if (!startPointOfLastCharacter) {
    return;
  }

  const lastCharacter = Editor.string(
    editor,
    Editor.range(editor, startPointOfLastCharacter, cursorPoint)
  );

  if (lastCharacter !== ' ') {
    return;
  }

  let end = startPointOfLastCharacter;

  const tmpStart = Editor.before(editor, end, {
    unit: 'character',
  });

  if (tmpStart) {
    start = tmpStart;
  }

  const startOfTextNode = Editor.point(editor, currentNodePath, {
    edge: 'start',
  });

  while (
    Editor.string(editor, Editor.range(editor, start, end)) !== ' ' &&
    !Point.isBefore(start, startOfTextNode)
  ) {
    end = start;

    const tmpStart = Editor.before(editor, end, { unit: 'character' });

    if (tmpStart) {
      start = tmpStart;
    }
  }

  const lastWordRange = Editor.range(editor, end, startPointOfLastCharacter);
  const lastWord = Editor.string(editor, lastWordRange);

  if (isUrl(lastWord)) {
    Promise.resolve().then(() => {
      Transforms.wrapNodes(
        editor,
        {
          type: ACTION_TYPE.link,
          href: lastWord,
          children: [{ text: lastWord }],
        },
        { split: true, at: lastWordRange }
      );
    });
  }
};

import { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

import {
  QUANTERIX_TECH_EMAIL,
  SUPPORT_PHONE_NUMBER,
} from 'src/utils/constants/contacts';
import { PRIVATE_ROUTES_MAP } from 'src/router';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

const NotFound: FC = () => {
  const classes = useStyles();

  return (
    <MuiBox className={classes.root} data-testid={TEST_ID.notFoundPage}>
      <MuiGrid
        container
        direction="column"
        justifyContent="center"
        className={classes.content}
      >
        <MuiGrid item>
          <MuiBox py={3}>
            <MuiTypography
              variant="h1"
              component="span"
              className={classes.typography}
            >
              <FormattedMessage id="page.not_found.title" />
            </MuiTypography>
          </MuiBox>
          <MuiBox py={2}>
            <MuiTypography
              variant="h6"
              component="span"
              className={classes.typography}
            >
              <FormattedMessage
                id="page.not_found.description"
                values={{
                  link: (chunks: ReactNode) => (
                    <Link
                      to={PRIVATE_ROUTES_MAP.dashboard}
                      className={classes.link}
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </MuiTypography>
          </MuiBox>
          <MuiBox py={2}>
            <MuiTypography
              variant="h6"
              component="span"
              className={classes.typography}
            >
              <FormattedMessage id="page.not_found.contact.title" />
            </MuiTypography>
            <MuiGrid container direction="column">
              <MuiGrid item className={classes.listItem}>
                <MuiGrid container alignItems="center">
                  <MuiGrid item>
                    <div className={classes.dashElement} />
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography
                      variant="h6"
                      component="span"
                      className={classes.typography}
                    >
                      <MuiLink
                        href={`mailto:${QUANTERIX_TECH_EMAIL}`}
                        target="_blank"
                        className={classes.link}
                      >
                        {QUANTERIX_TECH_EMAIL}
                      </MuiLink>
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item className={classes.listItem}>
                <MuiGrid container alignItems="center">
                  <MuiGrid item>
                    <div className={classes.dashElement} />
                  </MuiGrid>
                  <MuiGrid item>
                    <MuiTypography
                      variant="h6"
                      component="span"
                      className={classes.typography}
                    >
                      {SUPPORT_PHONE_NUMBER}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
      <MuiBox className={classes.background} />
    </MuiBox>
  );
};

export default NotFound;

import { FC } from 'react';

import { RenderLeafProps } from 'slate-react';

export type EditorLeafProps = RenderLeafProps;

const EditorLeaf: FC<EditorLeafProps> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code style={{ backgroundColor: '#eeeeee' }}>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default EditorLeaf;

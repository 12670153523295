import { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import BackgroundImage from 'src/assets/images/molecules.png';
import { QUANTERIX_CUSTOMER_SERVICE_EMAIL } from 'src/utils/constants/contacts';

import { useStyles } from './styles';

const HelpBanner: FC = () => {
  const classes = useStyles();

  return (
    <MuiBox className={classes.root} mt={10} mb={2}>
      <img
        src={BackgroundImage}
        className={classes.backgroundImage}
        alt="background"
      />
      <MuiBox className={classes.textContainer}>
        <MuiTypography variant="h2" color="secondary" className={classes.text}>
          <FormattedMessage
            id="page.faq.help_banner"
            values={{
              link: (msg: ReactNode) => (
                <a href={`mailto:${QUANTERIX_CUSTOMER_SERVICE_EMAIL}`}>{msg}</a>
              ),
            }}
          />
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default HelpBanner;

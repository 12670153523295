import { privateGateway } from '../../../config';
import { CurrentUserSchema, UpdateCurrentUserVariables } from '../typings';

export const updateCurrentUser = async ({
  legal,
}: UpdateCurrentUserVariables): Promise<CurrentUserSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).put('/users/me', { legal });

  return response.data;
};

import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal,
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
import { isToday, isValid } from 'date-fns';

import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';

import { CognitoUserEntity, LastCheck } from './typings';

export const getDateToday = (): Date => new Date();

export const getAttributeFromAccessToken = (
  user: CognitoUserEntity,
  attribute: string
): any => {
  const result = user?.signInUserSession?.accessToken.payload[attribute];

  return result;
};

export const getAttributeFromIdToken = (
  user: CognitoUserEntity,
  attribute: string
): any => {
  const result = user?.signInUserSession?.idToken.payload[attribute];

  return result;
};

export const checkUserAccess = (
  user: CognitoUserEntity,
  group: string
): boolean => {
  const userGroups = getAttributeFromAccessToken(user, 'cognito:groups');

  return !!userGroups?.find((userGroup: string) => userGroup === group);
};

export const checkIsPasswordExpiryChecked = (userEmail?: string): boolean => {
  const lastCheckRawData = localStorage.getItem(
    LOCAL_STORAGE_KEY.passwordExpiryLastCheck
  );

  const { email, date } = JSON.parse(lastCheckRawData || '{}') as LastCheck;

  if (!email || email.length === 0) return false;
  if (!date || !isValid(new Date(date))) return false;

  if (userEmail === email && isToday(new Date(date))) {
    return true;
  }

  return false;
};

import { makeStyles } from '@material-ui/core';

interface Props {
  color: string;
}

export const useStyles = makeStyles((theme) => ({
  qhubPath: {
    fill: (props: Props) =>
      props.color === 'primary'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
  },

  iconPath: {
    fill: theme.palette.primary.main,
    fillRule: 'evenodd',
  },

  tmPath: {
    fill: (props: Props) =>
      props.color === 'primary'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
  },
}));

export enum LOCAL_STORAGE_KEY {
  isChangePasswordInProgress = 'is_change_password_in_progress',
  lastActivity = 'last_activity',
  acceleratorForm = 'quanterix-accelerator-form',
  userEmail = 'user_email',
  lastResend = 'last_resend',
  passwordExpiryLastCheck = 'password_expiry_last_check',
  personalFormData = 'personal_form_data',
  organizationFormData = 'organization_form_data',
  supportFileFormData = 'support_file_form_data',
  requestAccessStep = 'request_access_step',
  isCognitoInitialized = 'is_cognito_initialized',
}

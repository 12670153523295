import { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import { useFetchContentHubChildPages } from '../hooks/useFetchContentHubChildPages';
import { fetchContentHubPage } from '../requests/fetchContentHubPage';
import { ContentHubObjectSchema } from '../typings';
import { QUERY_NAME } from '../constants';

export const useOpenTreeViewItems = () => {
  const queryClient = useQueryClient();

  const [parentPage, setParentPage] = useState<ContentHubObjectSchema>();

  const { isFetching } = useFetchContentHubChildPages(
    Number(parentPage?.parent_id),
    {
      enabled: !!parentPage?.parent_id,
    }
  );

  const openTreeViewItem = useCallback(async (item: ContentHubObjectSchema) => {
    const parent = item.parent_id
      ? await fetchContentHubPage({ id: item.parent_id })
      : null;

    if (parent) {
      setParentPage(parent);
    }
  }, []);

  useEffect(() => {
    if (parentPage) {
      if (parentPage?.parent_id) {
        queryClient.setQueryData<ContentHubObjectSchema[]>(
          [QUERY_NAME.fetchContentHubPages],
          (previous = []) => {
            return previous.map((page) => {
              if (page.id === parentPage.parent_id) {
                return {
                  ...page,
                  is_open: true,
                };
              }
              return page;
            });
          }
        );
      } else {
        queryClient.setQueryData<ContentHubObjectSchema[]>(
          [QUERY_NAME.fetchContentHubPages],
          (previous = []) => {
            return previous.map((page) => {
              if (page.id === parentPage.id) {
                return {
                  ...page,
                  is_open: true,
                };
              }
              return page;
            });
          }
        );
      }
    }
  }, [queryClient, parentPage]);

  useEffect(() => {
    if (parentPage?.parent_id && !isFetching) {
      queryClient.setQueryData<ContentHubObjectSchema[]>(
        [QUERY_NAME.fetchContentHubChildPages, parentPage.parent_id],
        (previous = []) => {
          return previous.map((page) => {
            if (page.id === parentPage.id) {
              return {
                ...page,
                is_open: true,
              };
            }
            return page;
          });
        }
      );
    }
  }, [queryClient, parentPage, isFetching]);

  return { openTreeViewItem };
};

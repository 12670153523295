export enum QUERY_NAME {
  fetchContentHubPage = 'fetchContentHubPage',
  fetchContentHubPages = 'fetchContentHubPages',
  fetchContentHubChildPages = 'fetchContentHubChildPages',
  fetchContentHubPageFiles = 'fetchContentHubPageFiles',
  searchContentHubFiles = 'searchContentHubFiles',
  filesCount = 'filesCount',
  fetchFilesFilters = 'fetchFilesFilters',
  fetchSuggestions = 'fetchSuggestions',
}

export enum PAGE_TYPE {
  category = 'CATEGORY',
  subcategory = 'SUBCATEGORY',
  section = 'SECTION',
}

export enum PAGE_MODE {
  default = 'DEFAULT',
  legal = 'LEGAL',
  public = 'PUBLIC',
}

export enum PAGE_SLUG {
  legalDocuments = 'legal-documents',
  termsAndConditions = 'terms-and-conditions',
  privacyPolicy = 'privacy-policy',
  cookiePolicy = 'cookie-policy',
  customerSupportTool = 'customer-support-tool',
  userGuides = 'user-guides',
  publicAccess = 'public-access',
}

export enum FILE_STATUS {
  new = 'NEW',
  pending = 'PENDING',
  published = 'PUBLISHED',
}

export enum MOVE_DIRECTION {
  up = 'up',
  down = 'down',
}

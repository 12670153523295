import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    marginBottom: 8,
  },

  dialogContent: {
    paddingLeft: 0,
    paddingRight: 0,
    overflowY: 'unset',
  },

  label: {
    width: '100%',
    textAlign: 'left',
    marginBottom: 4,
    marginLeft: 8,
    fontFamily: theme.fonts.fontFamily.regular,
  },
}));

import { AxiosResponse } from 'axios';

import { RequestChangePasswordParams } from '../typings';
import { privateGateway } from '../../../config';

export const requestChangePassword = async ({
  currentPassword,
  newPassword,
}: RequestChangePasswordParams): Promise<AxiosResponse> => {
  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).post(`/users/password/change`, {
    old_password: currentPassword,
    new_password: newPassword,
  });

  return response.data;
};

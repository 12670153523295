import { useState } from 'react';

import { useSnackbar } from 'src/utils/hooks/useSnackbar';

export type UseStorageResult<T> = {
  storedValue: T | undefined;
  setValue: (value: T) => void;
  removeValue: () => void;
};

export type CreateStorageResult = <T = unknown>(
  key: string,
  fallback?: T | (() => void)
) => UseStorageResult<T>;

export const createStorage = (
  storageType: 'local' | 'session'
): CreateStorageResult => {
  const storage = storageType === 'local' ? localStorage : sessionStorage;

  return <T = unknown>(key: string, fallback?: T | (() => void)) => {
    const { enqueueErrorSnackbar } = useSnackbar();

    const [storedValue, setStoredValue] = useState<T | undefined>(() => {
      try {
        const item = storage.getItem(key);

        if (item) {
          return JSON.parse(item);
        }
      } catch (error) {
        console.error(error);

        enqueueErrorSnackbar({ message: 'api.response.unknown_error' });
      }

      if (fallback !== undefined) {
        if (fallback instanceof Function) {
          fallback();
        } else {
          return fallback;
        }
      }
    });

    const setValue = (value: T) => {
      try {
        setStoredValue(value);
        storage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error(error);
      }
    };

    const removeValue = () => {
      try {
        setStoredValue(undefined);
        storage.removeItem(key);
      } catch (error) {
        console.error(error);
      }
    };

    return { storedValue, setValue, removeValue };
  };
};

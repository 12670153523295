import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import axios, { CancelTokenSource } from 'axios';

import {
  MALWARE_SCAN_SOURCE,
  useMalwareScan,
  UseMalwareScanResult,
} from 'src/utils/hooks';
import { submitSupportFiles } from 'src/api/endpoints/users';

type UploadProgress = number | null;

type CaseObject = {
  caseId: string;
  caseReference: string;
  caseDescription: string;
};

export interface UploadProgressContextResult {
  isMalwareScanCompleted: UseMalwareScanResult['isCompleted'];
  filesWithMalware: UseMalwareScanResult['filesWithMalware'];

  onMalwareScanCompleted: (
    variant: 'success' | 'error',
    callback: (fileName?: string) => void
  ) => void;

  cancelSource: { current: CancelTokenSource };

  uploadProgress: UploadProgress;
  setUploadProgress: Dispatch<SetStateAction<UploadProgress>>;

  files: string[];
  setFiles: Dispatch<SetStateAction<string[]>>;

  caseObject: CaseObject;
  setCaseObject: Dispatch<SetStateAction<CaseObject>>;

  onCancel: () => void;
}

const axiosCancelSource = axios.CancelToken.source();

const UploadProgressContext = createContext<UploadProgressContextResult>({
  isMalwareScanCompleted: false,
  filesWithMalware: [],

  onMalwareScanCompleted: () => {
    // void
  },

  cancelSource: { current: axiosCancelSource },

  uploadProgress: null,
  setUploadProgress: () => {
    // void
  },

  files: [],
  setFiles: () => {
    // void
  },

  caseObject: {
    caseId: '',
    caseReference: '',
    caseDescription: '',
  },
  setCaseObject: () => {
    // void
  },

  onCancel: () => {
    // void
  },
});

const UploadProgressProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  /* eslint-disable @typescript-eslint/no-empty-function */
  const successCallback = useRef(() => {});
  const errorCallback = useRef((fileName?: string) => {});
  /* eslint-enable @typescript-eslint/no-empty-function */

  const cancelSource = useRef(axiosCancelSource);

  const [uploadProgress, setUploadProgress] = useState<UploadProgress>(null);

  const [files, setFiles] = useState<string[]>([]);

  const [caseObject, setCaseObject] = useState<CaseObject>({
    caseId: '',
    caseReference: '',
    caseDescription: '',
  });

  const onMalwareScanCompleted: UploadProgressContextResult['onMalwareScanCompleted'] =
    useCallback((variant, callback) => {
      if (variant === 'success') {
        successCallback.current = callback;
      }

      if (variant === 'error') {
        errorCallback.current = callback;
      }
    }, []);

  const {
    addFilesToScanQueue,
    removeFileFromScanQueue,
    inProgress: isMalwareScanInProgress,
    isCompleted: isMalwareScanCompleted,
    filesWithMalware,
    totalScannedFilesCount,
    cleanTemporaryFilesWithMalware,
    removeAllFilesFromScanQueue,
  } = useMalwareScan(
    MALWARE_SCAN_SOURCE.blobStorage,
    cancelSource.current.token
  );

  useEffect(() => {
    if (uploadProgress === 50 && files.length > 0) {
      cleanTemporaryFilesWithMalware();
      addFilesToScanQueue(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, uploadProgress, cleanTemporaryFilesWithMalware]);

  useEffect(() => {
    const submitRequest = async () => {
      try {
        await submitSupportFiles(
          {
            caseId: caseObject.caseId,
            caseReference: caseObject.caseReference,
            caseDescription: caseObject.caseDescription,
            attachments: files,
          },
          { cancelToken: cancelSource.current.token }
        );

        successCallback.current();
      } catch (error) {
        console.error(error.message);
      }
    };

    if (isMalwareScanCompleted && uploadProgress === 100 && files.length > 0) {
      if (filesWithMalware.length > 0) {
        const fileNames = filesWithMalware.map((file) =>
          file.split('/').at(-1)
        );
        errorCallback.current(fileNames.join(', '));
      } else {
        submitRequest();
      }

      setTimeout(() => {
        setFiles([]);
        removeAllFilesFromScanQueue();
      }, 1000);
    }
  }, [
    caseObject,
    files,
    filesWithMalware,
    isMalwareScanCompleted,
    cancelSource.current.token,
    uploadProgress,
    removeFileFromScanQueue,
    setFiles,
    setUploadProgress,
    removeAllFilesFromScanQueue,
    cleanTemporaryFilesWithMalware,
  ]);

  useEffect(() => {
    if (isMalwareScanInProgress) {
      const totalScannedProgress =
        50 + totalScannedFilesCount * (50 / files.length);

      setUploadProgress(totalScannedProgress);
    }
  }, [
    isMalwareScanInProgress,
    totalScannedFilesCount,
    setUploadProgress,
    files,
  ]);

  const onCancel = useCallback(() => {
    setTimeout(() => {
      setFiles([]);
      removeAllFilesFromScanQueue();
      setUploadProgress(null);
      cancelSource.current = axios.CancelToken.source();
    }, 1000);
  }, [removeAllFilesFromScanQueue]);

  return (
    <UploadProgressContext.Provider
      value={{
        isMalwareScanCompleted,
        filesWithMalware,
        onMalwareScanCompleted,

        cancelSource,

        uploadProgress,
        setUploadProgress,

        files,
        setFiles,

        caseObject,
        setCaseObject,

        onCancel,
      }}
    >
      {children}
    </UploadProgressContext.Provider>
  );
};

export const useUploadProgressContext = (): UploadProgressContextResult => {
  const context = useContext(UploadProgressContext);

  if (!context) {
    throw new Error(
      'useUploadProgressContext must be used within a UploadProgressProvider'
    );
  }

  return context;
};

export default UploadProgressProvider;

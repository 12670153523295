import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { useFilterContext } from 'src/context/FilterContext';
import { usePaginationContext } from 'src/context/PaginationContext';

import { fetchUserRequests } from '../requests/fetchUserRequests';
import { QUERY_NAME } from '../constants';
import { FetchUserRequestsResult } from '../typings';

type UseFetchUserRequestsResult = UseQueryResult<FetchUserRequestsResult>;

type UseFetchUserRequestsOptions = {
  keepPreviousData?: boolean;
};

export const useFetchUserRequests = (
  options: UseFetchUserRequestsOptions = {}
): UseFetchUserRequestsResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const { filter } = useFilterContext();

  const { pagination } = usePaginationContext();

  const result = useQuery<FetchUserRequestsResult, Error>(
    [QUERY_NAME.userRequests, { filter, pagination }],
    () => fetchUserRequests({ filter, pagination }),
    {
      onSuccess: async (userRequests) => {
        userRequests.data.forEach((userRequest) => {
          queryClient.setQueryData(
            [QUERY_NAME.userRequest, userRequest.id],
            userRequest
          );
        });

        const prefetchPagination = {
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page + 1,
          skip: (pagination.page + 1) * pagination.rowsPerPage,
        };

        await queryClient.prefetchQuery(
          [QUERY_NAME.userRequests, { filter, pagination: prefetchPagination }],
          () => fetchUserRequests({ filter, pagination: prefetchPagination })
        );
      },
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
      ...options,
    }
  );

  return result;
};

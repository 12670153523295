/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

import { SnackbarOrigin } from 'notistack';

import { CreateUserRequestParams } from 'src/api/endpoints/userRequests';

export enum CognitoEvents {
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  ERROR = 'error',
}

export interface CognitoUserAttributes {
  [key: string]: string;
}

export interface CognitoUser {
  id: string;
  name: string;
  email: string;
  emailVerified: boolean;
  attributes: CognitoUserAttributes;
}

export type CognitoUserEntity = Record<string, any> | null;

export interface SignInOptions {
  email: string;
  password: string;
  redirectTo?: string;
  isRefreshDisabled?: boolean;
}
export type SignInDefault = () => void;
export type SignInCustom = ({
  email,
  password,
}: SignInOptions) => Promise<void>;

export type SignUpOptions = CreateUserRequestParams;
export type SignUpCustom = (options: SignUpOptions) => Promise<void>;

export interface SignOutOptions {
  global?: boolean;
  reason?: string;
  withDefaultReason?: boolean;
}
export type SignOutDefault = () => void;
export type SignOutCustom = (options?: SignOutOptions) => Promise<void>;

export type CheckIsAuthenticated = () => Promise<boolean>;
export type CheckIsAdmin = () => Promise<boolean>;

export interface UpdateUserOptions {
  attributes: CognitoUserAttributes;
}
export type UpdateUserCustom = ({
  attributes,
}: UpdateUserOptions) => Promise<void>;

export interface ForgotPasswordOptions {
  email: string;
}
export type ForgotPasswordCustom = ({
  email,
}: ForgotPasswordOptions) => Promise<void>;

export interface ForgotPasswordSubmitOptions {
  email: string;
}
export type ForgotPasswordSubmitCustom = ({
  email,
}: ForgotPasswordOptions) => Promise<void>;

export interface ResetPasswordOptions {
  email: string;
  confirmationCode: string;
  password: string;
  redirectTo?: string;
}
export type ResetPasswordCustom = ({
  email,
  confirmationCode,
  password,
  redirectTo,
}: ResetPasswordOptions) => Promise<void>;

export interface ChangePasswordOptions {
  currentPassword: string;
  newPassword: string;
  redirectTo?: string;
}
export type ChangePasswordCustom = ({
  currentPassword,
  newPassword,
}: ChangePasswordOptions) => Promise<void>;

export interface CompleteNewPasswordOptions {
  user: CognitoUserEntity;
  newPassword: string;
}
export type CompleteNewPasswordCustom = ({
  user,
  newPassword,
}: CompleteNewPasswordOptions) => Promise<void>;

export interface ExternalService {
  title: string;
  description: string;
  footerLinkText: string;
  pathname: string;
}

export interface CognitoConfig {
  user: CognitoUserEntity;
  signIn: SignInCustom | SignInDefault;
  signOut: SignOutCustom | SignOutDefault;
  refreshUser: () => void;
}

export interface CognitoConfigExtended extends CognitoConfig {
  isLoading: boolean;
  isLogoutRunning: boolean;
  isEmailVerified: boolean;
  isPasswordExpired: boolean;
  daysTillPasswordExpiry: number;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  groups: string[];
  availableExternalServices: ExternalService[];
  forgotPassword: ForgotPasswordCustom;
  resetPassword: ResetPasswordCustom;
  changePassword: ChangePasswordCustom;
  completeNewPassword: CompleteNewPasswordCustom;
  signUp: SignUpCustom;
  setSnackbarAnchor: Dispatch<SetStateAction<SnackbarOrigin>>;
  checkIsAuthenticated: CheckIsAuthenticated;
  checkIsAdmin: CheckIsAdmin;
  updateUser: UpdateUserCustom;
}

export interface CognitoOptions {
  snackbarAnchor?: SnackbarOrigin;
}

export interface CognitoState
  extends Omit<CognitoConfigExtended, 'setSnackbarAnchor'> {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLoading: boolean;
  user: CognitoUserEntity;
  userData: CognitoUser;
  userFullName: string;
  checkAccess: (group: string) => boolean;
}

export interface LastCheck {
  email: string;
  date: string;
}

import { useCallback, useEffect, useMemo, useState } from 'react';

import axios, { AxiosRequestConfig } from 'axios';

import {
  requestBlobStorageMalwareScan,
  RequestMalwareScanResult,
} from 'src/api/endpoints/blobStorage';
import { requestDocumentsMalwareScan } from 'src/api/endpoints/admin';
import { useDebouncedCallback } from 'src/utils/hooks';

import { MALWARE_SCAN_SOURCE, MALWARE_SCAN_STATUS } from './constants';

export interface UseMalwareScanResult {
  isCompleted: boolean;
  inProgress: boolean;
  filesWithMalware: string[];
  temporaryFilesWithMalware: string[];
  totalScannedFilesCount: number;
  addFilesToScanQueue: (files: string[]) => void;
  removeFileFromScanQueue: (file: string) => void;
  removeAllFilesFromScanQueue: () => void;
  cleanTemporaryFilesWithMalware: () => void;
}

export const useMalwareScan = (
  malwareScanSource: MALWARE_SCAN_SOURCE,
  cancelToken?: AxiosRequestConfig['cancelToken']
): UseMalwareScanResult => {
  const [inProgress, setInProgress] = useState(false);

  const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  const [temporaryFilesWithMalware, setTemporaryFilesWithMalware] = useState<
    string[]
  >([]);
  const [filesInQueue, setFilesInQueue] = useState<RequestMalwareScanResult>(
    {}
  );

  const cleanTemporaryFilesWithMalware = useCallback(() => {
    setTemporaryFilesWithMalware([]);
  }, []);

  const filesToScan = useMemo(() => {
    return Object.entries(filesInQueue)
      .filter(([_key, value]) => value === MALWARE_SCAN_STATUS.inProgress)
      .map(([key]) => key);
  }, [filesInQueue]);

  const [subscribeForMalwareScan] = useDebouncedCallback(
    async () => {
      if (filesToScan.length === 0) return;

      setInProgress(true);

      try {
        let scannedFiles: RequestMalwareScanResult = {};

        switch (malwareScanSource) {
          case MALWARE_SCAN_SOURCE.blobStorage: {
            scannedFiles = await requestBlobStorageMalwareScan(
              filesToScan,
              cancelToken
            );
            break;
          }

          case MALWARE_SCAN_SOURCE.documents: {
            scannedFiles = await requestDocumentsMalwareScan(
              filesToScan,
              cancelToken
            );
            break;
          }

          default: {
            throw new Error('Malware Scan Source was not provided!');
          }
        }

        setFilesInQueue((prevFiles) => ({ ...prevFiles, ...scannedFiles }));

        const scannedFilesWithMalware = Object.entries(scannedFiles)
          .filter(([_key, value]) => value === MALWARE_SCAN_STATUS.malwareFound)
          .map(([key]) => key);

        setTemporaryFilesWithMalware((prevFiles) => [
          ...prevFiles,
          ...scannedFilesWithMalware,
        ]);
      } catch (error) {
        if (axios.isCancel(error)) {
          removeAllFilesFromScanQueue();
          setInProgress(false);
        }
      }
    },
    // 10 ms just to delay call a little bit
    10,
    { leading: true }
  );

  const createInterval = useCallback(
    (timer: number) => {
      return setInterval(
        (function intervalCallback() {
          // check files status every 5 sec
          subscribeForMalwareScan();

          return intervalCallback;
        })(),
        timer
      );
    },
    [subscribeForMalwareScan]
  );

  const filesWithMalware = useMemo(() => {
    return Object.entries(filesInQueue)
      .filter(([_key, value]) => value === MALWARE_SCAN_STATUS.malwareFound)
      .map(([key]) => key);
  }, [filesInQueue]);

  const totalScannedFilesCount = useMemo(() => {
    return (
      Object.values(filesInQueue).filter(
        (value) => value !== MALWARE_SCAN_STATUS.inProgress
      ).length + temporaryFilesWithMalware.length
    );
  }, [filesInQueue, temporaryFilesWithMalware]);

  const isCompleted = useMemo(() => {
    return filesToScan.length === 0 && totalScannedFilesCount !== 0;
  }, [filesToScan, totalScannedFilesCount]);

  useEffect(() => {
    if (!intervalTimer && filesToScan.length > 0) {
      setIntervalTimer(createInterval(1000 * 5));
    } else if (intervalTimer && filesToScan.length === 0) {
      clearInterval(intervalTimer);
      setIntervalTimer(null);
    }
  }, [intervalTimer, createInterval, filesToScan]);

  useEffect(() => {
    return () => {
      if (intervalTimer) clearInterval(intervalTimer);
    };
  }, [intervalTimer]);

  useEffect(() => {
    if (isCompleted) {
      setInProgress(false);
    }
  }, [isCompleted]);

  const removeFileFromScanQueue = (file: string) => {
    const tmpFilesInQueue = { ...filesInQueue };

    delete tmpFilesInQueue[file];

    setFilesInQueue({ ...tmpFilesInQueue });
  };

  const removeAllFilesFromScanQueue = () => {
    setFilesInQueue({});
  };

  const addFilesToScanQueue = useCallback(
    (files: string[]) => {
      for (const file of files) {
        setFilesInQueue((prevFiles) => {
          if (prevFiles[file]) {
            return prevFiles;
          }

          cleanTemporaryFilesWithMalware(); // TODO: SHOULD BE RE-VIEWED

          return { ...prevFiles, [file]: MALWARE_SCAN_STATUS.inProgress };
        });
      }
    },
    [cleanTemporaryFilesWithMalware]
  );

  return {
    isCompleted,
    inProgress,
    filesWithMalware,
    temporaryFilesWithMalware,
    cleanTemporaryFilesWithMalware,
    totalScannedFilesCount,
    addFilesToScanQueue,
    removeFileFromScanQueue,
    removeAllFilesFromScanQueue,
  };
};

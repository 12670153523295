import { FC, useEffect, useMemo, useState } from 'react';

import { createPortal } from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { PASSWORD_EXPIRY_LAST_DAYS, useCognito } from 'src/aws/Cognito';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import ChangePasswordForm from 'src/components/ChangePasswordForm';
import ConfirmDialog from 'src/components/ConfirmDialog';

import { TEST_ID } from './constants';

const ChangePassword: FC = () => {
  const { formatMessage } = useIntl();

  const history = useHistory();
  const location = useLocation();

  const { daysTillPasswordExpiry, isPasswordExpired } = useCognito();

  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState(false);

  const dialogTitle = useMemo(() => {
    if (isPasswordExpired) {
      return formatMessage({
        id: 'page.change_password.dialog.title.expired_password',
      });
    } else {
      return formatMessage(
        { id: 'page.change_password.dialog.title.password_expiry_soon' },
        { days: daysTillPasswordExpiry }
      );
    }
  }, [daysTillPasswordExpiry, isPasswordExpired, formatMessage]);

  const handleClose = () => {
    setIsChangePasswordDialogOpen(false);
  };

  const handleCancel = () => {
    handleClose();
    history.push(PRIVATE_ROUTES_MAP.dashboard);
  };

  const handleAccept = () => {
    handleClose();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { from: fromLocation } = (location.state as any) || {};

    if (fromLocation && fromLocation !== PRIVATE_ROUTES_MAP.dashboard) {
      window.history.replaceState({ from: '' }, '');
      window.location.reload();
    } else if (!fromLocation) {
      setIsChangePasswordDialogOpen(
        daysTillPasswordExpiry <= PASSWORD_EXPIRY_LAST_DAYS
      );
    }
  }, [location.state, daysTillPasswordExpiry]);

  return (
    <div data-testid={TEST_ID.changePassword}>
      <Box mt={2} mb={3} textAlign="center">
        <Typography variant="subtitle1">
          <FormattedMessage id="page.change_password.title" />
        </Typography>
      </Box>
      <ChangePasswordForm />
      <div>
        {createPortal(
          <ConfirmDialog
            disableBackdrop
            open={isChangePasswordDialogOpen}
            title={dialogTitle}
            acceptButtonText={formatMessage({
              id: isPasswordExpired ? 'app.button.ok' : 'app.button.yes',
            })}
            cancelButtonText={formatMessage({ id: 'app.button.no' })}
            hideCancelButton={isPasswordExpired}
            onClose={handleClose}
            onCancel={handleCancel}
            onAccept={handleAccept}
          />,

          /* document.createElement('div') - is for testing purpose */
          document.getElementById('modal-root') || document.createElement('div')
        )}
      </div>
    </div>
  );
};

export default ChangePassword;

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 340,
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 2px 8px ${alpha(theme.palette.common.black, 0.08)}`,
    overflow: 'hidden',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      height: 300,
    },

    [theme.breakpoints.down('md')]: {
      height: 260,
    },

    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },

  cardActionArea: {
    width: '100%',
    height: '100%',

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  container: {
    height: '100%',
  },

  cardDetails: {
    width: '100%',
    height: '100%',
    padding: '50px 25px 42px',
  },

  cardTitle: {
    minWidth: 0,
    fontFamily: theme.fonts.fontFamily.medium,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  descriptionContainer: {
    display: 'flex',
    flex: 1,
    marginTop: 10,
  },

  bookmarksDescription: {
    marginTop: 10,
    maginBottom: 5,
  },

  commonDescription: {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,

    [theme.breakpoints.down('md')]: {
      WebkitLineClamp: 3,
    },

    [theme.breakpoints.down('sm')]: {
      WebkitLineClamp: 5,
    },
  },

  bookmarkIcon: {
    marginRight: 10,
    transform: 'translateY(-1px)',
  },

  cardActionContainer: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },

  cardAction: {
    width: '100%',
    maxWidth: 160,
    height: 35,
    marginRight: 10,
  },

  cardImage: {
    '& img': {
      height: '100%',
      maxWidth: '100%',
      display: 'block',
    },
  },

  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },

  image: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

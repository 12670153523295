import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { convertToPascalCase } from 'src/utils/helpers/common';

import { createContentHubRootPage } from '../requests/createContentHubRootPage';
import {
  ContentHubObjectSchema,
  EditableContentHubPageVariables,
} from '../typings';
import { PAGE_SLUG, QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: ContentHubObjectSchema[];
}

export type UseCreateContentHubRootPageResult = UseMutationResult<
  ContentHubObjectSchema,
  Error,
  EditableContentHubPageVariables,
  UseMutationContext
>;

export const useCreateContentHubRootPage =
  (): UseCreateContentHubRootPageResult => {
    const queryClient = useQueryClient();

    const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

    const queryKey = [QUERY_NAME.fetchContentHubPages];

    const mutation = useMutation<
      ContentHubObjectSchema,
      Error,
      EditableContentHubPageVariables,
      UseMutationContext
    >(createContentHubRootPage, {
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);

        const previousData =
          queryClient.getQueryData<ContentHubObjectSchema[]>(queryKey);

        return { previousData };
      },

      onSuccess: (data) => {
        queryClient.setQueryData<ContentHubObjectSchema[]>(
          queryKey,
          (previous = []) => {
            const publicAccessPage = previous.find(
              (page) => page.slug === PAGE_SLUG.publicAccess
            );

            const pagesWithoutPublicAccess = previous.filter(
              (page) => page.slug !== PAGE_SLUG.publicAccess
            );

            const result = [...pagesWithoutPublicAccess, data];

            if (publicAccessPage) {
              result.push(publicAccessPage);
            }

            return result;
          }
        );

        enqueueSuccessSnackbar({
          message: 'page.content_management.api.pages.create',
          intlValues: {
            type: convertToPascalCase(data.type),
            name: data.name,
          },
        });
      },

      onError: ({ message }, _variables, context) => {
        queryClient.setQueryData(queryKey, context?.previousData);

        enqueueErrorSnackbar({ message });
      },
    });

    return mutation;
  };

import { Text } from 'slate';
import { RenderElementProps, RenderLeafProps } from 'slate-react';
import { renderToStaticMarkup } from 'react-dom/server';

import { CustomElement, CustomText } from 'src/typings/slate';

import EditorLeaf from '../components/EditorLeaf';
import EditorElement from '../components/EditorElement';

export const serializeNode = (node: CustomElement | CustomText): string => {
  if (Text.isText(node)) {
    const attributes = {} as RenderLeafProps['attributes'];

    return renderToStaticMarkup(
      <EditorLeaf attributes={attributes} text={node} leaf={node}>
        {node.text}
      </EditorLeaf>
    );
  }

  const children = node.children.map((n) => serializeNode(n)).join('');
  const attributes = {} as RenderElementProps['attributes'];

  return renderToStaticMarkup(
    <EditorElement attributes={attributes} element={node}>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </EditorElement>
  );
};

export const serialize = (nodes: CustomElement[]) => {
  return nodes.map((node) => serializeNode(node)).join('\n');
};

import { PASSWORD_FIELD_NAME } from 'src/components/form/ControlledPasswordTextField';

export const defaultValues = {
  [PASSWORD_FIELD_NAME.newPassword]: '',
  [PASSWORD_FIELD_NAME.repeatPassword]: '',
};

export const TIME_LIMITATION_SECONDS = 180;

export const TEST_ID = {
  changeTemporaryPassword: 'changeTemporaryPassword',
};

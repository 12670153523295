/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, PropsWithChildren } from 'react';

import cx from 'classnames';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

import MuiCircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

interface Props {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  invalid?: boolean;
  isFocused?: boolean;
  inProgress?: boolean;
  isFileDialogActive?: boolean;
}

const Dropzone: FC<PropsWithChildren<Props>> = ({
  children,
  rootProps,
  inputProps,
  invalid = false,
  isFocused = false,
  inProgress = false,
  isFileDialogActive = false,
}) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.dropzone, {
        isFocused,
        isFileDialogActive,
        invalid,
      })}
      {...rootProps}
    >
      <input {...inputProps} id="dropzone" />
      {inProgress && <MuiCircularProgress size={30} thickness={3} />}
      {!inProgress && (
        // https://github.com/react-dropzone/react-dropzone/issues/182
        <label htmlFor="dropzone" onClick={(event) => event.stopPropagation()}>
          {children}
        </label>
      )}
    </div>
  );
};

export default Dropzone;

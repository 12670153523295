import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { deleteFAQSection } from '../requests/deleteFAQSection';
import { DeleteFAQSectionVariables, FAQSectionSchema } from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: FAQSectionSchema[];
}

export type UseDeleteFAQSectionResult = UseMutationResult<
  FAQSectionSchema,
  Error,
  DeleteFAQSectionVariables,
  UseMutationContext
>;

export const useDeleteFAQSection = (): UseDeleteFAQSectionResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = [QUERY_NAME.fetchFAQSections];

  const mutation = useMutation<
    FAQSectionSchema,
    Error,
    DeleteFAQSectionVariables,
    UseMutationContext
  >(deleteFAQSection, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<FAQSectionSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (_data, { id }, context) => {
      const deletedSection = context?.previousData?.find(
        (section) => section.id === id
      );

      queryClient.setQueryData<FAQSectionSchema[]>(queryKey, (previous = []) =>
        previous.filter((section) => section.id !== id)
      );

      if (deletedSection) {
        enqueueSuccessSnackbar({
          message: 'page.faq_management.api.section.delete',
        });
      }
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

import { useQuery, UseQueryResult } from 'react-query';

import { fetchContentHubPageBySlug } from '../requests/fetchContentHubPageBySlug';
import { ContentHubObjectSchema } from '../typings';
import { QUERY_NAME } from '../constants';

import {
  defaultOptions,
  UseFetchContentHubPageOptions,
} from './useFetchContentHubPage';

export const useFetchContentHubPageBySlug = (
  slug: string,
  options: UseFetchContentHubPageOptions = defaultOptions
): UseQueryResult<ContentHubObjectSchema> => {
  const result = useQuery<ContentHubObjectSchema>(
    [QUERY_NAME.fetchContentHubPage, slug],
    () => fetchContentHubPageBySlug({ slug }),
    options
  );

  return result;
};

import axios, { AxiosInstance } from 'axios';

import { transformErrorResponse } from './interceptors';

const publicGateway = (apiGateway: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: apiGateway,
  });

  axiosInstance.interceptors.response.use((res) => res, transformErrorResponse);

  return axiosInstance;
};

export { publicGateway };

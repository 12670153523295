import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { useFilterContext } from 'src/context/FilterContext';

import { fetchCompanyNames } from '../requests/fetchCompanyNames';
import { QUERY_NAME } from '../constants';
import { FetchCompanyNamesResult } from '../typings';

type UseFetchCompanyNamesResult = UseQueryResult<FetchCompanyNamesResult>;

type UseFetchCompanyNamesOptions = {
  name?: string;
};

export const useFetchCompanyNames = (
  options?: UseFetchCompanyNamesOptions
): UseFetchCompanyNamesResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const { filter } = useFilterContext();

  const result = useQuery<FetchCompanyNamesResult, Error>(
    [QUERY_NAME.companyNames, { filter }],
    () => fetchCompanyNames({ filter }),
    {
      onSuccess: async (companyNames) => {
        companyNames.data.forEach((companyName) => {
          queryClient.setQueryData(
            [QUERY_NAME.companyNames, companyName],
            companyName
          );
        });

        await queryClient.prefetchQuery(
          [QUERY_NAME.userRequests, { filter }],
          () => fetchCompanyNames({ filter })
        );
      },
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
      ...options,
    }
  );

  return result;
};

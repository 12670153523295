import { FC, useCallback, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import ControlledPasswordTextField, {
  PASSWORD_FIELD_NAME,
} from 'src/components/form/ControlledPasswordTextField';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { useSnackbar } from 'src/utils/hooks';

import { useStyles } from './styles';
import { defaultValues, TEST_ID, TIME_LIMITATION_SECONDS } from './constants';
import TimeLimitation from './components/TimeLimitation';

interface Props {
  timeLimitationSeconds?: number; // NOTE: for testing purpose only
}

const TemporaryPasswordForm: FC<Props> = ({
  timeLimitationSeconds = TIME_LIMITATION_SECONDS,
}) => {
  const classes = useStyles();

  const { enqueueSuccessSnackbar } = useSnackbar();

  const { user, isLoading, completeNewPassword, signOut } = useCognito();

  const formMethods = useForm({
    defaultValues,
  });

  const { handleSubmit } = formMethods;

  useEffect(() => {
    const isChangePasswordInProgress = localStorage.getItem(
      LOCAL_STORAGE_KEY.isChangePasswordInProgress
    );

    if (isChangePasswordInProgress !== 'true') {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.isChangePasswordInProgress,
        JSON.stringify(true)
      );
    } else {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteNewPassword = async ({
    newPassword,
  }: typeof defaultValues) => {
    try {
      await completeNewPassword({
        user,
        newPassword,
      });
      enqueueSuccessSnackbar({
        message: 'page.change_temporary_password.snackbar.success',
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleTimerEnds = useCallback(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <MuiBox data-testid={TEST_ID.changeTemporaryPassword}>
        <MuiBox mt={0} mb={5} textAlign="center">
          <Typography variant="subtitle1">
            <FormattedMessage id="page.change_temporary_password.title" />
          </Typography>
        </MuiBox>
        <MuiBox mb={2}>
          <Typography variant="body1">
            <FormattedMessage id="page.change_temporary_password.description" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="page.change_temporary_password.session_expiry" />
            <TimeLimitation
              seconds={timeLimitationSeconds}
              onTimerEnds={handleTimerEnds}
            />
          </Typography>
        </MuiBox>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(handleCompleteNewPassword)}
        >
          <MuiBox py={1}>
            <FormLabel component="legend">
              <FormattedMessage id="page.change_temporary_password.password.label" />
            </FormLabel>
            <FormControl fullWidth size="small">
              <ControlledPasswordTextField
                withPattern
                name={PASSWORD_FIELD_NAME.newPassword}
              />
            </FormControl>
          </MuiBox>
          <MuiBox py={1}>
            <FormLabel component="legend">
              <FormattedMessage id="page.change_temporary_password.repeat_password.label" />
            </FormLabel>
            <FormControl fullWidth size="small">
              <ControlledPasswordTextField
                name={PASSWORD_FIELD_NAME.repeatPassword}
                equalTo={PASSWORD_FIELD_NAME.newPassword}
              />
            </FormControl>
          </MuiBox>
          <MuiBox
            mt={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Button
              className={classes.button}
              type="submit"
              color="secondary"
              loading={isLoading}
              disabled={isLoading}
            >
              <FormattedMessage id="page.change_temporary_password.button.submit" />
            </Button>
          </MuiBox>
        </form>
      </MuiBox>
    </FormProvider>
  );
};

export default TemporaryPasswordForm;

export const QUANTERIX_WEBSITE = 'https://www.quanterix.com/';
export const QUANTERIX_LOCATION =
  '900 Middlesex Turnpike, Building 1, Billerica, MA, 01821 USA';

export const QUANTERIX_INFO_EMAIL = 'info@quanterix.com';

export const QUANTERIX_ACC_SERVICES_EMAIL = 'accclientservices@quanterix.com';

export const QUANTERIX_CUSTOMER_SERVICE_EMAIL = 'customerservice@quanterix.com';

export const QUANTERIX_TECH_EMAIL = 'techsupport@quanterix.com';
export const QUANTERIX_TECH_PHONE_NUMBER = '+1-978-435-2030';

export const SUPPORT_PHONE_NUMBER = '+1 (617) 301-9400';

export const QUANTERIX_FACEBOOK_PAGE = 'https://www.facebook.com/Quanterix/';
export const QUANTERIX_TWITTER_ACC = 'https://twitter.com/quanterixsimoa';
export const QUANTERIX_LINKEDIN_PAGE =
  'https://www.linkedin.com/company/quanterix/';
export const QUANTERIX_YOUTUBE_CHANNEL =
  'https://www.youtube.com/channel/UCwiKufTScQN_81Rxgnm2ZNg/featured';

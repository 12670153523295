import { FC, useCallback, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
  useConfirmUserRequest,
  useFetchUserRequest,
  useUpdateUserRequest,
} from 'src/api/endpoints/userRequests';
import { PROTECTED_ROUTES_MAP } from 'src/router';
import UserViewerProfile, {
  UserUpdateParams,
} from 'src/components/UserViewerProfile';
import UserViewerProfileContextProvider, {
  useUserViewerProfileContext,
} from 'src/components/UserViewerProfile/context';

const UserRequestProfile: FC = () => {
  const history = useHistory();

  const { requestId } = useParams<{ requestId: string }>();

  const { userData, setUserData } = useUserViewerProfileContext();

  const { data: userRequest, isFetched } = useFetchUserRequest(requestId);

  const { mutateAsync: updateUserRequest, isLoading: isUserUpdating } =
    useUpdateUserRequest(requestId);
  const { mutateAsync: confirmUserRequest, isLoading: isUserConfirming } =
    useConfirmUserRequest();

  const handleUserUpdate = useCallback(
    async ({ personalData, organizationData }: UserUpdateParams) => {
      const { region, ...restUserData } = userData;

      if (personalData) {
        const { firstname, lastname, ...restData } = personalData;

        return updateUserRequest({
          ...restUserData,
          ...restData,
          state: region,
          first_name: firstname,
          last_name: lastname,
        });
      }

      if (organizationData) {
        return updateUserRequest(organizationData);
      }

      return;
    },
    [userData, updateUserRequest]
  );

  const handleConfirmUserRequest = async () => {
    await confirmUserRequest(requestId);
    history.push(PROTECTED_ROUTES_MAP.adminUserRequests);
  };

  useEffect(() => {
    if (userRequest) {
      setUserData({
        firstname: userRequest.first_name,
        lastname: userRequest.last_name,
        email: userRequest.email,
        phone: userRequest.phone,
        company: userRequest.company,
        address1: userRequest.address1,
        address2: userRequest.address2,
        zip: userRequest.zip,
        region: userRequest.state,
        country: userRequest.country,
        city: userRequest.city,
        website: userRequest.website,
      });
    }
  }, [userRequest, setUserData]);

  return (
    <UserViewerProfile
      isLoading={isUserUpdating}
      isFetched={isFetched}
      isUserConfirming={isUserConfirming}
      onUserUpdate={handleUserUpdate}
      onUserConfirm={handleConfirmUserRequest}
    />
  );
};

const UserRequestProfileWithContext = () => (
  <UserViewerProfileContextProvider>
    <UserRequestProfile />
  </UserViewerProfileContextProvider>
);

export default UserRequestProfileWithContext;

import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

const Heading: FC = () => {
  return (
    <MuiBox pb={4}>
      <MuiTypography variant="h4">
        <FormattedMessage id="page.accelerator_form.heading.notification" />
      </MuiTypography>
    </MuiBox>
  );
};

export default Heading;

import { UserDataState } from './context';

export const combineAddress = (data: Partial<UserDataState>) => {
  return JSON.stringify({
    street_address: data.address1,
    street_address2: data.address2,
    locality: data.city,
    region: data.region,
    postal_code: data.zip,
    country: data.country,
  });
};

export const combineUserAttributesFromData = (data: Partial<UserDataState>) => {
  const userAttributes = [];

  userAttributes.push({ Name: 'given_name', Value: data.firstname });
  userAttributes.push({ Name: 'family_name', Value: data.lastname });
  userAttributes.push({ Name: 'website', Value: data.website });
  userAttributes.push({ Name: 'custom:company', Value: data.company });
  userAttributes.push({
    Name: 'phone_number',
    Value: data.phone?.indexOf('+') === 0 ? data.phone : `+${data.phone}`,
  });
  userAttributes.push({ Name: 'address', Value: combineAddress(data) });

  return userAttributes;
};

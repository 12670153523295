import { ReactNode, useCallback } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import * as Notistack from 'notistack';

import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';

import UploadProgressSnackbar from 'src/components/elements/UploadProgressSnackbar';
import { SNACKBAR } from 'src/utils/constants/app';

interface SnackbarParams {
  message: string;
  persist?: boolean;
  intlValues?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  action?: ReactNode;
  autoHideDuration?: number;
}

interface IntlSnackbarParams extends SnackbarParams {
  variant: Notistack.VariantType;
  autoHideDuration?: number;
  resumeHideDuration?: number;
  action?: ReactNode;
}

interface UploadProgressSnackbarParams {
  showUploadWarning?: boolean;
  onClose: () => void;
}

interface UseSnackbarResult {
  enqueueSnackbar: Notistack.ProviderContext['enqueueSnackbar'];
  enqueueIntlSnackbar: (params: IntlSnackbarParams) => Notistack.SnackbarKey;
  enqueueSuccessSnackbar: (params: SnackbarParams) => Notistack.SnackbarKey;
  enqueueErrorSnackbar: (params: SnackbarParams) => Notistack.SnackbarKey;
  enqueueUploadProgressSnackbar: (
    params: UploadProgressSnackbarParams
  ) => Notistack.SnackbarKey;
  closeSnackbar: Notistack.ProviderContext['closeSnackbar'];
}

export const useSnackbar = (
  anchorOrigin = SNACKBAR.defaultOptions.anchorOrigin
): UseSnackbarResult => {
  const { enqueueSnackbar, closeSnackbar } = Notistack.useSnackbar();

  const { formatMessage } = useIntl();

  const enqueueIntlSnackbar = useCallback(
    ({
      message,
      variant,
      autoHideDuration,
      resumeHideDuration,
      action,
      persist = false,
      intlValues = {},
    }: IntlSnackbarParams) => {
      return enqueueSnackbar(
        formatMessage(
          {
            id: message,
            defaultMessage: message,
          },
          intlValues
        ),
        {
          ...SNACKBAR.defaultOptions,
          variant,
          persist,
          autoHideDuration,
          resumeHideDuration,
          anchorOrigin,
          action: (key) => {
            if (action) {
              if (typeof action === 'string') {
                return (
                  <MuiButton
                    variant="text"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                  >
                    <FormattedMessage id={action} defaultMessage={action} />
                  </MuiButton>
                );
              } else {
                return (
                  <MuiIconButton
                    color="inherit"
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                  >
                    {action}
                  </MuiIconButton>
                );
              }
            }
          },
        }
      );
    },
    [enqueueSnackbar, formatMessage, anchorOrigin, closeSnackbar]
  );

  const enqueueSuccessSnackbar = useCallback(
    ({
      message,
      autoHideDuration,
      persist = false,
      intlValues = {},
    }: SnackbarParams) => {
      return enqueueIntlSnackbar({
        message,
        variant: 'success',
        autoHideDuration,
        resumeHideDuration: SNACKBAR.resumeHideDuration.success,
        persist,
        intlValues,
      });
    },
    [enqueueIntlSnackbar]
  );

  const enqueueErrorSnackbar = useCallback(
    ({
      message,
      autoHideDuration,
      action,
      persist = false,
      intlValues = {},
    }: SnackbarParams) => {
      return enqueueIntlSnackbar({
        message,
        variant: 'error',
        autoHideDuration,
        resumeHideDuration: SNACKBAR.resumeHideDuration.error,
        action,
        persist,
        intlValues,
      });
    },
    [enqueueIntlSnackbar]
  );

  const enqueueUploadProgressSnackbar = useCallback(
    ({ showUploadWarning, onClose }: UploadProgressSnackbarParams) => {
      return enqueueSnackbar(null, {
        ...SNACKBAR.defaultOptions,
        persist: true,
        content: (key) => (
          <UploadProgressSnackbar
            showUploadWarning={showUploadWarning}
            id={key}
            onClose={onClose}
          />
        ),
      });
    },
    [enqueueSnackbar]
  );

  return {
    enqueueSnackbar,
    enqueueSuccessSnackbar,
    enqueueIntlSnackbar,
    enqueueErrorSnackbar,
    enqueueUploadProgressSnackbar,
    closeSnackbar,
  };
};

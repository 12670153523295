import { FC, MouseEventHandler, useState } from 'react';

import cx from 'classnames';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiListItem from '@material-ui/core/ListItem';
import MuiCollapse from '@material-ui/core/Collapse';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiTypography from '@material-ui/core/Typography';
import MuiBadge from '@material-ui/core/Badge';
import MuiIconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  NOTIFICATION_STATUS,
  NotificationSchema,
  useUpdateNotification,
} from 'src/api/endpoints/notifications';

import { TEST_ID } from '../../constants';

import { useStyles } from './styles';

interface Props {
  notification: NotificationSchema;
}

const NotificationMenuItem: FC<Props> = ({ notification }) => {
  const classes = useStyles();

  const { mutate: updateNotification } = useUpdateNotification();

  const [showDescription, setShowDescription] = useState(false);

  const [optionsMenuAnc, setOptionsMenuAnc] = useState<HTMLElement | null>(
    null
  );

  const handleOpenOptions: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOptionsMenuAnc(event.currentTarget);
  };

  const handleToggleShowDescription = () => {
    const shouldShowDescription = !showDescription;

    setShowDescription(shouldShowDescription);

    if (shouldShowDescription) {
      updateNotification({
        notificationId: notification.id,
        params: { status: NOTIFICATION_STATUS.acknowledged },
      });
    }
  };

  const handleMarkNotificationAsRead = () => {
    setOptionsMenuAnc(null);
    updateNotification({
      notificationId: notification.id,
      params: { status: NOTIFICATION_STATUS.acknowledged },
    });
  };

  const handleClearNotification = () => {
    setOptionsMenuAnc(null);
    updateNotification({
      notificationId: notification.id,
      params: { status: NOTIFICATION_STATUS.deleted },
    });
  };

  const createdSecondsAgo =
    (new Date(notification.date).getTime() - new Date().getTime()) / 1000;

  const isNotificationUnread =
    notification.status === NOTIFICATION_STATUS.available;

  return (
    <MuiListItem
      className={cx(classes.menuItem, { unread: isNotificationUnread })}
    >
      <MuiGrid container direction="column" alignItems="flex-start">
        <MuiBox pb={1}>
          <MuiButtonBase onClick={handleToggleShowDescription}>
            <MuiBadge
              data-testid={TEST_ID.notificationAvailableBadge}
              variant="dot"
              color="primary"
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              className={classes.unreadBadge}
              classes={{ dot: classes.unreadDot }}
              invisible={!isNotificationUnread}
            />
            <MuiTypography
              align="left"
              variant="body2"
              className={cx(classes.title, {
                collapsedTitle: !showDescription,
              })}
            >
              {notification.title}
            </MuiTypography>
            {showDescription ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </MuiButtonBase>
        </MuiBox>
        <MuiCollapse unmountOnExit in={showDescription} timeout="auto">
          <MuiBox pb={1}>
            <MuiTypography className={classes.description}>
              {notification.description}
            </MuiTypography>
          </MuiBox>
        </MuiCollapse>
        <MuiTypography className={classes.date}>
          <FormattedRelativeTime
            updateIntervalInSeconds={1}
            value={createdSecondsAgo}
          />
        </MuiTypography>
      </MuiGrid>
      <MuiIconButton
        data-testid={TEST_ID.notificationOptionsButton}
        className={cx(classes.kebabButton, { show: Boolean(optionsMenuAnc) })}
        onClick={handleOpenOptions}
      >
        <MoreVertIcon />
      </MuiIconButton>
      <MuiMenu
        classes={{ paper: classes.optionsMenu }}
        anchorEl={optionsMenuAnc}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(optionsMenuAnc)}
        onClose={() => setOptionsMenuAnc(null)}
      >
        {isNotificationUnread && (
          <MuiMenuItem onClick={handleMarkNotificationAsRead}>
            <FormattedMessage id="app_header.notifications.mark_as_read" />
          </MuiMenuItem>
        )}
        <MuiMenuItem onClick={handleClearNotification}>
          <FormattedMessage id="app_header.notifications.clear" />
        </MuiMenuItem>
      </MuiMenu>
    </MuiListItem>
  );
};

export default NotificationMenuItem;

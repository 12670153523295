import { privateGateway } from '../../../config';
import { RequestFileUploadPlacementResult } from '../typings';

interface RequestFileUploadPlacementParams {
  file_name: string;
  source: string;
}

export const requestFileUploadPlacement = async ({
  file_name,
  source,
}: RequestFileUploadPlacementParams): Promise<RequestFileUploadPlacementResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post(`/files/upload`, { file_name, source });

  return response.data;
};

import { FC } from 'react';

import { useLocation } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';

import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { useFetchFAQSections } from 'src/api/endpoints/faq';

import DialogContextProvider from '../../context/Dialog';
import HelpBanner from '../HelpBanner';
import Section from '../Section';

const ContentBody: FC = () => {
  const location = useLocation();

  const {
    data: faqSections = [],
    isFetching,
    isFetched,
  } = useFetchFAQSections();

  const isFAQManagementPage = location.pathname.startsWith('/admin/');

  return (
    <ProgressOverlay
      withLoadingDisk
      loading={isFetching}
      opacity={1}
      paddingTop={8}
    >
      <MuiBox mb={2}>
        {faqSections.map((section) => (
          <div key={section.id}>
            <DialogContextProvider>
              <Section section={section} />
            </DialogContextProvider>
          </div>
        ))}
      </MuiBox>
      {!isFAQManagementPage && isFetched && <HelpBanner />}
    </ProgressOverlay>
  );
};

export default ContentBody;

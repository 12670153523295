import { privateGateway } from '../../../config';
import { BookmarksFilters } from '../typings';

export const fetchBookmarksFilters = async (): Promise<BookmarksFilters> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/bookmarks/filters`);

  return response.data;
};

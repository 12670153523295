import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { createFAQSectionItem } from '../requests/createFAQSectionItem';
import {
  CreateFAQSectionItemVariables,
  FAQSectionItemSchema,
} from '../typings';
import { QUERY_NAME } from '../constants';
import { getQueryKey } from '../utils';

interface UseMutationContext {
  previousData?: FAQSectionItemSchema[];
}

export type UseCreateFAQSectionItemResult = UseMutationResult<
  FAQSectionItemSchema,
  Error,
  CreateFAQSectionItemVariables,
  UseMutationContext
>;

export const useCreateFAQSectionItem = (): UseCreateFAQSectionItemResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const mutation = useMutation<
    FAQSectionItemSchema,
    Error,
    CreateFAQSectionItemVariables,
    UseMutationContext
  >(createFAQSectionItem, {
    onMutate: async ({ section_id }) => {
      await queryClient.cancelQueries(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      const previousData = queryClient.getQueryData<FAQSectionItemSchema[]>(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      return { previousData };
    },

    onSuccess: (_data, { section_id }, _context) => {
      queryClient.invalidateQueries(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      enqueueSuccessSnackbar({
        message: 'page.faq_management.api.section_item.create',
      });
    },

    onError: ({ message }, _variables, _context) => {
      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

const horizontalOffset = 30;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'sticky',
    bottom: 0,
    right: 0,
    display: 'flex',
    width: 'calc(100% + 60px)',
    height: 140,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginLeft: -horizontalOffset,
    marginRight: -horizontalOffset,
    paddingLeft: horizontalOffset,
    paddingRight: horizontalOffset,
    paddingBottom: 30,
    backgroundImage: `linear-gradient(to top, ${theme.palette.common.white} 50%, transparent 100%)`,
    zIndex: theme.zIndex.modal,
  },
}));

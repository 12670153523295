import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseJSON,
} from 'date-fns';
import { useHistory } from 'react-router-dom';

import ButtonBase from '@material-ui/core/ButtonBase';

import { useCognito } from 'src/aws/Cognito';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { IS_TEST_ENV } from 'src/utils/constants/env';

import { useStyles } from '../../styles';

import { RESEND_CODE_COUNTER, TEST_ID } from './constants';

const ResendValidationCode: FC = () => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { isLoading, forgotPassword } = useCognito();

  const {
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const history = useHistory();

  const [resendCounter, setResendCounter] = useState(() => {
    const lastResendDate = localStorage.getItem(LOCAL_STORAGE_KEY.lastResend);

    return lastResendDate
      ? RESEND_CODE_COUNTER -
          differenceInSeconds(new Date(), parseJSON(lastResendDate))
      : 0;
  });

  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [formattedTimeRemaining, setFormattedTimeRemaining] = useState('');
  const [resendTimerEnabled, setResendTimerEnabled] = useState(false);

  const formatTimeRemaining = useCallback(
    (endDate: string) => {
      let timeDifference;
      let timeRemaining;

      const date = new Date();

      const ticksRemaining = differenceInSeconds(parseJSON(endDate), date);
      if (ticksRemaining < 1) {
        clearErrors('confirmationCode');
        localStorage.removeItem('confirmationCode');
        setResendTimerEnabled(false);
        return '0 sec';
      }
      setSecondsRemaining(ticksRemaining);

      timeDifference = differenceInMinutes(parseJSON(endDate), date);

      if (timeDifference > 59) {
        timeRemaining = differenceInHours(parseJSON(endDate), date) + 1;
        timeRemaining = timeRemaining > 24 ? 24 : timeRemaining;
        const formattedHoursString = `${timeRemaining} hours`;
        return formattedHoursString;
      }

      timeDifference = differenceInSeconds(parseJSON(endDate), date);

      if (timeDifference > 60) {
        timeRemaining = `${
          differenceInMinutes(parseJSON(endDate), date) + 1
        } min`;
        return timeRemaining;
      }

      timeDifference = differenceInSeconds(parseJSON(endDate), date);

      const formattedSecondsString = `${timeDifference} sec`;

      return formattedSecondsString;
    },
    [setSecondsRemaining, clearErrors]
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (resendCounter > 0) {
      timeoutId = setTimeout(() => setResendCounter(resendCounter - 1), 1000);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY.lastResend);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [resendCounter]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state } = history.location as any;

    const errorDetails = JSON.parse(
      localStorage.getItem('confirmationCode') as string
    );
    const error = state?.error || errorDetails;

    if (error) {
      if ('email' in error && error.email !== state.email) return;
      const timeRemaining = formatTimeRemaining(error.end_date);

      if (timeRemaining === '0 sec') return;

      setFormattedTimeRemaining(timeRemaining);
      setResendTimerEnabled(true);

      setError('confirmationCode', {
        message: error.message,
      });
    }
  }, [history.location, setError, formatTimeRemaining]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state } = history.location as any;

    const errorDetails = JSON.parse(
      localStorage.getItem('confirmationCode') as string
    );
    const error = state?.error || errorDetails;
    let timeoutId: NodeJS.Timeout;

    if (error) {
      if ('email' in error && error.email !== state.email) return;

      timeoutId = setTimeout(() => {
        const timeRemaining = formatTimeRemaining(error.end_date);

        if (timeRemaining === '0 sec') return;

        setFormattedTimeRemaining(timeRemaining);
        setResendTimerEnabled(true);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    errors,
    history,
    setError,
    formatMessage,
    secondsRemaining,
    formatTimeRemaining,
  ]);

  const handleResendCode = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state } = history.location as any;

    try {
      const email = getValues('email');

      clearErrors('confirmationCode');

      await forgotPassword({ email });

      setResendCounter(RESEND_CODE_COUNTER);
      localStorage.setItem(
        LOCAL_STORAGE_KEY.lastResend,
        JSON.stringify(new Date())
      );
    } catch (error) {
      console.error(error.message);

      if (error.message.startsWith('password.error.')) {
        const errorDetails = {
          message: error.message,
          end_date: error.end_date,
          email: state.email,
        };
        localStorage.setItem('confirmationCode', JSON.stringify(errorDetails));

        const timeRemaining = formatTimeRemaining(error.end_date);
        if (timeRemaining) setFormattedTimeRemaining(timeRemaining);

        setResendTimerEnabled(true);
      }
    }
  };

  const isResendDisabled = resendCounter > 0;
  const shouldDisableResendButton = !IS_TEST_ENV
    ? isResendDisabled || resendTimerEnabled
    : false;
  const shouldTextBeShown = IS_TEST_ENV || shouldDisableResendButton;

  return (
    <>
      <FormattedMessage
        id="page.forgot_password.code.description"
        values={{
          link: (msg: ReactNode) => (
            <ButtonBase
              className={classes.link}
              classes={{ disabled: classes.linkDisabled }}
              disabled={isLoading || shouldDisableResendButton}
              data-testid={TEST_ID.resendButton}
              onClick={handleResendCode}
            >
              {msg}
            </ButtonBase>
          ),
        }}
      />
      {shouldTextBeShown && (
        <FormattedMessage
          id="page.forgot_password.code.resend_threshold"
          values={{
            timeRemaining: resendCounter
              ? `${resendCounter} sec.`
              : formattedTimeRemaining,
          }}
        />
      )}
    </>
  );
};

export default ResendValidationCode;

import { INITIAL_EDITOR_VALUE } from 'src/components/RichTextEditor';

export const TEST_ID = {};

export enum FORM_VALUES {
  title = 'title',
  description = 'description',
  image = 'image',
}

export enum STATIC_PAGES {
  legalDocuments = 'legal-documents',
}

export const DEFAULT_DESCRIPTION_VALUE = JSON.stringify(INITIAL_EDITOR_VALUE);

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  mainGridContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  pageTitle: {
    fontSize: 28,
    fontFamily: theme.fonts.fontFamily.bold,
  },

  sidebarLabel: {
    fontSize: 16,
    fontFamily: theme.fonts.fontFamily.medium,
    color: theme.palette.text.primary,
  },

  checkboxGroup: {
    marginLeft: 5,
  },

  searchInputContainer: {
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      maxWidth: 880,
    },
  },
}));

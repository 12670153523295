import { QueryBuilder } from 'odata-query-builder';

import { PaginationState } from 'src/context/PaginationContext';
import { SortingState } from 'src/context/SortingContext';

import { BookmarksFilters, FetchBookmarksResult } from '../typings';
import { privateGateway } from '../../../config';

interface FetchBookmarksParams {
  pagination?: PaginationState;
  sorting?: SortingState;
  filters?: BookmarksFilters;
}

export const fetchBookmarks = async ({
  pagination,
  sorting,
  filters,
}: FetchBookmarksParams = {}): Promise<FetchBookmarksResult> => {
  const query = new QueryBuilder();
  let filtersQuery = '';

  query.count();

  if (pagination) {
    query.top(pagination.rowsPerPage);
    query.skip(pagination.skip);
  }

  if (sorting && sorting.orderBy && sorting.order) {
    query.orderBy(`${sorting.orderBy} ${sorting.order}`);
  }

  if (filters?.extensions && filters?.extensions.length > 0) {
    filtersQuery = `${filtersQuery}&extensions=${filters?.extensions.join(
      ','
    )}`;
  }

  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/bookmarks${query.toQuery()}${filtersQuery}`);

  return response.data;
};

import { privateGateway } from '../../../config';
import { DeletePageFileVariables } from '../typings';

export const deleteContentHubPageFile = async (
  pageId: number,
  { id }: DeletePageFileVariables
): Promise<void> => {
  await privateGateway(process.env.REACT_APP_DOCUMENTS_API as string).delete(
    `/pages/${pageId}/files/${id}`
  );
};

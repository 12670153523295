import { privateGateway } from '../../../config';
import { CurrentUserSchema } from '../typings';

export const fetchCurrentUser = async (): Promise<CurrentUserSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get('/users/me');

  return response.data;
};

import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiTypography from '@material-ui/core/Typography';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogContent from '@quanterix-ui/core/DialogContent';
import DialogActions from '@quanterix-ui/core/DialogActions';
import Button from '@quanterix-ui/core/Button';
import ControlledTextField from '@quanterix-ui/core/ControlledTextField';

import { usePostAdminNotification } from 'src/api/endpoints/notifications';
import { PostAdminNotificationParams } from 'src/api/endpoints/notifications/typings';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface NotifyUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotifyUsersDialog: FC<NotifyUsersModalProps> = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const formMethods = useForm({
    defaultValues: {
      title: formatMessage({
        id: 'notify_users_modal.title.value',
      }),
      description: formatMessage({
        id: 'notify_users_modal.description.value',
      }),
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = formMethods;

  const { mutateAsync: postAdminNotification, isLoading } =
    usePostAdminNotification();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSendNotification = async ({
    title,
    description,
  }: PostAdminNotificationParams) => {
    try {
      await postAdminNotification({ title, description });

      handleClose();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      data-testid={TEST_ID.notifyUsersDialog}
      onClose={handleClose}
    >
      <MuiTypography variant="h3" className={classes.dialogTitle}>
        <FormattedMessage id="notify_users_modal.title" />
      </MuiTypography>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSendNotification)}>
          <DialogContent className={classes.dialogContent}>
            <MuiBox mb={4}>
              <MuiInputLabel
                required
                htmlFor="title-input"
                className={classes.label}
              >
                <FormattedMessage id="notify_users_modal.label.title" />
              </MuiInputLabel>
              <ControlledTextField
                fullWidth
                id="title-input"
                name="title"
                control={control}
                inputProps={{
                  maxLength: 300,
                }}
                rules={{
                  required: formatMessage({
                    id: 'notify_users_modal.title.required.value',
                  }),
                  pattern: {
                    value: /^[^|$]+$/,
                    message: formatMessage({
                      id: 'notify_users_modal.not_allowed_characters',
                    }),
                  },
                }}
                helperText={errors.title?.message}
              />
            </MuiBox>
            <MuiBox mb={2}>
              <MuiInputLabel
                required
                htmlFor="description-input"
                className={classes.label}
              >
                <FormattedMessage id="notify_users_modal.label.description" />
              </MuiInputLabel>
              <ControlledTextField
                fullWidth
                multiline
                id="description-input"
                name="description"
                minRows={5}
                maxRows={5}
                control={control}
                inputProps={{
                  maxLength: 300,
                }}
                rules={{
                  required: formatMessage({
                    id: 'notify_users_modal.description.required.value',
                  }),
                  maxLength: {
                    value: 300,
                    message: formatMessage({
                      id: 'notify_users_modal.description.length.value',
                    }),
                  },
                  pattern: {
                    value: /^[^|$]+$/,
                    message: formatMessage({
                      id: 'notify_users_modal.not_allowed_characters',
                    }),
                  },
                }}
                helperText={errors.description?.message}
              />
            </MuiBox>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={handleClose}
            >
              <FormattedMessage id="app.button.cancel" />
            </Button>
            <Button
              type="submit"
              disabled={!isDirty || isLoading}
              loading={isLoading}
            >
              <FormattedMessage id="app.button.send" />
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default NotifyUsersDialog;

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { useFilterContext } from 'src/context/FilterContext';
import { usePaginationContext } from 'src/context/PaginationContext';

import { deleteUserRequest } from '../requests/deleteUserRequest';
import { QUERY_NAME } from '../constants';
import { FetchUserRequestsResult } from '../typings';

type UseDeleteUserRequestResult = UseMutationResult<
  void,
  Error,
  string,
  UseDeleteUserRequestContext
>;

type UseDeleteUserRequestContext = {
  previousUserRequests: FetchUserRequestsResult | undefined;
};

export const useDeleteUserRequest = (): UseDeleteUserRequestResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const { filter } = useFilterContext();
  const { pagination } = usePaginationContext();

  const result = useMutation<void, Error, string, UseDeleteUserRequestContext>(
    deleteUserRequest,
    {
      onMutate: async (requestId: string) => {
        await queryClient.cancelQueries([
          QUERY_NAME.userRequests,
          { filter, pagination },
        ]);

        const previousUserRequests =
          queryClient.getQueryData<FetchUserRequestsResult>([
            QUERY_NAME.userRequests,
            { filter, pagination },
          ]);

        const nextPageUserRequests =
          queryClient.getQueryData<FetchUserRequestsResult>([
            QUERY_NAME.userRequests,
            {
              filter,
              pagination: {
                rowsPerPage: pagination.rowsPerPage,
                page: pagination.page + 1,
                skip: (pagination.page + 1) * pagination.rowsPerPage,
              },
            },
          ]);

        if (previousUserRequests) {
          queryClient.setQueryData<FetchUserRequestsResult>(
            [QUERY_NAME.userRequests, { filter, pagination }],
            () => {
              const newUserRequests = previousUserRequests.data.filter(
                (userRequest) => userRequest.id !== requestId
              );

              if (nextPageUserRequests?.data[0]) {
                newUserRequests.push(nextPageUserRequests.data[0]);
              }

              return {
                count: previousUserRequests.count - 1,
                data: newUserRequests,
              };
            }
          );
        }

        return { previousUserRequests };
      },
      onError: (error, _, context) => {
        if (context?.previousUserRequests) {
          queryClient.setQueryData<FetchUserRequestsResult>(
            [QUERY_NAME.userRequests, { filter, pagination }],
            context.previousUserRequests
          );
        }

        enqueueErrorSnackbar({ message: error.message });
      },
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_NAME.userRequests);
      },
    }
  );

  return result;
};

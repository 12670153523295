import { AxiosResponse } from 'axios';

import { RequestChangeTemporaryPasswordParams } from '../typings';
import { publicGateway } from '../../../config';

export const requestChangeTemporaryPassword = async ({
  email,
  newPassword,
  session,
}: RequestChangeTemporaryPasswordParams): Promise<AxiosResponse> => {
  const response = await publicGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).post(`/users/password/change/temporary`, {
    email,
    new_password: newPassword,
    session,
  });

  return response.data;
};

import { AxiosRequestConfig } from 'axios';

import { privateGateway } from '../../../config';
import { SubmitSupportFilesParams } from '../typings';

interface SubmitSupportFilesOptions {
  cancelToken?: AxiosRequestConfig['cancelToken'];
}

export const submitSupportFiles = async (
  params: SubmitSupportFilesParams,
  { cancelToken }: SubmitSupportFilesOptions = {}
): Promise<void> => {
  await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).post(
    `/users/support/filedrop/request`,
    {
      case_id: params.caseId,
      case_reference: params.caseReference,
      attachments: params.attachments,
      comment: params.caseDescription,
    },
    { cancelToken }
  );
};

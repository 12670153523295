import { FC } from 'react';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';

import SectionAddButton from '../SectionAddButton';

import { useStyles } from './styles';

const ContentHeader: FC = () => {
  const classes = useStyles();

  return (
    <MuiBox mt={1.5} className={classes.root}>
      <MuiGrid container justifyContent="flex-end" alignItems="center">
        <SectionAddButton />
      </MuiGrid>
    </MuiBox>
  );
};

export default ContentHeader;

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { ORDER } from 'src/utils/constants/table';

export interface SortingState {
  order: ORDER | null;
  orderBy: string | null;
}

export type SortingContextResult = {
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
};

export interface SortingProviderProps {
  defaultSorting?: SortingState;
}

export const DEFAULT_STATE: SortingState = {
  order: ORDER.asc,
  orderBy: 'created_at',
};

export const SortingContext = createContext<SortingContextResult>({
  sorting: DEFAULT_STATE,
  setSorting: () => {
    // void
  },
});

export const useSortingContext = (): SortingContextResult => {
  const context = useContext(SortingContext);

  if (!context) {
    throw new Error('useSortingContext must be used within a SortingProvider');
  }

  return context;
};

const SortingProvider: FC<PropsWithChildren<SortingProviderProps>> = ({
  children,
  defaultSorting = DEFAULT_STATE,
}) => {
  const [sorting, setSorting] = useState(defaultSorting);

  return (
    <SortingContext.Provider value={{ sorting, setSorting }}>
      {children}
    </SortingContext.Provider>
  );
};

export default SortingProvider;

import { useCallback, useRef, useState } from 'react';

import { BaseSelection, Editor } from 'slate';
import { isEqual } from 'lodash';

export const useSelection = (editor: Editor) => {
  const previousSelection = useRef<BaseSelection | null>(null);

  const [selection, setSelection] = useState<BaseSelection>(editor.selection);

  const handleSetSelection = useCallback(
    (newSelection: BaseSelection) => {
      // NOTE: don't update the component state if selection hasn't changed.
      if (isEqual(selection, newSelection)) {
        return;
      }

      previousSelection.current = selection;

      setSelection(newSelection);
    },
    [setSelection, selection]
  );

  return {
    selection,
    previousSelection: previousSelection.current,
    setSelection: handleSetSelection,
  };
};

import { privateGateway } from '../../../config';
import { uploadFile } from '../../blobStorage';
import { ContentHubFileSchema, UploadPageFileVariables } from '../typings';

export const uploadContentHubPageFile = async (
  pageId: number,
  { file, source }: UploadPageFileVariables
): Promise<ContentHubFileSchema> => {
  const { blobStorageKey } = await uploadFile({ file, source });

  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post(`/pages/${pageId}/files`, {
    filename: file.name,
    blob_storage_key: blobStorageKey,
  });

  return response.data;
};

import { FC, useState } from 'react';

import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import EditIcon from '@material-ui/icons/Edit';

import ProfileAvatar from 'src/components/elements/ProfileAvatar';
import UserViewerProfileNavigation from 'src/components/UserViewerProfileNavigation';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { PRIVATE_ROUTES_MAP } from 'src/router';

import { useStyles } from './styles';
import { TEST_ID } from './constants';
import { UserUpdateParams } from './typings';
import { useUserViewerProfileContext } from './context/UserViewerProfileContext';
import AttributeViewer from './components/AttributeViewer';
import PersonalDataEditor from './components/PersonalDataEditor';
import OrganizationDataEditor from './components/OrganizationDataEditor';

interface Props {
  isLoading: boolean;
  isFetched: boolean;
  status?: string;
  isUserEnabled?: boolean;
  isUserTogglingAvailability?: boolean;
  isUserConfirming?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUserUpdate: (data: UserUpdateParams) => Promise<any> | undefined;
  onUserConfirm?: () => void;
  onUserToggleAvailability?: () => Promise<void | undefined>;
}

const UserViewerProfile: FC<Props> = ({
  isLoading,
  isFetched,
  status,
  isUserEnabled,
  isUserTogglingAvailability,
  isUserConfirming,
  onUserUpdate,
  onUserConfirm,
  onUserToggleAvailability,
}) => {
  const classes = useStyles();

  const location = useLocation();

  const isProfilePages = location.pathname.startsWith(
    PRIVATE_ROUTES_MAP.profile
  );

  const { personalData, organizationData, profileCompletionPercentge } =
    useUserViewerProfileContext();

  const [isPersonalEditMode, setIsPersonalEditMode] = useState(false);
  const [isOrganizationEditMode, setIsOrganizationEditMode] = useState(false);

  const handleActivatePersonalEditMode = () => {
    setIsPersonalEditMode(true);
  };

  const handleActivateOrganizationEditMode = () => {
    setIsOrganizationEditMode(true);
  };

  const handleClosePersonalDataEditMode = () => {
    setIsPersonalEditMode(false);
  };

  const handleCloseOrganizationEditMode = () => {
    setIsOrganizationEditMode(false);
  };

  return (
    <ProgressOverlay withLoadingDisk loading={!isFetched} opacity={1} size={40}>
      <MuiBox data-testid={TEST_ID.userViewerProfile}>
        <UserViewerProfileNavigation
          status={status}
          isUserEnabled={isUserEnabled}
          isUserConfirming={isUserConfirming}
          isUserTogglingAvailability={isUserTogglingAvailability}
          onUserConfirm={onUserConfirm}
          onUserToggleAvailability={onUserToggleAvailability}
        />
        <MuiGrid container>
          <MuiGrid item xs={12} lg={6}>
            <MuiGrid container alignItems="center">
              <ProfileAvatar
                givenName={personalData.firstname.value}
                familyName={personalData.lastname.value}
                color="secondary"
                size="large"
              />
              <MuiBox pl={2}>
                <MuiTypography variant="subtitle1" className={classes.title}>
                  {personalData.firstname.value} {personalData.lastname.value}
                </MuiTypography>
              </MuiBox>
            </MuiGrid>
            <MuiBox className={classes.profileCompletion}>
              <MuiCircularProgress
                size={25}
                variant="determinate"
                value={profileCompletionPercentge}
                color={
                  profileCompletionPercentge === 100 ? 'primary' : 'secondary'
                }
                thickness={22}
              />
              <MuiTypography variant="body2">
                <FormattedMessage
                  id="page.profile.profile_completion"
                  values={{
                    keyWord: isProfilePages ? 'Your' : 'The',
                    value: profileCompletionPercentge,
                  }}
                />
              </MuiTypography>
            </MuiBox>
            <MuiGrid container justifyContent="space-between" spacing={4}>
              <MuiGrid item xs={12} lg={6}>
                <MuiBox mb={2}>
                  <MuiTypography variant="h3" className={classes.title}>
                    <FormattedMessage id="page.profile.personal_information" />
                    <MuiIconButton
                      color="inherit"
                      size="small"
                      className={cx({ [classes.hidden]: isPersonalEditMode })}
                      onClick={handleActivatePersonalEditMode}
                    >
                      <EditIcon fontSize="small" />
                    </MuiIconButton>
                  </MuiTypography>
                </MuiBox>
                {isPersonalEditMode && (
                  <PersonalDataEditor
                    isLoading={isLoading}
                    onUserUpdate={onUserUpdate}
                    onCloseEditMode={handleClosePersonalDataEditMode}
                  />
                )}
                {!isPersonalEditMode &&
                  Object.values(personalData).map(
                    (entry, index) =>
                      !entry.hideInList && (
                        <AttributeViewer
                          key={index}
                          labelId={entry.labelId}
                          value={entry.value}
                        />
                      )
                  )}
                <MuiButtonBase
                  className={cx(classes.editButton, {
                    [classes.hidden]: isPersonalEditMode,
                  })}
                  onClick={handleActivatePersonalEditMode}
                >
                  <MuiTypography variant="body2">
                    <FormattedMessage id="page.profile.edit_personal_data" />
                  </MuiTypography>
                </MuiButtonBase>
              </MuiGrid>
              <MuiGrid item xs={12} lg={6}>
                <MuiBox mb={2}>
                  <MuiTypography variant="h3" className={classes.title}>
                    <FormattedMessage id="page.profile.organization_information" />
                    <MuiIconButton
                      color="inherit"
                      size="small"
                      className={cx({
                        [classes.hidden]: isOrganizationEditMode,
                      })}
                      onClick={handleActivateOrganizationEditMode}
                    >
                      <EditIcon fontSize="small" />
                    </MuiIconButton>
                  </MuiTypography>
                </MuiBox>
                {isOrganizationEditMode && (
                  <OrganizationDataEditor
                    isLoading={isLoading}
                    onUserUpdate={onUserUpdate}
                    onCloseEditMode={handleCloseOrganizationEditMode}
                  />
                )}
                {!isOrganizationEditMode && (
                  <>
                    <AttributeViewer
                      labelId={organizationData.company.labelId}
                      value={organizationData.company.value}
                    />
                    <AttributeViewer
                      labelId="app.form.label.address"
                      value={
                        <FormattedMessage
                          id="page.profile.org_address"
                          values={{
                            address: organizationData.address1.value,
                            region: organizationData.region.value,
                            city: organizationData.city.value,
                            zipCode: organizationData.zip.value,
                            country: organizationData.country.value,
                            br: <br />,
                          }}
                        />
                      }
                    />
                    <AttributeViewer
                      labelId={organizationData.address2.labelId}
                      value={organizationData.address2.value}
                    />
                    <AttributeViewer
                      labelId={organizationData.website.labelId}
                      value={organizationData.website.value}
                    />
                    <MuiButtonBase
                      className={cx(classes.editButton, {
                        [classes.hidden]: isOrganizationEditMode,
                      })}
                      onClick={handleActivateOrganizationEditMode}
                    >
                      <MuiTypography variant="body2">
                        <FormattedMessage id="page.profile.edit_organization_data" />
                      </MuiTypography>
                    </MuiButtonBase>
                  </>
                )}
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
    </ProgressOverlay>
  );
};

export default UserViewerProfile;

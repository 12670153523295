import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import MuiCloseIcon from '@material-ui/icons/Close';
import MuiBookmarkIcon from '@material-ui/icons/Bookmark';

import TextField from '@quanterix-ui/core/TextField';

import PaginationProvider from 'src/context/PaginationContext';
import SortingProvider from 'src/context/SortingContext';
import { useNotFoundContext } from 'src/context/NotFoundContext';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import {
  useFetchContentHubPage,
  useFetchSuggestions,
  useGetFullPageName,
} from 'src/api/endpoints/contentHub';
import ContentTreeViewer from 'src/components/ContentTreeViewer';
import { useDebounce } from 'src/utils/hooks';
import Autosuggestion from 'src/components/Autosuggestion';
import IconButton from 'src/components/elements/IconButton';

import ContentSearchFilter from './components/ContentSearchFilter';
import BookmarksFilter from './components/BookmarksFilter';
import ContentFilter from './components/ContentFilter';
import ContentList, {
  DEFAULT_ORDER,
  DEFAULT_ORDER_BY,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from './components/ContentList';
import { useStyles } from './styles';
import SearchFilterProvider from './context/SearchFilterContext';
import ContentFilterProvider from './context/ContentFilterContext';
import BookmarksFilterProvider from './context/BookmarksFilterContext';
import { SEARCH_DEBOUNCE_INTERVAL, TEST_ID } from './constants';

const ContentHubViewer: FC = () => {
  const classes = useStyles();

  const searchInputRef = useRef<HTMLInputElement>();

  const { setPageExists } = useNotFoundContext();

  const history = useHistory();
  const location = useLocation<{ searchString?: string }>();
  const { pageId, pageName } = useParams<{
    pageId: string;
    pageName: string;
  }>();

  const allCategoriesPageMatch = useRouteMatch({
    path: PRIVATE_ROUTES_MAP.contentHubViewerAll,
    exact: true,
    strict: true,
  });
  const isAllCategoriesPage = Boolean(allCategoriesPageMatch);

  const bookmarksPageMatch = useRouteMatch({
    path: PRIVATE_ROUTES_MAP.contentHubViewerBookmarks,
    exact: true,
    strict: true,
  });
  const isBookmarksPage = Boolean(bookmarksPageMatch);

  const { formatMessage } = useIntl();

  const theme = useTheme();
  const isViewportLargeAndUpper = useMediaQuery(theme.breakpoints.up('xl'));

  const [searchString, setSearchString] = useState('');
  const [isAutosuggestionOpen, setIsAutosuggestionOpen] = useState(true);

  const [debouncedSearchString] = useDebounce(
    searchString,
    SEARCH_DEBOUNCE_INTERVAL,
    { leading: false }
  );

  const { data: suggestions = [], isFetching: isSuggestionsFetching } =
    useFetchSuggestions(debouncedSearchString, {
      enabled: debouncedSearchString.length >= 3,
    });

  const { data: page } = useFetchContentHubPage(Number(pageId), {
    enabled: !isBookmarksPage && Boolean(pageId),
    onError: () => {
      setPageExists(false);
    },
  });

  const { fullName, isLoading: isLoadingFullName } = useGetFullPageName(
    Number(pageId)
  );

  useEffect(() => {
    if ((!page && !isAllCategoriesPage) || page?.name !== pageName) {
      setPageExists(false);
    }
  }, [page, pageId, pageName, isAllCategoriesPage, setPageExists]);

  useEffect(() => {
    if (location.state?.searchString) {
      setSearchString(location.state.searchString);
      searchInputRef.current?.focus();
      history.replace({ state: undefined });
    }
  }, [location.state, history]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchString(value);

    if (document.activeElement === searchInputRef.current) {
      setIsAutosuggestionOpen(true);
    }
  };

  const handleSearchClear = () => {
    setSearchString('');
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchString(suggestion);
    setIsAutosuggestionOpen(false);

    searchInputRef.current?.focus();
  };

  const isSearchMode = searchString.length > 0;
  const isFullNameLoading = !isAllCategoriesPage && isLoadingFullName;
  const isFullNameReady = !isAllCategoriesPage && !isLoadingFullName;

  const spacing = isViewportLargeAndUpper ? 8 : 3;

  return (
    <SearchFilterProvider>
      <ContentFilterProvider>
        <BookmarksFilterProvider>
          <MuiBox data-testid={TEST_ID.contentHubViewer}>
            <MuiBox mb={4}>
              <MuiGrid
                container
                spacing={spacing}
                className={classes.mainGridContainer}
              >
                <MuiGrid item xs={12} md={3}>
                  <MuiTypography
                    className={classes.pageTitle}
                    color="secondary"
                  >
                    <FormattedMessage id="page.content_hub.title" />
                  </MuiTypography>
                </MuiGrid>
                {!isBookmarksPage && (
                  <MuiGrid item xs={12} md={9} lg={8}>
                    <MuiBox className={classes.searchInputContainer}>
                      <TextField
                        fullWidth
                        inputRef={searchInputRef}
                        value={searchString}
                        placeholder={formatMessage({
                          id: 'page.content_hub.search.input.placeholder',
                        })}
                        InputProps={{
                          endAdornment: isSearchMode && (
                            <MuiInputAdornment position="end">
                              <MuiTooltip title="Clear search query">
                                <div>
                                  <IconButton
                                    edge="end"
                                    loading={isSuggestionsFetching}
                                    onClick={handleSearchClear}
                                  >
                                    <MuiCloseIcon />
                                  </IconButton>
                                </div>
                              </MuiTooltip>
                            </MuiInputAdornment>
                          ),
                        }}
                        onChange={handleSearchChange}
                      />
                      <Autosuggestion
                        open={isAutosuggestionOpen}
                        inputRef={searchInputRef.current}
                        suggestions={suggestions}
                        onClick={handleSuggestionClick}
                      />
                    </MuiBox>
                  </MuiGrid>
                )}
              </MuiGrid>
            </MuiBox>
            <MuiBox mb={{ xs: 3, lg: 6 }}>
              {!isSearchMode && !isBookmarksPage && (
                <MuiTypography
                  className={classes.pageTitle}
                  color="secondary"
                  component="h2"
                >
                  {isFullNameLoading && (
                    <MuiCircularProgress
                      size={18}
                      thickness={4}
                      color="secondary"
                    />
                  )}
                  {isAllCategoriesPage && (
                    <FormattedMessage id="page.content_hub.title.all" />
                  )}
                  {isFullNameReady && fullName}
                </MuiTypography>
              )}
              {isSearchMode && !isBookmarksPage && (
                <MuiTypography variant="subtitle1" color="secondary">
                  <FormattedMessage
                    id="page.content_hub.title.search_results"
                    values={{ searchString }}
                  />
                </MuiTypography>
              )}
              {isBookmarksPage && (
                <MuiGrid container alignItems="center">
                  <MuiBox mt={0.5} mr={1}>
                    <MuiBookmarkIcon color="primary" />
                  </MuiBox>
                  <MuiTypography variant="subtitle1" color="secondary">
                    <FormattedMessage id="page.bookmarks.title" />
                  </MuiTypography>
                </MuiGrid>
              )}
            </MuiBox>
            <MuiGrid
              container
              spacing={spacing}
              className={classes.mainGridContainer}
            >
              <MuiGrid item xs={3}>
                {!isSearchMode && (
                  <>
                    <MuiTypography className={classes.sidebarLabel}>
                      <FormattedMessage id="page.content_hub.category" />
                    </MuiTypography>
                    <ContentTreeViewer
                      rootPath={PRIVATE_ROUTES_MAP.contentHub}
                      selectAllPath={PRIVATE_ROUTES_MAP.contentHubViewerAll}
                    />
                  </>
                )}
                {isSearchMode && <ContentSearchFilter />}
                {isBookmarksPage && <BookmarksFilter />}
                {!isBookmarksPage && !isSearchMode && <ContentFilter />}
              </MuiGrid>
              <MuiGrid item xs={9} lg={8}>
                <SortingProvider
                  defaultSorting={{
                    order: DEFAULT_ORDER,
                    orderBy: DEFAULT_ORDER_BY,
                  }}
                >
                  <PaginationProvider
                    defaultPagination={{
                      page: DEFAULT_PAGE,
                      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
                      skip: DEFAULT_PAGE * DEFAULT_ROWS_PER_PAGE,
                    }}
                  >
                    <ContentList searchString={debouncedSearchString} />
                  </PaginationProvider>
                </SortingProvider>
              </MuiGrid>
            </MuiGrid>
          </MuiBox>
        </BookmarksFilterProvider>
      </ContentFilterProvider>
    </SearchFilterProvider>
  );
};

export default ContentHubViewer;

import { QueryBuilder } from 'odata-query-builder';

import { FetchNotificationsResult } from '../typings';
import { privateGateway } from '../../../config';

export const fetchNotifications =
  async (): Promise<FetchNotificationsResult> => {
    const query = new QueryBuilder();

    query.count();

    const response = await privateGateway(
      process.env.REACT_APP_NOTIFICATIONS_API as string
    ).get(`/notifications${query.toQuery()}`);

    return response.data;
  };

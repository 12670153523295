import { FC, MouseEventHandler, useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiDivider from '@material-ui/core/Divider';

import { useCognito } from 'src/aws/Cognito';
import {
  PRIVATE_ROUTES_MAP,
  PROTECTED_ROUTES_MAP,
  PUBLIC_ROUTES_MAP,
} from 'src/router';
import QHubLogo from 'src/components/elements/QHubLogo';
import { useNotFoundContext } from 'src/context/NotFoundContext';
import ProfileAvatar from 'src/components/elements/ProfileAvatar';

import { TEST_ID } from './constants';
import { useStyles } from './styles';
import NotificationsDropdown from './components/NotificationsDropdown';
import SearchBar from './components/SearchBar';
import ServicesMenu from './components/ServicesMenu';

interface Props {
  isPublic: boolean;
}

const AppHeader: FC<Props> = ({ isPublic }) => {
  const classes = useStyles();

  const history = useHistory();

  const { user, isAdmin, isAuthenticated, signOut } = useCognito();

  const { given_name, family_name } = user?.attributes || {};

  const { pageExists } = useNotFoundContext();

  const [isAdminPage, setIsAdminPage] = useState(false);
  const [isUsersPage, setIsUsersPage] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setIsAdminPage(history.location.pathname.startsWith('/admin/'));
    setIsUsersPage(history.location.pathname.startsWith('/admin/users/'));
  }, [history.location.pathname]);

  const handleSignOut = () => {
    signOut({ withDefaultReason: true });
    setAnchorEl(null);
  };

  const handleGoToProfile = () => {
    history.push(PRIVATE_ROUTES_MAP.profile);
    handleClose();
  };

  const handleGoToHome = () => {
    history.push(PRIVATE_ROUTES_MAP.dashboard);
    handleClose();
  };

  const handleGoToAdminUsers = () => {
    history.push('/admin/users/registered');
    handleClose();
  };

  const handleGoToContentManagement = () => {
    history.push(PROTECTED_ROUTES_MAP.adminContentManagement);
    handleClose();
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSelected = (adminType: string) => {
    return history.location.pathname.startsWith('/admin/users/' + adminType);
  };

  const usersClass = isSelected('registered') ? 'selected' : 'regular';
  const requestsClass = isSelected('requests') ? 'selected' : 'regular';

  const logoNavigateTo = isAuthenticated
    ? PRIVATE_ROUTES_MAP.dashboard
    : PUBLIC_ROUTES_MAP.login;

  return (
    <MuiGrid
      container
      className={classes.appHeader}
      justifyContent="space-between"
      alignItems="center"
      data-testid={TEST_ID.appHeader}
    >
      <MuiGrid item xs={5} md={4}>
        <MuiGrid container alignItems="center">
          <MuiBox>
            <Link
              className={classes.logoLink}
              to={logoNavigateTo}
              data-testid={TEST_ID.appHeaderLogo}
            >
              <QHubLogo className={classes.headerLogo} />
              {isAdmin && pageExists && (
                <span className={classes.adminHeader}>| Admin</span>
              )}
            </Link>
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
      {!isPublic && (
        <MuiGrid item xs={2} md={4}>
          <MuiGrid container justifyContent="center">
            {isAdmin && isAdminPage && isUsersPage && pageExists && (
              <div className={classes.appHeaderRoutes}>
                <Link
                  to={PROTECTED_ROUTES_MAP.adminRegisteredUsers}
                  className={usersClass}
                >
                  <FormattedMessage id="page.users.registered_users.link" />
                </Link>
                <Link
                  to={PROTECTED_ROUTES_MAP.adminUserRequests}
                  className={requestsClass}
                >
                  <FormattedMessage id="page.users.user_requests.link" />
                </Link>
              </div>
            )}
          </MuiGrid>
        </MuiGrid>
      )}
      {!isPublic && (
        <MuiGrid item xs={5} md={4}>
          <MuiGrid container justifyContent="flex-end" alignItems="center">
            <MuiBox mr={1}>
              <SearchBar />
            </MuiBox>
            <ServicesMenu />
            {isAuthenticated && (
              <MuiBox>
                <NotificationsDropdown />
              </MuiBox>
            )}
            {isAuthenticated && (
              <MuiBox>
                <MuiIconButton
                  color="inherit"
                  edge="end"
                  data-testid={TEST_ID.appHeaderProfileAvatar}
                  onClick={handleClick}
                >
                  <ProfileAvatar
                    givenName={given_name}
                    familyName={family_name}
                  />
                </MuiIconButton>
                <MuiMenu
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(anchorEl)}
                  classes={{ paper: classes.profileMenuPaper }}
                  onClose={handleClose}
                >
                  <MuiMenuItem onClick={handleGoToProfile}>
                    <FormattedMessage id="app_header.my_profile" />
                  </MuiMenuItem>
                  <MuiMenuItem onClick={handleGoToHome}>
                    <FormattedMessage id="app_header.home" />
                  </MuiMenuItem>
                  {isAdmin && (
                    <MuiMenuItem onClick={handleGoToContentManagement}>
                      <FormattedMessage id="app_header.content_management" />
                    </MuiMenuItem>
                  )}
                  {isAdmin && (
                    <MuiMenuItem onClick={handleGoToAdminUsers}>
                      <FormattedMessage id="app_header.user_management" />
                    </MuiMenuItem>
                  )}
                  <MuiDivider />
                  <MuiMenuItem onClick={handleSignOut}>
                    <FormattedMessage id="app_header.log_out" />
                  </MuiMenuItem>
                </MuiMenu>
              </MuiBox>
            )}
          </MuiGrid>
        </MuiGrid>
      )}
    </MuiGrid>
  );
};

export default AppHeader;

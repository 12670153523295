import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    minHeight: 80,
    borderRadius: theme.constants.borderRadius,
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    marginBottom: 15,
    padding: '0 15px',
    overflow: 'hidden',

    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },

  buttonBase: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  avatar: {
    width: 26,
    height: 26,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    marginLeft: 10,
  },
}));

import { FC, useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import {
  useFetchUserRequest,
  useUpdateUserRequest,
} from 'src/api/endpoints/userRequests';
import { INSTRUMENT_NAME } from 'src/api';
import UserViewerProfileInstruments, {
  InstrumentField,
} from 'src/components/UserViewerProfileInstruments';
import UserViewerProfileInstrumentsContextProvider, {
  DEFAULT_STATE as DEFAULT_INSTRUMENTS,
  useUserViewerProfileInstrumentsContext,
} from 'src/components/UserViewerProfileInstruments/context';

const UserRequestProfileInstruments: FC = () => {
  const { setInstruments } = useUserViewerProfileInstrumentsContext();

  const { requestId } = useParams<{ requestId: string }>();

  const { data: userRequest, isFetched } = useFetchUserRequest(requestId);

  const { mutateAsync: updateUserRequest, isLoading: isUserUpdating } =
    useUpdateUserRequest(requestId);

  const instruments = useMemo(() => {
    if (!userRequest) {
      return DEFAULT_INSTRUMENTS;
    }

    const inst = userRequest.instruments.reduce<InstrumentField>(
      (instruments, instrument) => {
        let key = '';

        if (instrument.name === INSTRUMENT_NAME.acceleratorServices) {
          key = 'acceleratorServices';
        } else {
          key = instrument.name.toLowerCase().replace('-', '');
        }

        return {
          ...instruments,
          [key]: {
            name: instrument.name,
            serialNumber: instrument.serial_number,
            selected: true,
          },
        };
      },
      DEFAULT_INSTRUMENTS
    );

    return inst;
  }, [userRequest]);

  const handleUpdateUserInstruments = useCallback(
    async (instruments: InstrumentField, onSuccessCallback: () => void) => {
      const instrumentAttributes = Object.entries(instruments)
        .filter(([_key, instrument]) => instrument.name && instrument.selected)
        .map(([_key, instrument]) => ({
          name: instrument.name as INSTRUMENT_NAME,
          serial_number: instrument.serialNumber,
        }));

      await updateUserRequest({ instruments: instrumentAttributes });

      onSuccessCallback();
    },
    [updateUserRequest]
  );

  useEffect(() => {
    if (instruments) {
      setInstruments(instruments);
    }
  }, [instruments, setInstruments]);

  return (
    <UserViewerProfileInstruments
      isLoading={isUserUpdating}
      isFetched={isFetched}
      onUpdateUserInstruments={handleUpdateUserInstruments}
    />
  );
};

const UserRequestProfileInstrumentsWithContext = () => (
  <UserViewerProfileInstrumentsContextProvider>
    <UserRequestProfileInstruments />
  </UserViewerProfileInstrumentsContextProvider>
);

export default UserRequestProfileInstrumentsWithContext;

import { privateGateway } from '../../../config';
import { ContentHubObjectSchema } from '../typings';

interface FetchContentHubPageParams {
  slug: string;
}

export const fetchContentHubPageBySlug = async ({
  slug,
}: FetchContentHubPageParams): Promise<ContentHubObjectSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/pages/slug/${slug}`);

  return response.data;
};

import { FC, MouseEventHandler } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import { PASSWORD_FIELD_NAME } from 'src/components/form/ControlledPasswordTextField';

import CurrentPassword from './components/CurrentPassword';
import NewPassword from './components/NewPassword';
import RepeatPassword from './components/RepeatPassword';

const defaultValues = {
  [PASSWORD_FIELD_NAME.currentPassword]: '',
  [PASSWORD_FIELD_NAME.newPassword]: '',
  [PASSWORD_FIELD_NAME.repeatPassword]: '',
};

const ChangePasswordForm: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const {
    isLoading,
    isLogoutRunning,
    changePassword,
    signOut,
    isPasswordExpired,
  } = useCognito();

  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;

  const isActionsDisabled = isLogoutRunning || isLoading;

  const isChangePasswordPage =
    location.pathname === PRIVATE_ROUTES_MAP.changePassword;

  const redirectTo = isChangePasswordPage
    ? PRIVATE_ROUTES_MAP.dashboard
    : PRIVATE_ROUTES_MAP.profile;

  const handleFormSubmit = ({
    currentPassword,
    newPassword,
  }: typeof defaultValues) => {
    changePassword({
      currentPassword,
      newPassword,
      redirectTo,
    });
  };

  const handleCancelClick: MouseEventHandler = () => {
    if (isPasswordExpired) {
      signOut();
    } else {
      history.push(redirectTo);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container direction="column">
          <CurrentPassword />
          <NewPassword />
          <RepeatPassword />
          <Grid container justifyContent="space-between">
            <Button
              variant="outlined"
              loading={isLogoutRunning}
              disabled={isActionsDisabled}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={isLoading}
              disabled={isActionsDisabled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ChangePasswordForm;

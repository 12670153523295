import { useMemo } from 'react';

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { QUERY_NAME } from '../constants';
import { ContentHubFileSchema, UploadPageFileVariables } from '../typings';
import { uploadContentHubPageFile } from '../requests/uploadContentHubPageFile';

interface UseMutationContext {
  previousData?: ContentHubFileSchema[];
}

export type UseUploadContentHubPageFileResult = UseMutationResult<
  ContentHubFileSchema,
  Error,
  UploadPageFileVariables,
  UseMutationContext
>;

export const useUploadContentHubPageFile = (
  pageId: number
): UseUploadContentHubPageFileResult => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    return pageId
      ? [QUERY_NAME.fetchContentHubPageFiles, pageId]
      : [QUERY_NAME.fetchContentHubPageFiles];
  }, [pageId]);

  const mutation = useMutation<
    ContentHubFileSchema,
    Error,
    UploadPageFileVariables,
    UseMutationContext
  >((variables) => uploadContentHubPageFile(pageId, variables), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<ContentHubFileSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (data) => {
      queryClient.setQueryData<ContentHubFileSchema[]>(
        queryKey,
        (previous = []) => [...previous, data]
      );
      queryClient.invalidateQueries([QUERY_NAME.filesCount]);
    },

    onError: (_error, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);
    },
  });

  return mutation;
};

export enum MALWARE_SCAN_STATUS {
  inProgress = 'in_progress',
  malwareFound = 'malware_found',
  ok = 'ok',
}

export enum MALWARE_SCAN_SOURCE {
  blobStorage = 'blobStorage',
  documents = 'documents',
}

import { useQuery } from 'react-query';

import { fetchBookmarksCount } from '../requests/fetchBookmarksCount';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseQueryOptions {
  enabled?: boolean;
}

export const useFetchBookmarksCount = (
  options: UseQueryOptions = defaultOptions
) => {
  const result = useQuery<number>(
    [QUERY_NAME.fetchBookmarksCount],
    fetchBookmarksCount,
    options
  );

  return result;
};

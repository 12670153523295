export const PROTECTED_ROUTES_MAP = {
  adminContentManagement: '/admin/content-management',
  adminContentManagementPageView: '/admin/content-management/:pageId/:pageName',

  adminFAQManagement: '/admin/faq',

  adminUserRequests: '/admin/users/requests',
  adminUserRequestProfile: '/admin/users/requests/:requestId',
  adminUserRequestInstruments: '/admin/users/requests/:requestId/instruments',

  adminUsersNew: '/admin/users/new',
  adminUsersConfirm: '/admin/users/:username/confirm',
  adminRegisteredUsers: '/admin/users/registered',
  adminRegisteredUserProfile: '/admin/users/registered/:username',
  adminRegisteredUserInstruments:
    '/admin/users/registered/:username/instruments',
};

import { FC } from 'react';

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {
  useFetchContentHubPages,
  useFetchFilesCount,
} from 'src/api/endpoints/contentHub';
import { useFetchBookmarksCount } from 'src/api/endpoints/bookmarks';
import { PRIVATE_ROUTES_MAP, PROTECTED_ROUTES_MAP } from 'src/router';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';

import TreeViewerItem from './components/TreeViewerItem';
import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props {
  rootPath: string;
  selectAllPath?: string;
  showHidden?: boolean;
}

const ContentTreeViewer: FC<Props> = ({
  rootPath,
  selectAllPath,
  showHidden = false,
}) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const isContentManagement = location.pathname.startsWith(
    PROTECTED_ROUTES_MAP.adminContentManagement
  );

  const selectAllMatch = useRouteMatch({
    path: selectAllPath ?? rootPath,
    strict: true,
    exact: true,
  });

  const bookmarksPageMatch = useRouteMatch({
    path: PRIVATE_ROUTES_MAP.contentHubViewerBookmarks,
    exact: true,
    strict: true,
  });
  const isBookmarksPage = Boolean(bookmarksPageMatch);

  const { data: contentHubPages, isLoading } = useFetchContentHubPages({
    showHidden,
  });
  const { data: { count: filesCount = 0 } = {} } = useFetchFilesCount();

  const { data: bookmarksCount = 0 } = useFetchBookmarksCount({
    enabled: !isContentManagement,
  });

  const handleAllClick = () => {
    if (location.pathname === rootPath || !selectAllPath) return;

    history.push(selectAllPath || rootPath);
  };

  const handleBookmarksClick = () => {
    if (location.pathname === rootPath) return;

    history.push(PRIVATE_ROUTES_MAP.contentHubViewerBookmarks);
  };

  return (
    <ProgressOverlay withLoadingDisk loading={isLoading} opacity={1}>
      <MuiBox data-testid={TEST_ID.contentTreeViewer}>
        <List>
          <ListItem
            button
            className={cx(classes.listItem, { isActive: selectAllMatch })}
            data-testid={TEST_ID.allButton}
            onClick={handleAllClick}
          >
            <ListItemText className={classes.listItemText}>
              {isContentManagement ? (
                <FormattedMessage
                  id="page.content_management.button.all"
                  values={{ filesCount }}
                />
              ) : (
                <FormattedMessage id="app.button.all" />
              )}
            </ListItemText>
          </ListItem>
          {!isContentManagement && (
            <ListItem
              button
              className={cx(classes.listItem, { isActive: isBookmarksPage })}
              data-testid={TEST_ID.bookmarksButton}
              onClick={handleBookmarksClick}
            >
              <ListItemText className={classes.listItemText}>
                <FormattedMessage
                  id="page.bookmarks.tree_view_button"
                  values={{ bookmarksCount }}
                />
              </ListItemText>
            </ListItem>
          )}
          {contentHubPages.map((page) => (
            <TreeViewerItem
              key={page.id}
              item={page}
              rootPath={rootPath}
              localRoutePath={`/${page.name}`}
            />
          ))}
        </List>
      </MuiBox>
    </ProgressOverlay>
  );
};

export default ContentTreeViewer;

import { ChangeEvent, FC, KeyboardEvent, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import MuiInputBase from '@material-ui/core/InputBase';
import MuiSearchIcon from '@material-ui/icons/Search';

import { PRIVATE_ROUTES_MAP } from 'src/router';
import { useFetchSuggestions } from 'src/api/endpoints/contentHub';
import { useDebounce } from 'src/utils/hooks';
import Autosuggestion from 'src/components/Autosuggestion';
import IconButton from 'src/components/elements/IconButton';

import { useStyles } from './styles';
import { TEST_ID } from './constants';

const SearchBar: FC = () => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement>();

  const history = useHistory();

  const { formatMessage } = useIntl();

  const [searchString, setSearchString] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isAutosuggestionOpen, setIsAutosuggestionOpen] = useState(true);

  const [debouncedSearchString] = useDebounce(searchString, 200, {
    leading: false,
  });

  const { data: suggestions = [], isFetching: isSuggestionsFetching } =
    useFetchSuggestions(debouncedSearchString, {
      enabled: debouncedSearchString.length >= 3,
    });

  const handleSearchClick = () => {
    if (isSearchOpen) {
      if (searchString.length > 0) {
        setSearchString('');
        setIsSearchOpen(false);

        history.push(PRIVATE_ROUTES_MAP.contentHubViewerAll, { searchString });
      } else {
        setIsSearchOpen(false);
      }
    } else {
      setIsSearchOpen(true);
      inputRef.current?.focus();
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchString(value);

    if (document.activeElement === inputRef.current) {
      setIsAutosuggestionOpen(true);
    }
  };

  const handleSearchKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchString(suggestion);
    setIsAutosuggestionOpen(false);

    inputRef.current?.focus();
  };

  const isActive = isSearchOpen || searchString.length > 0;

  return (
    <MuiBox
      className={cx(classes.search, { isActive })}
      data-testid={TEST_ID.searchBar}
    >
      <MuiInputBase
        inputRef={inputRef}
        placeholder={formatMessage({
          id: 'app_header.search.input.placeholder',
        })}
        classes={{
          root: classes.inputRoot,
          input: cx(classes.inputInput, { isActive }),
        }}
        inputProps={{
          'data-testid': TEST_ID.searchInput,
        }}
        value={searchString}
        onChange={handleSearchChange}
        onKeyUp={handleSearchKeyUp}
      />
      <IconButton
        color="inherit"
        className={classes.searchButton}
        data-testid={TEST_ID.searchIcon}
        tabIndex={-1}
        loading={isSuggestionsFetching}
        onClick={handleSearchClick}
      >
        <MuiSearchIcon />
      </IconButton>
      <Autosuggestion
        open={isSearchOpen && isAutosuggestionOpen}
        inputRef={inputRef.current}
        suggestions={suggestions}
        onClick={handleSuggestionClick}
      />
    </MuiBox>
  );
};

export default SearchBar;

import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import MuiCloseIcon from '@material-ui/icons/Close';

import TextField, { TextFieldProps } from '@quanterix-ui/core/TextField';

import { useFetchCompanyNames } from 'src/api/endpoints/userRequests/hooks/useFetchCompanyNames';
import IconButton from 'src/components/elements/IconButton';
import Autosuggestion from 'src/components/Autosuggestion';

import { useStyles as useCommonStyles } from '../styles';

import { COMPANY_MAX_LENGTH, COMPANY_MIN_LENGTH } from './constants';

interface Props extends TextFieldProps {
  name: string;
  label?: string;
}

const ControlledCompanyTextField: FC<Props> = ({
  id,
  name,
  label,
  required = true,
}) => {
  const commonClasses = useCommonStyles();

  const { formatMessage } = useIntl();

  const { watch, setValue } = useFormContext();
  const watchedValue = watch(name);

  const inputRef = useRef<HTMLInputElement>();

  const { data: fetchedCompanyNames } = useFetchCompanyNames();

  const [isAutosuggestionOpen, setIsAutosuggestionOpen] = useState(false);

  const suggestions = useMemo(() => {
    if (watchedValue.length >= COMPANY_MIN_LENGTH) {
      return (
        fetchedCompanyNames?.data
          .filter((value) =>
            value.toLowerCase().startsWith(watchedValue.toLowerCase())
          )
          .map((name) => ({
            short: name.slice(watchedValue.length),
            full: name,
          })) || []
      );
    }

    return [];
  }, [watchedValue, fetchedCompanyNames]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newValue = value.length <= COMPANY_MAX_LENGTH ? value : watchedValue;

    setValue(name, newValue);

    if (document.activeElement === inputRef.current) {
      setIsAutosuggestionOpen(true);
    }
  };

  const handleClear = () => {
    setValue(name, '');
  };

  const handleSuggestionClick = (suggestion: string) => {
    setValue(name, suggestion, { shouldValidate: true });
    setIsAutosuggestionOpen(false);

    inputRef.current?.focus();
  };

  const isEmpty = watchedValue.length === 0;

  return (
    <MuiBox position="relative">
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.company" />}
      </MuiFormLabel>
      <Controller
        name={name}
        rules={{ required }}
        render={({ field: { value }, fieldState: { error } }) => {
          return (
            <TextField
              fullWidth
              autoComplete="off"
              id={name}
              inputRef={inputRef}
              value={value}
              placeholder={formatMessage({
                id: 'app.form.placeholder.company',
              })}
              inputProps={{
                form: {
                  autocomplete: 'off',
                },
              }}
              InputProps={{
                error: !!error,
                endAdornment: !isEmpty && (
                  <MuiInputAdornment position="end">
                    <MuiTooltip title="Clear">
                      <div>
                        <IconButton edge="end" onClick={handleClear}>
                          <MuiCloseIcon />
                        </IconButton>
                      </div>
                    </MuiTooltip>
                  </MuiInputAdornment>
                ),
              }}
              onChange={handleChange}
            />
          );
        }}
      />
      <Autosuggestion
        open={isAutosuggestionOpen}
        inputRef={inputRef.current}
        suggestions={suggestions}
        onClick={handleSuggestionClick}
      />
    </MuiBox>
  );
};

export default ControlledCompanyTextField;

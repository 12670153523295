import { UserStatuses } from 'src/utils/Constants';

import {
  FilterSchemaForRegisteredUsers,
  FilterSchemaForUserRequests,
} from './typings';

export const FILTER_FIELDS_FOR_USER_REQUESTS: FilterSchemaForUserRequests = {
  email: {
    name: 'email',
    label: 'E-mail',
    operator: '^=',
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    operator: '^=',
  },
  first_name: {
    name: 'first_name',
    label: 'First Name',
    operator: '^=',
  },
  last_name: {
    name: 'last_name',
    label: 'Last Name',
    operator: '^=',
  },
};

export const FILTER_FIELDS_FOR_REGISTERED_USERS: FilterSchemaForRegisteredUsers =
  {
    email: {
      name: 'email',
      label: 'E-mail',
      operator: '^=',
    },
    phone_number: {
      name: 'phone_number',
      label: 'Phone',
      operator: '^=',
    },
    given_name: {
      name: 'given_name',
      label: 'First Name',
      operator: '^=',
    },
    family_name: {
      name: 'family_name',
      label: 'Last Name',
      operator: '^=',
    },
    'cognito:user_status': {
      name: 'cognito:user_status',
      label: 'Status',
      operator: '=',
      values: Object.entries(UserStatuses).map(([key, value]) => ({
        title: value,
        value: key,
      })),
    },
    status: {
      name: 'status',
      label: 'Enabled',
      operator: '=',
      values: [
        {
          title: 'Inactive',
          value: 'Disabled',
        },
        {
          title: 'Active',
          value: 'Enabled',
        },
      ],
    },
  };

export const DEFAULT_FILTER = {
  field: 'email',
  value: '',
  operator: '',
};

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

// TODO: Test if style changes affected other SignIn components

export const useStyles = makeStyles((theme: Theme) => ({
  formLabel: {
    color: theme.palette.text.primary,
  },

  button: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 320,
    },
  },

  // NOTE: Will be reworked
  oldButton: {
    width: 150,
  },

  wrapper: {
    position: 'relative',
  },

  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },

  legalDocumentsLink: {
    fontFamily: theme.fonts.fontFamily.medium,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },

  forgotPassMessage: {
    fontWeight: 900,
  },
}));

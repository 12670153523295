import { privateGateway } from '../../../config';

export const exportUserRequests = async (): Promise<void> => {
  const currentLocalDate = new Date();
  const timezoneOffset = currentLocalDate.getTimezoneOffset() * -1;

  await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get(`/user_requests/export?tz_minutes=${timezoneOffset}`);
};

import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  passwordExpiration: {
    paddingLeft: 10,
    marginBottom: 48,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export const TEST_ID = {
  supportFileUpload: 'supportFileUpload',
  userGuideLinks: 'userGuideLinks',
};

export const DEFAULT_SUPPORT_FILE_FORM_VALUES = {
  caseReference: '',
  caseId: '',
  caseDescription: '',
};

export const FILES_MAX_SIZE = 50 * 1024 * 1024 * 1024; // 50 GB

export const FILE_UPLOAD_MAX_PROGRESS = 75;

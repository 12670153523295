import { privateGateway } from '../../../config';
import { SuggestionSchema } from '../typings';

export const fetchSuggestions = async (
  searchString: string
): Promise<SuggestionSchema[]> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post('/files/search/suggestions', { searchString });

  return response.data;
};

export const TEST_ID = {
  contentTreeViewer: 'contentTreeViewer',
  allButton: 'allButton',
  bookmarksButton: 'bookmarksButton',
};

export const FILE_TYPES = {
  pdf: 'pdf',
  xlsx: 'xlsx',
};

import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import MuiFormLabel from '@material-ui/core/FormLabel';

import ControlledTextField, {
  ControlledTextFieldProps,
} from '@quanterix-ui/core/ControlledTextField';

import { useStyles as useCommonStyles } from '../styles';

interface Props extends ControlledTextFieldProps {
  label?: string;
}

const ControlledWebsiteTextField: FC<Props> = ({
  id,
  name,
  label,
  required = false,
  ...restProps
}) => {
  const commonClasses = useCommonStyles();

  const { formatMessage } = useIntl();

  return (
    <>
      <MuiFormLabel
        htmlFor={id ?? name}
        required={required}
        className={commonClasses.label}
      >
        {label ?? <FormattedMessage id="app.form.label.website" />}
      </MuiFormLabel>
      <ControlledTextField
        fullWidth
        id={id ?? name}
        name={name}
        rules={{ required }}
        placeholder={formatMessage({ id: 'app.form.placeholder.website' })}
        {...restProps}
      />
    </>
  );
};

export default ControlledWebsiteTextField;

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { updateUserRequest } from '../requests/updateUserRequest';
import { QUERY_NAME } from '../constants';
import { UpdateUserRequestSchema, UserRequestSchema } from '../typings';

type UseUpdateUserRequestResult = UseMutationResult<
  UserRequestSchema,
  Error,
  UpdateUserRequestSchema
>;

export const useUpdateUserRequest = (
  requestId: string
): UseUpdateUserRequestResult => {
  const { enqueueErrorSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const queryKey = [QUERY_NAME.userRequest, requestId];

  const result = useMutation<UserRequestSchema, Error, UpdateUserRequestSchema>(
    (data) => updateUserRequest({ requestId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },

      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
    }
  );

  return result;
};

import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import ContentHeader from './components/ContentHeader';
import ContentBody from './components/ContentBody';
import SectionsTreeViewer from './components/SectionsTreeViewer';
import { useStyles } from './styles';

const FAQManagement: FC = () => {
  const classes = useStyles();

  const location = useLocation();

  const isFAQManagementPage = location.pathname.startsWith('/admin/');

  return (
    <MuiBox>
      <MuiBox mb={4}>
        <MuiGrid container className={classes.mainGridContainer}>
          <MuiGrid item xs={12} md={6}>
            <MuiTypography className={classes.pageTitle} color="secondary">
              {isFAQManagementPage && (
                <FormattedMessage id="page.faq_management.title" />
              )}
              {!isFAQManagementPage && <FormattedMessage id="page.faq.title" />}
            </MuiTypography>
            <MuiTypography variant="body1" color="secondary">
              {!isFAQManagementPage && (
                <FormattedMessage id="page.faq.subtitle" />
              )}
            </MuiTypography>
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
      <MuiGrid container justifyContent="space-between">
        <MuiGrid item xs={4} lg={2}>
          <SectionsTreeViewer />
        </MuiGrid>
        <MuiGrid item xs={8} lg={10}>
          <MuiGrid container justifyContent="center">
            <MuiBox width="100%" maxWidth={800} pr={1} mt={-2}>
              {isFAQManagementPage && <ContentHeader />}
              <ContentBody />
            </MuiBox>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

export default FAQManagement;

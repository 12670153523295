import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

interface UseStylesProps {
  opacity: number;
}

export const useStyles = makeStyles<Theme, UseStylesProps>((theme) => ({
  root: {
    position: 'relative',
    minHeight: 120,

    '&.isLoading': {
      overflow: 'hidden',
    },
  },

  overlay: ({ opacity }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    zIndex: theme.zIndex.modal,
    backgroundColor: alpha(theme.palette.common.white, opacity),
  }),

  indicator: {},
}));

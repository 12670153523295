import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  fileUpload: {
    color: theme.palette.secondary.main,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },

  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  loadingIndicator: {
    height: 12,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
  },

  deleteButton: {
    padding: 0,
  },
}));

import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import MuiGrid from '@material-ui/core/Grid';

import Input from '@quanterix-ui/core/Input';
import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';

const ConfirmSignInForm: FC = () => {
  const history = useHistory();

  const { user } = useCognito();

  const [code, setCode] = useState('');

  const handleCodeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setCode(value);
  };

  const handleSignIn = async () => {
    await Auth.confirmSignIn(
      user,
      code,
      user?.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );

    history.push('/');
  };

  useEffect(() => {
    setCode('');
  }, [history]);

  return (
    <MuiGrid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <MuiGrid item>
        <div>Login to Quanterix Customer Portal</div>
        <div>Secret code to confirm SignIn</div>
      </MuiGrid>
      <MuiGrid item>
        <Input onChange={handleCodeChange} />
      </MuiGrid>
      <MuiGrid item>
        <Button onClick={handleSignIn}>Confirm</Button>
      </MuiGrid>
    </MuiGrid>
  );
};

export default ConfirmSignInForm;

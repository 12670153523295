import {
  requestFileUrl,
  requestSaveTemplate,
  requestTemplateDownloadUrl,
  RequestTemplateResult,
  uploadFile,
  UploadFileOptions,
} from 'src/api/endpoints/blobStorage';

import { UPLOAD_SOURCE } from '../constants/uploadSource';

export const getDownloadUrl = async (file_name: string): Promise<string> => {
  const data = await requestFileUrl({ file_name });

  return data.download_url;
};

export const uploadToBlobStorage = async (
  file: File,
  source: UPLOAD_SOURCE,
  options?: UploadFileOptions
): Promise<string> => {
  const { blobStorageKey } = await uploadFile({ file, source }, options);

  return blobStorageKey;
};

export const uploadTemplateToBlobStorage = async (
  file: File,
  template_type: string
): Promise<RequestTemplateResult> => {
  const file_name = await uploadToBlobStorage(file, UPLOAD_SOURCE.templates);
  const response = await requestSaveTemplate({
    file_name,
    template_type,
  });

  return response;
};

export const getTemplateDownloadUrl = async (
  template_type: string
): Promise<string> => {
  const { file_name } = await requestTemplateDownloadUrl({ template_type });
  const download_url = await getDownloadUrl(file_name);

  return download_url;
};

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { INSTRUMENT_NAME } from 'src/api';

import { InstrumentField } from '../typings';

export type UserViewerProfileInstrumentsContextResult = {
  instruments: InstrumentField;
  setInstruments: Dispatch<SetStateAction<InstrumentField>>;
};

export const DEFAULT_STATE: InstrumentField = {
  acceleratorServices: {
    name: INSTRUMENT_NAME.acceleratorServices,
    selected: false,
    serialNumber: '',
  },
  spx: {
    name: INSTRUMENT_NAME.spx,
    selected: false,
    serialNumber: '',
  },
  srx: {
    name: INSTRUMENT_NAME.srx,
    selected: false,
    serialNumber: '',
  },
  hd1: {
    name: INSTRUMENT_NAME.hd1,
    selected: false,
    serialNumber: '',
  },
  hdx: {
    name: INSTRUMENT_NAME.hdx,
    selected: false,
    serialNumber: '',
  },
};

export const UserViewerProfileInstrumentsContext =
  createContext<UserViewerProfileInstrumentsContextResult>({
    instruments: {} as InstrumentField,
    setInstruments: () => {
      // void
    },
  });

const UserViewerProfileInstrumentsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [instruments, setInstruments] = useState(DEFAULT_STATE);

  return (
    <UserViewerProfileInstrumentsContext.Provider
      value={{
        instruments,
        setInstruments,
      }}
    >
      {children}
    </UserViewerProfileInstrumentsContext.Provider>
  );
};

export const useUserViewerProfileInstrumentsContext =
  (): UserViewerProfileInstrumentsContextResult => {
    const context = useContext(UserViewerProfileInstrumentsContext);

    if (!context) {
      throw new Error(
        'useUserViewerProfileInstrumentsContext must be used within a UserViewerProfileInstrumentsProvider'
      );
    }

    return context;
  };

export default UserViewerProfileInstrumentsProvider;

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

import {
  FILE_CONTAINER_HEIGHT,
  VIDEO_PREVIEW_HEIGHT,
  VIDEO_PREVIEW_WIDTH,
} from './constants';

export const useStyles = makeStyles((theme: Theme) => ({
  fileCard: {
    borderRadius: theme.constants.borderRadius,
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,

    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },

  videoPreview: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: VIDEO_PREVIEW_WIDTH,
    height: VIDEO_PREVIEW_HEIGHT,
  },

  fileButton: {
    position: 'relative',
    width: '100%',
    height: FILE_CONTAINER_HEIGHT,
    padding: 25,

    '&.isVideo': {
      paddingLeft: VIDEO_PREVIEW_WIDTH + 25,
    },

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  gridRowTop: {
    width: '100%',
    paddingBottom: 4,
  },

  fileName: {
    flex: 1,
    paddingRight: 25,
    fontFamily: theme.fonts.fontFamily.bold,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  avatar: {
    width: 26,
    height: 26,
    backgroundColor: theme.palette.secondary.main,

    '& [class*=MuiTypography-caption]': {
      marginTop: 3,
    },
  },

  fileBookmarkWrapper: {
    position: 'relative',
    width: 24,
    height: 24,
  },

  fileBookmarkButton: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  videoPlayerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

import {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { findDOMNode } from 'react-dom';
import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player/file';
import screenfull from 'screenfull';
import cx from 'classnames';

import MuiPaper from '@material-ui/core/Paper';
import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiSlider from '@material-ui/core/Slider';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTypography from '@material-ui/core/Typography';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiFade from '@material-ui/core/Fade';
import MuiPlayArrowIcon from '@material-ui/icons/PlayArrow';
import MuiPauseIcon from '@material-ui/icons/Pause';
import MuiFastForwardIcon from '@material-ui/icons/FastForward';
import MuiFullscreenIcon from '@material-ui/icons/Fullscreen';
import MuiFullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import MuiFeaturedVideoIcon from '@material-ui/icons/FeaturedVideoOutlined';
import MuiDownloadIcon from '@material-ui/icons/GetApp';
import MuiVolumeDownIcon from '@material-ui/icons/VolumeDown';
import MuiVolumeUpIcon from '@material-ui/icons/VolumeUp';
import MuiVolumeMuteIcon from '@material-ui/icons/VolumeOff';

import { useDebouncedCallback } from 'src/utils/hooks';

import { useStyles } from './styles';
import { formatSeconds } from './utils';
import {
  TEST_ID,
  VIDEO_MOUSEMOVE_TIMEOUT,
  VIDEO_PLAYBACK_RATE,
  VIDEO_PROGRESS_MAX,
  VIDEO_PROGRESS_MIN,
  VIDEO_PROGRESS_STEP,
  VIDEO_VOLUME_DEFAULT,
  VIDEO_VOLUME_MAX,
  VIDEO_VOLUME_MIN,
  VIDEO_VOLUME_STEP,
} from './constants';

interface Props {
  name: string;
  url: string;
}

const VideoPlayer: FC<Props> = ({ name, url }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const rootRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<ReactPlayer | null>(null);
  const savedVolumeRef = useRef<number>(VIDEO_VOLUME_DEFAULT);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isRootHovered, setIsRootHovered] = useState(false);
  const [isVolumeHovered, setIsVolumeHovered] = useState(false);
  const [pip, setPip] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [volume, setVolume] = useState(VIDEO_VOLUME_DEFAULT);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [, setLoaded] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(VIDEO_PLAYBACK_RATE.normal);

  const [handleDebouncedRootMouseStop] = useDebouncedCallback(
    (event: MouseEvent<HTMLElement>) => {
      if ((event.target as HTMLElement).tagName === 'VIDEO') {
        setIsRootHovered(false);
      }
    },
    VIDEO_MOUSEMOVE_TIMEOUT
  );

  const handleRootMouseMove = (event: MouseEvent<HTMLElement>) => {
    setIsRootHovered(true);
    handleDebouncedRootMouseStop(event);
  };

  const handleVideoReady = () => {
    setIsVideoReady(true);
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleRootMouseOver = () => {
    setIsRootHovered(true);
  };

  const handleRootMouseLeave = () => {
    setIsRootHovered(false);
  };

  const handleVolumeMouseOver = () => {
    setIsVolumeHovered(true);
  };

  const handleVolumeMouseLeave = () => {
    setIsVolumeHovered(false);
  };

  const handleTogglePlayPause = () => {
    setPlaying((playing) => !playing);
  };

  const handleVolumeClick = () => {
    if (
      volume === VIDEO_VOLUME_MIN &&
      savedVolumeRef.current !== VIDEO_VOLUME_MIN
    ) {
      setVolume(savedVolumeRef.current);
    } else if (
      volume === VIDEO_VOLUME_MIN &&
      savedVolumeRef.current === VIDEO_VOLUME_MIN
    ) {
      setVolume(VIDEO_VOLUME_DEFAULT);
    } else {
      setVolume(VIDEO_VOLUME_MIN);
    }
  };

  const handleVolumeChange = (
    _event: ChangeEvent<Record<string, never>>,
    value: number | number[]
  ) => {
    setVolume(value as number);
    savedVolumeRef.current = value as number;
  };

  const handleSeekChange = (
    _event: ChangeEvent<Record<string, never>>,
    value: number | number[]
  ) => {
    setSeeking(true);
    setPlayed(value as number);
  };

  const handleSeekMouseUp = (
    _event: ChangeEvent<Record<string, never>>,
    value: number | number[]
  ) => {
    setSeeking(false);
    playerRef.current?.seekTo?.(value as number);
  };

  const handleFastForward = () => {
    if (playbackRate === VIDEO_PLAYBACK_RATE.fast) {
      setPlaybackRate(VIDEO_PLAYBACK_RATE.normal);
    } else {
      setPlaybackRate(VIDEO_PLAYBACK_RATE.fast);
    }

    if (!playing) {
      setPlaying(true);
    }
  };

  const handleTogglePiP = () => {
    setPip((pip) => !pip);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handleProgress = (state: {
    loaded: number;
    played: number;
    playedSeconds: number;
  }) => {
    if (!seeking) {
      setLoaded(state.loaded);
      setPlayed(state.played);
      setPlayedSeconds(state.playedSeconds);
    }
  };

  const handleEnterFullscreen = () => {
    screenfull.request(findDOMNode(rootRef.current) as Element);
  };

  const handleExitFullscreen = () => {
    screenfull.exit();
  };

  useEffect(() => {
    screenfull.onchange(() => {
      setIsFullscreen(screenfull.isFullscreen);
    });
  }, []);

  const volumeButtonTranslationId =
    volume !== VIDEO_VOLUME_MIN
      ? 'video_player.controls.mute'
      : 'video_player.controls.unmute';

  return (
    <MuiPaper
      ref={rootRef}
      className={classes.root}
      data-testid={TEST_ID.videoPlayerContainer}
      onMouseOver={handleRootMouseOver}
      onMouseMove={isFullscreen ? handleRootMouseMove : undefined}
      onMouseLeave={handleRootMouseLeave}
    >
      {!isVideoReady && (
        <MuiBox className={classes.readySpinner} data-testid={TEST_ID.spinner}>
          <MuiCircularProgress size={50} thickness={3} color="primary" />
        </MuiBox>
      )}
      <ReactPlayer
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        playing={playing}
        pip={pip}
        playbackRate={playbackRate}
        volume={volume}
        onClick={handleTogglePlayPause}
        onReady={handleVideoReady}
        onPlay={handlePlay}
        onPause={handlePause}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      <MuiFade in={isRootHovered}>
        <MuiBox
          className={cx(classes.videoHeader, { isFullscreen })}
          data-testid={TEST_ID.header}
        >
          <MuiTypography variant="h2" className={classes.videoTitle}>
            {name}
          </MuiTypography>
        </MuiBox>
      </MuiFade>
      <MuiFade in={isRootHovered}>
        <MuiGrid
          container
          justifyContent="space-between"
          alignItems="center"
          className={cx(classes.controls, { isFullscreen })}
          data-testid={TEST_ID.controls}
        >
          <MuiBox className={classes.progressBar}>
            <MuiSlider
              min={VIDEO_PROGRESS_MIN}
              max={VIDEO_PROGRESS_MAX}
              step={VIDEO_PROGRESS_STEP}
              value={played}
              data-testid={TEST_ID.progressSlider}
              onChange={handleSeekChange}
              onChangeCommitted={handleSeekMouseUp}
            />
          </MuiBox>
          <MuiGrid item xs={9}>
            <MuiGrid container alignItems="center">
              <MuiBox>
                {!playing && (
                  <MuiTooltip
                    title={formatMessage({ id: 'video_player.controls.play' })}
                  >
                    <MuiIconButton
                      color="inherit"
                      data-testid={TEST_ID.playButton}
                      onClick={handlePlay}
                    >
                      <MuiPlayArrowIcon
                        className={classes.controlsHighlight}
                        fontSize="large"
                      />
                    </MuiIconButton>
                  </MuiTooltip>
                )}
                {playing && (
                  <MuiTooltip
                    title={formatMessage({ id: 'video_player.controls.pause' })}
                  >
                    <MuiIconButton
                      color="inherit"
                      data-testid={TEST_ID.pauseButton}
                      onClick={handlePause}
                    >
                      <MuiPauseIcon
                        className={classes.controlsHighlight}
                        fontSize="large"
                      />
                    </MuiIconButton>
                  </MuiTooltip>
                )}
                <MuiTooltip
                  title={formatMessage({
                    id: 'video_player.controls.fast_forward',
                  })}
                >
                  <MuiIconButton
                    color="inherit"
                    data-testid={TEST_ID.fastForwardButton}
                    onClick={handleFastForward}
                  >
                    <MuiFastForwardIcon
                      className={cx(classes.controlsHighlight, {
                        isActive: playbackRate === VIDEO_PLAYBACK_RATE.fast,
                      })}
                      fontSize="medium"
                    />
                  </MuiIconButton>
                </MuiTooltip>
              </MuiBox>
              <MuiBox
                ml={2}
                data-testid={TEST_ID.volumeContainer}
                onMouseOver={handleVolumeMouseOver}
                onMouseLeave={handleVolumeMouseLeave}
              >
                <MuiGrid container alignItems="center">
                  <MuiBox mr={1.5}>
                    <MuiTooltip
                      title={formatMessage({
                        id: volumeButtonTranslationId,
                      })}
                    >
                      <MuiIconButton
                        color="inherit"
                        data-testid={TEST_ID.volumeButton}
                        onClick={handleVolumeClick}
                      >
                        {volume > 0.5 && (
                          <MuiVolumeUpIcon
                            className={classes.controlsHighlight}
                            fontSize="medium"
                            data-testid={TEST_ID.volumeUpIcon}
                          />
                        )}
                        {volume <= 0.5 && volume !== VIDEO_VOLUME_MIN && (
                          <MuiVolumeDownIcon
                            className={classes.controlsHighlight}
                            fontSize="medium"
                            data-testid={TEST_ID.volumeDownIcon}
                          />
                        )}
                        {volume === VIDEO_VOLUME_MIN && (
                          <MuiVolumeMuteIcon
                            className={classes.controlsHighlight}
                            fontSize="medium"
                            data-testid={TEST_ID.volumeMuteIcon}
                          />
                        )}
                      </MuiIconButton>
                    </MuiTooltip>
                  </MuiBox>
                  <MuiFade unmountOnExit in={isVolumeHovered}>
                    <MuiBox width={80} height={28} mr={1.5}>
                      <MuiSlider
                        min={VIDEO_VOLUME_MIN}
                        max={VIDEO_VOLUME_MAX}
                        step={VIDEO_VOLUME_STEP}
                        value={volume}
                        data-testid={TEST_ID.volumeSlider}
                        onChange={handleVolumeChange}
                      />
                    </MuiBox>
                  </MuiFade>
                </MuiGrid>
              </MuiBox>
              <MuiBox ml={3.5}>
                <MuiTypography className={classes.controlsHighlight}>
                  {formatSeconds(playedSeconds)} / {formatSeconds(duration)}
                </MuiTypography>
              </MuiBox>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item xs={3}>
            <MuiGrid container justifyContent="flex-end" alignItems="center">
              <MuiBox mr={2}>
                <MuiTooltip
                  title={formatMessage({
                    id: 'video_player.controls.download',
                  })}
                >
                  <MuiIconButton
                    download
                    color="inherit"
                    component="a"
                    href={url}
                    data-testid={TEST_ID.downloadButton}
                  >
                    <MuiDownloadIcon
                      className={classes.controlsHighlight}
                      fontSize="medium"
                    />
                  </MuiIconButton>
                </MuiTooltip>
              </MuiBox>
              <MuiBox mr={2}>
                <MuiTooltip
                  title={formatMessage({
                    id: 'video_player.controls.pip',
                  })}
                >
                  <MuiIconButton
                    color="inherit"
                    data-testid={TEST_ID.pipButton}
                    onClick={handleTogglePiP}
                  >
                    <MuiFeaturedVideoIcon
                      className={classes.controlsHighlight}
                      fontSize="medium"
                      color={pip ? 'primary' : undefined}
                    />
                  </MuiIconButton>
                </MuiTooltip>
              </MuiBox>
              <MuiBox>
                {!isFullscreen && (
                  <MuiTooltip
                    title={formatMessage({
                      id: 'video_player.controls.fullscreen',
                    })}
                  >
                    <MuiIconButton
                      color="inherit"
                      data-testid={TEST_ID.fullscreenEnterButton}
                      onClick={handleEnterFullscreen}
                    >
                      <MuiFullscreenIcon
                        className={classes.controlsHighlight}
                        fontSize="medium"
                      />
                    </MuiIconButton>
                  </MuiTooltip>
                )}
                {isFullscreen && (
                  <MuiTooltip
                    title={formatMessage({
                      id: 'video_player.controls.fullscreen_exit',
                    })}
                  >
                    <MuiIconButton
                      color="inherit"
                      data-testid={TEST_ID.fullscreenExitButton}
                      onClick={handleExitFullscreen}
                    >
                      <MuiFullscreenExitIcon
                        className={classes.controlsHighlight}
                        fontSize="medium"
                      />
                    </MuiIconButton>
                  </MuiTooltip>
                )}
              </MuiBox>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiFade>
    </MuiPaper>
  );
};

export default VideoPlayer;

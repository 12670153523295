export const TEST_ID = {
  fileCard: 'fileCard',
  fileCardButton: 'fileCardButton',
  fileCardLink: 'fileCardLink',
  bookmarkButton: 'bookmarkButton',
  bookmarkedTrueIcon: 'bookmarkedTrueIcon',
  bookmarkedFalseIcon: 'bookmarkedFalseIcon',
  videoPreview: 'videoPreview',
};

export const FILE_CONTAINER_HEIGHT = 106;
export const VIDEO_PREVIEW_WIDTH = 188;
export const VIDEO_PREVIEW_HEIGHT = FILE_CONTAINER_HEIGHT;

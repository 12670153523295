import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';

import Stepper from '@quanterix-ui/core/Stepper';
import Step from '@quanterix-ui/core/Step';
import StepLabel from '@quanterix-ui/core/StepLabel';

import { useSessionStorage } from 'src/utils/hooks';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import FilterProvider from 'src/context/FilterContext';

import { useStyles } from './styles';
import PersonalForm from './components/PersonalForm';
import OrganizationForm from './components/OrganizationForm';
import { DEFAULT_FILTER } from './constants';

const SignUp: FC = () => {
  const classes = useStyles();

  const { storedValue: activeStep, setValue: setActiveStep } =
    useSessionStorage(LOCAL_STORAGE_KEY.requestAccessStep, 0);

  const handlePersonalFormSubmit = () => {
    setActiveStep(1);
  };

  const handleOranizationFormBack = () => {
    setActiveStep(0);
  };

  return (
    <>
      <MuiBox display="flex" justifyContent="center" mb={5}>
        <MuiBox flexGrow={1}>
          <Stepper className={classes.stepper} activeStep={activeStep}>
            <Step>
              <StepLabel>
                <FormattedMessage id="page.request_access.stepper.personal_data" />
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                <FormattedMessage id="page.request_access.stepper.organization_data" />
              </StepLabel>
            </Step>
          </Stepper>
        </MuiBox>
      </MuiBox>
      <MuiBox>
        {activeStep === 0 && (
          <PersonalForm onSubmit={handlePersonalFormSubmit} />
        )}
        {activeStep === 1 && (
          <FilterProvider defaultFilter={DEFAULT_FILTER}>
            <OrganizationForm onBack={handleOranizationFormBack} />
          </FilterProvider>
        )}
      </MuiBox>
    </>
  );
};

export default SignUp;

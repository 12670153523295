import { FC, ReactNode, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiButton from '@material-ui/core/Button';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { COGNITO_CHALLENGE_NAME, useCognito } from 'src/aws/Cognito';
import {
  useFetchCurrentUser,
  useUpdateCurrentUser,
} from 'src/api/endpoints/users';
import { LegalDocument, PAGE_SLUG } from 'src/api/endpoints/contentHub';
import { useFetchPublicPageBySlug } from 'src/api/endpoints/contentHubPublic';
import { SNACKBAR } from 'src/utils/constants/app';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { PUBLIC_ROUTES_MAP } from 'src/router';
import ConfirmDialog from 'src/components/ConfirmDialog';
import LegalDocumentModal from 'src/components/LegalDocumentModal';

import ConfirmSignInForm from './components/ConfirmSignInForm';
import SignInForm from './components/SignInForm';
import { useStyles } from './styles';

const SignIn: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { formatMessage } = useIntl();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [legalDocumentModalOpen, setLegalDocumentModalOpen] =
    useState<LegalDocument>();

  const {
    user,
    isEmailVerified,
    isAuthenticated,
    daysTillPasswordExpiry,
    signOut,
  } = useCognito();

  const { data: privacyPolicy } = useFetchPublicPageBySlug(
    PAGE_SLUG.privacyPolicy
  );
  const { data: termsOfUse } = useFetchPublicPageBySlug(
    PAGE_SLUG.termsAndConditions
  );

  const { data: currentUser } = useFetchCurrentUser({ enabled: !!user });

  const {
    mutate: updateCurrentUser,
    isLoading: isUpdateCurrentUserInProgress,
  } = useUpdateCurrentUser();

  const isCurrentUserPrepaired =
    !!user && !!currentUser && !!privacyPolicy && !!termsOfUse;

  const isCurrentUserAcceptedLegalDocuments =
    currentUser?.legal.privacy_policy_version === privacyPolicy?.updated_at &&
    currentUser?.legal.terms_of_use_version === termsOfUse?.updated_at;

  const isUserCouldBeRedirectedNext =
    isAuthenticated &&
    isEmailVerified &&
    !isNaN(daysTillPasswordExpiry) &&
    isCurrentUserPrepaired &&
    isCurrentUserAcceptedLegalDocuments;

  const isLegalDocumentsDialogOpen =
    isCurrentUserPrepaired &&
    !isCurrentUserAcceptedLegalDocuments &&
    !legalDocumentModalOpen;

  useEffect(() => {
    if (user?.challengeName !== COGNITO_CHALLENGE_NAME.newPasswordRequired) {
      if (isUserCouldBeRedirectedNext) {
        closeSnackbar();
        history.push('/');
        window.location.reload();
      }
    }

    if (user?.challengeName === COGNITO_CHALLENGE_NAME.newPasswordRequired) {
      closeSnackbar();
      history.push(PUBLIC_ROUTES_MAP.changeTemporaryPassword);
    }

    const isChangePasswordInProgress = localStorage.getItem(
      LOCAL_STORAGE_KEY.isChangePasswordInProgress
    );

    if (isChangePasswordInProgress === 'true') {
      enqueueSnackbar(
        formatMessage({ id: 'page.login.change_password.failed' }),
        {
          ...SNACKBAR.defaultOptions,
          variant: 'info',
          persist: true,
          action: (key) => (
            <MuiButton
              variant="text"
              color="inherit"
              size="small"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              {formatMessage({ id: 'app.button.ok' })}
            </MuiButton>
          ),
        }
      );

      localStorage.removeItem(LOCAL_STORAGE_KEY.isChangePasswordInProgress);
    }
  }, [
    user,
    history,
    isUserCouldBeRedirectedNext,
    formatMessage,
    enqueueSnackbar,
    closeSnackbar,
    signOut,
  ]);

  const handleLegalDocumentCloseModal = () => {
    setLegalDocumentModalOpen(undefined);
  };

  const handleLegalDocumentOpenModal = (document: LegalDocument) => {
    setLegalDocumentModalOpen(document);
  };

  const handleLegalDocumentsDecline = () => {
    signOut();
  };

  const handleLegalDocumentsClose = () => {
    // void
  };

  const handleLegalDocumentsAccept = async () => {
    if (!currentUser || !privacyPolicy || !termsOfUse) {
      return;
    }

    updateCurrentUser({
      legal: {
        ...currentUser.legal,
        privacy_policy_version: privacyPolicy.updated_at,
        terms_of_use_version: termsOfUse.updated_at,
      },
    });
  };

  if (isAuthenticated) {
    if (
      user?.challengeName === COGNITO_CHALLENGE_NAME.smsMFA ||
      user?.challengeName === COGNITO_CHALLENGE_NAME.softwareTokenMFA
    ) {
      return <ConfirmSignInForm />;
    }
  }

  return (
    <>
      <SignInForm isRefreshDisabled={!isCurrentUserAcceptedLegalDocuments} />
      <ConfirmDialog
        disableBackdrop
        open={isLegalDocumentsDialogOpen}
        title={formatMessage({
          id: 'page.login.legal_documents.dialog.title',
        })}
        loading={isUpdateCurrentUserInProgress}
        acceptButtonText={formatMessage({
          id: 'app.button.accept',
        })}
        cancelButtonText={formatMessage({ id: 'app.button.decline' })}
        onClose={handleLegalDocumentsClose}
        onCancel={handleLegalDocumentsDecline}
        onAccept={handleLegalDocumentsAccept}
      >
        <MuiDialogContent>
          <FormattedMessage
            id="page.login.legal_documents.dialog.content"
            values={{
              privacy: (msg: ReactNode) => (
                <MuiButtonBase
                  className={classes.legalDocumentsLink}
                  onClick={() =>
                    handleLegalDocumentOpenModal(PAGE_SLUG.privacyPolicy)
                  }
                >
                  {msg}
                </MuiButtonBase>
              ),
              terms: (msg: ReactNode) => (
                <MuiButtonBase
                  className={classes.legalDocumentsLink}
                  onClick={() =>
                    handleLegalDocumentOpenModal(PAGE_SLUG.termsAndConditions)
                  }
                >
                  {msg}
                </MuiButtonBase>
              ),
            }}
          />
        </MuiDialogContent>
      </ConfirmDialog>
      <LegalDocumentModal
        open={!!legalDocumentModalOpen}
        legalDocumentPath={legalDocumentModalOpen!}
        onClose={handleLegalDocumentCloseModal}
      />
    </>
  );
};

export default SignIn;

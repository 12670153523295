import { useQuery, UseQueryResult } from 'react-query';

import { fetchFilesFilters } from '../requests/fetchFilesFilters';
import { FilesFiltersResult } from '../typings';
import { QUERY_NAME } from '../constants';

export const useFetchFilesFilters = (
  pageId?: string
): UseQueryResult<FilesFiltersResult> => {
  const result = useQuery([QUERY_NAME.fetchFilesFilters, pageId], () =>
    fetchFilesFilters(pageId)
  );

  return result;
};

import { useQuery, UseQueryResult } from 'react-query';

import { fetchSubmission } from '../requests/fetchSubmission';
import { SubmissionSchema } from '../typings';
import { QUERY_NAME } from '../constants';

export const useFetchSubmission = (
  formId: string
): UseQueryResult<SubmissionSchema> => {
  const result = useQuery([QUERY_NAME.submission, formId], () =>
    fetchSubmission(formId)
  );

  return result;
};

export const TEST_ID = {
  userList: 'userList',
};

export const SMALL_AMOUNT_ROWS_PER_PAGE = 10;
export const MEDIUM_AMOUNT_ROWS_PER_PAGE = 20;
export const LARGE_AMOUNT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_OPTIONS = [
  SMALL_AMOUNT_ROWS_PER_PAGE,
  MEDIUM_AMOUNT_ROWS_PER_PAGE,
  LARGE_AMOUNT_ROWS_PER_PAGE,
];

export const DEFAULT_ROWS_PER_PAGE = SMALL_AMOUNT_ROWS_PER_PAGE;
export const DEFAULT_PAGE = 0;

export const TABLE_COLUMNS_COUNT = 5;

export const MAX_STRING_LENGTH = 25;

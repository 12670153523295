import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: theme.palette.text.primary,
  },

  button: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 320,
    },
  },

  link: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },

  forgotPassMessage: {
    fontWeight: 900,
  },
}));

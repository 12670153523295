import { FC } from 'react';

import cx from 'classnames';

import { useStyles } from './styles';

const DNALoader: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {[...Array(10)].map((_, index) => (
          <div key={index} className={cx(classes.dot, `dot-${index + 1}`)} />
        ))}
        {[...Array(5)].map((_, index) => (
          <div key={index} className={cx(classes.base, `base-${index + 1}`)} />
        ))}
      </div>
    </div>
  );
};

export default DNALoader;

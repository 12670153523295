import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  flexibleContainer: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

import {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';

import TextField from '@quanterix-ui/core/TextField';
import Button from '@quanterix-ui/core/Button';
import Select from '@quanterix-ui/core/Select';
import SelectOption from '@quanterix-ui/core/SelectOption';

import { useFilterContext } from 'src/context/FilterContext';
import { UserStatuses } from 'src/utils/Constants';

import {
  DEFAULT_FILTER,
  FILTER_FIELDS_FOR_REGISTERED_USERS,
  FILTER_FIELDS_FOR_USER_REQUESTS,
} from './constants';

type FilterFieldsSchema =
  | typeof FILTER_FIELDS_FOR_REGISTERED_USERS
  | typeof FILTER_FIELDS_FOR_USER_REQUESTS;

interface Props {
  isRegisteredUsersPage: boolean;
  isUserRequestsPage: boolean;
  refetch: () => void;
}

const Filter: FC<Props> = ({
  isRegisteredUsersPage,
  isUserRequestsPage,
  refetch,
}) => {
  const { formatMessage } = useIntl();

  const { filter, isFilterApplied, setFilter } = useFilterContext();

  const [filterField, setFilterField] = useState(DEFAULT_FILTER.field);
  const [filterValue, setFilterValue] = useState('');

  const filterFields = useMemo<FilterFieldsSchema>(() => {
    if (isRegisteredUsersPage) {
      return FILTER_FIELDS_FOR_REGISTERED_USERS;
    }

    if (isUserRequestsPage) {
      return FILTER_FIELDS_FOR_USER_REQUESTS;
    }

    return [] as unknown as FilterFieldsSchema;
  }, [isRegisteredUsersPage, isUserRequestsPage]);

  const filterValues = useMemo(() => {
    if (isUserRequestsPage) return undefined;

    const { values } =
      filterFields[
        filterField as keyof typeof FILTER_FIELDS_FOR_REGISTERED_USERS
      ];

    return values;
  }, [isUserRequestsPage, filterField, filterFields]);

  const handleFilterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);

    if (event.target.value === '') {
      setFilter({ ...filter, value: '' });
    }
  };

  const handleFilterFieldChange = (event: ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as keyof typeof filterFields;

    setFilterField(filterFields[value].name);
    setFilterValue('');
  };

  const handleApplyFilters = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    setFilter({
      field: filterField,
      value: filterValue,
      operator: filterFields[filterField].operator,
    });
  };

  const handleFilterSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;

    if (filterValue && !value && isFilterApplied) {
      handleApplyFilters();
    }

    setFilterValue(value);
  };

  const handleClearFilters = () => {
    setFilterValue('');
    setFilter({ ...filter, value: '' });
  };

  useEffect(() => {
    refetch();
  }, [filter.value, refetch]);

  return (
    <MuiBox mt={4}>
      <form onSubmit={handleApplyFilters}>
        <MuiGrid container>
          <MuiBox mr={3}>
            <Select
              value={filterFields[filterField].name}
              renderValue={(key) =>
                filterFields[key as keyof typeof filterFields].label
              }
              onChange={handleFilterFieldChange}
            >
              {Object.keys(filterFields).map((key) => (
                <SelectOption key={key} value={filterFields[key].name}>
                  {filterFields[key].label}
                </SelectOption>
              ))}
            </Select>
          </MuiBox>
          <MuiBox mr={3} width={245}>
            {filterValues ? (
              <Select
                fullWidth
                value={filterValue}
                renderValue={(rawValue) => {
                  let result;
                  const value = rawValue as keyof typeof UserStatuses;

                  if (filterValues) {
                    result = filterValues.find((x) => x.value === value)?.title;
                  }

                  if (!result) {
                    result = UserStatuses[value] || value;
                  }

                  return result;
                }}
                onChange={handleFilterSelectChange}
              >
                {filterValues?.map((v) => (
                  <SelectOption key={v.value} value={v.value}>
                    {v.title}
                  </SelectOption>
                ))}
              </Select>
            ) : (
              <TextField
                fullWidth
                placeholder={formatMessage({
                  id: 'page.users.filter.placeholder',
                })}
                value={filterValue}
                onChange={handleFilterInputChange}
              />
            )}
          </MuiBox>
          <MuiBox mr={3}>
            <Button disabled={!filterValue} type="submit">
              <FormattedMessage id="app.button.search" />
            </Button>
          </MuiBox>
          <MuiBox mr={3}>
            <Button variant="outlined" onClick={handleClearFilters}>
              <FormattedMessage id="app.button.clear" />
            </Button>
          </MuiBox>
        </MuiGrid>
      </form>
    </MuiBox>
  );
};

export default Filter;

import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import FormLabel from '@material-ui/core/FormLabel';
import MuiBox from '@material-ui/core/Box';

import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step6: FC<AcceleratorFormStepComponentProps> = ({ onPrevious }) => {
  const classes = useStyles();

  return (
    <div>
      <MuiBox mt={4} className={classes.formControl}>
        <FormLabel component="legend">
          <FormattedMessage id="page.accelerator_form.step6.thank_you_message" />
        </FormLabel>
      </MuiBox>
      <StepperNavigation>
        <StepperNavButton direction="finish" onClick={onPrevious} />
      </StepperNavigation>
    </div>
  );
};

export default Step6;

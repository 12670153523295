import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: 24,
    borderRadius: theme.constants.borderRadius,
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,

    '&:hover': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },

  cardButton: {
    padding: 25,
    width: '100%',
    justifyContent: 'start',

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  title: {
    fontFamily: theme.fonts.fontFamily.bold,
    textDecoration: 'underline',
  },
}));

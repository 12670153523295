import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

const heightOfTheContainer = 40;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: heightOfTheContainer + 10, // 10px is for padding
    padding: '5px 5px 5px 16px',
    margin: '3px 0',
    borderRadius: 10,

    '& .action-icon': {
      display: 'none',
    },

    '&:hover, &.isFocused': {
      backgroundColor: theme.palette.grey[100],

      '& .action-icon': {
        display: 'block',
      },
    },

    '& a': {
      color: 'initial',
      textDecoration: 'none',
    },

    '& svg': {
      color: theme.palette.primary.main,
    },

    '&.hasError': {
      backgroundColor: alpha(theme.palette.error.main, 0.02),
      boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
    },

    '&.isDirty': {
      backgroundColor: theme.palette.grey[100],
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
  },

  gridItemHeightHolder: {
    width: 1,
    height: heightOfTheContainer,
  },

  fileTypeIconContainer: {
    position: 'relative',
    display: 'inline-block',
    width: 24,
    height: 24,
  },

  fileTypeIcon: {
    '&.inProgress': {
      visibility: 'hidden',
    },
  },

  fileProgressContainer: {
    position: 'absolute',
    top: 1,
    left: 1,
  },

  italicLink: {
    fontStyle: 'italic',
    opacity: 0.4,
  },

  link: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  actionButton: {
    minWidth: 'auto !important',
  },

  editInputBase: {
    width: '100%',
    maxWidth: '100%',
    height: 40,
  },

  editInput: {
    marginTop: 1,
    fontSize: 14,
    fontFamily: theme.fonts.fontFamily.medium,
    fontWeight: 400,
  },
}));

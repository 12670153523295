import { FC, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiIconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/CameraAlt';

import { ContentHubObjectSchema } from 'src/api/endpoints/contentHub';

import { useEditableContentContext } from '../../../../context/EditableContent';
import ImageUploader from '../ImageUploader';

import { FIELD_NAME } from './constants';
import { useStyles } from './styles';

interface Props {
  isEditable?: boolean;
  item?: ContentHubObjectSchema;
}

const ImageHolder: FC<Props> = ({ isEditable = true, item }) => {
  const classes = useStyles();

  const { imageKeyToBeDeleted, setImageKeyToBeDeleted } =
    useEditableContentContext();

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { image, title } = watch();

  useEffect(() => {
    setValue('image', null);
    setImageKeyToBeDeleted(null);
  }, [setValue, setImageKeyToBeDeleted, item]);

  const handleDeleteImage = async () => {
    if (item?.img_blob_storage_key) {
      setImageKeyToBeDeleted(item.img_blob_storage_key);
    }

    setValue(FIELD_NAME, null, { shouldDirty: true });
  };

  const error = errors[FIELD_NAME];

  const isCurrentImageAvailable =
    item?.img_blob_storage_key &&
    item?.img_blob_storage_key !== imageKeyToBeDeleted;
  const isImageUploaderShown = isEditable && !image && !isCurrentImageAvailable;
  const isImagePlaceholderShown =
    !isEditable && !image && !isCurrentImageAvailable;
  const isImageRemoveButtonShown =
    isEditable && (image || isCurrentImageAvailable);

  const imageToShow =
    image?.preview || (isCurrentImageAvailable && item?.image);

  return (
    <MuiBox className={classes.root}>
      <MuiBox className={classes.imageContainer}>
        {isImageUploaderShown && <ImageUploader />}
        {isImagePlaceholderShown && (
          <MuiBox className={classes.imageIconWrapper}>
            <ImageIcon />
          </MuiBox>
        )}
        {imageToShow && (
          <img src={imageToShow} className={classes.image} alt={title} />
        )}
        {isImageRemoveButtonShown && (
          <MuiIconButton
            color="secondary"
            size="small"
            className={classes.clearIconButton}
            onClick={handleDeleteImage}
          >
            <ClearIcon color="secondary" />
          </MuiIconButton>
        )}
      </MuiBox>
      <MuiBox className={classes.formErrorsContainer}>
        {error && (
          <MuiFormHelperText error>
            <FormattedMessage id={error.message} />
          </MuiFormHelperText>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default ImageHolder;

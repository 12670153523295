import { ChangeEvent, FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';
import MuiFormGroup from '@material-ui/core/FormGroup';
import MuiFormLabel from '@material-ui/core/FormLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@quanterix-ui/core/Checkbox';
import TextField from '@quanterix-ui/core/TextField';

import { isNumeric } from 'src/utils/helpers/common';
import { INSTRUMENT_NAME } from 'src/api';

import { useStyles as useCommonStyles } from '../styles';

import { Instrument, InstrumentField } from './typings';
import { DEFAULT_INSTRUMENT_VALUE, INSTRUMENTS } from './constants';

interface Props {
  name: string;
}

const ControlledInstrumentSelect: FC<Props> = ({ name }) => {
  const commonClasses = useCommonStyles();

  const { formatMessage } = useIntl();

  const {
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const handleCheckInstrument = (
    event: ChangeEvent<HTMLInputElement>,
    instrument: Instrument
  ) => {
    const instruments = getValues(name);

    trigger(`${name}.${instrument}`);

    return {
      ...instruments,
      [instrument]: {
        ...instruments[instrument],
        selected: event.target.checked,
      },
    };
  };

  const handleChangeInstrumentSerialNumber = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    instrument: Instrument
  ) => {
    const instruments = getValues(name);

    return isNumeric(event.target.value) || event.target.value === ''
      ? {
          ...instruments,
          [instrument]: {
            ...instruments[instrument],
            serialNumber: event.target.value,
          },
        }
      : instruments;
  };

  return (
    <MuiBox pl={1}>
      <MuiFormGroup>
        {INSTRUMENTS.map((instrument) => (
          <span key={instrument}>
            <MuiFormControlLabel
              control={
                <Controller
                  name={name}
                  defaultValue={DEFAULT_INSTRUMENT_VALUE}
                  rules={{
                    validate: {
                      oneInstrumentRequired: (instruments: InstrumentField) => {
                        const selectedInstrumentsCount = Object.values(
                          instruments
                        ).filter((instrument) => instrument.selected).length;

                        return (
                          selectedInstrumentsCount >= 1 ||
                          formatMessage({
                            id: 'app.form.error.instrument_required',
                          })
                        );
                      },
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      checked={value[instrument].selected}
                      onChange={(event) =>
                        onChange(handleCheckInstrument(event, instrument))
                      }
                    />
                  )}
                />
              }
              label={INSTRUMENT_NAME[instrument]}
            />
            {instrument !== 'acceleratorServices' &&
              watch(name, DEFAULT_INSTRUMENT_VALUE)[instrument].selected && (
                <MuiBox mt={1} mb={2} ml={-1}>
                  <MuiFormLabel
                    htmlFor={instrument}
                    className={commonClasses.label}
                  >
                    <FormattedMessage
                      id="app.form.label.instrument_serial_number"
                      values={{ instrument: INSTRUMENT_NAME[instrument] }}
                    />
                  </MuiFormLabel>
                  <Controller
                    name={name}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        id={instrument}
                        placeholder={formatMessage(
                          {
                            id: 'app.form.placeholder.instrument_serial_number',
                          },
                          { instrument: INSTRUMENT_NAME[instrument] }
                        )}
                        value={value[instrument].serialNumber}
                        onChange={(event) =>
                          onChange(
                            handleChangeInstrumentSerialNumber(
                              event,
                              instrument
                            )
                          )
                        }
                      />
                    )}
                  />
                </MuiBox>
              )}
          </span>
        ))}
      </MuiFormGroup>
      {errors[name]?.message && (
        <MuiFormHelperText error>{errors[name].message}</MuiFormHelperText>
      )}
    </MuiBox>
  );
};

export default ControlledInstrumentSelect;

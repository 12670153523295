import { FC, useState } from 'react';

import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import IconAdd from '@material-ui/icons/Add';

import Button from '@quanterix-ui/core/Button';

import { useCreateFAQSectionItem } from 'src/api/endpoints/faq';

import SectionItemDialog, { FormValuesSchema } from '../SectionItemDialog';

import { useStyles } from './styles';

interface Props {
  sectionId: number;
}

const SectionItemAddButton: FC<Props> = ({ sectionId }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: createFAQSectionItem, isLoading } =
    useCreateFAQSectionItem();

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSectionItemCreate = async ({
    question,
    answer,
  }: FormValuesSchema) => {
    await createFAQSectionItem({ section_id: sectionId, question, answer });
  };

  return (
    <MuiBox>
      <Button
        variant="text"
        color="secondary"
        startIcon={<IconAdd />}
        className={classes.button}
        onClick={handleDialogOpen}
      >
        <MuiBox mt={0.5}>
          <FormattedMessage id="page.faq_management.button.add_question" />
        </MuiBox>
      </Button>
      <div>
        {createPortal(
          <SectionItemDialog
            open={isOpen}
            loading={isLoading}
            titleTranslationId="page.faq_management.dialog.title.section_item.add"
            submitButtonTranslationId="app.button.add"
            onClose={handleDialogClose}
            onSubmit={handleSectionItemCreate}
          />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </MuiBox>
  );
};

export default SectionItemAddButton;

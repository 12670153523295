import { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';

import ControlledPasswordTextField, {
  PASSWORD_FIELD_NAME,
} from 'src/components/form/ControlledPasswordTextField';

const name = PASSWORD_FIELD_NAME.repeatPassword;

const RepeatPassword: FC = () => {
  return (
    <Grid item>
      <Box mb={4}>
        <FormLabel component="legend">Repeat password</FormLabel>
        <ControlledPasswordTextField
          name={name}
          equalTo={PASSWORD_FIELD_NAME.newPassword}
        />
      </Box>
    </Grid>
  );
};

export default RepeatPassword;

import {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Editor, Element, NodeEntry, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import isUrl from 'is-url';

import MuiPaper from '@material-ui/core/Paper';
import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiLinkIcon from '@material-ui/icons/Link';

import Input from '@quanterix-ui/core/Input';
import Button from '@quanterix-ui/core/Button';

import { CustomElement } from 'src/typings/slate';

import { ACTION_TYPE } from '../../constants';

import { useStyles } from './styles';
import { LINK_EDITOR_ROLE } from './constants';

interface Props {
  offsetX?: number;
  offsetY?: number;
}

const LinkEditor: FC<Props> = ({ offsetX, offsetY }) => {
  const classes = useStyles();

  const linkEditorRef = useRef<HTMLDivElement | null>(null);

  const editor = useSlate();

  const nodeEntry = Editor.above(editor, {
    match: (n) => Element.isElement(n) && n.type === ACTION_TYPE.link,
  });
  const [linkNode, path] = (nodeEntry || []) as NodeEntry<CustomElement>;

  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(linkNode?.href || '');
  }, [linkNode]);

  useEffect(() => {
    const linkEditorEl = linkEditorRef.current;

    if (!linkEditorEl || !offsetX || !offsetY || !linkNode) return;

    const linkDOMNode = ReactEditor.toDOMNode(editor, linkNode);
    const {
      x: nodeX,
      y: nodeY,
      height: nodeHeight,
    } = linkDOMNode.getBoundingClientRect();

    linkEditorEl.style.display = 'block';
    linkEditorEl.style.top = `${nodeY + nodeHeight - offsetY}px`;
    linkEditorEl.style.left = `${nodeX - offsetX}px`;
  }, [editor, offsetX, offsetY, linkNode]);

  const handleHrefChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHref(event.target.value);
  };

  const handleHrefApply = (_event: MouseEvent<HTMLButtonElement>) => {
    Transforms.setNodes(editor, { href }, { at: path });
  };

  const isSaveAvailable = href && isUrl(href);

  if (!offsetX || !offsetY || !linkNode) {
    return null;
  }

  return (
    <MuiPaper ref={linkEditorRef} className={classes.root}>
      <MuiGrid container justifyContent="space-between">
        <MuiBox flex={1} mr={1}>
          <Input
            fullWidth
            name="href"
            startAdornment={
              <MuiBox display="flex" mr={1.3}>
                <MuiLinkIcon style={{ transform: 'rotate(-45deg)' }} />
              </MuiBox>
            }
            value={href}
            inputProps={{ role: LINK_EDITOR_ROLE.input }}
            onChange={handleHrefChange}
          />
        </MuiBox>
        <Button
          role={LINK_EDITOR_ROLE.button}
          variant="contained"
          className={classes.button}
          disabled={!isSaveAvailable}
          onClick={handleHrefApply}
        >
          Save
        </Button>
      </MuiGrid>
    </MuiPaper>
  );
};

export default LinkEditor;

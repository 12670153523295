import { useMutation, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { updateNotification } from '../requests/updateNotification';
import {
  FetchNotificationsResult,
  NotificationSchema,
  UpdateNotificationParams,
  UpdateNotificationResult,
} from '../typings';
import { NOTIFICATION_STATUS, QUERY_NAME } from '../constants';

type UseUpdateNotificationContext = {
  previousNotifications?: FetchNotificationsResult;
};

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const result = useMutation<
    UpdateNotificationResult,
    Error,
    UpdateNotificationParams,
    UseUpdateNotificationContext
  >(updateNotification, {
    onMutate: async ({ notificationId, params }) => {
      await queryClient.cancelQueries(QUERY_NAME.notifications);

      const previousNotifications =
        queryClient.getQueryData<FetchNotificationsResult>(
          QUERY_NAME.notifications
        );

      if (previousNotifications) {
        queryClient.setQueryData<FetchNotificationsResult>(
          QUERY_NAME.notifications,
          params.status === NOTIFICATION_STATUS.deleted
            ? {
                count: previousNotifications.count - 1,
                data: previousNotifications.data.filter(
                  (notification) => !(notification.id === notificationId)
                ),
              }
            : {
                count: previousNotifications.count,
                data: previousNotifications.data.map((notification) =>
                  notification.id === notificationId
                    ? {
                        ...notification,
                        status: params.status as NotificationSchema['status'],
                      }
                    : notification
                ),
              }
        );
      }

      return { previousNotifications };
    },
    onError: (error, _, context) => {
      if (context?.previousNotifications) {
        queryClient.setQueryData<FetchNotificationsResult>(
          QUERY_NAME.notifications,
          context.previousNotifications
        );
      }

      enqueueErrorSnackbar({ message: error.message });
    },
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_NAME.notifications);
    },
  });

  return result;
};

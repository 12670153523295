import { useMemo } from 'react';

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { convertToPascalCase } from 'src/utils/helpers/common';

import { deleteContentHubPage } from '../requests/deleteContentHubPage';
import {
  ContentHubObjectSchema,
  DeleteContentHubPageVariables,
} from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: ContentHubObjectSchema[];
}

export type UseDeleteContentHubPageResult = UseMutationResult<
  void,
  Error,
  DeleteContentHubPageVariables,
  UseMutationContext
>;

export const useDeleteContentHubPage = (
  parentId: number
): UseDeleteContentHubPageResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = useMemo(() => {
    return parentId
      ? [QUERY_NAME.fetchContentHubChildPages, parentId]
      : [QUERY_NAME.fetchContentHubPages];
  }, [parentId]);

  const mutation = useMutation<
    void,
    Error,
    DeleteContentHubPageVariables,
    UseMutationContext
  >(({ id }) => deleteContentHubPage(id), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<ContentHubObjectSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (_data, { id }, context) => {
      const deletedPage = context?.previousData?.find((page) => page.id === id);

      queryClient.setQueryData<ContentHubObjectSchema[]>(
        queryKey,
        (previous = []) => previous.filter((page) => page.id !== id)
      );

      if (deletedPage) {
        enqueueSuccessSnackbar({
          message: 'page.content_management.api.pages.delete',
          intlValues: {
            type: convertToPascalCase(deletedPage.type),
            name: deletedPage.name,
          },
        });
      }
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

import { FC, MouseEventHandler, ReactNode } from 'react';

import { useSlate } from 'slate-react';
import cx from 'classnames';

import MuiIconButton from '@material-ui/core/IconButton';

import { CustomElement, FormattedText } from 'src/typings/slate';

import { isBlockActive, toggleBlock } from '../../utils/block';
import { isMarkActive, toggleMark } from '../../utils/mark';
import { isLinkActive, toggleLink } from '../../utils/link';
import { ACTION_TYPE } from '../../constants';

import { useStyles } from './styles';

interface Props {
  icon: ReactNode;
  variant: ACTION_TYPE;
  format: CustomElement['type'] | keyof FormattedText;
}

const EditorButton: FC<Props> = ({ format, icon, variant }) => {
  const classes = useStyles();

  const editor = useSlate();

  const isActive =
    isBlockActive(editor, format as CustomElement['type']) ||
    isMarkActive(editor, format as keyof FormattedText) ||
    isLinkActive(editor, format as CustomElement['type']);

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (variant === ACTION_TYPE.block) {
      toggleBlock(editor, format as CustomElement['type']);
    }

    if (variant === ACTION_TYPE.mark) {
      toggleMark(editor, format as keyof FormattedText);
    }

    if (variant === ACTION_TYPE.link) {
      toggleLink(editor);
    }
  };

  return (
    <MuiIconButton
      className={cx(classes.button, {
        isActive,
      })}
      onMouseDown={handleMouseDown}
    >
      {icon}
    </MuiIconButton>
  );
};

export default EditorButton;

import { AxiosResponse } from 'axios';

import { RequestForgotPasswordParams } from '../typings';
import { publicGateway } from '../../../config';

export const requestForgotPassword = async ({
  email,
}: RequestForgotPasswordParams): Promise<AxiosResponse> => {
  const response = await publicGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).post(`/users/password/forgot`, {
    email,
  });

  return response;
};

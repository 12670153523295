import { FC } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CopyrightIcon from '@material-ui/icons/Copyright';

import {
  QUANTERIX_FACEBOOK_PAGE,
  QUANTERIX_LINKEDIN_PAGE,
  QUANTERIX_LOCATION,
  QUANTERIX_TECH_EMAIL,
  QUANTERIX_TECH_PHONE_NUMBER,
  QUANTERIX_TWITTER_ACC,
  QUANTERIX_WEBSITE,
  QUANTERIX_YOUTUBE_CHANNEL,
} from 'src/utils/constants/contacts';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

const AppFooter: FC = () => {
  const classes = useStyles();

  return (
    <MuiGrid
      container
      className={classes.appFooter}
      data-testid={TEST_ID.appFooter}
    >
      <MuiGrid item xs={12} className={classes.footerTop}>
        <MuiGrid container>
          <MuiGrid item xs={6}>
            <MuiGrid container alignItems="flex-start">
              <MuiGrid item xs={12} lg="auto" className={classes.footerLogo}>
                <a target="_blank" rel="noreferrer" href={QUANTERIX_WEBSITE}>
                  <MuiTypography component="span">Quanterix</MuiTypography>
                  <MuiTypography component="span">
                    The Science of Precision Health
                  </MuiTypography>
                </a>
              </MuiGrid>
              <MuiGrid item xs={12} md="auto">
                <MuiBox className={classes.footerLocation}>
                  <MuiTypography component="span">
                    <FormattedMessage id="app_footer.location" />
                  </MuiTypography>
                  <MuiTypography component="address">
                    {QUANTERIX_LOCATION}
                  </MuiTypography>
                </MuiBox>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
          <MuiGrid item xs={6}>
            <MuiGrid container className={classes.footerTopRightColumn}>
              <MuiGrid item xs={12} lg="auto">
                <MuiBox className={classes.footerContactUs}>
                  <MuiTypography component="span">
                    <FormattedMessage id="app_footer.contact_us" />
                  </MuiTypography>
                  <MuiTypography
                    component="a"
                    href={`mailto:${QUANTERIX_TECH_EMAIL}`}
                  >
                    {QUANTERIX_TECH_EMAIL}
                  </MuiTypography>
                  <MuiTypography
                    component="a"
                    href={`tel:${QUANTERIX_TECH_EMAIL}`}
                  >
                    {QUANTERIX_TECH_PHONE_NUMBER}
                  </MuiTypography>
                </MuiBox>
              </MuiGrid>
              <MuiGrid item xs={12} lg="auto">
                <MuiGrid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  className={classes.socials}
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={QUANTERIX_FACEBOOK_PAGE}
                  >
                    <FacebookIcon fontSize="small" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={QUANTERIX_TWITTER_ACC}
                  >
                    <TwitterIcon fontSize="small" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={QUANTERIX_LINKEDIN_PAGE}
                  >
                    <LinkedInIcon fontSize="small" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={QUANTERIX_YOUTUBE_CHANNEL}
                  >
                    <YouTubeIcon fontSize="small" />
                  </a>
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid item xs={12} className={classes.footerBottom}>
        <MuiGrid container justifyContent="space-between">
          <MuiGrid item xs={6} className={classes.footerCopyRights}>
            Quanterix <CopyrightIcon className={classes.copyrightIcon} />{' '}
            {new Date().getFullYear()} All rights reserved
          </MuiGrid>
          <MuiGrid item xs={6} className={classes.privacyAndTerms}>
            <Link to="/legal/privacy-policy">
              <FormattedMessage id="app_footer.privacy_policy" />
            </Link>
            <Link to="/legal/terms-and-conditions">
              <FormattedMessage id="app_footer.terms_and_conditions" />
            </Link>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};

export default AppFooter;

import { useQuery } from 'react-query';

import { fetchFAQSections } from '../requests/fetchFAQSections';
import { FAQSectionSchema } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseQueryOptions {
  enabled?: boolean;
}

export const useFetchFAQSections = (
  options: UseQueryOptions = defaultOptions
) => {
  const result = useQuery<FAQSectionSchema[]>(
    [QUERY_NAME.fetchFAQSections],
    fetchFAQSections,
    options
  );

  return result;
};

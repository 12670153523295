import { SortingState } from 'src/context/SortingContext';
import { ORDER } from 'src/utils/constants/table';

export const SMALL_AMOUNT_ROWS_PER_PAGE = 10;
export const MEDIUM_AMOUNT_ROWS_PER_PAGE = 20;
export const LARGE_AMOUNT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_OPTIONS = [
  SMALL_AMOUNT_ROWS_PER_PAGE,
  MEDIUM_AMOUNT_ROWS_PER_PAGE,
  LARGE_AMOUNT_ROWS_PER_PAGE,
];

export const DEFAULT_ROWS_PER_PAGE = SMALL_AMOUNT_ROWS_PER_PAGE;
export const DEFAULT_PAGE = 0;

export enum ORDER_BY_FIELDS {
  name = 'name',
  createdAt = 'created_at',
}

export const DEFAULT_ORDER = ORDER.asc;
export const DEFAULT_ORDER_BY = ORDER_BY_FIELDS.name;
export const DEFAULT_SORTING_OPTION_WITH_SEARCH_INDEX = 0;
export const DEFAULT_SORTING_OPTION_INDEX = 3;

export const SORTING_OPTIONS: { labelId: string; value: SortingState }[] = [
  {
    labelId: 'page.content_hub.table.sorting.best_match',
    value: {
      order: null,
      orderBy: null,
    },
  },
  {
    labelId: 'page.content_hub.table.sorting.asc_creation',
    value: {
      order: ORDER.asc,
      orderBy: ORDER_BY_FIELDS.createdAt,
    },
  },
  {
    labelId: 'page.content_hub.table.sorting.desc_creation',
    value: {
      order: ORDER.desc,
      orderBy: ORDER_BY_FIELDS.createdAt,
    },
  },
  {
    labelId: 'page.content_hub.table.sorting.asc_name',
    value: {
      order: ORDER.asc,
      orderBy: ORDER_BY_FIELDS.name,
    },
  },
  {
    labelId: 'page.content_hub.table.sorting.desc_name',
    value: {
      order: ORDER.desc,
      orderBy: ORDER_BY_FIELDS.name,
    },
  },
];

export const PASSWORD_ACCEPTABLE_SYMBOLS = `! @ # $ % & * ( + _ ) . / ? ' “ ; : \\ |`;

export const PASSWORD_MIN_LENGTH = 10;
export const PASSWORD_MAX_LENGTH = 30;

export const PASSWORD_PATTERN = {
  atLeastOneDigit: '(?=.*[0-9])',
  atLeastOneLowercase: '(?=.*[a-z])',
  atLeastOneUppercase: '(?=.*[A-Z])',
  atLeastOneSymbol: `(?=.*[${PASSWORD_ACCEPTABLE_SYMBOLS.replace(
    '\\',
    '\\\\'
  ).replace(/\s/g, '')}])`,
  hasNoSpace: '(?=.*[\\s])',
};

export enum PASSWORD_FIELD_NAME {
  password = 'password',
  currentPassword = 'currentPassword',
  newPassword = 'newPassword',
  repeatPassword = 'repeatPassword',
}

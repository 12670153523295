import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  sortBySelect: {
    fontFamily: theme.fonts.fontFamily.medium,
    height: 'unset !important',
    paddingTop: 'unset !important',
    paddingBottom: 'unset !important',
    textAlign: 'right',
    marginRight: 1,

    '& ~ [class*=MuiOutlinedInput-notchedOutline]': {
      border: 'none',
    },
  },

  sortBySelectOutline: {
    border: 'none',
  },

  sortByPlaceholder: {
    '&[data-value=""]': {
      opacity: 1,
      fontFamily: theme.fonts.fontFamily.medium,
      color: `${theme.palette.text.primary} !important`,
    },
  },

  title: {
    fontSize: 16,
    fontFamily: theme.fonts.fontFamily.bold,
    color: theme.palette.secondary.main,
  },
}));

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { deleteFAQSectionItem } from '../requests/deleteFAQSectionItem';
import {
  DeleteFAQSectionItemVariables,
  FAQSectionItemSchema,
} from '../typings';
import { QUERY_NAME } from '../constants';
import { getQueryKey } from '../utils';

interface UseMutationContext {
  previousData?: FAQSectionItemSchema[];
}

export type UseDeleteFAQSectionItemResult = UseMutationResult<
  FAQSectionItemSchema,
  Error,
  DeleteFAQSectionItemVariables,
  UseMutationContext
>;

export const useDeleteFAQSectionItem = (): UseDeleteFAQSectionItemResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const mutation = useMutation<
    FAQSectionItemSchema,
    Error,
    DeleteFAQSectionItemVariables,
    UseMutationContext
  >(deleteFAQSectionItem, {
    onMutate: async ({ section_id }) => {
      await queryClient.cancelQueries(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      const previousData = queryClient.getQueryData<FAQSectionItemSchema[]>(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id)
      );

      return { previousData };
    },

    onSuccess: (_data, { id, section_id }, context) => {
      const deletedSection = context?.previousData?.find(
        (item) => item.id === id
      );

      queryClient.setQueryData<FAQSectionItemSchema[]>(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id),
        (previous = []) => previous.filter((item) => item.id !== id)
      );

      if (deletedSection) {
        enqueueSuccessSnackbar({
          message: 'page.faq_management.api.section_item.delete',
        });
      }
    },

    onError: ({ message }, { section_id }, context) => {
      queryClient.setQueryData(
        getQueryKey(QUERY_NAME.fetchFAQSectionItems, section_id),
        context?.previousData
      );

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

import { FC, useMemo, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiPaper from '@material-ui/core/Paper';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiTypography from '@material-ui/core/Typography';
import MuiTooltip from '@material-ui/core/Tooltip';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiIconDelete from '@material-ui/icons/Close';
import MuiIconArrowUpward from '@material-ui/icons/ArrowUpward';
import MuiIconArrowDownward from '@material-ui/icons/ArrowDownward';

import {
  ContentHubObjectSchema,
  MOVE_DIRECTION,
  useDeleteContentHubPage,
  useOrderChangeForContentHubPage,
} from 'src/api/endpoints/contentHub';
import { PROTECTED_ROUTES_MAP } from 'src/router';
import IconButton from 'src/components/elements/IconButton';
import { useContentManagementContext } from 'src/pages/ContentManagement/context/ContentManagementContext';
import { PAGE_SLUG } from 'src/api/endpoints/contentHub/constants';

import { useStyles } from './styles';

interface Props {
  item: ContentHubObjectSchema;
}

const NavCard: FC<Props> = ({ item }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const location = useLocation();
  const history = useHistory();
  const { pageId } = useParams<{ pageId: string }>();

  const { isLegalDocumentsPage, isLegalDocumentsCategory } =
    useContentManagementContext();

  const [isHovered, setIsHovered] = useState(false);

  const {
    mutate: changeOrder,
    isMoveDownLoading,
    isMoveUpLoading,
  } = useOrderChangeForContentHubPage({
    parentId: Number(pageId),
  });

  const { mutate: deleteContentHubPage, isLoading: isDeleting } =
    useDeleteContentHubPage(Number(pageId));

  const parsedDate = useMemo(
    () => format(new Date(item.updated_at), 'dd MMM yyyy, HH:mm'),
    [item.updated_at]
  );

  const authorShortCut = useMemo(() => {
    if (!item.author || item.author.length === 0) {
      return '';
    }

    const nameParts = item.author.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[1] || firstName.charAt(1);

    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }, [item.author]);

  const handleItemClick = () => {
    const newLocationPathname =
      PROTECTED_ROUTES_MAP.adminContentManagementPageView
        .replace(':pageId', String(item.id))
        .replace(':pageName', encodeURIComponent(item.name));

    if (location.pathname !== newLocationPathname) {
      history.push(newLocationPathname);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMoveUp = () => {
    changeOrder({ id: item.id, direction: MOVE_DIRECTION.up });
  };

  const handleMoveDown = () => {
    changeOrder({ id: item.id, direction: MOVE_DIRECTION.down });
  };

  const handleDelete = () => {
    deleteContentHubPage({ id: item.id });
  };

  const isActionsLoading = isDeleting || isMoveDownLoading || isMoveUpLoading;
  const isActionsVisible = isHovered || isActionsLoading;
  const isActionsDisabled =
    isLegalDocumentsPage ||
    isLegalDocumentsCategory ||
    item.slug === PAGE_SLUG.publicAccess ||
    item.slug === PAGE_SLUG.legalDocuments;

  return (
    <MuiPaper
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MuiButtonBase className={classes.buttonBase} onClick={handleItemClick} />
      <MuiGrid container>
        <MuiGrid item xs={12}>
          <MuiGrid container justifyContent="space-between" alignItems="center">
            <MuiTypography variant="h6" color="secondary">
              {item.name}
            </MuiTypography>
            {!isActionsDisabled && isActionsVisible && (
              <MuiGrid item>
                <MuiGrid container>
                  <MuiBox ml={1}>
                    <MuiTooltip
                      title={formatMessage({ id: 'app.button.move_up' })}
                    >
                      <div>
                        <IconButton
                          size="small"
                          loading={isMoveUpLoading}
                          disabled={isMoveUpLoading}
                          onClick={handleMoveUp}
                        >
                          <MuiIconArrowUpward color="secondary" />
                        </IconButton>
                      </div>
                    </MuiTooltip>
                  </MuiBox>
                  <MuiBox ml={1}>
                    <MuiTooltip
                      title={formatMessage({ id: 'app.button.move_down' })}
                    >
                      <div>
                        <IconButton
                          size="small"
                          loading={isMoveDownLoading}
                          disabled={isMoveDownLoading}
                          onClick={handleMoveDown}
                        >
                          <MuiIconArrowDownward color="secondary" />
                        </IconButton>
                      </div>
                    </MuiTooltip>
                  </MuiBox>
                  <MuiBox ml={1}>
                    <MuiTooltip
                      title={formatMessage({ id: 'app.button.delete' })}
                    >
                      <div>
                        <IconButton
                          size="small"
                          loading={isDeleting}
                          disabled={isDeleting}
                          onClick={handleDelete}
                        >
                          <MuiIconDelete color="secondary" />
                        </IconButton>
                      </div>
                    </MuiTooltip>
                  </MuiBox>
                </MuiGrid>
              </MuiGrid>
            )}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid container alignItems="center">
        <MuiTypography variant="subtitle2" component="span">
          <FormattedMessage
            id="page.content_management.nav_card.last_update"
            values={{ date: <strong>{parsedDate}</strong> }}
          />
        </MuiTypography>
        <MuiTooltip title={item.author}>
          <MuiAvatar className={classes.avatar}>
            <MuiTypography variant="body1">{authorShortCut}</MuiTypography>
          </MuiAvatar>
        </MuiTooltip>
      </MuiGrid>
    </MuiPaper>
  );
};

export default NavCard;

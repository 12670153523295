export const DefaultTextFile = '_text.txt';
export const DefaultHeaderFile = '_header.txt';
export const DefaultImageFile = '_img.png';
export const DefaultPropertiesFile = '_props.json';
export const OrderFile = '_order.json';
export const DS_Store = '.DS_Store';
export const FileStatuses = {
  New: '__new__',
  Pending: '__pending__',
  Published: 'published',
};

export enum UserStatuses {
  'UNCONFIRMED' = 'Pending Confirmation',
  'CONFIRMED' = 'Confirmed',
  'FORCE_CHANGE_PASSWORD' = 'Force Change Password',
  'RESET_REQUIRED' = 'Reset Password Required',
  'ARCHIVED' = 'Archived',
  'COMPROMISED' = 'Compromised',
}

export const AllDefaultFiles = [
  DefaultTextFile,
  DefaultImageFile,
  DefaultPropertiesFile,
  DefaultHeaderFile,
  OrderFile,
  DS_Store,
];

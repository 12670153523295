export const convertToPascalCase = (str: string) => {
  return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const snakeToCamelCase = <T extends string>(s: T): T => {
  return s.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  ) as T;
};

export const convertToTitleCase = (s: string) => {
  return convertToPascalCase(
    s.replace(/([-_][a-z])/g, (group) =>
      group.replace('-', ' ').replace('_', ' ')
    )
  );
};

interface NullifyDateTimeOptions {
  isEndOfTheDay?: boolean;
}

interface AddTimeToDateOptions {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export const nullifyDateTime = (
  date: Date,
  { isEndOfTheDay = false }: NullifyDateTimeOptions = {}
): Date => {
  const endOfTheDayTime = (24 * 60 - 1) * 60000;
  const stringDate = date.toDateString();
  const calculatedDate = isEndOfTheDay
    ? new Date(stringDate).getTime() + endOfTheDayTime
    : stringDate;

  return new Date(calculatedDate);
};

export const isNumeric = (value: string): boolean => {
  return /^-?\d+$/.test(value);
};

export const addTimeToDate = (
  date: Date,
  { days = 0, hours = 0, minutes = 0, seconds = 0 }: AddTimeToDateOptions
): Date => {
  const today = new Date();
  today.setDate(date.getDate() + days);
  today.setHours(date.getHours() + hours);
  today.setMinutes(date.getMinutes() + minutes);
  today.setSeconds(date.getSeconds() + seconds);

  return today;
};

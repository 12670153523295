import { FC, ReactNode, useState } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import cx from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import MuiBox from '@material-ui/core/Box';

import Checkbox from '@quanterix-ui/core/Checkbox';
import Button from '@quanterix-ui/core/Button';

import ControlledFirstNameTextField from 'src/components/form/ControlledFirstNameTextField';
import ControlledLastNameTextField from 'src/components/form/ControlledLastNameTextField';
import ControlledEmailTextField from 'src/components/form/ControlledEmailTextField';
import LegalDocumentModal from 'src/components/LegalDocumentModal';
import { validatePhone } from 'src/utils/StringHelper';
import { PUBLIC_ROUTES_MAP } from 'src/router';
import { useSessionStorage } from 'src/utils/hooks';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { LegalDocument, PAGE_SLUG } from 'src/api/endpoints/contentHub';
import { useFetchPublicPageBySlug } from 'src/api/endpoints/contentHubPublic';

import { useStyles } from '../styles';
import { PersonalFormSchema } from '../typings';
import { DEFAULT_PERSONAL_FORM_VALUES } from '../constants';

interface PersonalFormProps {
  onSubmit: () => void;
}

const PersonalForm: FC<PersonalFormProps> = ({ onSubmit }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  // Prefetching legal docs in background to avoid loading state
  const { data: privacyPolicy } = useFetchPublicPageBySlug(
    PAGE_SLUG.privacyPolicy
  );
  const { data: termsAndConditions } = useFetchPublicPageBySlug(
    PAGE_SLUG.termsAndConditions
  );

  const [legalDocumentModalOpen, setLegalDocumentModalOpen] =
    useState<LegalDocument>();

  const { storedValue: personalData, setValue: setPersonalData } =
    useSessionStorage<PersonalFormSchema>(
      LOCAL_STORAGE_KEY.personalFormData,
      DEFAULT_PERSONAL_FORM_VALUES
    );

  const formMethods = useForm<PersonalFormSchema>({
    defaultValues: personalData,
  });

  const {
    getValues,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const getLegalData = () => {
    const personalData = getValues();

    return {
      privacy_policy_version: personalData.acceptTerms
        ? privacyPolicy?.updated_at || ''
        : '',
      terms_of_use_version: personalData.acceptTerms
        ? termsAndConditions?.updated_at || ''
        : '',
    };
  };

  const handleFormChange = () => {
    const personalData = getValues();
    const legal = getLegalData();

    setPersonalData({ ...personalData, legal });
  };

  const handleOpenLegalDocumentModal = (document: LegalDocument) => {
    setLegalDocumentModalOpen(document);
  };

  const handleCloseLegalDocumentModal = () => {
    setLegalDocumentModalOpen(undefined);
  };

  const handlePhoneInputChange = (phone: string) => {
    const personalData = getValues();
    const legal = getLegalData();

    setPersonalData({ ...personalData, phone, legal });
  };

  return (
    <>
      <LegalDocumentModal
        legalDocumentPath={legalDocumentModalOpen!}
        open={!!legalDocumentModalOpen}
        onClose={handleCloseLegalDocumentModal}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
          <MuiBox mb={3}>
            <ControlledFirstNameTextField name="firstName" />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledLastNameTextField name="lastName" />
          </MuiBox>
          <MuiBox mb={3}>
            <ControlledEmailTextField name="email" />
          </MuiBox>
          <MuiBox mb={3}>
            <FormLabel required htmlFor="phone" className={classes.formLabel}>
              <FormattedMessage id="app.form.label.phone" />
            </FormLabel>
            <Controller
              name="phone"
              rules={{ validate: validatePhone }}
              render={({ field: { value, onChange } }) => (
                <PhoneInput
                  containerClass={classes.phoneInput}
                  inputProps={{ id: 'phone' }}
                  disableCountryGuess={false}
                  value={value}
                  placeholder={formatMessage({
                    id: 'app.form.placeholder.phone',
                  })}
                  onChange={(value) => {
                    handlePhoneInputChange(value);
                    onChange(value);
                  }}
                />
              )}
            />
            <FormHelperText error variant="filled">
              {errors.phone?.message}
            </FormHelperText>
          </MuiBox>
          <MuiBox component={FormGroup} pl={1} className={classes.checkboxes}>
            <FormControlLabel
              control={
                <Controller
                  name="confirmAge"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={value} onChange={onChange} />
                  )}
                />
              }
              label={
                <Typography
                  variant="caption"
                  color={errors.confirmAge ? 'error' : 'initial'}
                >
                  <FormattedMessage id="page.request_access.form.checkbox.confirm" />
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Controller
                  name="acceptTerms"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={value} onChange={onChange} />
                  )}
                />
              }
              label={
                <Typography
                  variant="caption"
                  color={errors.acceptTerms ? 'error' : 'initial'}
                >
                  <FormattedMessage
                    id="page.request_access.form.checkbox.terms"
                    values={{
                      terms: (msg: ReactNode) => (
                        <ButtonBase
                          className={classes.link}
                          onClick={() =>
                            handleOpenLegalDocumentModal(
                              PAGE_SLUG.termsAndConditions
                            )
                          }
                        >
                          {msg}
                        </ButtonBase>
                      ),
                      privacy: (msg: ReactNode) => (
                        <ButtonBase
                          className={classes.link}
                          onClick={() =>
                            handleOpenLegalDocumentModal(
                              PAGE_SLUG.privacyPolicy
                            )
                          }
                        >
                          {msg}
                        </ButtonBase>
                      ),
                    }}
                  />
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Controller
                  name="acceptNewsletter"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox checked={value} onChange={onChange} />
                  )}
                />
              }
              label={
                <Typography variant="caption">
                  <FormattedMessage id="page.request_access.form.checkbox.newsletter" />
                </Typography>
              }
            />
          </MuiBox>
          <MuiBox
            mt={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Button type="submit" color="secondary" className={classes.button}>
              <FormattedMessage id="app.button.continue" />
            </Button>
            <MuiBox pt={2}>
              <Typography>
                <FormattedMessage
                  id="page.request_access.link.login"
                  values={{
                    link: (msg: ReactNode) => (
                      <Typography component="span" variant="body2">
                        <Link
                          to={PUBLIC_ROUTES_MAP.login}
                          className={cx(classes.link, classes.fontBold)}
                        >
                          {msg}
                        </Link>
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </MuiBox>
          </MuiBox>
        </form>
      </FormProvider>
    </>
  );
};

export default PersonalForm;

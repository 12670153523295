import { FC, useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import RichTextEditor, { getPlainText } from 'src/components/RichTextEditor';
import { CustomElement } from 'src/typings/slate';

import { useStyles } from './styles';
import { FIELD_NAME, MAX_LENGTH } from './constants';

interface Props {
  disabled: boolean;
}

const AnswerTextField: FC<Props> = ({ disabled }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const { [FIELD_NAME]: initialValue } = watch();

  const handleChange = useCallback(
    (value: CustomElement[]) => {
      const valueLength = getPlainText(value).length;
      const isDirty = valueLength > 0;

      setValue(FIELD_NAME, JSON.stringify(value), {
        shouldValidate: isDirty,
        shouldDirty: isDirty,
      });
    },
    [setValue]
  );

  useEffect(() => {
    register(FIELD_NAME, {
      validate: {
        required: (value) => {
          return (
            getPlainText(JSON.parse(value)).length > 0 ||
            'page.faq_management.input.answer.validation.required'
          );
        },

        maxLength: (value) => {
          return (
            getPlainText(JSON.parse(value)).length <= MAX_LENGTH ||
            'page.faq_management.input.answer.validation.max_length'
          );
        },
      },
    });
  }, [register]);

  const error = errors[FIELD_NAME];

  return (
    <MuiBox flex={1}>
      <RichTextEditor
        readOnly={disabled}
        initialValue={initialValue}
        maxLength={MAX_LENGTH}
        withError={!!error}
        placeholder={formatMessage({
          id: 'page.faq_management.input.answer.placeholder',
        })}
        width="100%"
        height={220}
        onChange={handleChange}
      />
      <MuiBox className={classes.formErrorsContainer}>
        {error && (
          <MuiFormHelperText error>
            <FormattedMessage
              id={error.message}
              values={{
                maxLength: MAX_LENGTH,
              }}
            />
          </MuiFormHelperText>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default AnswerTextField;

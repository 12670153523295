export enum QUERY_NAME {
  notifications = 'notifications',
}

export enum NOTIFICATION_STATUS {
  available = 'AVAILABLE',
  acknowledged = 'ACKNOWLEDGED',
  deleted = 'DELETED',
}

export const NEW_NOTIFICATION_ACTION = 'new_notification';

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    marginTop: 5,
  },

  paper: {
    boxShadow: '0 2px 10px rgba(0 0 0 / 10%)',
  },
}));

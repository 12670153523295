import { FC, useState } from 'react';

import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import IconAdd from '@material-ui/icons/Add';

import Button from '@quanterix-ui/core/Button';

import { ContentHubObjectSchema } from 'src/api/endpoints/contentHub';

import { useRelatedPageType } from '../../hooks/useRelatedPageType';
import ContentCreationDialog from '../ContentCreationDialog';

import { useStyles } from './styles';

interface Props {
  item?: ContentHubObjectSchema;
}

const CreateButton: FC<Props> = ({ item }) => {
  const classes = useStyles();

  const { single: relatedType } = useRelatedPageType({ type: item?.type });
  const lowerCasedRelatedType = relatedType.toLowerCase();

  const [isOpen, setIsOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  return (
    <MuiBox>
      <Button
        variant="text"
        color="secondary"
        startIcon={<IconAdd />}
        className={classes.button}
        onClick={handleDialogOpen}
      >
        <MuiBox mt={0.5}>
          <FormattedMessage id="app.button.add" /> {lowerCasedRelatedType}
        </MuiBox>
      </Button>
      <div>
        {createPortal(
          <ContentCreationDialog
            open={isOpen}
            type={item?.type}
            onClose={handleDialogClose}
          />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </MuiBox>
  );
};

export default CreateButton;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  clickableUserCell: {
    cursor: 'pointer',
  },

  highlightedUserCell: {
    color: theme.palette.secondary.main,
  },

  tooltip: {
    margin: '-16px 0 0 16px ',
  },

  labelInactive: {
    fontSize: '10px',
  },

  labelActive: {
    fontSize: '10px',
    color: theme.palette.primary.main,
  },
}));

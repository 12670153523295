import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { updateCurrentUser } from '../requests/updateCurrentUser';
import { CurrentUserSchema, UpdateCurrentUserVariables } from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: CurrentUserSchema;
}

export type UseUpdateCurrentUserResult = UseMutationResult<
  CurrentUserSchema,
  Error,
  UpdateCurrentUserVariables,
  UseMutationContext
>;

export const useUpdateCurrentUser = (): UseUpdateCurrentUserResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const queryKey = [QUERY_NAME.currentUser];

  const mutation = useMutation<
    CurrentUserSchema,
    Error,
    UpdateCurrentUserVariables,
    UseMutationContext
  >(updateCurrentUser, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<CurrentUserSchema>(queryKey);

      return { previousData };
    },

    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);

      queryClient.setQueryData<CurrentUserSchema>(
        [QUERY_NAME.currentUser],
        data
      );
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

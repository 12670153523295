import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface NotFoundContextResult {
  pageExists: boolean;
  setPageExists: Dispatch<SetStateAction<boolean>>;
}

export const NotFoundContext = createContext<NotFoundContextResult>({
  pageExists: true,
  setPageExists: () => {
    // void
  },
});

const NotFoundContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [pageExists, setPageExists] = useState(true);

  const notFoundContext: NotFoundContextResult = {
    pageExists,
    setPageExists,
  };

  return (
    <NotFoundContext.Provider value={notFoundContext}>
      {children}
    </NotFoundContext.Provider>
  );
};

export const useNotFoundContext = (): NotFoundContextResult => {
  const context = useContext(NotFoundContext);

  if (!context) {
    throw new Error(
      'useNotFoundContext must be used within a NotFoundContextProvider'
    );
  }

  return context;
};

export default NotFoundContextProvider;

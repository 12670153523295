import { FC, PropsWithChildren, ReactNode } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@quanterix-ui/core/Button';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props {
  open: boolean;
  title: ReactNode;
  hideCancelButton?: boolean;
  disableBackdrop?: boolean;
  cancelButtonText?: string;
  acceptButtonText?: string;
  loading?: boolean;
  onAccept: () => void;
  onClose: () => void;
  onCancel?: () => void;
}

const ConfirmDialog: FC<PropsWithChildren<Props>> = ({
  open,
  title,
  children,
  disableBackdrop,
  loading = false,
  hideCancelButton = false,
  cancelButtonText = 'Cancel',
  acceptButtonText = 'Ok',
  onAccept,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) onCancel();

    onClose();
  };

  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      data-testid={TEST_ID.confirmDialog}
      onClose={!disableBackdrop ? onClose : undefined}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      {children}
      <DialogActions className={classes.actions}>
        {!hideCancelButton && (
          <Button
            variant="outlined"
            data-testid={TEST_ID.cancelButton}
            onClick={handleCancel}
          >
            {cancelButtonText}
          </Button>
        )}
        <Button
          loading={loading}
          disabled={loading}
          data-testid={TEST_ID.acceptButton}
          onClick={handleAccept}
        >
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

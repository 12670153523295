import { useCallback, useState } from 'react';

import { useCognito } from 'src/aws/Cognito';
import { useSnackbar } from 'src/utils/hooks';

import { USER_POOL_ID } from '../constants';

import { useIdentityService } from './useIdentityService';

interface UseConfirmUserResult {
  inProgress: boolean;
  confirmUser: (username: string) => void;
}

export const useConfirmUser = (): UseConfirmUserResult => {
  const { identityService } = useIdentityService();
  const { isAdmin } = useCognito();
  const { enqueueErrorSnackbar } = useSnackbar();

  const [inProgress, setInProgress] = useState(false);

  const confirmUser = useCallback(
    (username: string) => {
      if (identityService && isAdmin) {
        setInProgress(true);

        identityService.adminConfirmSignUp(
          {
            UserPoolId: USER_POOL_ID as string,
            Username: username,
          },
          (error) => {
            if (error) {
              enqueueErrorSnackbar({
                message: `User confirmation failed. ${error.message}`,
              });

              throw new Error(error.message);
            }

            setInProgress(false);
          }
        );
      }
    },
    [identityService, isAdmin, enqueueErrorSnackbar]
  );

  return { inProgress, confirmUser };
};

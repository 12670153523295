import { FC, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

import RichTextEditor from 'src/components/RichTextEditor';
import { CustomElement } from 'src/typings/slate';
import { useContentManagementContext } from 'src/pages/ContentManagement/context/ContentManagementContext';

import { TEST_ID } from '../../constants';

import { useStyles } from './styles';
import { FIELD_NAME } from './constants';

interface Props {
  isEditable?: boolean;
}

const DescriptionTextField: FC<Props> = ({ isEditable = true }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { isLegalDocumentsPage } = useContentManagementContext();

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { [FIELD_NAME]: initialValue } = watch();

  const handleChange = useCallback(
    (value: CustomElement[]) => {
      setValue(FIELD_NAME, JSON.stringify(value), {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const error = errors[FIELD_NAME];

  return (
    <MuiBox flex={1} data-testid={TEST_ID.descriptionTextField}>
      <RichTextEditor
        readOnly={!isEditable}
        initialValue={initialValue}
        placeholder={formatMessage({
          id: 'page.content_management.input.placeholder.description',
        })}
        width="100%"
        height={isLegalDocumentsPage ? 480 : 200}
        onChange={handleChange}
      />
      <MuiBox className={classes.formErrorsContainer}>
        {error && (
          <MuiFormHelperText error>
            <FormattedMessage id={error.message} />
          </MuiFormHelperText>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default DescriptionTextField;

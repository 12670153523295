import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxWidth: 800,
    padding: 40,
  },

  flexibleContainer: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },

  dialogTitle: {
    paddingLeft: 1,
    paddingRight: 1,
    textAlign: 'left',

    '& [class*=MuiTypography-root]': {
      fontWeight: 400,
    },
  },

  dialogContent: {
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 12,
    paddingBottom: 20,
    textAlign: 'left',
  },

  editInputBase: {
    width: '100%',
    maxWidth: '100%',
  },

  editInput: {
    fontWeight: 400,
  },

  formErrorsContainer: {
    minHeight: 26,
    paddingTop: 2,

    '& p': {
      margin: 0,
    },
  },
}));

export const TEST_ID = {
  activityTracker: 'activityTracker',
};

export const SESSION_TIMEOUT_MINUTES = 30;
export const SESSION_WARNING_DURATION_MINUTES = 2;
export const SESSION_CHECK_INTERVAL = 1000;

export const ACTIVITY_TRACKER_DEBOUNCE_THRESHOLD = 1000;
export const ACTIVITY_TRACKER_THRESHOLD =
  ACTIVITY_TRACKER_DEBOUNCE_THRESHOLD * 2;

export const INACTIVITY_WARNING_THRESHOLD =
  (SESSION_TIMEOUT_MINUTES - SESSION_WARNING_DURATION_MINUTES) * 60;

import { alpha, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

import { MAX_TITLE_ROW_COUNT } from './constants';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    padding: '20px 35px',

    '&.unread': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },

    '&:hover $kebabButton': {
      display: 'initial',
    },
  },

  title: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: MAX_TITLE_ROW_COUNT,

    '&.collapsedTitle': {
      display: '-webkit-box',
    },
  },

  description: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },

  date: {
    fontFamily: theme.fonts.fontFamily.light,
    fontSize: 12,
  },

  expandIcon: {
    verticalAlign: 'middle',
  },

  unreadBadge: {
    right: 15,
    top: 10,
    alignSelf: 'flex-start',
  },

  unreadDot: {
    width: 10,
    height: 10,
    borderRadius: 10,
  },

  kebabButton: {
    display: 'none',
    position: 'absolute',
    right: 15,
    padding: 0,

    '&.show': {
      display: 'initial',
    },
  },

  optionsMenu: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.16)}`,
    borderRadius: 5,
  },
}));

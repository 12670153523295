import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { createFAQSection } from '../requests/createFAQSection';
import { CreateFAQSectionVariables, FAQSectionSchema } from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: FAQSectionSchema[];
}

export type UseCreateFAQSectionResult = UseMutationResult<
  FAQSectionSchema,
  Error,
  CreateFAQSectionVariables,
  UseMutationContext
>;

export const useCreateFAQSection = (): UseCreateFAQSectionResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = [QUERY_NAME.fetchFAQSections];

  const mutation = useMutation<
    FAQSectionSchema,
    Error,
    CreateFAQSectionVariables,
    UseMutationContext
  >(createFAQSection, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<FAQSectionSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (_data, _variables, _context) => {
      queryClient.invalidateQueries(queryKey);

      enqueueSuccessSnackbar({
        message: 'page.faq_management.api.section.create',
      });
    },

    onError: ({ message }, _variables, _context) => {
      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

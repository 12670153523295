import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export interface ContextResult {
  isLegalDocumentsCategory: boolean;
  setIsLegalDocumentsCategory: Dispatch<SetStateAction<boolean>>;

  isLegalDocumentsPage: boolean;
  setIsLegalDocumentsPage: Dispatch<SetStateAction<boolean>>;
}

const ContentManagementContext = createContext<ContextResult>({
  isLegalDocumentsCategory: false,
  setIsLegalDocumentsCategory: () => {
    // void
  },

  isLegalDocumentsPage: false,
  setIsLegalDocumentsPage: () => {
    // void
  },
});

const ContentManagementContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isLegalDocumentsCategory, setIsLegalDocumentsCategory] =
    useState(false);
  const [isLegalDocumentsPage, setIsLegalDocumentsPage] = useState(false);

  return (
    <ContentManagementContext.Provider
      value={{
        isLegalDocumentsCategory,
        setIsLegalDocumentsCategory,

        isLegalDocumentsPage,
        setIsLegalDocumentsPage,
      }}
    >
      {children}
    </ContentManagementContext.Provider>
  );
};

export const useContentManagementContext = (): ContextResult => {
  const context = useContext(ContentManagementContext);

  if (!context) {
    throw new Error(
      'useContentManagementContext must be used within a ContentManagementContextProvider'
    );
  }

  return context;
};

export default ContentManagementContextProvider;

import { useEffect, useMemo, useState } from 'react';

import { useFetchContentHubPage } from '../hooks/useFetchContentHubPage';

export const useGetFullPageName = (pageId?: number) => {
  const [pageIdToFetch, setPageIdToFetch] = useState<number | null>();
  const [pageNames, setPageNames] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { refetch } = useFetchContentHubPage(pageIdToFetch!, {
    enabled: false,
    onSuccess: (page) => {
      setPageIdToFetch(page.parent_id);

      if (!page.parent_id) {
        setIsLoading(false);
      }

      setPageNames([page.name, ...pageNames]);
    },
  });

  useEffect(() => {
    if (pageId) {
      setIsLoading(true);
      setPageNames([]);
      setPageIdToFetch(pageId);
    }
  }, [pageId]);

  useEffect(() => {
    if (pageIdToFetch) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIdToFetch]);

  const fullName = useMemo(() => pageNames.join(' | '), [pageNames]);

  return { fullName, isLoading };
};

import { FC, memo } from 'react';

import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

import {
  ContentHubObjectSchema,
  PAGE_TYPE,
  QUERY_NAME,
  useFetchContentHubChildPages,
  useToggleIsPageOpen,
} from 'src/api/endpoints/contentHub';
import { PROTECTED_ROUTES_MAP } from 'src/router';

import NavLink from '../NavLink';

interface Props {
  item: ContentHubObjectSchema;
  localRoutePath: string;
  rootPath: string;
}

const TreeViewItem: FC<Props> = ({ item, localRoutePath, rootPath }) => {
  const queryClient = useQueryClient();

  const managementPageMatch = useRouteMatch({
    path: PROTECTED_ROUTES_MAP.adminContentManagement,
    exact: false,
    strict: false,
  });
  const isManagementPage = Boolean(managementPageMatch);

  const { toggleIsPageOpen } = useToggleIsPageOpen();

  const nestingLevel = localRoutePath.split('/').length - 1;

  const isExpandable = item.type !== PAGE_TYPE.section;

  const { data: childPages, refetch } = useFetchContentHubChildPages(
    Number(item.id),
    { enabled: item.children && item.is_open }
  );

  const handlePageMouseEnterAndPrefetch = () => {
    if (item.is_open) return;

    const previousData = queryClient.getQueryData([
      QUERY_NAME.fetchContentHubChildPages,
      item.id,
    ]);

    if (previousData) return;

    refetch();
  };

  const handleRootClick = () => {
    if (isExpandable) {
      toggleIsPageOpen(item);
    }
  };

  if (item.total_published_files === 0 && !isManagementPage) {
    return null;
  }

  return (
    <>
      <NavLink
        isManagementPage={isManagementPage}
        item={item}
        nestingLevel={nestingLevel}
        rootPath={rootPath}
        onClick={handleRootClick}
        onMouseEnter={handlePageMouseEnterAndPrefetch}
      />
      {isExpandable && (
        <Collapse in={item.is_open}>
          <List disablePadding component="div">
            {childPages.map((page) => (
              <TreeViewItem
                key={page.id}
                item={page}
                rootPath={rootPath}
                localRoutePath={`${localRoutePath}/${page.name}`}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const MemoiazedTreeViewItem = memo(TreeViewItem);

export default MemoiazedTreeViewItem;

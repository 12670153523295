import { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import MuiCloseIcon from '@material-ui/icons/Close';

import Button from '@quanterix-ui/core/Button';
import NavButton from '@quanterix-ui/core/NavButton';

import { OLD_PUBLIC_ROUTES_MAP } from 'src/router';
import { useCognito } from 'src/aws/Cognito';
import {
  useFetchCurrentUser,
  useUpdateCurrentUser,
} from 'src/api/endpoints/users';
import { useFetchPublicPageBySlug } from 'src/api/endpoints/contentHubPublic';
import { PAGE_SLUG } from 'src/api/endpoints/contentHub';
import IconButton from 'src/components/elements/IconButton';

import { useStyles } from './styles';

const CookieBanner: FC = () => {
  const classes = useStyles();

  const { user } = useCognito();

  const { data: cookiePolicy } = useFetchPublicPageBySlug(
    PAGE_SLUG.cookiePolicy
  );

  const { data: currentUser, isFetching: isCurrentUserFetching } =
    useFetchCurrentUser({ enabled: !!user });

  const {
    mutate: updateCurrentUser,
    isLoading: isUpdateCurrentUserInProgress,
  } = useUpdateCurrentUser();

  const handleAcceptAllCookies = () => {
    if (!currentUser || !cookiePolicy) {
      return;
    }

    updateCurrentUser({
      legal: {
        ...currentUser.legal,
        cookies_policy_versions: {
          ...currentUser.legal.cookies_policy_versions,
          all: cookiePolicy?.updated_at,
        },
      },
    });
  };

  if (
    !currentUser ||
    isCurrentUserFetching ||
    currentUser?.legal.cookies_policy_versions.all === cookiePolicy?.updated_at
  ) {
    return null;
  }

  return (
    <MuiBox className={classes.root}>
      <MuiBox className={classes.container}>
        <MuiBox mb={2}>
          <MuiGrid container alignItems="center" justifyContent="space-between">
            <MuiTypography className={classes.title} variant="h2">
              <FormattedMessage id="cookie.banner.title" />
            </MuiTypography>
            <IconButton
              className={classes.iconButton}
              edge="end"
              onClick={handleAcceptAllCookies}
            >
              <MuiCloseIcon />
            </IconButton>
          </MuiGrid>
        </MuiBox>
        <MuiTypography className={classes.text} variant="h4">
          <FormattedMessage
            id="cookie.banner.content"
            values={{
              privacy: (msg: ReactNode) => (
                <Link
                  to={OLD_PUBLIC_ROUTES_MAP.legal.replace(
                    ':name',
                    'privacy-policy'
                  )}
                  target="_blank"
                >
                  {msg}
                </Link>
              ),
              cookie: (msg: ReactNode) => (
                <Link
                  to={OLD_PUBLIC_ROUTES_MAP.legal.replace(
                    ':name',
                    'cookie-policy'
                  )}
                  target="_blank"
                >
                  {msg}
                </Link>
              ),
            }}
          />
        </MuiTypography>
        <MuiBox mt={4}>
          <MuiGrid container justifyContent="flex-end">
            <MuiBox mr={2}>
              <NavButton
                variant="text"
                // to={PRIVATE_ROUTES_MAP.profileCookieManager} TODO: should be uncommented when profileCookieManager page will be ready
                to={OLD_PUBLIC_ROUTES_MAP.legal.replace(
                  ':name',
                  'privacy-policy'
                )}
              >
                <FormattedMessage id="cookie.banner.button.manage" />
              </NavButton>
            </MuiBox>
            <Button
              variant="contained"
              loading={isUpdateCurrentUserInProgress}
              onClick={handleAcceptAllCookies}
            >
              <FormattedMessage id="cookie.banner.button.accept_all" />
            </Button>
          </MuiGrid>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default CookieBanner;

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import { useSnackbar } from 'src/utils/hooks';
import {
  QUERY_NAME as CH_QUERY_NAME,
  RequestSearchContentHubFilesResult,
  RequestSearchContentHubFilesVariables,
} from 'src/api/endpoints/contentHub';
import { usePaginationContext } from 'src/context/PaginationContext';
import { useSortingContext } from 'src/context/SortingContext';
import { useContentFilterContext } from 'src/pages/ContentHubViewer/context/ContentFilterContext';
import { PRIVATE_ROUTES_MAP } from 'src/router';

import { addToBookmarks } from '../requests/addToBookmarks';
import { deleteFromBookmarks } from '../requests/deleteFromBookmarks';
import { QUERY_NAME } from '../constants';
import { BookmarksFilters, ToggleBookmarkVariables } from '../typings';

interface UseMutationContext {
  previousData?: RequestSearchContentHubFilesResult;
}

export type UseToggleBookmarkResult = UseMutationResult<
  void,
  Error,
  ToggleBookmarkVariables,
  UseMutationContext
>;

interface UseToggleBookmarkParams {
  searchParams: RequestSearchContentHubFilesVariables;
  bookmarksFilter: BookmarksFilters;
}

export const useToggleBookmark = ({
  searchParams,
  bookmarksFilter,
}: UseToggleBookmarkParams): UseToggleBookmarkResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const bookmarksPageMatch = useRouteMatch({
    path: PRIVATE_ROUTES_MAP.contentHubViewerBookmarks,
    exact: true,
    strict: true,
  });
  const isBookmarksPage = Boolean(bookmarksPageMatch);

  const { pagination } = usePaginationContext();
  const { sorting } = useSortingContext();
  const { contentFilter } = useContentFilterContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let queryKey: any;
  if (isBookmarksPage) {
    queryKey = [
      QUERY_NAME.fetchBookmarks,
      bookmarksFilter,
      { pagination, sorting },
    ];
  } else {
    queryKey = [
      CH_QUERY_NAME.searchContentHubFiles,
      searchParams,
      contentFilter,
      { pagination, sorting },
    ];
  }

  const mutation = useMutation<
    void,
    Error,
    ToggleBookmarkVariables,
    UseMutationContext
  >(
    (file) => {
      if (file.bookmark) {
        return deleteFromBookmarks(file);
      }

      return addToBookmarks(file);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);

        const previousData =
          queryClient.getQueryData<RequestSearchContentHubFilesResult>(
            queryKey
          );

        return { previousData };
      },

      onSuccess: (_data, { id, name, bookmark }) => {
        queryClient.setQueryData<RequestSearchContentHubFilesResult>(
          queryKey,
          (previous) => {
            if (isBookmarksPage) {
              return {
                count: previous ? previous.count - 1 : 0,
                data: previous?.data.filter((file) => file.id !== id) || [],
              };
            }

            return {
              count: previous?.count || 0,
              data:
                previous?.data.map((file) => {
                  if (file.id === id) {
                    file.bookmark = !bookmark;
                  }

                  return file;
                }) || [],
            };
          }
        );
        queryClient.invalidateQueries([QUERY_NAME.fetchBookmarksCount]);
        queryClient.invalidateQueries([QUERY_NAME.fetchBookmarksFilters]);

        const message = bookmark
          ? 'page.bookmarks.snackbar.remove.success'
          : 'page.bookmarks.snackbar.add.success';

        enqueueSuccessSnackbar({
          message,
          intlValues: {
            name,
          },
        });
      },

      onError: ({ message }, _variables, context) => {
        queryClient.setQueryData(queryKey, context?.previousData);

        enqueueErrorSnackbar({ message });
      },
    }
  );

  return mutation;
};

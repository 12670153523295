import { useQuery } from 'react-query';

import { fetchFAQSectionItems } from '../requests/fetchFAQSectionItems';
import { FAQSectionItemSchema } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseQueryOptions {
  enabled?: boolean;
}

export const useFetchFAQSectionItems = (
  sectionId: number,
  options: UseQueryOptions = defaultOptions
) => {
  const result = useQuery<FAQSectionItemSchema[]>(
    [QUERY_NAME.fetchFAQSectionItems, sectionId],
    () => fetchFAQSectionItems(sectionId),
    options
  );

  return result;
};

import { Editor, Element, Node, Transforms } from 'slate';

import { CustomText } from 'src/typings/slate';

export const withLinks = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Node.children(editor, path));

      for (const [child, childPath] of children) {
        if (
          Element.isElement(child) &&
          child.type === 'link' &&
          (child.children[0] as CustomText).text === ''
        ) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, {
              type: 'paragraph',
              children: [{ text: '' }],
            });
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
};

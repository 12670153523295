export const TEST_ID = {
  fileComponent: 'fileComponent',
  fileApprovalButton: 'fileApprovalButton',
  filePublishButton: 'filePublishButton',
  fileEditButton: 'fileEditButton',
  fileRemoveButton: 'fileRemoveButton',
  fileCancelButton: 'fileCancelButton',
  fileSaveButton: 'fileSaveButton',
  inProgressSpinner: 'inProgressSpinner',
  fileTypeIcon: 'fileTypeIcon',
  fileTextField: 'fileTextField',
};

export const FILE_NAME_RESTRICTED_SYMBOLS = ['/'];
export const FILE_NAME_PATTERN = new RegExp(
  `^[^${FILE_NAME_RESTRICTED_SYMBOLS.join('')}]+$`
);
export const FILE_NAME_MAX_LENGTH = 100;

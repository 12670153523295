import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  input: {
    '& .form-control': {
      width: '100%',
      height: 40,
      paddingTop: 3,
      borderRadius: 10,
      color: theme.palette.text.primary,
      ...theme.typography.body1,

      '&::placeholder': {
        color: '#c3c3c3',
      },
    },

    '& .flag-dropdown, & .selected-flag': {
      borderRadius: '10px 0 0 10px',

      '&.open, & .selected-flag.open': {
        borderRadius: '10px 0 0 10px',
      },
    },

    '&:hover': {
      '& .form-control, & .flag-dropdown': {
        borderColor: theme.palette.grey['500'],
      },

      '&.invalid': {
        '& .form-control, & .flag-dropdown': {
          borderColor: theme.palette.error.main,
        },
      },
    },

    '&.invalid': {
      '& .form-control, & .flag-dropdown': {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

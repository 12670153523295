import { AxiosRequestConfig } from 'axios';

import { privateGateway } from '../../../config';
import { RequestMalwareScanResult } from '../typings';

export const requestBlobStorageMalwareScan = async (
  file_names: string[],
  cancelToken?: AxiosRequestConfig['cancelToken']
): Promise<RequestMalwareScanResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_SUBMISSION_API_GATEWAY as string
  ).post(`/form/file/malware_scan`, { file_names }, { cancelToken });

  return response.data;
};

import { FC, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import MuiBox from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import MuiButton from '@material-ui/core/Button';

import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { useSessionStorage } from 'src/utils/hooks';
import { formatInstrumentRequest } from 'src/utils/helpers/form';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import ControlledCompanyTextField from 'src/components/form/ControlledCompanyTextField';
import ControlledAddressTextField from 'src/components/form/ControlledAddressTextField';
import ControlledZipCodeTextField from 'src/components/form/ControlledZipCodeTextField';
import ControlledCityTextField from 'src/components/form/ControlledCityTextField';
import ControlledStateTextField from 'src/components/form/ControlledStateTextField';
import ControlledCountrySelect from 'src/components/form/ControlledCountrySelect';
import ControlledInstrumentSelect from 'src/components/form/ControlledInstrumentSelect';

import { useStyles } from '../styles';
import { OrganizationFormSchema, PersonalFormSchema } from '../typings';
import { DEFAULT_ORGANIZATION_FORM_VALUES } from '../constants';

interface OrganizationFormProps {
  onBack: () => void;
}

const OrganizationForm: FC<OrganizationFormProps> = ({ onBack }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLoading, signUp } = useCognito();

  const { storedValue: personalData, removeValue: removePersonalData } =
    useSessionStorage<PersonalFormSchema>(
      LOCAL_STORAGE_KEY.personalFormData,
      onBack
    );

  const {
    storedValue: organizationData,
    setValue: setOrganizationData,
    removeValue: removeOrganizationData,
  } = useSessionStorage<OrganizationFormSchema>(
    LOCAL_STORAGE_KEY.organizationFormData,
    DEFAULT_ORGANIZATION_FORM_VALUES
  );

  const formMethods = useForm<OrganizationFormSchema>({
    defaultValues: organizationData || DEFAULT_ORGANIZATION_FORM_VALUES,
  });

  const { handleSubmit, watch } = formMethods;
  const watchedOrganizationData = watch();

  const handleFormSubmit = async (data: OrganizationFormSchema) => {
    try {
      await signUp({
        first_name: personalData!.firstName,
        last_name: personalData!.lastName,
        phone:
          personalData!.phone.indexOf('+') === 0
            ? personalData!.phone
            : `+${personalData!.phone}`,
        email: personalData!.email,
        company: data.company,
        address1: data.address1,
        address2: data.address2,
        zip: data.zipCode,
        city: data.city,
        state: data.state,
        country: data.country,
        instruments: formatInstrumentRequest(data.instruments),
        website: '',
        legal: personalData!.legal,
      });

      removePersonalData();
      removeOrganizationData();

      enqueueSnackbar(
        formatMessage({ id: 'page.request_access.snackbar.submit' }),
        {
          variant: 'success',
          persist: true,
          action: (key) => (
            <MuiButton
              variant="text"
              color="inherit"
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <FormattedMessage id="app.button.ok" />
            </MuiButton>
          ),
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isEqual(watchedOrganizationData, organizationData)) {
      setOrganizationData(watchedOrganizationData);
    }
  }, [organizationData, watchedOrganizationData, setOrganizationData]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <MuiBox mb={3}>
          <ControlledCompanyTextField name="company" />
        </MuiBox>
        <MuiBox mb={3}>
          <ControlledAddressTextField
            name="address1"
            label={formatMessage({ id: 'app.form.label.address1' })}
          />
        </MuiBox>
        <MuiBox mb={3}>
          <ControlledAddressTextField
            required={false}
            name="address2"
            label={formatMessage({ id: 'app.form.label.address2' })}
          />
        </MuiBox>
        <MuiBox mb={3} className={classes.cityInputWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <ControlledZipCodeTextField
                name="zipCode"
                className={classes.zipCodeInput}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <ControlledCityTextField name="city" />
            </Grid>
          </Grid>
        </MuiBox>
        <MuiBox mb={3}>
          <ControlledStateTextField name="state" />
        </MuiBox>
        <MuiBox mb={3}>
          <ControlledCountrySelect name="country" />
        </MuiBox>
        <MuiBox>
          <ControlledInstrumentSelect name="instruments" />
        </MuiBox>
        <MuiBox
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button
            type="submit"
            color="secondary"
            loading={isLoading}
            disabled={isLoading}
            className={classes.button}
          >
            <FormattedMessage id="page.request_access.form.button.request_access" />
          </Button>
          <MuiBox pt={2}>
            <ButtonBase className={classes.link} onClick={onBack}>
              <FormattedMessage id="app.button.back" />
            </ButtonBase>
          </MuiBox>
        </MuiBox>
      </form>
    </FormProvider>
  );
};

export default OrganizationForm;

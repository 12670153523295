import { useMutation, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { updateNotification } from '../requests/updateNotification';
import {
  FetchNotificationsResult,
  NotificationSchema,
  UpdateNotificationParams,
  UpdateNotificationResult,
} from '../typings';
import { NOTIFICATION_STATUS, QUERY_NAME } from '../constants';

type UseUpdateNotificationContext = {
  previousNotifications?: FetchNotificationsResult;
};

type UseUpdateNotificationParams = {
  notificationIds: string[];
  params: UpdateNotificationParams['params'];
};

export const useBatchUpdateNotifications = () => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const result = useMutation<
    UpdateNotificationResult[],
    Error,
    UseUpdateNotificationParams,
    UseUpdateNotificationContext
  >(
    ({ notificationIds, params }) =>
      Promise.all(
        notificationIds.map((notificationId) => {
          return updateNotification({ notificationId, params });
        })
      ),
    {
      onMutate: async ({ params }) => {
        await queryClient.cancelQueries(QUERY_NAME.notifications);

        const previousNotifications =
          queryClient.getQueryData<FetchNotificationsResult>(
            QUERY_NAME.notifications
          );

        if (previousNotifications) {
          queryClient.setQueryData<FetchNotificationsResult>(
            QUERY_NAME.notifications,
            params.status === NOTIFICATION_STATUS.deleted
              ? { count: 0, data: [] }
              : {
                  count: previousNotifications.count,
                  data: previousNotifications.data.map((notification) => ({
                    ...notification,
                    status: params.status as NotificationSchema['status'],
                  })),
                }
          );
        }

        return { previousNotifications };
      },

      onError: (error, _, context) => {
        if (context?.previousNotifications) {
          queryClient.setQueryData<FetchNotificationsResult>(
            QUERY_NAME.notifications,
            context.previousNotifications
          );
        }

        enqueueErrorSnackbar({ message: error.message });
      },

      onSettled: () => {
        queryClient.invalidateQueries(QUERY_NAME.notifications);
      },
    }
  );

  return result;
};

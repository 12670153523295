import { FC, PropsWithChildren } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiContainer from '@material-ui/core/Container';

import AppHeader from 'src/components/AppHeader';
import AppFooter from 'src/components/AppFooter';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

interface Props {
  isAdvancedDisabled?: boolean;
}

const PublicLayout: FC<PropsWithChildren<Props>> = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiGrid
      container
      className={classes.root}
      data-testid={TEST_ID.layoutContainer}
    >
      <MuiGrid item xs={12} className={classes.header}>
        <AppHeader isPublic />
      </MuiGrid>
      <MuiGrid item xs={12}>
        <MuiGrid container>
          <MuiBox flex={1}>
            <OverlayScrollbarsComponent
              options={{
                scrollbars: { autoHide: 'move' },
                sizeAutoCapable: false,
              }}
              className={classes.scrollbars}
            >
              <MuiContainer className={classes.content}>
                <>{children}</>
              </MuiContainer>
              <AppFooter />
            </OverlayScrollbarsComponent>
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
export default PublicLayout;

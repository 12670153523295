import { useQuery, UseQueryResult } from 'react-query';

import { FileSearchItemSchema } from 'src/api/endpoints/contentHub';

import { fetchPublicPageFiles } from '../requests/fetchPublicPageFiles';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseFetchPublicPageFilesOptions {
  enabled?: boolean;
}

export interface UseFetchPublicPageFilesResult
  extends Omit<UseQueryResult, 'data'> {
  data: FileSearchItemSchema[];
}

export const useFetchPublicPageFiles = (
  pageId: number,
  options: UseFetchPublicPageFilesOptions = defaultOptions
): UseFetchPublicPageFilesResult => {
  const { data = [], ...restValues } = useQuery<FileSearchItemSchema[]>(
    [QUERY_NAME.fetchPublicPageFiles, pageId],
    () => fetchPublicPageFiles(pageId),
    { enabled: options.enabled }
  );

  return {
    data,
    ...restValues,
  } as UseFetchPublicPageFilesResult;
};

import { QueryBuilder } from 'odata-query-builder';

import { FetchCompanyNamesParams, FetchCompanyNamesResult } from '../typings';
import { publicGateway } from '../../../config';

export const fetchCompanyNames = async ({
  filter,
}: FetchCompanyNamesParams = {}): Promise<FetchCompanyNamesResult> => {
  const query = new QueryBuilder();

  query.count();

  if (filter?.value) {
    query.filter((f) =>
      f.filterPhrase(
        `contains(${filter.field}, '${encodeURIComponent(filter.value)}')`
      )
    );
  }

  const response = await publicGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get(`/user_requests/suggestions/company${query.toQuery()}`);

  return response.data;
};

import { FC, MouseEvent, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import MuiBox from '@material-ui/core/Box';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiAppsIcon from '@material-ui/icons/Apps';

import IconButton from 'src/components/elements/IconButton';
import { PRIVATE_ROUTES_MAP } from 'src/router';
import { useServicesStore } from 'src/micro-frontend/stores/ServicesStore';
import { convertToTitleCase } from 'src/utils/helpers/common';

const SubServicesMenu: FC = observer(() => {
  const history = useHistory();

  const { availableServices, isLoading } = useServicesStore();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (pathname: string) => {
    return (_event: MouseEvent<HTMLElement>) => {
      setAnchorEl(null);
      history.push(pathname);
    };
  };

  if (!isLoading && availableServices.length === 0) {
    return null;
  }

  return (
    <MuiBox>
      <IconButton loading={isLoading} color="inherit" onClick={handleMenuClick}>
        <MuiAppsIcon />
      </IconButton>
      <MuiMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {availableServices.map((service) => {
          const pathname = PRIVATE_ROUTES_MAP.microFrontendSubmodule
            .replace(':moduleName', service.client!)
            .replace(':submoduleName', service.name!);

          return (
            <MuiMenuItem
              key={service.name}
              onClick={handleMenuItemClick(pathname)}
            >
              {convertToTitleCase(
                (service.displayName || service.name) as string
              )}
            </MuiMenuItem>
          );
        })}
      </MuiMenu>
    </MuiBox>
  );
});

export default SubServicesMenu;

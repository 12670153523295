import { privateGateway } from '../../../config';
import { ToggleBookmarkVariables } from '../typings';

export const deleteFromBookmarks = async ({
  id,
}: ToggleBookmarkVariables): Promise<void> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).delete('/bookmarks', {
    data: {
      file_id: id,
    },
  });

  return response.data;
};

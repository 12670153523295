import { FC, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import { PRIVATE_ROUTES_MAP } from 'src/router';
import { ExternalService, useCognito } from 'src/aws/Cognito';
import { IS_PREPROD_ENV, IS_PROD_ENV } from 'src/utils/constants/env';

import { TEST_ID } from '../../constants';
import NavCard from '../NavCard';

import { useStyles } from './styles';

const Services: FC = () => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { availableExternalServices } = useCognito();

  const services = useMemo<ExternalService[]>(
    () =>
      [
        {
          title: formatMessage({
            id: 'page.dashboard.services.content_hub.title',
          }),
          description: formatMessage({
            id: 'page.dashboard.services.content_hub.description',
          }),
          footerLinkText: formatMessage({
            id: 'app.link.discover_more',
          }),
          pathname: PRIVATE_ROUTES_MAP.contentHub,
        },
        {
          title: formatMessage({
            id: 'page.dashboard.services.accelerator_form.title',
          }),
          description: formatMessage({
            id: 'page.dashboard.services.accelerator_form.description',
          }),
          footerLinkText: formatMessage({
            id: 'app.link.discover_more',
          }),
          pathname: PRIVATE_ROUTES_MAP.accelerator,
        },
        {
          title: formatMessage({
            id: 'page.dashboard.services.support_file_upload.title',
          }),
          description: formatMessage({
            id: 'page.dashboard.services.support_file_upload.description',
          }),
          footerLinkText: formatMessage({
            id: 'page.dashboard.services.support_file_upload.link',
          }),
          pathname: PRIVATE_ROUTES_MAP.supportFileUpload,
        },
        {
          title: formatMessage({
            id: 'page.dashboard.services.faq.title',
          }),
          description: formatMessage({
            id: 'page.dashboard.services.faq.description',
          }),
          footerLinkText: formatMessage({
            id: 'app.link.discover_more',
          }),
          pathname: PRIVATE_ROUTES_MAP.faq,
        },
      ].filter((service) => {
        // TODO: This filter temporary, for release 3.0
        if (
          (IS_PROD_ENV || IS_PREPROD_ENV) &&
          service.pathname === PRIVATE_ROUTES_MAP.supportFileUpload
        ) {
          return false;
        }

        return true;
      }),
    [formatMessage]
  );

  const allServices = [...services, ...availableExternalServices];

  return (
    <MuiBox data-testid={TEST_ID.services}>
      <MuiGrid container>
        <MuiGrid item xs={12}>
          <MuiBox mb={3}>
            <MuiTypography variant="subtitle1">
              <FormattedMessage id="page.dashboard.services.title" />
            </MuiTypography>
          </MuiBox>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiBox className={classes.gridContainer}>
            {allServices.map((service, index) => (
              <NavCard
                key={service.title}
                index={index}
                title={service.title}
                description={service.description}
                footerLinkText={service.footerLinkText}
                url={service.pathname}
              />
            ))}
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

export default Services;

import { FC, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  useConfirmUserRequest,
  useDeleteUserRequest,
  useFetchUserRequests,
} from 'src/api/endpoints/userRequests';
import { PROTECTED_ROUTES_MAP } from 'src/router';
import UserList, {
  DEFAULT_FILTER,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  transformUsersDataSchema,
  UserSchema,
} from 'src/components/UserList';
import FilterProvider from 'src/context/FilterContext';
import PaginationProvider from 'src/context/PaginationContext';

const UserRequests: FC = () => {
  const history = useHistory();

  const {
    data: requestUsers,
    refetch: refetchUsers,
    isPreviousData,
    isFetching,
    isLoading,
  } = useFetchUserRequests({ keepPreviousData: true });

  const transformedUsers = transformUsersDataSchema({
    requestUsers: requestUsers?.data || [],
  });

  const { mutate: confirmUserRequest } = useConfirmUserRequest();
  const { mutate: deleteUserRequest } = useDeleteUserRequest();

  const handleConfirmUserRequest = useCallback(
    (user: UserSchema) => {
      confirmUserRequest(user.id);
    },
    [confirmUserRequest]
  );

  const handleDeleteUserRequest = useCallback(
    (user: UserSchema) => {
      deleteUserRequest(user.id);
    },
    [deleteUserRequest]
  );

  const handleRedirectToView = useCallback(
    (user: UserSchema) => {
      history.push(
        PROTECTED_ROUTES_MAP.adminUserRequestProfile.replace(
          ':requestId',
          user.id
        )
      );
    },
    [history]
  );

  return (
    <UserList
      withPagination
      users={transformedUsers}
      usersCount={requestUsers?.count}
      isPreviousData={isPreviousData}
      isFetching={isFetching}
      isLoading={isLoading}
      onConfirmUserRequest={handleConfirmUserRequest}
      onRefetchUsers={refetchUsers}
      onDeleteUser={handleDeleteUserRequest}
      onRedirectToView={handleRedirectToView}
    />
  );
};

const UserRequestsContainer: FC = () => {
  return (
    <FilterProvider defaultFilter={DEFAULT_FILTER}>
      <PaginationProvider
        defaultPagination={{
          page: DEFAULT_PAGE,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
          skip: DEFAULT_PAGE * DEFAULT_ROWS_PER_PAGE,
        }}
      >
        <UserRequests />
      </PaginationProvider>
    </FilterProvider>
  );
};

export default UserRequestsContainer;

import { Editor } from 'slate';

interface WithTextLimitationOptions {
  maxLength?: number;
}

export const withTextLimitation =
  ({ maxLength = 5000 }: WithTextLimitationOptions) =>
  (editor: Editor) => {
    const { insertText } = editor;

    editor.insertText = (text) => {
      if (Editor.string(editor, []).length < maxLength) {
        insertText(text);
      }
    };

    return editor;
  };

import { FC, useState } from 'react';

import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { createPortal } from 'react-dom';

import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';

import {
  useFetchContentHubPage,
  useUpdateContentHubPage,
} from 'src/api/endpoints/contentHub';
import {
  useDeleteBlobStorageFiles,
  useUploadBlobStorageFile,
} from 'src/api/endpoints/blobStorage';
import { UPLOAD_SOURCE } from 'src/utils/constants/uploadSource';
import { PROTECTED_ROUTES_MAP } from 'src/router';
import ConfirmDialog from 'src/components/ConfirmDialog';

import { useEditableContentContext } from '../../context/EditableContent';
import { useContentManagementContext } from '../../context/ContentManagementContext';
import { FORM_VALUES } from '../../constants';

import { useStyles } from './styles';

interface Props {
  onCancel: () => void;
}

const ContentActionBar: FC<Props> = ({ onCancel }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { pageId, pageName } = useParams<{
    pageId: string;
    pageName: string;
  }>();
  const history = useHistory();

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const {
    isEditable,
    setIsEditable,
    imageKeyToBeDeleted,
    setImageKeyToBeDeleted,
  } = useEditableContentContext();

  const { isLegalDocumentsPage, isLegalDocumentsCategory } =
    useContentManagementContext();

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext();
  const { image } = watch();

  const { data: currentPage } = useFetchContentHubPage(Number(pageId), {
    enabled: Boolean(pageId),
  });

  const { mutateAsync: uploadBlobStorageFile, isLoading: isUploading } =
    useUploadBlobStorageFile();

  const { mutateAsync: updateContentHubPage, isLoading: isUpdating } =
    useUpdateContentHubPage(Number(pageId));

  const { mutateAsync: deleteBlobStorageFiles, isLoading: isDeleting } =
    useDeleteBlobStorageFiles();

  const handleCancelClick = () => {
    setIsEditable(false);
    setValue(FORM_VALUES.image, imageKeyToBeDeleted, { shouldDirty: false });
    setImageKeyToBeDeleted(null);
    onCancel();
  };

  const handlePublishClick: SubmitHandler<FieldValues> = async (data) => {
    let blobStorageKey = currentPage?.img_blob_storage_key || null;

    if (image) {
      ({ blobStorageKey } = await uploadBlobStorageFile({
        file: image,
        source: UPLOAD_SOURCE.documents,
      }));
    }

    if (imageKeyToBeDeleted) {
      await deleteBlobStorageFiles({ file_names: [imageKeyToBeDeleted] });
    }

    try {
      // NOTE: navigate to new path should be before update call, otherwise redirect will go to the "page not found"
      if (pageName !== data.title) {
        const newLocationPathname =
          PROTECTED_ROUTES_MAP.adminContentManagementPageView
            .replace(':pageId', String(pageId))
            .replace(':pageName', encodeURIComponent(data.title));

        history.push(newLocationPathname);
      }

      await updateContentHubPage({
        name: data.title,
        text: data.description,
        img_blob_storage_key: imageKeyToBeDeleted ? null : blobStorageKey,
      });
    } catch (error) {
      history.push(PROTECTED_ROUTES_MAP.adminContentManagement);
    }

    setIsEditable(false);
  };

  const handleUpdateDialogCancel = () => {
    setIsUpdateDialogOpen(false);
  };

  const handleUpdateDialogOpen = () => {
    setIsUpdateDialogOpen(true);
  };

  const handleUpdateDialogAccept = () => {
    handleSubmit(handlePublishClick)();
    setIsUpdateDialogOpen(false);
  };

  if (!isEditable) {
    return null;
  }

  const isLoading = isUploading || isUpdating || isDeleting;
  const isLegalDocuments = isLegalDocumentsCategory || isLegalDocumentsPage;
  const handlePublish = isLegalDocuments
    ? handleUpdateDialogOpen
    : handleSubmit(handlePublishClick);

  return (
    <>
      <MuiBox className={classes.root}>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={handleCancelClick}
        >
          <FormattedMessage id="app.button.cancel" />
        </Button>
        <Button
          loading={isLoading}
          disabled={!isDirty || isLoading}
          onClick={handlePublish}
        >
          {isLegalDocuments && <FormattedMessage id="app.button.update" />}
          {!isLegalDocuments && <FormattedMessage id="app.button.publish" />}
        </Button>
      </MuiBox>
      <div>
        {createPortal(
          <ConfirmDialog
            disableBackdrop
            open={isUpdateDialogOpen}
            title={formatMessage({
              id: 'page.content_management.legal_documents.dialog.confirmation_message',
            })}
            acceptButtonText={formatMessage({
              id: 'app.button.yes',
            })}
            cancelButtonText={formatMessage({ id: 'app.button.no' })}
            onClose={handleUpdateDialogCancel}
            onCancel={handleUpdateDialogCancel}
            onAccept={handleUpdateDialogAccept}
          />,

          /* document.createElement('div') - is for testing purpose */
          document.getElementById('modal-root') || document.createElement('div')
        )}
      </div>
    </>
  );
};

export default ContentActionBar;

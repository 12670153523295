import { privateGateway } from '../../../config';
import {
  DeleteFAQSectionItemVariables,
  FAQSectionItemSchema,
} from '../typings';

export const deleteFAQSectionItem = async ({
  id,
  section_id,
}: DeleteFAQSectionItemVariables): Promise<FAQSectionItemSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).delete(`/faq/sections/${section_id}/items/${id}`);

  return response.data;
};

import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { usePaginationContext } from 'src/context/PaginationContext';

import { fetchUserActivity } from '../requests/fetchUserActivity';
import { QUERY_NAME } from '../constants';
import { FetchUserActivityResult } from '../typings';

type UseFetchUserActivityResult = UseQueryResult<FetchUserActivityResult>;

type UseFetchUserActivityOptions = {
  keepPreviousData?: boolean;
};

export const useFetchUserActivity = (
  options: UseFetchUserActivityOptions = {}
): UseFetchUserActivityResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar } = useSnackbar();

  const { pagination } = usePaginationContext();

  const result = useQuery<FetchUserActivityResult, Error>(
    [QUERY_NAME.userActivity, { pagination }],
    () => fetchUserActivity({ pagination }),
    {
      onSuccess: async () => {
        const prefetchPagination = {
          rowsPerPage: pagination.rowsPerPage,
          page: pagination.page + 1,
          skip: (pagination.page + 1) * pagination.rowsPerPage,
        };

        await queryClient.prefetchQuery(
          [QUERY_NAME.userActivity, { pagination: prefetchPagination }],
          () => fetchUserActivity({ pagination: prefetchPagination })
        );
      },
      onError: (error) => {
        enqueueErrorSnackbar({ message: error.message });
      },
      ...options,
    }
  );

  return result;
};

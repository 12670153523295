import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface FilterState {
  field: string;
  value: string;
  operator?: string;
}

export interface FilterContextResult {
  isFilterApplied: boolean;
  filter: FilterState;
  setFilter: Dispatch<SetStateAction<FilterState>>;
}

export interface FilterProviderProps {
  defaultFilter?: FilterState;
}

export const DEFAULT_STATE: FilterState = {
  field: '',
  value: '',
  operator: '',
};

const FilterContext = createContext<FilterContextResult>({
  isFilterApplied: false,
  filter: DEFAULT_STATE,
  setFilter: () => {
    // void
  },
});

export const useFilterContext = (): FilterContextResult => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }

  return context;
};

const FilterProvider: FC<PropsWithChildren<FilterProviderProps>> = ({
  children,
  defaultFilter = DEFAULT_STATE,
}) => {
  const [filter, setFilter] = useState(defaultFilter);

  const isFilterApplied = useMemo(() => {
    return filter.value !== DEFAULT_STATE.value;
  }, [filter.value]);

  return (
    <FilterContext.Provider value={{ isFilterApplied, filter, setFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;

import { AxiosRequestConfig } from 'axios';

import { publicGateway } from '../../../config';
import { UploadFileResponse, UploadFileVariables } from '../typings';

import { requestFileUploadPlacement } from './requestFileUploadPlacement';

export interface UploadFileOptions {
  onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  cancelToken?: AxiosRequestConfig['cancelToken'];
}

export const uploadFile = async (
  { file, source }: UploadFileVariables,
  options: UploadFileOptions = {}
): Promise<UploadFileResponse> => {
  const formData: FormData = new FormData();

  const { url, data } = await requestFileUploadPlacement({
    file_name: file.name,
    source,
  });

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  formData.append('file', file);

  await publicGateway('').post(url, formData, options);

  return { blobStorageKey: data.key };
};

import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { createEditor, Descendant, Transforms } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';

import { CustomElement } from 'src/typings/slate';

import { useStyles } from './styles';
import { INITIAL_EDITOR_VALUE } from './constants';
import Markup from './components/Markup';
import EditorToolbar from './components/EditorToolbar';
import LinkEditor from './components/LinkEditor';
import { useEditorConfig } from './hooks/useEditorConfig';
import { useSelection } from './hooks/useSelection';
import { withLinks } from './plugins/withLinks';
import { withParagraphs } from './plugins/withParagraphs';
import { withTextLimitation } from './plugins/withTextLimitation';
import { isLinkActive } from './utils/link';

interface Props {
  initialValue: CustomElement[] | string | null;
  width: number | string;
  height: number | string;
  readOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  withError?: boolean;
  onChange: (value: CustomElement[]) => void;
}

const RichTextEditor: FC<Props> = ({
  initialValue,
  width,
  height,
  placeholder,
  readOnly = false,
  maxLength,
  withError,
  onChange,
}) => {
  const classes = useStyles();

  const editorRef = useRef<HTMLDivElement | null>(null);

  const [value, setValue] = useState<CustomElement[]>(INITIAL_EDITOR_VALUE);
  const [isFocused, setIsFocused] = useState(false);

  const [editor] = useState(
    withTextLimitation({ maxLength })(
      withReact(withHistory(withLinks(withParagraphs(createEditor()))))
    )
  );

  const { setSelection } = useSelection(editor);

  const { renderElement, renderLeaf, onKeyDown } = useEditorConfig(editor);

  const handleChange = useCallback(
    (document: Descendant[]) => {
      setValue(document as CustomElement[]);
      onChange(document as CustomElement[]);
      setSelection(editor.selection);
    },
    [editor, setSelection, onChange]
  );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (typeof initialValue === 'string') {
      if (initialValue.length === 0) {
        editor.children = INITIAL_EDITOR_VALUE;
        setValue(INITIAL_EDITOR_VALUE);
      } else {
        const parsedValue = JSON.parse(initialValue);
        editor.children = parsedValue;
        setValue(parsedValue);
      }
    } else {
      const newValue = initialValue || INITIAL_EDITOR_VALUE;
      editor.children = newValue;
      setValue(newValue);
    }
  }, [initialValue, editor]);

  useEffect(() => {
    if (readOnly) {
      Transforms.deselect(editor);
    }
  }, [readOnly, editor]);

  return (
    <MuiBox>
      <div
        ref={editorRef}
        className={cx(classes.root, {
          isDisabled: readOnly,
          isFocused,
          withError,
        })}
        style={{ width, height }}
      >
        <Slate editor={editor} value={value} onChange={handleChange}>
          {!readOnly && isLinkActive(editor) && (
            <LinkEditor
              offsetX={editorRef.current?.getBoundingClientRect().x}
              offsetY={editorRef.current?.getBoundingClientRect().y}
            />
          )}
          {!readOnly && <EditorToolbar />}
          <OverlayScrollbarsComponent
            options={{
              scrollbars: { autoHide: 'move' },
              sizeAutoCapable: false,
            }}
            className={classes.scrollbars}
          >
            {readOnly && <Markup value={value} />}
            {!readOnly && (
              <Editable
                spellCheck
                readOnly={readOnly}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder}
                className={classes.editor}
                onKeyDown={onKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            )}
          </OverlayScrollbarsComponent>
        </Slate>
      </div>
    </MuiBox>
  );
};

export default RichTextEditor;

import { lighten, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    display: 'flex',
    minWidth: 0,
    flex: 1,
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left',
    padding: '0 15px',

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  fileContent: {
    flex: 1,
    minWidth: 0,
  },

  fileExtensionIcon: {
    alignSelf: 'flex-start',
    paddingTop: 15,
    paddingRight: 12,
  },

  fileName: {
    flex: 1,
    display: 'block',
    minWidth: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  fileStatus: {
    height: 20,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],

    '& [class*=MuiTypography-root]': {
      color: theme.palette.grey[300],
    },

    '&.isStatusNew': {
      borderColor: theme.palette.info.main,
      backgroundColor: lighten(theme.palette.info.main, 0.9),

      '& [class*=MuiTypography-root]': {
        color: theme.palette.info.main,
      },
    },

    '&.isStatusPending': {
      borderColor: theme.palette.warning.main,
      backgroundColor: lighten(theme.palette.warning.main, 0.9),

      '& [class*=MuiTypography-root]': {
        color: theme.palette.warning.main,
      },
    },
  },

  avatar: {
    width: 26,
    height: 26,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    marginLeft: 10,
  },
}));

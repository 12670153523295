import { FC, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import MuiButton from '@material-ui/core/Button';

import { PROTECTED_ROUTES_MAP } from 'src/router';
import UserList, {
  DEFAULT_FILTER,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  transformUsersDataSchema,
  UserSchema,
} from 'src/components/UserList';
import {
  deleteUserByAdmin,
  disableUserByAdmin,
  enableUserByAdmin,
} from 'src/utils/CognitoIdentityHelper';
import { useConfirmUser, useGetUsers } from 'src/aws/UserPool';
import { useSnackbar } from 'src/utils/hooks';
import { SNACKBAR } from 'src/utils/constants/app';
import FilterProvider from 'src/context/FilterContext';
import PaginationProvider from 'src/context/PaginationContext';

const RegisteredUsers: FC = () => {
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { confirmUser } = useConfirmUser();

  const {
    users = [],
    isLoading,
    refetchUsers,
    paginationToken,
  } = useGetUsers();

  const transformedUsers = transformUsersDataSchema({
    awsUsers: users,
  });

  const handleGetUsers = useCallback(() => {
    refetchUsers({ paginationToken: '' });
  }, [refetchUsers]);

  const handleChangeUserAvailability = useCallback(
    (username: string, _enabled: boolean) => {
      for (const user of users) {
        if (user?.Username === username) {
          setTimeout(() => {
            refetchUsers({
              paginationToken: '',
            });
          }, 500);
        }
      }
    },
    [users, refetchUsers]
  );

  const handleEnableUser = async (username: string) => {
    const request = await enableUserByAdmin(username);

    request.on('success', () => {
      handleChangeUserAvailability(username, true);
    });

    request.on('error', (response) => {
      console.error('User enabling failed. ' + response.message);
      throw new Error('enabling failed');
    });
  };

  const handleDisableUser = useCallback(
    async (username: string) => {
      const request = await disableUserByAdmin(username);

      request.on('success', () => {
        handleChangeUserAvailability(username, false);
      });

      request.on('error', (response) => {
        console.error('User disabling failed. ' + response.message);
        throw new Error('disabling failed');
      });
    },
    [handleChangeUserAvailability]
  );

  const handleToggleUserAvailability = async (user: UserSchema) => {
    const username = user.id;
    const enabled = !user.enabled;

    handleChangeUserAvailability(username, enabled);

    if (enabled) {
      await handleEnableUser(username);
    } else {
      await handleDisableUser(username);
    }
  };

  const handleConfirmUser = (user: UserSchema) => {
    try {
      confirmUser(user.id);

      setTimeout(async () => {
        handleGetUsers();
      }, 1000);
    } catch (error) {
      enqueueSnackbar(error.message, {
        ...SNACKBAR.defaultOptions,
        variant: 'error',
        persist: true,
        action: (key) => (
          <MuiButton
            variant="text"
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(key)}
          >
            Ok
          </MuiButton>
        ),
      });
    }
  };

  const handleDeleteUser = useCallback(
    async (user: UserSchema) => {
      const awsUser = users.find((u) => u?.Username === user.id);

      if (!awsUser || !awsUser.Username) return;

      if (awsUser.Enabled) {
        await handleDisableUser(awsUser.Username);
      }

      const request = await deleteUserByAdmin(awsUser.Username);

      request.on('success', () => {
        refetchUsers({
          paginationToken: '',
        });
      });

      request.on('error', (response) => {
        console.error('User deleting failed. ' + response.message);
      });
    },
    [users, handleDisableUser, refetchUsers]
  );

  const handleLoadMoreUsers = useCallback(async () => {
    refetchUsers({ paginationToken });
  }, [paginationToken, refetchUsers]);

  const handleRedirectToView = useCallback(
    (user: UserSchema) => {
      history.push(
        PROTECTED_ROUTES_MAP.adminRegisteredUserProfile.replace(
          ':username',
          user.id
        )
      );
    },
    [history]
  );

  return (
    <UserList
      withLoadMore
      users={transformedUsers}
      usersCount={transformedUsers.length}
      paginationToken={paginationToken}
      isLoading={isLoading}
      onLoadMore={handleLoadMoreUsers}
      onRefetchUsers={handleGetUsers}
      onDeleteUser={handleDeleteUser}
      onToggleUserAvailability={handleToggleUserAvailability}
      onConfirmRegisteredUser={handleConfirmUser}
      onRedirectToView={handleRedirectToView}
    />
  );
};

const RegisteredUsersContainer: FC = () => {
  return (
    <FilterProvider defaultFilter={DEFAULT_FILTER}>
      <PaginationProvider
        defaultPagination={{
          page: DEFAULT_PAGE,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
          skip: DEFAULT_PAGE * DEFAULT_ROWS_PER_PAGE,
        }}
      >
        <RegisteredUsers />
      </PaginationProvider>
    </FilterProvider>
  );
};

export default RegisteredUsersContainer;

import { useQuery } from 'react-query';

import { usePaginationContext } from 'src/context/PaginationContext';
import { useSortingContext } from 'src/context/SortingContext';

import { fetchBookmarks } from '../requests/fetchBookmarks';
import { BookmarksFilters, FetchBookmarksResult } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseFetchBookmarksOptions {
  enabled?: boolean;
}

export const useFetchBookmarks = (
  filters: BookmarksFilters,
  options: UseFetchBookmarksOptions = defaultOptions
) => {
  const { pagination } = usePaginationContext();
  const { sorting } = useSortingContext();

  const result = useQuery<FetchBookmarksResult>(
    [QUERY_NAME.fetchBookmarks, filters, { pagination, sorting }],
    () => fetchBookmarks({ pagination, sorting, filters }),
    options
  );

  return result;
};

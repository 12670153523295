import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    '&.isActive': {
      '& svg': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
    },
  },
}));

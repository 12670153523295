import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ContentHubObjectSchema } from 'src/api/endpoints/contentHub/typings';

import { fetchPublicPageBySlug } from '../requests/fetchPublicPageBySlug';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

type UseFetchContentHubPageOptions = UseQueryOptions<ContentHubObjectSchema>;

export const useFetchPublicPageBySlug = (
  slug: string,
  options: UseFetchContentHubPageOptions = defaultOptions
): UseQueryResult<ContentHubObjectSchema> => {
  const result = useQuery<ContentHubObjectSchema>(
    [QUERY_NAME.fetchPublicPage, slug],
    () => fetchPublicPageBySlug(slug),
    options
  );

  return result;
};

import { FC, PropsWithChildren, useEffect } from 'react';

import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';

import { useCognito } from 'src/aws/Cognito';
import { PRIVATE_ROUTES_MAP, PUBLIC_ROUTES_MAP } from 'src/router';
import DefaultLayout from 'src/layouts/DefaultLayout';
import PrivateLayout from 'src/layouts/PrivateLayout';
import { useNotFoundContext } from 'src/context/NotFoundContext';
import NotFound from 'src/pages/NotFound';
import RootLayout from 'src/micro-frontend/layouts/RootLayout';

const PrivateRoute: FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...restProps
}) => {
  const history = useHistory();
  const location = useLocation();

  const { checkIsAuthenticated, isPasswordExpired } = useCognito();

  const { pageExists, setPageExists } = useNotFoundContext();

  useEffect(() => {
    const checkAuthentication = async () => {
      setPageExists(true);

      const isAuthenticated = await checkIsAuthenticated();

      if (!isAuthenticated) {
        history.push(PUBLIC_ROUTES_MAP.login);
      }

      if (
        isPasswordExpired &&
        location.pathname !== PRIVATE_ROUTES_MAP.changePassword
      ) {
        history.push(PRIVATE_ROUTES_MAP.changePassword);
      }
    };

    checkAuthentication();
  }, [
    history,
    location.pathname,
    isPasswordExpired,
    checkIsAuthenticated,
    setPageExists,
  ]);

  switch (location.pathname) {
    case PRIVATE_ROUTES_MAP.passwordExpiry:
    case PRIVATE_ROUTES_MAP.changePassword: {
      return (
        <Route {...restProps}>
          <DefaultLayout>{children}</DefaultLayout>
        </Route>
      );
    }

    default: {
      if (location.pathname.startsWith('/modules/')) {
        return (
          <Route {...restProps}>
            <RootLayout>{pageExists ? children : <NotFound />}</RootLayout>
          </Route>
        );
      }

      return (
        <Route {...restProps}>
          <PrivateLayout>{pageExists ? children : <NotFound />}</PrivateLayout>
        </Route>
      );
    }
  }
};

export default PrivateRoute;

import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

import SuggestionCard from './components/SuggestionCard';
import { DEFAULT_SUGGESTIONS } from './constants';
import { useStyles } from './styles';

const Suggestions: FC = () => {
  const classes = useStyles();

  return (
    <MuiBox>
      <MuiBox pl={0} mb={2}>
        <MuiTypography className={classes.title}>
          <FormattedMessage id="page.content_hub.suggestions.title" />
        </MuiTypography>
      </MuiBox>
      {DEFAULT_SUGGESTIONS.map((suggestion) => (
        <SuggestionCard key={suggestion} title={suggestion} />
      ))}
    </MuiBox>
  );
};

export default Suggestions;

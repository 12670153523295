import { useQuery, UseQueryResult } from 'react-query';

import { fetchContentHubPageFiles } from '../requests/fetchContentHubPageFiles';
import { ContentHubFileSchema } from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseFetchContentHubPageFilesOptions {
  enabled?: boolean;
}

export interface UseFetchContentHubPageFilesResult
  extends Omit<UseQueryResult, 'data'> {
  data: ContentHubFileSchema[];
}

export const useFetchContentHubPageFiles = (
  pageId: number,
  options: UseFetchContentHubPageFilesOptions = defaultOptions
): UseFetchContentHubPageFilesResult => {
  const { data = [], ...restValues } = useQuery<ContentHubFileSchema[]>(
    [QUERY_NAME.fetchContentHubPageFiles, pageId],
    () => fetchContentHubPageFiles(pageId),
    { enabled: options.enabled }
  );

  return {
    data,
    ...restValues,
  } as UseFetchContentHubPageFilesResult;
};

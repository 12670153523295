export enum INSTRUMENT_NAME {
  acceleratorServices = 'Accelerator Services',
  spx = 'SP-X',
  srx = 'SR-X',
  hdx = 'HD-X',
  hd1 = 'HD-1',
}

export interface InstrumentSchema {
  name: INSTRUMENT_NAME;
  serial_number?: string;
}

import { privateGateway } from '../../../config';
import { FAQSectionSchema, UpdateFAQSectionVariables } from '../typings';

export const updateFAQSection = async ({
  id,
  name,
}: UpdateFAQSectionVariables): Promise<FAQSectionSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).put(`/faq/sections/${id}`, { name });

  return response.data;
};

export const TEST_ID = {
  qrcodeModal: 'qrcodeModal',
  qrcodeCopyButton: 'qrcodeCopyButton',
  linkCopyButton: 'linkCopyButton',
};

export const QRCODE_ID = 'qrcode';
export const QRCODE_SIZE = 200;
export const QRCODE_BG_COLOR = '#FFFFFF';
export const QRCODE_FG_COLOR = '#000000';

import { FC, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';

import { AcceleratorFormSchema } from 'src/api/endpoints/accelerator';

import {
  validateMaxLength,
  validateSpecialCharacters2,
  validateWhitespaces,
} from '../../utils';
import { useManifestsSyncWithForm } from '../../hooks/useManifestsSyncWithForm';
import { AcceleratorFormStepComponentProps } from '../../typings';
import { useStyles } from '../../styles';
import StepperNavButton from '../StepperNavButton';
import StepperNavigation from '../StepperNavigation';

const Step2: FC<AcceleratorFormStepComponentProps> = ({
  onNext,
  onPrevious,
  formData,
  onFormDataChange,
  onFormCancelOpen,
  onFormCancel,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData },
  });

  const allFieldsData = watch();

  useEffect(() => {
    onFormDataChange(allFieldsData);
  }, [allFieldsData, onFormDataChange]);

  useManifestsSyncWithForm({
    sampleReagentsManifests: formData.sampleReagentsManifests,
    sampleReagentsManifestLinks: formData.sampleReagentsManifestLinks,
    getValues,
    reset,
  });

  const onSubmit = (data: AcceleratorFormSchema) => {
    onNext(data);
  };

  const handleOnPrevious = () => {
    const formData = getValues();
    onPrevious(formData);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formControl}>
        <FormLabel component="legend">
          <FormattedMessage id="page.accelerator_form.step2.field.po_number.label" />
        </FormLabel>
        <FormHelperText>
          <FormattedMessage id="page.accelerator_form.step2.field.po_number.help_text" />
        </FormHelperText>
        <ControlledTextField
          fullWidth
          name="poNumber"
          control={control}
          rules={{
            ...validateMaxLength(20),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.poNumber?.message}
        />
      </div>
      <div className={classes.formControl}>
        <FormLabel required component="legend">
          <FormattedMessage id="page.accelerator_form.step2.field.project_description.label" />
        </FormLabel>
        <ControlledTextField
          fullWidth
          multiline
          name="projectDescription"
          minRows={5}
          maxRows={5}
          control={control}
          rules={{
            required: true,
            ...validateMaxLength(80),
            ...validateSpecialCharacters2,
            validate: {
              validateWhitespaces,
            },
          }}
          helperText={errors.projectDescription?.message}
        />
      </div>
      <StepperNavigation
        onFormCancelOpen={onFormCancelOpen}
        onFormCancel={onFormCancel}
      >
        <StepperNavButton direction="previous" onClick={handleOnPrevious} />
        <StepperNavButton direction="next" />
      </StepperNavigation>
    </form>
  );
};

export default Step2;

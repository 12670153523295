import { FC, memo } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import cx from 'classnames';
import ReactGA from 'react-ga';

import MuiBox from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ChevronRight';

import {
  ContentHubObjectSchema,
  PAGE_TYPE,
} from 'src/api/endpoints/contentHub';

import { useStyles } from '../../styles';

interface Props {
  item: ContentHubObjectSchema;
  nestingLevel: number;
  rootPath: string;
  isManagementPage?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

const NavLink: FC<Props> = ({
  item,
  nestingLevel,
  rootPath,
  isManagementPage,
  onClick,
  onMouseEnter,
}) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const { pageId } = useParams<{ pageId: string }>();

  const isActive = pageId === String(item.id);
  const isExpandable = item.type !== PAGE_TYPE.section;
  const totalFiles = isManagementPage
    ? item.total_files
    : item.total_published_files;

  const handleItemClick = () => {
    if (onClick) onClick();

    const newLocationPathname = `${rootPath}/${encodeURI(
      String(item.id)
    )}/${encodeURI(item.name)}`;

    if (location.pathname !== newLocationPathname) {
      history.push(newLocationPathname);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).ga) {
      ReactGA.event({
        category: 'Content Hub',
        action: item.name,
        label: 'Clicked',
      });
    }
  };

  const nestingMargin = nestingLevel > 1 ? nestingLevel + 0.2 : 0;
  const expandableMargin = !isExpandable ? 1.5 : 0;

  return (
    <ListItem
      button
      className={cx(classes.listItem, {
        isActive,
        open: item.is_open,
        section: item.type === PAGE_TYPE.section,
        subcategory: item.type === PAGE_TYPE.subcategory,
      })}
      onClick={handleItemClick}
      onMouseEnter={onMouseEnter}
    >
      <MuiBox ml={nestingMargin + expandableMargin} />
      {isExpandable && (
        <ListItemIcon className={classes.listItemIcon}>
          {item.is_open && <ExpandLess fontSize="small" />}
          {!item.is_open && <ExpandMore fontSize="small" />}
        </ListItemIcon>
      )}
      <ListItemText className={classes.listItemText}>
        {item.name}
        {totalFiles > 0 && ` (${totalFiles})`}
      </ListItemText>
    </ListItem>
  );
};

const MemoizedNavLink = memo(NavLink);

export default MemoizedNavLink;

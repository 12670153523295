import { QueryBuilder } from 'odata-query-builder';

import { ORDER } from 'src/utils/constants/table';

import { FetchUserActivityParams, FetchUserActivityResult } from '../typings';
import { privateGateway } from '../../../config';

export const fetchUserActivity = async ({
  pagination,
}: FetchUserActivityParams = {}): Promise<FetchUserActivityResult> => {
  const query = new QueryBuilder();

  query.count();
  query.orderBy(`created_at ${ORDER.desc}`);

  if (pagination) {
    query.top(pagination.rowsPerPage);
    query.skip(pagination.skip);
  }

  const response = await privateGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).get(`/users/activity/signins${query.toQuery()}`);

  return response.data;
};

import { PRIVATE_ROUTES_MAP } from 'src/router/components/PrivateRoute';
import { PROTECTED_ROUTES_MAP } from 'src/router/components/ProtectedRoute';

import { NavSchema } from './typings';

export const PROFILE_NAV_SCHEMA: NavSchema[] = [
  {
    title: 'page.profile.personal_information',
    route: PRIVATE_ROUTES_MAP.profile,
  },
  {
    title: 'page.profile.password',
    route: PRIVATE_ROUTES_MAP.profileChangePassword,
  },
  {
    title: 'page.profile.activity',
    route: PRIVATE_ROUTES_MAP.profileActivity,
  },
  {
    title: 'page.profile.instruments',
    route: PRIVATE_ROUTES_MAP.profileInstruments,
  },
];

export const REGISTERED_USER_PROFILE_NAV_SCHEMA: NavSchema[] = [
  {
    title: 'page.profile.personal_information',
    route: PROTECTED_ROUTES_MAP.adminRegisteredUserProfile,
  },
  {
    title: 'page.profile.instruments',
    route: PROTECTED_ROUTES_MAP.adminRegisteredUserInstruments,
  },
];

export const USER_REQUEST_PROFILE_NAV_SCHEMA: NavSchema[] = [
  {
    title: 'page.profile.personal_information',
    route: PROTECTED_ROUTES_MAP.adminUserRequestProfile,
  },
  {
    title: 'page.profile.instruments',
    route: PROTECTED_ROUTES_MAP.adminUserRequestInstruments,
  },
];

import { FC, ReactNode, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import Button from '@quanterix-ui/core/Button';

import { useCognito } from 'src/aws/Cognito';
import { PUBLIC_ROUTES_MAP } from 'src/router';
import ControlledPasswordTextField, {
  PASSWORD_FIELD_NAME,
} from 'src/components/form/ControlledPasswordTextField';
import { useSnackbar } from 'src/utils/hooks';

import ResendValidationCode from './components/ResendValidationCode';
import { HandleConfirmOptions } from './typings';
import { useStyles } from './styles';

const ResetPassword: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const { formatMessage } = useIntl();

  const { isLoading, resetPassword } = useCognito();

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');

  const formMethods = useForm({
    defaultValues: {
      email: '',
      confirmationCode: '',
      [PASSWORD_FIELD_NAME.password]: '',
      [PASSWORD_FIELD_NAME.repeatPassword]: '',
    },
  });

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state } = history.location as any;

    if (state?.email) {
      setValue('email', state.email);
      setEmail(state.email);
    } else {
      history.push(PUBLIC_ROUTES_MAP.forgotPassword);
    }
  }, [history, setValue]);

  const handleConfirm = async ({
    email,
    confirmationCode,
    password,
  }: HandleConfirmOptions) => {
    try {
      await resetPassword({ email, confirmationCode, password });

      enqueueSnackbar('Your password has been changed.', {
        variant: 'success',
      });
    } catch (error) {
      console.error(error.message);

      if (error.message.startsWith('password.error.')) {
        setError('confirmationCode', {
          message: formatMessage({ id: error.message }),
        });
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      <MuiBox textAlign="center">
        <Typography variant="subtitle1">
          <FormattedMessage id="page.forgot_password.new_password_title" />
        </Typography>
      </MuiBox>
      <MuiBox mb={3}>
        <Typography>
          <FormattedMessage
            id="page.forgot_password.code.info"
            values={{ email }}
          />
        </Typography>
      </MuiBox>
      <form onSubmit={handleSubmit(handleConfirm)}>
        <MuiBox mb={3}>
          <MuiGrid container spacing={1}>
            <MuiGrid item>
              <FormLabel
                htmlFor="confirmationCode"
                className={classes.formLabel}
              >
                <FormattedMessage id="page.forgot_password.code.label" />
              </FormLabel>
            </MuiGrid>
            <MuiGrid item>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={formatMessage({
                  id: 'page.forgot_password.code.tooltip',
                })}
                placement="bottom-start"
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </MuiGrid>
          </MuiGrid>
          <ControlledTextField
            fullWidth
            control={control}
            rules={{ required: 'app.field.required' }}
            id="confirmationCode"
            name="confirmationCode"
            placeholder={formatMessage({
              id: 'page.forgot_password.code.placeholder',
            })}
          />
          {errors?.confirmationCode && (
            <FormHelperText error>
              <FormattedMessage
                id={errors.confirmationCode.message}
                values={{ b: (msg: ReactNode) => <b>{msg}</b>, email }}
              />
            </FormHelperText>
          )}
          <FormHelperText className={classes.formLabel}>
            <ResendValidationCode />
          </FormHelperText>
        </MuiBox>
        <MuiBox mb={3}>
          <FormLabel required htmlFor="password" className={classes.formLabel}>
            <FormattedMessage id="page.forgot_password.password.label" />
          </FormLabel>
          <FormControl fullWidth size="small">
            <ControlledPasswordTextField
              withPattern
              id="password"
              name={PASSWORD_FIELD_NAME.password}
              placeholder={formatMessage({
                id: 'page.forgot_password.password.placeholder',
              })}
            />
          </FormControl>
        </MuiBox>
        <MuiBox mb={4}>
          <FormLabel
            required
            htmlFor="repeatPassword"
            className={classes.formLabel}
          >
            <FormattedMessage id="page.forgot_password.repeat_password.label" />
          </FormLabel>
          <FormControl fullWidth size="small">
            <ControlledPasswordTextField
              id="repeatPassword"
              name={PASSWORD_FIELD_NAME.repeatPassword}
              equalTo={PASSWORD_FIELD_NAME.password}
              placeholder={formatMessage({
                id: 'page.forgot_password.repeat_password.placeholder',
              })}
            />
          </FormControl>
        </MuiBox>
        <MuiBox display="flex" flexDirection="column" alignItems="center">
          <Button
            color="secondary"
            className={classes.button}
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage id="page.forgot_password.set_password_button" />
          </Button>
        </MuiBox>
      </form>
    </FormProvider>
  );
};

export default ResetPassword;

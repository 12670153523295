import { useMutation, UseMutationResult } from 'react-query';

import { deleteFiles } from '../requests/deleteFiles';
import { DeleteBlobStorageFilesVariables } from '../typings';

export type UseDeleteBlobStorageFilesResult = UseMutationResult<
  void,
  Error,
  DeleteBlobStorageFilesVariables
>;

export const useDeleteBlobStorageFiles =
  (): UseDeleteBlobStorageFilesResult => {
    const mutation = useMutation<void, Error, DeleteBlobStorageFilesVariables>(
      deleteFiles
    );

    return mutation;
  };

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';
import { convertToPascalCase } from 'src/utils/helpers/common';

import { createContentHubNestedPage } from '../requests/createContentHubNestedPage';
import {
  ContentHubObjectSchema,
  CreateContentHubPageVariables,
} from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: ContentHubObjectSchema[];
}

export type UseCreateContentHubNestedPageResult = UseMutationResult<
  ContentHubObjectSchema,
  Error,
  CreateContentHubPageVariables,
  UseMutationContext
>;

export const useCreateContentHubNestedPage = (
  parentId: number
): UseCreateContentHubNestedPageResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = [QUERY_NAME.fetchContentHubChildPages, parentId];

  const mutation = useMutation<
    ContentHubObjectSchema,
    Error,
    CreateContentHubPageVariables,
    UseMutationContext
  >((data) => createContentHubNestedPage(parentId, data), {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<ContentHubObjectSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (data) => {
      queryClient.setQueryData<ContentHubObjectSchema[]>(
        queryKey,
        (previous = []) => [...previous, data]
      );

      enqueueSuccessSnackbar({
        message: 'page.content_management.api.pages.create',
        intlValues: {
          type: convertToPascalCase(data.type),
          name: data.name,
        },
      });
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

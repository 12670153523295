import { privateGateway } from '../../../config';
import {
  ContentHubObjectSchema,
  EditableContentHubPageVariables,
} from '../typings';

export const updateContentHubPage = async (
  pageId: number,
  data: EditableContentHubPageVariables
): Promise<ContentHubObjectSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).put(`/pages/${pageId}`, data);

  return response.data;
};

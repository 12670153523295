import { lighten, makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

import { NAV_CARD_HEIGHT, NAV_CARD_WIDTH } from './constants';

const footerHeight = 40;
const contentPadding = 15;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: NAV_CARD_WIDTH,
    height: NAV_CARD_HEIGHT,
    paddingBottom: footerHeight,
    borderRadius: theme.constants.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: lighten(theme.palette.common.black, 0.68),
    backgroundColor: theme.palette.common.white,
    marginBottom: 50,
  },

  button: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    alignItems: 'flex-start',

    '& [class*=MuiTouchRipple-root]': {
      opacity: 0.15,
    },
  },

  header: {
    padding: `${contentPadding}px ${contentPadding}px 0`,
  },

  iconWrapper: {
    width: 38,
    height: 38,
    marginRight: contentPadding,
    borderRadius: theme.constants.borderRadius / 2,
    backgroundColor: theme.palette.secondary.main,

    '&.isOdd': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  title: {
    maxWidth: '75%',
    fontFamily: theme.fonts.fontFamily.bold,
    fontSize: 18,
    lineHeight: '18px',
  },

  content: {
    padding: contentPadding,

    '& [class*=MuiTypography-root]': {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      overflow: 'hidden',
      fontFamily: theme.fonts.fontFamily.medium,
      fontSize: 12,
    },
  },

  footer: {
    position: 'absolute',
    bottom: -1,
    left: -1,
    width: 'calc(100% + 2px)',
    height: footerHeight,
    borderRadius: theme.constants.borderRadius / 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: lighten(theme.palette.common.black, 0.68),
    backgroundColor: theme.palette.secondary.main,

    '& a': {
      marginLeft: contentPadding,
      fontFamily: theme.fonts.fontFamily.medium,
      color: theme.palette.common.white,
      textDecoration: 'underline',

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },

  footerContent: {
    height: '100%',
  },

  footerExtraText: {
    marginLeft: contentPadding,
  },
}));

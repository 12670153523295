import { CustomElement } from 'src/typings/slate';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export enum ACTION_TYPE {
  mark = 'mark',
  block = 'block',
  link = 'link',
}

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export const INITIAL_EDITOR_VALUE: CustomElement[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

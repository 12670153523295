import { FC, PropsWithChildren } from 'react';

import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import LoadingDisk from '@quanterix-ui/core/LoadingDisk';

import { useStyles } from './styles';

interface Props {
  loading: boolean;
  size?: number;
  opacity?: number;
  paddingTop?: number;
  withLoadingDisk?: boolean;
}

const ProgressOverlay: FC<PropsWithChildren<Props>> = ({
  children,
  loading,
  size = 30,
  opacity = 0.8,
  paddingTop = 6,
  withLoadingDisk = false,
}) => {
  const classes = useStyles({ opacity });

  return (
    <MuiBox className={cx(classes.root, { isLoading: loading })}>
      {children}
      {loading && (
        <MuiBox pt={paddingTop} className={classes.overlay}>
          <MuiBox className={classes.indicator}>
            {withLoadingDisk && <LoadingDisk size={size} />}
            {!withLoadingDisk && (
              <CircularProgress size={size} thickness={4} color="secondary" />
            )}
          </MuiBox>
        </MuiBox>
      )}
    </MuiBox>
  );
};

export default ProgressOverlay;

import { DROPZONE_ACCEPT_CSV_SCHEMA } from 'src/utils/constants/file';
import { formatBytes } from 'src/utils/helpers/formatters';

export const MAX_FILES_SIZE_PER_UPLOAD = 10 * 1024 * 1024 * 1024; // 10 GB
export const MAX_FILES_COUNT_PER_UPLOAD = 10;
export const MAX_FILES_COUNT = 1000;

export const READABLE_MAX_FILES_SIZE = formatBytes(MAX_FILES_SIZE_PER_UPLOAD);

export const DROPZONE_ACCEPTABLE_SCHEMA = {
  ...DROPZONE_ACCEPT_CSV_SCHEMA,

  'application/pdf': ['.pdf'],
  'application/xml': ['.xml'],
  'text/xml': ['.xml'],
  'application/octet-stream': ['.exe', '.rpexpe'],
  'application/mp4': ['.mp4'],
  'video/mp4': ['.mp4'],
};

export enum DROPZONE_ERROR_CODES {
  tooManyFiles = 'too-many-files',
  fileTooLarge = 'file-too-large',
  fileInvalidType = 'file-invalid-type',
}

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { useSnackbar } from 'src/utils/hooks';

import { updateFAQSection } from '../requests/updateFAQSection';
import { FAQSectionSchema, UpdateFAQSectionVariables } from '../typings';
import { QUERY_NAME } from '../constants';

interface UseMutationContext {
  previousData?: FAQSectionSchema[];
}

export type UseUpdateFAQSectionResult = UseMutationResult<
  FAQSectionSchema,
  Error,
  UpdateFAQSectionVariables,
  UseMutationContext
>;

export const useUpdateFAQSection = (): UseUpdateFAQSectionResult => {
  const queryClient = useQueryClient();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();

  const queryKey = [QUERY_NAME.fetchFAQSections];

  const mutation = useMutation<
    FAQSectionSchema,
    Error,
    UpdateFAQSectionVariables,
    UseMutationContext
  >(updateFAQSection, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      const previousData =
        queryClient.getQueryData<FAQSectionSchema[]>(queryKey);

      return { previousData };
    },

    onSuccess: (data, { id }, _context) => {
      queryClient.setQueryData<FAQSectionSchema[]>(queryKey, (previous = []) =>
        previous.map((section) => (section.id === id ? data : section))
      );

      enqueueSuccessSnackbar({
        message: 'page.faq_management.api.section.update',
      });
    },

    onError: ({ message }, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);

      enqueueErrorSnackbar({ message });
    },
  });

  return mutation;
};

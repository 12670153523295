import { ContentHubObjectSchema } from 'src/api/endpoints/contentHub';

import { publicGateway } from '../../../config';

export const fetchPublicPageById = async (
  pageId: number
): Promise<ContentHubObjectSchema> => {
  const response = await publicGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/public/pages/${pageId}`);

  return response.data;
};

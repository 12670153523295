import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((_theme: Theme) => ({
  dialogContent: {
    paddingTop: 30,
    paddingBottom: 40,
  },

  dialogActions: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,

    '& > :not(:first-child)': {
      marginLeft: 0,
    },
  },

  linkCopyButton: {
    marginTop: 20,

    '& svg': {
      transform: 'rotate(-45deg)',
    },
  },
}));

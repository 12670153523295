import { privateGateway } from '../../../config';
import { ContentHubObjectSchema } from '../typings';

export const fetchContentHubChildPages = async (
  pageId: number
): Promise<ContentHubObjectSchema[]> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/pages/${pageId}/pages`);

  return response.data.data;
};

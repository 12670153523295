import { privateGateway } from '../../../config';
import { UpdateNotificationParams, UpdateNotificationResult } from '../typings';

export const updateNotification = async ({
  notificationId,
  params,
}: UpdateNotificationParams): Promise<UpdateNotificationResult> => {
  const response = await privateGateway(
    process.env.REACT_APP_NOTIFICATIONS_API as string
  ).put(`/notifications/${notificationId}`, params);

  return response.data;
};

import { privateGateway } from '../../../config';
import { ContentHubObjectSchema } from '../typings';

interface FetchContentHubPageParams {
  id: number;
}

export const fetchContentHubPage = async ({
  id,
}: FetchContentHubPageParams): Promise<ContentHubObjectSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/pages/${id}`);

  return response.data;
};

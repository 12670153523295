import { FC, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiButtonBase from '@material-ui/core/ButtonBase';
import MuiTypography from '@material-ui/core/Typography';
import IconImage from '@material-ui/icons/CameraAlt';

import { useStyles } from './styles';

interface Props {
  hasError?: boolean;
}

const ImageUploader: FC<Props> = ({ hasError = false }) => {
  const classes = useStyles();

  const { setValue, watch } = useFormContext();
  const { image: uploadedImage } = watch();

  const handleDropAllFiles = async (droppedFiles: File[]) => {
    const file = droppedFiles[0];

    setValue(
      'image',
      Object.assign(file, { preview: URL.createObjectURL(file) }),
      { shouldDirty: true }
    );
  };

  const { getRootProps, getInputProps, isFocused, isFileDialogActive } =
    useDropzone({
      multiple: false,
      useFsAccessApi: false,
      accept: { 'image/*': [] },
      maxFiles: 1,
      onDrop: handleDropAllFiles,
    });

  useEffect(() => {
    if (uploadedImage) {
      URL.revokeObjectURL(uploadedImage.preview);
    }
  }, [uploadedImage]);

  return (
    <>
      <MuiButtonBase
        {...getRootProps({
          className: cx(classes.dropzone, {
            isFocused,
            isFileDialogActive,
            hasError,
          }),
        })}
      >
        <input {...getInputProps()} />
        <MuiGrid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconImage fontSize="large" color="secondary" />
          <MuiBox mt={1}>
            <MuiTypography color="secondary">
              <FormattedMessage
                id="page.content_management.files.uploader.image.placeholder"
                values={{ br: <br /> }}
              />
            </MuiTypography>
          </MuiBox>
        </MuiGrid>
      </MuiButtonBase>
    </>
  );
};

export default ImageUploader;

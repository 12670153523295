import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 100,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
    cursor: 'pointer',
    userSelect: 'none',
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontFamily: theme.fonts.fontFamily.bold,
    transition: theme.transitions.create('border', {
      duration: theme.transitions.duration.shortest,
    }),

    '&.isFileDialogActive': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
    },

    '&.invalid': {
      borderColor: theme.palette.error.main + '!important',
      color: theme.palette.error.main + '!important',
    },

    '& label': {
      cursor: 'pointer',
    },
  },
}));

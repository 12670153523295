import { FC, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import {
  PAGE_MODE,
  PAGE_TYPE,
  useFetchContentHubPage,
} from 'src/api/endpoints/contentHub';
import ProgressOverlay from 'src/components/elements/ProgressOverlay';
import { convertToPascalCase } from 'src/utils/helpers/common';

import { useEditableContentContext } from '../../context/EditableContent';
import { useContentManagementContext } from '../../context/ContentManagementContext';
import EditButton from '../EditButton';
import ShareButton from '../ShareButton';
import NotifyButton from '../NotifyButton';
import FilesUploader from '../FilesUploader';

import TitleTextField from './components/TitleTextField';
import DescriptionTextField from './components/DescriptionTextField';
import ImageHolder from './components/ImageHolder';
import { TEST_ID } from './constants';
import { useStyles } from './styles';

const ContentHeader: FC = () => {
  const classes = useStyles();

  const prevPageId = useRef<string>();

  const { pageId } = useParams<{ pageId: string }>();

  const { isLegalDocumentsCategory, isLegalDocumentsPage } =
    useContentManagementContext();

  const { reset } = useFormContext();

  const { isEditable, setIsEditable } = useEditableContentContext();

  const { data: page, isFetching } = useFetchContentHubPage(Number(pageId), {
    enabled: Boolean(pageId),
  });

  useEffect(() => {
    if (pageId !== prevPageId.current) {
      setIsEditable(false);
      reset();
      prevPageId.current = pageId;
    }
  }, [pageId, setIsEditable, reset]);

  if (!pageId) {
    return null;
  }

  const isSection = page?.type === PAGE_TYPE.section;
  const isPublicPage = page?.mode === PAGE_MODE.public;
  const isNotifyAvailable = !isPublicPage;
  const isShareButtonAvailable = isPublicPage && isSection;

  const isImageHolderDisabled =
    isLegalDocumentsPage || isLegalDocumentsCategory;
  const isDescriptionDisabled = isLegalDocumentsCategory;
  const isFilesUploaderDisabled =
    isLegalDocumentsPage ||
    isLegalDocumentsCategory ||
    (isPublicPage && !isSection);

  return (
    <ProgressOverlay withLoadingDisk loading={isFetching} opacity={1}>
      <MuiBox
        mb={isLegalDocumentsCategory ? 2 : 5}
        data-testid={TEST_ID.contentHeader}
      >
        <MuiBox mb={2}>
          <MuiGrid container justifyContent="space-between" alignItems="center">
            <MuiTypography variant="subtitle1" color="secondary">
              {convertToPascalCase(page?.type || '')}
            </MuiTypography>
            <MuiBox>
              <EditButton />
              {isShareButtonAvailable && <ShareButton page={page} />}
              {isNotifyAvailable && <NotifyButton />}
            </MuiBox>
          </MuiGrid>
        </MuiBox>
        <TitleTextField isEditable={isEditable} />
        {page?.type !== PAGE_TYPE.section && (
          <MuiBox pt={2}>
            <MuiGrid
              container
              justifyContent="space-between"
              className={classes.flexibleContainer}
            >
              {!isImageHolderDisabled && (
                <ImageHolder isEditable={isEditable} item={page} />
              )}
              {!isDescriptionDisabled && (
                <DescriptionTextField isEditable={isEditable} />
              )}
            </MuiGrid>
          </MuiBox>
        )}
        {!isFilesUploaderDisabled && <FilesUploader />}
      </MuiBox>
    </ProgressOverlay>
  );
};

export default ContentHeader;

import { AxiosResponse } from 'axios';

import { RequestConfirmForgotPasswordParams } from '../typings';
import { publicGateway } from '../../../config';

export const requestConfirmForgotPassword = async ({
  email,
  password,
  confirmationCode,
}: RequestConfirmForgotPasswordParams): Promise<AxiosResponse> => {
  const response = await publicGateway(
    process.env.REACT_APP_USER_CONTROLLER_API_GATEWAY as string
  ).post(`/users/password/forgot/confirm`, {
    email,
    password,
    confirmation_code: confirmationCode,
  });

  return response.data;
};

import { FC, MouseEvent, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiAppsIcon from '@material-ui/icons/Apps';

import { useCognito } from 'src/aws/Cognito';
import IconButton from 'src/components/elements/IconButton';
import SubServicesMenu from 'src/micro-frontend/components/SubServicesMenu';

import { useStyles } from './styles';

const ServicesMenu: FC = () => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const { availableExternalServices, isLoading } = useCognito();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (pathname: string) => {
    return (_event: MouseEvent<HTMLElement>) => {
      setAnchorEl(null);
      history.push(pathname);
    };
  };

  if (location.pathname.startsWith('/modules/')) {
    return <SubServicesMenu />;
  }

  if (!isLoading && availableExternalServices.length === 0) {
    return null;
  }

  return (
    <MuiBox>
      <IconButton color="inherit" onClick={handleMenuClick}>
        <MuiAppsIcon />
      </IconButton>
      <MuiMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        className={classes.menu}
        classes={{ paper: classes.paper }}
        onClose={() => setAnchorEl(null)}
      >
        {availableExternalServices.map((service) => (
          <MuiMenuItem
            key={service.title}
            onClick={handleMenuItemClick(service.pathname)}
          >
            {service.title}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </MuiBox>
  );
};

export default ServicesMenu;

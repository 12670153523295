import { privateGateway } from '../../../config';
import { ContentHubFileSchema } from '../typings';

export const fetchContentHubPageFiles = async (
  pageId: number
): Promise<ContentHubFileSchema[]> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).get(`/pages/${pageId}/files`);

  return response.data.data;
};

import { FC, useCallback, useEffect, useMemo } from 'react';

import UserViewerProfile, {
  UserUpdateParams,
} from 'src/components/UserViewerProfile';
import UserViewerProfileContextProvider, {
  useUserViewerProfileContext,
} from 'src/components/UserViewerProfile/context';
import { useCognito } from 'src/aws/Cognito';
import {
  parseAddress,
  parseUserAttribute,
} from 'src/utils/CognitoIdentityHelper';

const Profile: FC = () => {
  const { setUserData } = useUserViewerProfileContext();

  const {
    userData: { attributes },
    updateUser,
    isLoading,
  } = useCognito();

  const userData = useMemo(
    () => ({
      firstname: parseUserAttribute(attributes, 'given_name'),
      lastname: parseUserAttribute(attributes, 'family_name'),
      email: parseUserAttribute(attributes, 'email'),
      phone: parseUserAttribute(attributes, 'phone_number'),
      company: parseUserAttribute(attributes, 'custom:company'),
      address1: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'street_address'
      ),
      address2: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'street_address2'
      ),
      zip: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'postal_code'
      ),
      city: parseAddress(parseUserAttribute(attributes, 'address'), 'locality'),
      region: parseAddress(parseUserAttribute(attributes, 'address'), 'region'),
      country: parseAddress(
        parseUserAttribute(attributes, 'address'),
        'country'
      ),
      website: parseUserAttribute(attributes, 'website'),
    }),
    [attributes]
  );

  const handleUserUpdate = useCallback(
    ({ personalData, organizationData }: UserUpdateParams) => {
      if (personalData) {
        return updateUser({
          attributes: {
            given_name: personalData.firstname,
            family_name: personalData.lastname,
            email: personalData.email,
            phone_number:
              personalData.phone.indexOf('+') === 0
                ? personalData.phone
                : `+${personalData.phone}`,
          },
        });
      }

      if (organizationData) {
        return updateUser({
          attributes: {
            'custom:company': organizationData.company,
            website: organizationData.website,
            address: JSON.stringify({
              street_address: organizationData.address1,
              street_address2: organizationData.address2,
              postal_code: organizationData.zip,
              locality: organizationData.city,
              region: organizationData.region,
              country: organizationData.country,
            }),
          },
        });
      }

      return;
    },
    [updateUser]
  );

  useEffect(() => {
    setUserData(userData);
  }, [userData, setUserData]);

  return (
    <UserViewerProfile
      isLoading={isLoading}
      isFetched={!!attributes}
      onUserUpdate={handleUserUpdate}
    />
  );
};

const ProfileWrappedWithContext = () => (
  <UserViewerProfileContextProvider>
    <Profile />
  </UserViewerProfileContextProvider>
);

export default ProfileWrappedWithContext;

import { FC, PropsWithChildren } from 'react';

import { Route, RouteProps, useRouteMatch } from 'react-router-dom';

import DefaultLayout from 'src/layouts/DefaultLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import { useNotFoundContext } from 'src/context/NotFoundContext';
import NotFound from 'src/pages/NotFound';

import { PUBLIC_ROUTES_MAP } from './constants';

const PublicRoute: FC<PropsWithChildren<RouteProps>> = ({
  children,
  ...restProps
}) => {
  const { pageExists } = useNotFoundContext();

  const contentPublicPageMatch = useRouteMatch({
    path: PUBLIC_ROUTES_MAP.contentHubViewerPublic,
    exact: true,
    strict: true,
  });
  const isContentPublicPage = Boolean(contentPublicPageMatch);

  if (isContentPublicPage) {
    return (
      <Route {...restProps}>
        <PublicLayout>{pageExists ? children : <NotFound />}</PublicLayout>
      </Route>
    );
  }

  return (
    <Route {...restProps}>
      <DefaultLayout>{children}</DefaultLayout>
    </Route>
  );
};

export default PublicRoute;

import { privateGateway } from '../../../config';
import { CreateFAQSectionVariables, FAQSectionSchema } from '../typings';

export const createFAQSection = async ({
  name,
}: CreateFAQSectionVariables): Promise<FAQSectionSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).post('/faq/sections', { name });

  return response.data;
};

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: '0 5px',

    '&.isActive': {
      '& [class*=MuiTypography-root]': {
        color: theme.palette.secondary.main,
        fontFamily: theme.fonts.fontFamily.bold,
      },

      '& [class*=MuiSvgIcon-root]': {
        fill: theme.palette.secondary.main,
      },
    },

    '&.section:nth-last-child(1), &.subcategory:nth-last-child(2)': {
      '&:not(.open)': {
        marginBottom: 8,
      },
    },
  },

  listItemIcon: {
    minWidth: 'auto',
  },

  listItemText: {
    width: '100%',
    margin: 0,
    padding: '4px 0',

    '& [class*=MuiTypography-root]': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

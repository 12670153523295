import { useQuery } from 'react-query';

import { usePaginationContext } from 'src/context/PaginationContext';
import { useSortingContext } from 'src/context/SortingContext';
import { useContentFilterContext } from 'src/pages/ContentHubViewer/context/ContentFilterContext';

import { requestSearchContentHubFiles } from '../requests/requestSearchContentHubFiles';
import {
  RequestSearchContentHubFilesResult,
  RequestSearchContentHubFilesVariables,
} from '../typings';
import { QUERY_NAME } from '../constants';

const defaultOptions = {
  enabled: true,
};

interface UseSearchContentHubFilesOptions {
  enabled?: boolean;
}

export const useSearchContentHubFiles = (
  variables: RequestSearchContentHubFilesVariables,
  options: UseSearchContentHubFilesOptions = defaultOptions
) => {
  const { pagination } = usePaginationContext();
  const { sorting } = useSortingContext();
  const { contentFilter: filters } = useContentFilterContext();

  const result = useQuery<RequestSearchContentHubFilesResult>(
    [
      QUERY_NAME.searchContentHubFiles,
      variables,
      filters,
      { pagination, sorting },
    ],
    () =>
      requestSearchContentHubFiles(variables, { pagination, sorting, filters }),
    options
  );

  return result;
};

import { privateGateway } from '../../../config';
import { ContentHubFileSchema, UpdatePageFileVariables } from '../typings';

export const updateContentHubPageFile = async (
  pageId: number,
  { id, name }: UpdatePageFileVariables
): Promise<ContentHubFileSchema> => {
  const response = await privateGateway(
    process.env.REACT_APP_DOCUMENTS_API as string
  ).put(`/pages/${pageId}/files/${id}`, {
    name,
  });

  return response.data;
};

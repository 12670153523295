import { FC } from 'react';

import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import Icon from '@mdi/react';
import { mdiQrcodeScan } from '@mdi/js';

import MuiBox from '@material-ui/core/Box';
import MuiLinkIcon from '@material-ui/icons/Link';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogActions from '@quanterix-ui/core/DialogActions';
import DialogContent from '@quanterix-ui/core/DialogContent';
import Button from '@quanterix-ui/core/Button';

import { useSnackbar } from 'src/utils/hooks';

import { useStyles } from './styles';
import {
  QRCODE_BG_COLOR,
  QRCODE_FG_COLOR,
  QRCODE_ID,
  QRCODE_SIZE,
  TEST_ID,
} from './constants';

interface Props {
  value: string;
  isOpen: boolean;
  onClose: () => void;
}

const QRCodeGenerator: FC<Props> = ({ value, isOpen, onClose }) => {
  const classes = useStyles();

  const { enqueueSuccessSnackbar } = useSnackbar();

  const handleCopyQRCode = () => {
    const canvas = document.getElementById(QRCODE_ID) as HTMLCanvasElement;

    canvas.toBlob((blob) => {
      if (!blob) return;

      const data = [
        new ClipboardItem(
          Object.defineProperty({}, blob.type, {
            value: blob,
            enumerable: true,
          })
        ),
      ];

      navigator.clipboard.write(data).then(() => {
        enqueueSuccessSnackbar({ message: 'qrcode.copy.success' });
      });
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(value).then(() => {
      enqueueSuccessSnackbar({ message: 'qrcode.link.copy.success' });
    });
  };

  return createPortal(
    <Dialog open={isOpen} data-testid={TEST_ID.qrcodeModal} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        {/* SVG QR code to be shown */}
        <QRCodeSVG
          value={value}
          size={QRCODE_SIZE}
          bgColor={QRCODE_BG_COLOR}
          fgColor={QRCODE_FG_COLOR}
        />
        <MuiBox display="none">
          {/* Canvas QR code only for copy purpose */}
          <QRCodeCanvas
            id={QRCODE_ID}
            value={value}
            size={QRCODE_SIZE}
            bgColor={QRCODE_BG_COLOR}
            fgColor={QRCODE_FG_COLOR}
          />
        </MuiBox>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          startIcon={<Icon path={mdiQrcodeScan} size={1} />}
          data-testid={TEST_ID.qrcodeCopyButton}
          onClick={handleCopyQRCode}
        >
          <MuiBox ml={0.5}>
            <FormattedMessage id="app.button.copy_qr_code" />
          </MuiBox>
        </Button>
        <Button
          variant="text"
          color="secondary"
          className={classes.linkCopyButton}
          startIcon={<MuiLinkIcon fontSize="small" />}
          data-testid={TEST_ID.linkCopyButton}
          onClick={handleCopyLink}
        >
          <MuiBox mt={0.3} ml={0.5}>
            <FormattedMessage id="app.button.copy_link" />
          </MuiBox>
        </Button>
      </DialogActions>
    </Dialog>,

    document.getElementById('modal-root') || document.createElement('div')
  );
};

export default QRCodeGenerator;

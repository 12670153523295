import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { createPortal } from 'react-dom';

import MuiBox from '@material-ui/core/Box';
import IconAddAlert from '@material-ui/icons/AddAlert';

import Button from '@quanterix-ui/core/Button';

import NotifyUsersDialog from '../NotifyUsersDialog';

import { useStyles } from './styles';

const NotifyButton: FC = () => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        startIcon={<IconAddAlert />}
        className={classes.button}
        onClick={handleDialogOpen}
      >
        <MuiBox mt={0.5}>
          <FormattedMessage id="app.button.notify_users" />
        </MuiBox>
      </Button>
      <div>
        {createPortal(
          <NotifyUsersDialog isOpen={isOpen} onClose={handleDialogClose} />,

          /* document.createElement('div') - is for testing purpose */
          (document.getElementById('modal-root') as HTMLDivElement) ||
            document.createElement('div')
        )}
      </div>
    </>
  );
};

export default NotifyButton;

import { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';

import MuiGrid from '@material-ui/core/Grid';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@quanterix-ui/core/Dialog';
import DialogTitle from '@quanterix-ui/core/DialogTitle';
import DialogContent from '@quanterix-ui/core/DialogContent';
import DialogActions from '@quanterix-ui/core/DialogActions';
import Button from '@quanterix-ui/core/Button';
import Switch from '@quanterix-ui/core/Switch';

import IconButton from 'src/components/elements/IconButton';
import { ConfirmationInfo } from 'src/pages/RegisteredUsersAddNew';
import { UserStatuses } from 'src/utils/Constants';

import { UserSchema } from '../../typings';

import { useStyles } from './styles';

interface Props {
  user: UserSchema;
  isRegisteredUsersPage: boolean;
  isUserRequestsPage: boolean;
  onConfirmUserRequest?: (user: UserSchema) => void;
  onConfirmRegisteredUser?: (user: UserSchema) => void;
  onToggleUserAvailability?: (user: UserSchema) => void;
  onDeleteUser: (user: UserSchema) => void;
  onRedirectToView: (user: UserSchema) => void;
}

const UserRow: FC<Props> = ({
  user,
  isRegisteredUsersPage,
  isUserRequestsPage,
  onConfirmUserRequest,
  onConfirmRegisteredUser,
  onToggleUserAvailability,
  onDeleteUser,
  onRedirectToView,
}) => {
  const classes = useStyles();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleConfirmUserRequest = () => {
    if (!onConfirmUserRequest) return;

    onConfirmUserRequest(user);
  };

  const handleDeleteUserRequest = () => {
    onDeleteUser(user);
    handleCloseDeleteModal();
  };

  const handleRedirectToView = () => {
    onRedirectToView(user);
  };

  return (
    <>
      <Dialog fullWidth open={deleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>
          <FormattedMessage id="page.users.table.confirm_delete.title" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="page.users.user_requests.table.confirm_delete.description"
            values={{
              name: user.fullname,
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleCloseDeleteModal}>
            <FormattedMessage id="app.button.cancel" />
          </Button>
          <Button onClick={handleDeleteUserRequest}>
            <FormattedMessage id="app.button.continue" />
          </Button>
        </DialogActions>
      </Dialog>
      <MuiTableRow>
        <MuiTooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-start"
          title={user.fullname}
        >
          <MuiTableCell
            scope="row"
            className={classes.clickableUserCell}
            onClick={handleRedirectToView}
          >
            {user.formattedFullname}
          </MuiTableCell>
        </MuiTooltip>
        <MuiTooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-start"
          title={user.email}
        >
          <MuiTableCell
            className={cx(
              classes.highlightedUserCell,
              classes.clickableUserCell
            )}
            onClick={handleRedirectToView}
          >
            {user.formattedEmail}
          </MuiTableCell>
        </MuiTooltip>
        <MuiTableCell
          className={cx(classes.highlightedUserCell, classes.clickableUserCell)}
          onClick={handleRedirectToView}
        >
          {user.phone}
        </MuiTableCell>
        <MuiTableCell
          className={classes.clickableUserCell}
          onClick={handleRedirectToView}
        >
          {user.company}
        </MuiTableCell>
        {isRegisteredUsersPage && (
          <MuiTableCell align="left">
            <ConfirmationInfo
              small
              status={user.status as keyof typeof UserStatuses}
              onConfirm={() => onConfirmRegisteredUser?.(user)}
            />
          </MuiTableCell>
        )}
        {isRegisteredUsersPage && (
          <MuiTableCell width={135} align="center">
            <Switch
              checked={!!user.enabled}
              rightSideText={
                !user.enabled ? (
                  <span className={classes.labelInactive}>
                    <FormattedMessage id="page.users.registered_users.status.inactive" />
                  </span>
                ) : (
                  <span className={classes.labelActive}>
                    <FormattedMessage id="page.users.registered_users.status.active" />
                  </span>
                )
              }
              onChange={() => onToggleUserAvailability?.(user)}
            />
          </MuiTableCell>
        )}
        <MuiTableCell width={180} align="right">
          <MuiGrid container justifyContent="flex-end" alignItems="center">
            {isUserRequestsPage && (
              <MuiTooltip title="Confirm">
                <div>
                  <IconButton onClick={handleConfirmUserRequest}>
                    <Icon path={mdiCheckCircleOutline} size={1} />
                  </IconButton>
                </div>
              </MuiTooltip>
            )}
            <MuiTooltip title="Delete">
              <div>
                <IconButton onClick={handleOpenDeleteModal}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </div>
            </MuiTooltip>
          </MuiGrid>
        </MuiTableCell>
      </MuiTableRow>
    </>
  );
};

export default UserRow;

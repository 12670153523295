import { alpha, makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

import { LAYOUT_DIMENSIONS } from 'src/utils/constants/app';

const { header } = LAYOUT_DIMENSIONS;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    right: 0,
    top: header.height.desktop,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 330,
    },
  },

  container: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 1px 11px ${alpha(theme.palette.common.black, 0.16)}`,
  },

  content: {
    padding: 15,
    display: 'flex',
  },

  close: {
    padding: 0,
    marginLeft: 15,
  },

  additionalMessage: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    paddingLeft: 15,
    paddingRight: 20,
    paddingBottom: 5,
    marginTop: -10,
  },
}));

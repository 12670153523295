import { ORDER } from 'src/utils/constants/table';

import { HeadCell } from './typings';

export const MEDIUM_AMOUNT_ROWS_PER_PAGE = 20;
export const LARGE_AMOUNT_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_OPTIONS = [
  MEDIUM_AMOUNT_ROWS_PER_PAGE,
  LARGE_AMOUNT_ROWS_PER_PAGE,
];

export const DEFAULT_ORDER = ORDER.asc;
export const DEFAULT_ORDERBY = 'status';
export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = MEDIUM_AMOUNT_ROWS_PER_PAGE;
export const DEFAULT_EMPTY_ROWS = 5;
export const DEFAULT_ROW_HEIGHT = 71;

export const headCells: HeadCell[] = [
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    sortable: true,
  },
  {
    id: 'studyName',
    numeric: false,
    label: 'Study Name',
    sortable: true,
  },
  {
    id: 'created_at',
    numeric: false,
    label: 'Submission Date',
    sortable: true,
  },
  {
    id: 'organizationName',
    numeric: false,
    label: 'Organization',
    sortable: true,
  },
  {
    id: 'sponsor',
    numeric: false,
    label: 'Contact',
    sortable: true,
    align: 'center',
  },
  {
    id: 'projectId',
    numeric: true,
    label: 'Project ID',
    sortable: true,
  },
  {
    id: 'totalSampleNumber',
    numeric: true,
    label: 'Sample Number',
    sortable: true,
  },
  {
    id: 'poNumber',
    numeric: true,
    label: 'PO Number',
    sortable: true,
  },
  {
    id: 'sampleTypes',
    numeric: true,
    label: 'Analyte',
    sortable: true,
  },
  {
    id: 'formUrl',
    numeric: false,
    label: '',
    sortable: false,
  },
  {
    id: 'sampleReagentsManifestLink',
    numeric: false,
    label: '',
    sortable: false,
  },
];

export const TABLE_COLUMNS_COUNT = headCells.length;

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Accept, useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { SnackbarKey } from 'notistack';

import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTypography from '@material-ui/core/Typography';
import MuiIconClose from '@material-ui/icons/Close';

import { formatBytes } from 'src/utils/helpers/formatters';
import { uploadToBlobStorage } from 'src/utils/helpers/blobStorage';
import { getFileName } from 'src/utils/StringHelper';
import { DROPZONE_ACCEPT_CSV_SCHEMA } from 'src/utils/constants/file';
import { UPLOAD_SOURCE } from 'src/utils/constants/uploadSource';
import { SNACKBAR } from 'src/utils/constants/app';
import { CustomFile } from 'src/components/File';
import { useSnackbar } from 'src/utils/hooks';

import UploadedFilesList from './components/UploadedFilesList';
import {
  DEFAUL_MAX_FILE_SIZE,
  DEFAUL_MAX_FILES_COUNT,
  ERROR_MESSAGE_PREFIX,
} from './constants';
import { useStyles } from './styles';

interface Props {
  accept?: Accept;
  maxFilesCount?: number;
  maxFileSize?: number;
  maxFilesSizePerUpload?: number;
  maxFilesCountPerUpload?: number;
  hasError?: boolean;
  isEditMode?: boolean;
  uploadedFiles?: CustomFile[];
  withSuccessfulUploadNotification?: boolean;
  onUpload?: (files: string[]) => void;
  onCustomUpload?: (files: File[]) => Promise<void>;
  onRemove: (file: string) => Promise<void>;
  onFilePublish?: (path: string) => Promise<void>;
  onRequireApproval?: (path: string) => Promise<void>;
}

const FilesUploader: FC<Props> = ({
  accept = DROPZONE_ACCEPT_CSV_SCHEMA,
  maxFilesCount = DEFAUL_MAX_FILES_COUNT,
  maxFileSize: defaultMaxFileSize,
  maxFilesSizePerUpload,
  maxFilesCountPerUpload,
  hasError = false,
  isEditMode = false,
  uploadedFiles = [],
  withSuccessfulUploadNotification = false,
  onUpload,
  onRemove,
  onFilePublish,
  onRequireApproval,
  onCustomUpload,
}) => {
  const classes = useStyles();

  const maxFileSize =
    defaultMaxFileSize || maxFilesSizePerUpload || DEFAUL_MAX_FILE_SIZE;

  const { enqueueSnackbar, enqueueErrorSnackbar, closeSnackbar } =
    useSnackbar();

  const [inProgress, setInProgress] = useState(false);
  const [snackbarKeys, setSnackbarKeys] = useState<SnackbarKey[]>([]);

  const readableMaxFileSize = useMemo(() => {
    return formatBytes(maxFileSize || 0);
  }, [maxFileSize]);

  const readableMaxFilesSumSize = useMemo(() => {
    return formatBytes(maxFilesSizePerUpload || 0);
  }, [maxFilesSizePerUpload]);

  useEffect(() => {
    return () => {
      for (const key of snackbarKeys) {
        closeSnackbar(key);
      }
    };
  }, [snackbarKeys, closeSnackbar]);

  const uploadedFilesCount = useMemo(
    () => uploadedFiles.length,
    [uploadedFiles]
  );

  const snackbarActions = useCallback(
    (key: SnackbarKey) => (
      <MuiIconButton
        size="small"
        color="inherit"
        onClick={() => {
          closeSnackbar(key);
          setSnackbarKeys((keys) => keys.filter((k) => k !== key));
        }}
      >
        <MuiIconClose fontSize="medium" color="inherit" />
      </MuiIconButton>
    ),
    [closeSnackbar]
  );

  const addNewErrorSnackbar = useCallback(
    (message: string) => {
      const snackbarKey = enqueueSnackbar(
        `${ERROR_MESSAGE_PREFIX}: ${message}`,
        {
          ...SNACKBAR.defaultOptions,
          variant: 'error',
          persist: true,
          action: snackbarActions,
        }
      );
      setSnackbarKeys((snackbarKeys) => [...snackbarKeys, snackbarKey]);
    },
    [enqueueSnackbar, snackbarActions]
  );

  const addNewSuccessSnackbar = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        ...SNACKBAR.defaultOptions,
        variant: 'success',
        persist: false,
        resumeHideDuration: SNACKBAR.resumeHideDuration.success,
      });
    },
    [enqueueSnackbar]
  );

  const handleFilesUpload = useCallback(
    async (files: File[]) => {
      setInProgress(true);

      try {
        const uploadedFiles = [];

        for (const file of files) {
          const result = await uploadToBlobStorage(
            file,
            UPLOAD_SOURCE.formSubmission
          );
          uploadedFiles.push(result);
        }

        if (onUpload && uploadedFiles.length > 0) {
          onUpload(uploadedFiles);
        }
      } catch (error) {
        console.error(error.message);
        enqueueErrorSnackbar({ message: error.message });
      } finally {
        setInProgress(false);
      }
    },
    [onUpload, enqueueErrorSnackbar]
  );

  const handleDropAllFiles = async (droppedFiles: File[]) => {
    const duplications: string[] = [];

    const acceptableFiles = droppedFiles.filter((file) => {
      const duplicatedFile = uploadedFiles.find(
        (f) => getFileName(f.key) === file.name
      );

      if (duplicatedFile) {
        duplications.push(getFileName(duplicatedFile.key));
      }

      return !duplicatedFile;
    });

    const acceptableFilesCount = acceptableFiles.length;

    if (duplications.length > 0) {
      addNewErrorSnackbar(`${duplications.join(', ')} already exist(s)`);
    }

    const totalSizeOfDroppedFiles = acceptableFiles.reduce(
      (totalSize, nextFile) => totalSize + nextFile.size,
      0
    );

    if (uploadedFilesCount + acceptableFilesCount > maxFilesCount) {
      addNewErrorSnackbar(`${maxFilesCount} files max`);
    } else if (
      maxFilesSizePerUpload &&
      totalSizeOfDroppedFiles > maxFilesSizePerUpload
    ) {
      addNewErrorSnackbar(`only up to ${readableMaxFilesSumSize} per upload`);
    } else if (
      maxFilesCountPerUpload &&
      acceptableFilesCount > maxFilesCountPerUpload
    ) {
      addNewErrorSnackbar(
        `only up to ${maxFilesCountPerUpload} file(s) per upload`
      );
    } else if (acceptableFilesCount > 0) {
      if (onCustomUpload) {
        setInProgress(true);
        await onCustomUpload(acceptableFiles);
        setInProgress(false);

        if (withSuccessfulUploadNotification) {
          addNewSuccessSnackbar(
            `${acceptableFilesCount} file(s) uploded succesfully`
          );
        }
      } else {
        handleFilesUpload(droppedFiles);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isFocused,
    isFileDialogActive,
  } = useDropzone({
    multiple: true,
    useFsAccessApi: false,
    accept,
    maxSize: maxFileSize,
    maxFiles: maxFilesCount,
    onDrop: handleDropAllFiles,
  });

  const isMaxFilesError = useMemo(() => {
    return !!fileRejections.find((f) =>
      f.errors.find((e) => e.code === 'too-many-files')
    );
  }, [fileRejections]);

  const filesWithMaxSizeError = useMemo(() => {
    return fileRejections.filter((f) =>
      f.errors.find((e) => e.code === 'file-too-large')
    );
  }, [fileRejections]);

  const filesWithInvalidTypeError = useMemo(() => {
    return fileRejections.filter((f) =>
      f.errors.find((e) => e.code === 'file-invalid-type')
    );
  }, [fileRejections]);

  useEffect(() => {
    if (isMaxFilesError) {
      addNewErrorSnackbar(`${maxFilesCount} files max`);
    }
  }, [isMaxFilesError, maxFilesCount, addNewErrorSnackbar]);

  useEffect(() => {
    if (filesWithMaxSizeError.length > 0) {
      addNewErrorSnackbar(`${readableMaxFileSize} limit per file`);
    }
  }, [filesWithMaxSizeError, readableMaxFileSize, addNewErrorSnackbar]);

  useEffect(() => {
    if (filesWithInvalidTypeError.length > 0) {
      addNewErrorSnackbar('file type is not allowed');
    }
  }, [filesWithInvalidTypeError, addNewErrorSnackbar]);

  return (
    <div>
      {isEditMode && uploadedFilesCount < maxFilesCount && (
        <div
          {...getRootProps({
            className: cx(classes.dropzone, {
              isFocused,
              isFileDialogActive,
              hasError,
            }),
          })}
        >
          <input {...getInputProps()} />
          {inProgress && <MuiCircularProgress size={30} thickness={3} />}
          {!inProgress && (
            <MuiTypography component="span">
              Drag & Drop files or Browse
            </MuiTypography>
          )}
        </div>
      )}
      <UploadedFilesList
        files={uploadedFiles}
        isEditMode={isEditMode}
        onRemove={onRemove}
        onFilePublish={onFilePublish}
        onRequireApproval={onRequireApproval}
      />
      {/* TODO: we should show file upload restrictions inside this component, not outside */}
    </div>
  );
};

export default FilesUploader;

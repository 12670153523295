import { ChangeEvent, FC, ReactNode, useState } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import MuiBox from '@material-ui/core/Box';

import Button from '@quanterix-ui/core/Button';
import Checkbox from '@quanterix-ui/core/Checkbox';

import { useCognito } from 'src/aws/Cognito';
import ControlledEmailTextField from 'src/components/form/ControlledEmailTextField';
import ControlledPasswordTextField, {
  PASSWORD_FIELD_NAME,
} from 'src/components/form/ControlledPasswordTextField';
import { LOCAL_STORAGE_KEY } from 'src/utils/constants/localStorage';
import { PUBLIC_ROUTES_MAP } from 'src/router';

import { useStyles } from '../../styles';
import { TEST_ID } from '../../constants';

import { isForgotPassMessageShown } from './utils';

interface Props {
  isRefreshDisabled: boolean;
}

const SignInForm: FC<Props> = ({ isRefreshDisabled }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const rememberedUserEmail = localStorage.getItem(LOCAL_STORAGE_KEY.userEmail);

  const [rememberUserIsChecked, setRememberUserIsChecked] = useState(
    !!rememberedUserEmail
  );

  const { isLoading, signIn } = useCognito();

  const formMethods = useForm({
    defaultValues: {
      email: rememberedUserEmail || '',
      [PASSWORD_FIELD_NAME.password]: '',
    },
  });

  const { handleSubmit } = formMethods;

  const handleSignIn = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.lastActivity);

    signIn({ email, password, isRefreshDisabled });

    if (rememberUserIsChecked) {
      localStorage.setItem(LOCAL_STORAGE_KEY.userEmail, email);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY.userEmail);
    }
  };

  const handleRememberUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberUserIsChecked(event.target.checked);
  };

  return (
    <FormProvider {...formMethods}>
      <div data-testid={TEST_ID.loginPage}>
        <form autoComplete="off" onSubmit={handleSubmit(handleSignIn)}>
          <FormControl fullWidth>
            <ControlledEmailTextField required name="email" />
          </FormControl>
          <MuiBox pt={3}>
            <FormLabel
              required
              htmlFor="password"
              className={classes.formLabel}
            >
              <FormattedMessage id="page.login.password.label" />
            </FormLabel>
            <FormControl fullWidth size="small">
              <ControlledPasswordTextField
                id="password"
                placeholder={formatMessage({
                  id: 'page.login.password.placeholder',
                })}
                name={PASSWORD_FIELD_NAME.password}
              />
            </FormControl>
          </MuiBox>
          <MuiBox
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberUserIsChecked}
                  name="remember"
                  onChange={handleRememberUserChange}
                />
              }
              label={formatMessage({ id: 'page.login.remember_me' })}
            />
            <Link
              to={PUBLIC_ROUTES_MAP.forgotPassword}
              className={classes.link}
            >
              <FormattedMessage id="page.login.password_reset" />
            </Link>
          </MuiBox>
          <MuiBox
            mt={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Button
              color="secondary"
              className={classes.button}
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              <FormattedMessage id="page.login.login_button" />
            </Button>
            <MuiBox pt={2}>
              <Typography>
                <FormattedMessage
                  id="page.login.link.request_access"
                  values={{
                    link: (msg: ReactNode) => (
                      <Typography component="span" variant="body2">
                        <Link to="/request-access" className={classes.link}>
                          {msg}
                        </Link>
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </MuiBox>
          </MuiBox>
          {isForgotPassMessageShown && (
            <p>
              <Typography
                variant="caption"
                color="secondary"
                className={classes.forgotPassMessage}
              >
                We updated the Customer Portal on October 19, 2020. If you have
                an existing portal account and have not logged in since then,
                you must reset your password in order to login. Select "Forgot
                Password" above. If you do not receive an email containing login
                instructions within 30 minutes, please click Register at the top
                of the page
              </Typography>
            </p>
          )}
        </form>
      </div>
    </FormProvider>
  );
};

export default SignInForm;

import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';

import ControlledTextField from '@quanterix-ui/core/ControlledTextField';
import Button from '@quanterix-ui/core/Button';

import {
  ContentHubFileSchema,
  useUpdateContentHubPageFile,
} from 'src/api/endpoints/contentHub';

import { useStyles } from './styles';
import { FileFormValues } from './typings';
import {
  FILE_NAME_MAX_LENGTH,
  FILE_NAME_PATTERN,
  FILE_NAME_RESTRICTED_SYMBOLS,
  TEST_ID,
} from './constants';

interface Props {
  file: ContentHubFileSchema;
  onClose: () => void;
}

const FileCardEditableContent: FC<Props> = ({ file, onClose }) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const { pageId } = useParams<{ pageId: string }>();

  const formMethods = useForm<FileFormValues>({
    defaultValues: { fileName: file.name },
  });
  const {
    handleSubmit,
    resetField,
    formState: { errors, isDirty },
  } = formMethods;

  const { mutateAsync: updatePageFile, isLoading: isUpdating } =
    useUpdateContentHubPageFile(Number(pageId));

  const handleCancelEditForm = (defaultValue?: string) => {
    resetField('fileName', { defaultValue });
    onClose();
  };

  const handleFileUpdate = async ({ fileName }: FileFormValues) => {
    try {
      await updatePageFile({ id: file.id, name: fileName });

      handleCancelEditForm(fileName);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <MuiBox flex={1} className={classes.root}>
        <form onSubmit={handleSubmit(handleFileUpdate)}>
          <MuiBox height={67}>
            <ControlledTextField
              fullWidth
              name="fileName"
              placeholder={formatMessage({
                id: 'page.content_management.input.placeholder.title',
              })}
              data-testid={TEST_ID.fileTextField}
              rules={{
                required: formatMessage({
                  id: 'page.content_management.input.validation.title.required',
                }),
                maxLength: {
                  value: FILE_NAME_MAX_LENGTH,
                  message: formatMessage(
                    {
                      id: 'page.content_management.input.validation.title.max_length',
                    },
                    { maxLength: FILE_NAME_MAX_LENGTH }
                  ),
                },
                pattern: {
                  value: FILE_NAME_PATTERN,
                  message: formatMessage(
                    {
                      id: 'page.content_management.input.validation.title.not_valid',
                    },
                    {
                      symbols: FILE_NAME_RESTRICTED_SYMBOLS.join(' '),
                    }
                  ),
                },
              }}
              helperText={errors.fileName?.message}
            />
          </MuiBox>
          <MuiGrid container justifyContent="space-between">
            <Button
              variant="outlined"
              disabled={isUpdating}
              data-testid={TEST_ID.fileCancelButton}
              onClick={() => handleCancelEditForm()}
            >
              <FormattedMessage id="app.button.cancel" />
            </Button>
            <Button
              type="submit"
              disabled={!isDirty || isUpdating}
              loading={isUpdating}
              data-testid={TEST_ID.fileSaveButton}
            >
              <FormattedMessage id="app.button.publish" />
            </Button>
          </MuiGrid>
        </form>
      </MuiBox>
    </FormProvider>
  );
};

export default FileCardEditableContent;

import { createContext, useContext } from 'react';

import { AxiosResponse } from 'axios';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import { privateGateway } from 'src/api/config';
import { ServiceConfigData } from 'src/micro-frontend/typings/common';

class ServicesStore {
  isLoaded = false;
  isLoading = false;

  groups: string[] = [];
  readonly services = observable<ServiceConfigData>([]);

  constructor() {
    makeAutoObservable(this, {
      isLoaded: observable,
      isLoading: observable,
      groups: observable,
      services: observable,
      availableServices: computed,
      setGroups: action,
      setServices: action,
      setIsLoading: action,
      setIsLoaded: action,
    });
  }

  get availableServices() {
    return this.services.slice();
  }

  setGroups = (groups: string[]) => {
    this.groups = groups;
  };

  setServices = (services: ServiceConfigData[]) => {
    this.services.replace(services);
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setIsLoaded = (isLoaded: boolean) => {
    this.isLoaded = isLoaded;
  };

  fetchServices = async () => {
    this.setIsLoading(true);

    const appIds = this._getServicesIds();

    const promises: Promise<AxiosResponse<ServiceConfigData>>[] = [];
    const localIds: string[] = [];
    const apps: ServiceConfigData[] = [];

    const date = new Date().getTime();

    const hostStack = window.location.pathname
      .split('/')
      .filter((item) => item.length > 0)[1];

    appIds.forEach(async (id) => {
      const appInfo = id.split(':');
      const appDomain = appInfo[0];
      const appStack = appInfo[1];
      const productName = appInfo.length > 2 ? appInfo[2] : '';

      if (hostStack === appStack) {
        const host = (process.env.REACT_APP_MODULE_LINK_PATTERN as string)
          .replace('{module}', appStack)
          .replace('{customer}', appDomain);

        if (productName && productName.length > 0) {
          const moduleUrl = `/modules/${productName}/module.json?date=${date}`;

          localIds.push(id);
          promises.push(privateGateway(host).get(moduleUrl));
        }
      }
    });

    try {
      await Promise.all(promises).then((responses) => {
        responses.forEach((appConfig, index) => {
          if (
            !!appConfig &&
            appConfig.status === 200 &&
            this._validateConfigData(appConfig.data)
          ) {
            const appInfo = localIds[index].split(':');
            appConfig.data['id'] = localIds[index];
            appConfig.data['domain'] = appInfo[0];
            appConfig.data['client'] = appInfo[1];
            appConfig.data['name'] = appInfo[2];
            appConfig.data['displayName'] = appInfo[2];
            apps.push(appConfig.data);
          }
        });

        this.setServices(apps);
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setIsLoaded(true);
      this.setIsLoading(false);
    }
  };

  protected _getServicesIds = (): string[] => {
    const appIds: string[] = [];

    this.groups.forEach((group) => {
      // Parsing cognito group name to get product
      const value = group.split(':');
      if (value.length >= 3 && value[0] === 'q') {
        value.length === 3
          ? appIds.push(`${value[1]}:${value[2]}`)
          : appIds.push(`${value[1]}:${value[2]}:${value[3]}`);
      }
    });

    return appIds;
  };

  protected _validateConfigData = (config: ServiceConfigData) => {
    return !!config && !!config.scriptPath;
  };
}

export const ServicesStoreInstance = new ServicesStore();

export const ServicesStoreContext = createContext<ServicesStore>(
  ServicesStoreInstance
);

export const ServicesStoreProvider = ServicesStoreContext.Provider;

export const useServicesStore = () => {
  const context = useContext(ServicesStoreContext);

  if (!context) {
    throw new Error(
      'useServicesStore must be used within a ServicesStoreProvider'
    );
  }

  return context;
};

export default ServicesStore;

import { FC, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';

import { PROTECTED_ROUTES_MAP } from 'src/router';
import { ExternalService } from 'src/aws/Cognito';

import { TEST_ID } from '../../constants';
import NavCard from '../NavCard';

import { useStyles } from './styles';

const Services: FC = () => {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const adminServices = useMemo<ExternalService[]>(
    () => [
      {
        title: formatMessage({
          id: 'page.dashboard.admin_services.content_management.title',
        }),
        description: formatMessage({
          id: 'page.dashboard.admin_services.content_management.description',
        }),
        footerLinkText: formatMessage({
          id: 'app.link.manage',
        }),
        pathname: PROTECTED_ROUTES_MAP.adminContentManagement,
      },
      {
        title: formatMessage({
          id: 'page.dashboard.admin_services.user_management.title',
        }),
        description: formatMessage({
          id: 'page.dashboard.admin_services.user_management.description',
        }),
        footerLinkText: formatMessage({
          id: 'app.link.manage',
        }),
        pathname: PROTECTED_ROUTES_MAP.adminRegisteredUsers,
      },
      {
        title: formatMessage({
          id: 'page.dashboard.admin_services.faq_management.title',
        }),
        description: formatMessage({
          id: 'page.dashboard.admin_services.faq_management.description',
        }),
        footerLinkText: formatMessage({
          id: 'app.link.manage',
        }),
        pathname: PROTECTED_ROUTES_MAP.adminFAQManagement,
      },
    ],
    [formatMessage]
  );

  return (
    <MuiBox data-testid={TEST_ID.adminServices}>
      <MuiGrid container>
        <MuiGrid item xs={12}>
          <MuiBox mb={3}>
            <MuiTypography variant="subtitle1">
              <FormattedMessage id="page.dashboard.admin_services.title" />
            </MuiTypography>
          </MuiBox>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiBox className={classes.gridContainer}>
            {adminServices.map((service, index) => (
              <NavCard
                key={service.title}
                index={index}
                title={service.title}
                description={service.description}
                footerLinkText={service.footerLinkText}
                url={service.pathname}
              />
            ))}
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

export default Services;

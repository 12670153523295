import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: theme.fonts.fontFamily.bold,
    fontWeight: 900,
    fontSize: 30,
    marginBottom: 25,
  },
}));

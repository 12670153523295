import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { getDownloadUrl } from 'src/utils/helpers/blobStorage';
import { useNotFoundContext } from 'src/context/NotFoundContext';

const Download: FC = () => {
  const [fileLink, setFileLink] = useState('');
  const [fileName, setFileName] = useState('');

  const { setPageExists } = useNotFoundContext();

  const params = useParams<{ file?: string }>();

  useEffect(() => {
    const getFile = async () => {
      let { file } = params;

      if (!file) {
        setPageExists(false);
      } else {
        try {
          file = decodeURIComponent(atob(file));
        } catch (error) {
          setPageExists(false);
        }

        const fileName = file.replace(/^.*[\\/]/, '');

        setFileName(fileName);

        try {
          await getDownloadUrl(file).then((link) => {
            setFileLink(link);

            const a = document.createElement('a');
            a.setAttribute('href', link);
            a.setAttribute('target', '__blank');
            a.setAttribute('download', fileName);

            const container = document.getElementById('link_holder');
            container?.appendChild(a);

            a.click();
          });
        } catch (error) {
          setPageExists(false);
        }
      }
    };
    getFile();
  }, [params, setPageExists]);

  return (
    <div>
      <div className="folderHeader">Download file</div>
      <h4>{fileName}</h4>
      <Typography variant="h5" component="p" id="link_holder">
        <a target="__blank" href={fileLink}>
          If your download doesn't start immediately, click here to download.
        </a>
      </Typography>
    </div>
  );
};

export default Download;

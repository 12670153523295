import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import MuiGrid from '@material-ui/core/Grid';
import MuiBox from '@material-ui/core/Box';
import MuiContainer from '@material-ui/core/Container';

import AppHeader from 'src/components/AppHeader';
import AppFooter from 'src/components/AppFooter';
import ActivityTracker from 'src/components/ActivityTracker';
import CookieBanner from 'src/components/CookieBanner';
import { PRIVATE_ROUTES_MAP, PROTECTED_ROUTES_MAP } from 'src/router';
import { useOverlayScrollbarsContext } from 'src/context/OverlayScrollbarsContext';

import { TEST_ID } from './constants';
import { useStyles } from './styles';

const PrivateLayout: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const osRef = useRef<OverlayScrollbarsComponent | null>(null);

  const { setOsInstance } = useOverlayScrollbarsContext();

  useEffect(() => {
    setOsInstance(osRef?.current?.osInstance() || null);
  }, [setOsInstance]);

  const isProfilePage = location.pathname.includes(PRIVATE_ROUTES_MAP.profile);
  const isRegistredUserViewerPage = location.pathname.startsWith(
    PROTECTED_ROUTES_MAP.adminRegisteredUserProfile.replace(':username', '')
  );
  const isUserRequestViewerPage = location.pathname.startsWith(
    PROTECTED_ROUTES_MAP.adminUserRequestProfile.replace(':requestId', '')
  );
  const isUserViewerPage =
    isProfilePage || isRegistredUserViewerPage || isUserRequestViewerPage;

  const isContentHubPage = location.pathname === PRIVATE_ROUTES_MAP.contentHub;

  const isMicroFEPage = location.pathname.startsWith('/modules/');

  const classes = useStyles({
    isContentHubPage,
    isUserViewerPage,
    isMicroFEPage,
  });

  return (
    <MuiGrid
      container
      className={classes.root}
      data-testid={TEST_ID.layoutContainer}
    >
      <MuiGrid item xs={12} className={classes.header}>
        <AppHeader isPublic={false} />
        <ActivityTracker />
      </MuiGrid>
      <MuiGrid item xs={12}>
        <MuiGrid container>
          <MuiBox flex={1}>
            <OverlayScrollbarsComponent
              ref={osRef}
              options={{
                scrollbars: { autoHide: 'move' },
                sizeAutoCapable: false,
              }}
              className={classes.scrollbars}
            >
              <MuiContainer className={classes.content}>
                <>{children}</>
              </MuiContainer>
              <AppFooter />
              <CookieBanner />
            </OverlayScrollbarsComponent>
          </MuiBox>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
export default PrivateLayout;

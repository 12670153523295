import { makeStyles } from '@material-ui/core';

import { Theme } from '@quanterix-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 80,
  },

  titleContainer: {
    flex: 1,
  },

  titleWrapper: {
    maxWidth: '75%',
  },

  title: {
    fontFamily: theme.fonts.fontFamily.bold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
